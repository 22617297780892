import BusinessObjectORM, { BusinessObject } from '@models/businessObject';
import { confirmation, prompt } from '@services/alert/alert.service';
import { invalidateBusinessObjects, useBusinessObjects } from '@stores/data.store';
import { useCallback, useMemo, useState } from 'react';
import Masonry from 'react-responsive-masonry';
import { Link } from 'react-router-dom';
import BusinessObjectForm from './BusinessObject/BusinessObjectForm.component';


const BusinessObjectsListPage = () => {
    const [loading, setLoading] = useState(false);

    const businessObjects = useBusinessObjects();
    const addNew = useCallback(() => {
        prompt({
            onConfirm: async (value: string) => {
                setLoading(true);
                const bizObj = await BusinessObjectORM.save({
                    id: null,
                    name: value,
                    fields: [],
                    description: ''
                });
            },
            onClose: () => {
                return;
            },
            promptText: 'Enter Object Name',
            header: 'New Business Object',
        })
    }, []);

    const newBusinessObject = useMemo(() => {
        return {
            id: null,
            name: '',
            description: '',
            fields: [],
        }
    }, []);

    const deleteBusinessObject = useCallback((businessObject: BusinessObject) => {
        confirmation({
            header: 'Delete business object?',
            confirmationButtonText: 'Delete',
            message: `To delete this object, please type "${businessObject.name}" in the field below.`,
            typedValueExpectation: businessObject.name,
            onClose: () => null,
            confirmationButtonVariant: 'danger',
            onConfirm: async () => {
                await BusinessObjectORM.delete(businessObject);
                invalidateBusinessObjects();
            }
        });
    }, []);
    return (
        <div className="row">
            <div className="col-3 border-right">
                <div className="content-inner">
                    <div className="section">
                        <h2>Business Objects</h2>
                        {/* <p>Copy goes here.</p> */}
                        <hr />
                        <h3>New Business Object</h3>
                        <BusinessObjectForm businessObject={newBusinessObject}/>

                    </div>
                </div>
            </div>
            <div className="col-9">
                <div className="content-inner">
                    <Masonry>
                        {businessObjects.data && businessObjects.data.map(bo => {
                            return <div className="grid-item m-3">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>{bo.name}</h3>
                                        <p className="description">{bo.description}</p>
                                        <div className="row">
                                            <div className="col-5 pe-1">
                                                <Link to={`/business-objects/${bo.id}/attributes`} className="btn btn-light w-100">Configure</Link>
                                            </div>
                                            <div className="col-4 pe-1">
                                                <Link to={`/business-objects/${bo.id}/data`} className="btn btn-light w-100">Data</Link>
                                            </div>
                                            <div className="col-3 ps-1">
                                                <button onClick={() => deleteBusinessObject(bo)} className="btn btn-light w-100">
                                                    <i className="mdi mdi-delete"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                        })}
                    </Masonry>
                </div>
            </div>
        </div>
       
       
    );
}

export default BusinessObjectsListPage;