import { BusinessObjectField } from "@models/businessObject";
import { Source, SourceRecordType } from "@models/source";
import { saveBusinessObject, useBusinessObjects, useSourceRecordTypes, useSources, useStandardizationPipelines } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import Select from 'react-select';
import { prompt } from '@services/alert/alert.service';
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import PageTitle from "@components/pageTitle/PageTitle.component";
import {v4 as uuidv4 } from 'uuid';

interface AttributeComponentProps {
    type: string;
    description: string;
    icon?: string;
    addField?: () => void;
}

export interface FieldWithIndex {
    field: BusinessObjectField;
    originalIndex: number;
}

const ATTRIBUTE_TYPES: AttributeComponentProps[] = [
    {
        type: 'STRING',
        description: 'Stores arbitrary text of variable length',
    }, {
        type: 'INT',
        description: 'Stores a numeric value without decimals', 
    }, {
        type: 'FLOAT', 
        description: 'Stores a numeric value with decimals', 
    }, {
        type: 'BOOLEAN', 
        description: 'Stores a true/false value', 
    }, {
        type: 'DATETIME', 
        description: 'Stores a date and time value', 
    }, {
        type: 'DATE', 
        description: 'Stores a date value', 
    }
];

const ATTRIBUTE_OPTIONS = ATTRIBUTE_TYPES.map(a => {
    return {
        label: a.type,
        value: a.type,
    }
});

interface PrioritizationOption {
    source: Source;
    sourceRecordType: SourceRecordType;
}

const BusinessObjectAttributesPage = () => {
    const businessObjects = useBusinessObjects();
    const { businessObjectId } = useParams();
    const businessObject = useMemo(() => {
        if (businessObjects.data) {
            return businessObjects.data.find(bo => bo.id === businessObjectId);
        }
    }, [businessObjectId, businessObjects.dataUpdatedAt]);

    const [sortAscending, setSortAscending] = useState(true);

    const [fields, setFields] = useImmer<BusinessObjectField[]>([]);

    useEffect(() => {
        if (!!businessObject) {
            setFields(businessObject.fields.map(f => {
                return {...f}
            }));
        }
    }, [businessObject]);
    const standardizationPipelines = useStandardizationPipelines('', businessObjectId);
    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();

    const prioritizationOptions: PrioritizationOption[] = useMemo(() => {
        if (!standardizationPipelines.data || !sources.data || !sourceRecordTypes.data) {
            return [];
        }
        const options = standardizationPipelines.data.map(sp => {
            const sourceRecordType = sourceRecordTypes.data.find(srt => srt.id === sp.source_record_type_id);
            const source = sources.data.find(s => s.id === sourceRecordType?.source_id);

            if (!sourceRecordType || !source) {
                return null;
            }
            return {
                source: source,
                sourceRecordType: sourceRecordType,
            }
        });
        return options.filter(o => !!o) as PrioritizationOption[];
    }, [standardizationPipelines.dataUpdatedAt, sources.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt]);

    const removeField = useCallback((idx: number) => {
        setFields(draft => {
            draft.splice(idx, 1);
        })
    }, [fields]);


    const fieldValueChanged = useCallback((idx: number, attribute: keyof BusinessObjectField, value: any) => {
        setFields(draft => {
            draft[idx][attribute] = value;
        });
    }, [fields]);

    const addAttribute = useCallback(() => {
        prompt({
            onConfirm: (val: string) => {
                setFields(draft => {
                    // split / trim / remove blanks
                    const fields = val.split(",").map((a) => a.trim()).filter(n => n);
                    const newAttrs: BusinessObjectField[] = fields.map((fieldname) => {
                        return {
                            name: fieldname,
                            label: fieldname,
                            description: '',
                            type: 'STRING',
                            id: uuidv4(),
                        }
                    });
                    draft.unshift(...newAttrs);
                });
            },
            onClose: () => {
                return;
            },
            promptText: 'Enter new field name',
            header: 'Add New Field',
        });
    }, [fields]);

    const sortedFields: FieldWithIndex[] = useMemo(() => {
        const fieldsWithIndex = fields.map((f, idx) => {
            return {
                field: f,
                originalIndex: idx,
            }
        });
        return fieldsWithIndex.sort((a, b) => {
            let modifier = 1;
            if (!sortAscending) {
                modifier = -1;
            }

            if (!a.field.name) {
                return -1;
            }
            if (!b.field.name) {
                return 1;
            }

            if (a.field.name < b.field.name) {
                return modifier * -1;
            }

            return modifier;
        })
    }, [fields, sortAscending]);

    const [saving, setSaving] = useState(false);

    const save = useCallback(async () => {
        if (!businessObject) {
            return;
        }
        setSaving(true);
        
        try {
            const res = await saveBusinessObject({
                ...businessObject,
                fields: [...fields],
            });
            toast('success', 'Saved!', 'Attributes successfully updated.');
        } catch (err) {
            toast('danger', 'Failed to save', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
        
    }, [fields, businessObject]);



    return <div className="content-inner has-subnav">


            <PageTitle title="Attributes" subtitle="Attributes are the details you want to know about your business objects.">
                <button className="btn btn-sm btn-pliable me-1" onClick={() => addAttribute()}>
                                <i className="mdi mdi-plus-box"></i> Add Attribute
                            </button>
                            <button className="btn btn-sm btn-secondary me-1" onClick={() => setSortAscending(!sortAscending)}>
                                {sortAscending && <><i className="mdi mdi-sort-alphabetical-ascending"></i> Sorting A-Z</>}
                                {!sortAscending && <><i className="mdi mdi-sort-alphabetical-descending"></i> Sorting Z-A</>}
                            </button>
                            
                            <button className="btn btn-sm btn-success" onClick={() => save()} disabled={saving}>
                                {saving && (<Spinner size="sm"/>)}
                                
                                Save
                            </button>
            </PageTitle>
                <div className="section">
                <table className="table table-centered">
                    <thead className="table-light">
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                Data Type
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {sortedFields.map((f) => {

                            return (
                                <tr key={f.originalIndex}>
                                    <td>
                                        <Form.Control value={f.field.label} className="inline-edit font-14 mt-1 fw-bold" placeholder="Field Name" onChange={(e) =>fieldValueChanged(f.originalIndex, 'label', e.target.value)}></Form.Control>
                                       

                                    </td>
                                    <td>
                                            <Form.Control className="inline-edit font-12"value={f.field.description} as="textarea" onChange={(e) => fieldValueChanged(f.originalIndex, 'description', e.target.value)} placeholder="Describe your field"></Form.Control>

                                    </td>
                                    <td>
                                    <Select className="input-sm" options={ATTRIBUTE_OPTIONS} value={{label: f.field.type, value: f.field.type}} onChange={(e) => fieldValueChanged(f.originalIndex, 'type', e?.value as string)}/>
                                    </td>
                                    {/* <td>
                                        <Badge bg="success">100% Agreement Rate</Badge>
                                    </td> */}
                                    
                                    <td className="text-end">
                                        <a className="action-icon" role="button" onClick={() => removeField(f.originalIndex)}>
                                            <i className="mdi mdi-delete"></i>
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                
                </table>
                </div>
            
            </div>

                
       
}

export default BusinessObjectAttributesPage;