import { ColumnStats } from "@models/source";
import { integerFormatter } from "@services/formatting.service";
import { useSourceRecordType } from "@stores/data.store";
import { useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";

interface Props {
    sourceId: string;
    sourceRecordTypeId: string;
}


const SourceDataStats = ({ sourceId, sourceRecordTypeId }: Props) => {
    const [error, setError] = useState('');

    const {isLoading: loading, data: recordType} = useSourceRecordType(sourceRecordTypeId);

    const identifiers = useMemo(() => {
        return recordType?.shape?.columns.filter(s => {
            return s.density > 0.95 && s.uniqueness > 0.6
        }).sort((a: ColumnStats, b: ColumnStats) => {
            return (a.uniqueness > b.uniqueness) ? -1 : 1;
        }).slice(0, 10);
    }, [recordType?.shape]);

    const categories = useMemo(() => {
        return recordType?.shape?.columns.filter(s => {
            return s.density > 0.95 && s.uniqueness < 0.5 && s.uniqueness > 0.01
        }).sort((a: ColumnStats, b: ColumnStats) => {
            return (a.uniqueness > b.uniqueness) ? 1 : -1;
        }).slice(0, 10);
    }, [recordType?.shape]);


    if (loading) {
        return <Spinner/>;
    } else if (error) {
        return <p className="alert alert-danger">{error}</p>
    } else if (!recordType?.shape?.columns.length) {
        return <p className="alert alert-danger">Failed to load statistics.</p>
    }
    
    return (
        <ul className="list-group">
            <li className="list-group-item">
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                        <i className="mdi mdi-information text-primary font-18"></i>
                    </div>
                    <div className="flex-grow-1">
                    There are <strong>{integerFormatter(recordType?.shape.total_records)}</strong> records in your source data.
                    </div>
                </div>
                
                
            </li>
            <li className="list-group-item">
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                        <i className="mdi mdi-information text-primary font-18"></i>
                    </div>
                    <div className="flex-grow-1">
                    Likely unique identifiers are {identifiers!.map(i => i.key).join(', ')}
                    </div>
                </div>
                
                
            </li>
            <li className="list-group-item">
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                        <i className="mdi mdi-information text-primary font-18"></i>
                    </div>
                    <div className="flex-grow-1">
                    Likely categories are {categories!.map((c: ColumnStats, idx: number) => {
                            return (
                                <>
                                    {idx !== 0 && <span>, </span>}
                                    
                                    {idx === (categories!.length - 1) && <span>and </span>}
                                    <a role="button" className="action-link">{c.key}</a>
                                    
                                </>
                            )   
                        })}. Click one on to view details.
                    </div>
                </div>
                
                
            </li>
            
        </ul>
    )
}

export default SourceDataStats;