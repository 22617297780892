import { 
    useTable,
    useSortBy, 
    useBlockLayout, 
    useResizeColumns, 
    usePagination 
} from 'react-table';
import styled from 'styled-components';
import { useMemo, MouseEvent, useEffect, ReactNode } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import LoadingCard from '@components/card/LoadingCard.component';
import { useSticky } from "react-table-sticky";

export interface Column {
    header: string;
    key: string;
    formatter?: string;
    onClick?: (value: any) => void;
}

interface Props {
    loading?: boolean;
    columns: Column[];
    data: Record<string, any>[];
    lastRowAsFooter?: boolean;
    groupByColumn?: string;
    onCellDoubleClick?: any;
    highlightRowsByIndexes?: number[];
    totalPageCount?: number;
    onShowColumnStats?: (column: string) => void;
    onFilterSortChange?: (params: FetchDataParams) => void;
}



export interface FetchDataParams {
    pageIndex: number;
    pageSize: number;
    sortBy: any;
}


interface SimpleProps {
    loading?: boolean;
    data: Record<string, any>[];
    columns?: string[];
    onColumnClick?: {
        [key: string]: (value: any) => void;
    }
    onCellDoubleClick?: any;
    highlightRowsByIndexes?: number[];
    totalPageCount?: number;
    onShowColumnStats?: (column: string) => void;
    onFilterSortChange?: (params: FetchDataParams) => void;
}

const Styles = styled.div`
height: 100%;
width: 100%;

  .table {
    display: inline-block;
    border-spacing: 0;
    font-size: 12px;
    border-width: 0px 0px 1px 0px;
    height: 100%;
    width: 100%;
    overflow: scroll;
    margin-bottom: 0px;

    .thead {
        position: sticky;
        z-index: 1;
        width: fit-content;
        top: 0;
    }


    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .tr.highlighted .td {
        --ct-table-accent-bg: rgba(255, 159, 0, 0.25) !important;
    }

    .th {
        .th-content {
            a {
                color: black;
                font-weight: 700;
                flex: 1;
            }
              
            a:hover {
                text-decoration: underline;
                color: black;
            }
    
            display: flex;
        }
        


    }

    .td, .th {
        border-right: solid 1px var(--ct-border-color);
    }

    .td {
        border-bottom: solid 1px var(--ct-border-color);

        a {
            color: var(--ct-primary);
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

    }

    .th {
        border-top: none;
    }

    .thead .tr {
        border-top: none;

        background-color: var(--bs-table-bg);
        z-index: 2;
    }

    .th,
    .td {
      margin: 0;
      padding: 3px 5px;

      ${'' /* In this example we use an absolutely position resizer,
       so this is required. */}
      position: relative;

      :last-child {
        border-right: 0;
      }

     

      .resizer {
        display: inline-block;
        background: transparent;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: transparent;
        }
      }
    }
  }
`

export const  DataTable = (props: Props) => {

    const defaultColumn = useMemo(
        () => ({
          minWidth: 30,
          width: 150,
          maxWidth: 400,
        }),
        []
      );

    const columns = useMemo(
        () => props.columns.map((col, idx) => {
            return {
                Header: col.header || `column_${idx+1}`, 
                accessor: col.header || `column_${idx+1}`,
                Cell: ({value} : any) => {
                    let innerContent: ReactNode;
                    if (typeof value === 'object') {
                        innerContent = <span>{JSON.stringify(value)}</span>;
                    }
                    if (!!value){
                        innerContent = <span>{value.toString()}</span>;
                    }
                    else {
                        innerContent = <span></span>;
                    }

                    if (!!col.onClick) {
                        return <a role="button" onClick={() => {
                            // @ts-ignore
                            col.onClick(value);
                        }}>{innerContent}</a>
                    }
                    return innerContent;
                }
            }
        }),
        [props.columns]
    );

    const data = useMemo(
        () => props.data,
        [props.data]
    );

    //const tableState = useTableState({ pageIndex: currentPage });
    const manualSortPag = useMemo(
        () => !props.totalPageCount || props.totalPageCount > 1,
        [props.totalPageCount]
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize, sortBy }
    } = useTable({ 
            columns, 
            data, 
            initialState: { pageIndex: 0, pageSize: 50 },

            // For some reason passing defaultColumn slows it the fuck down. unclear why
            // defaultColumn,
            manualSortBy: manualSortPag,
            // Tell the usePagination hook that we'll handle our own data fetching
            manualPagination: manualSortPag,
            // // This means we'll also have to provide our own pageCount.
            pageCount: props.totalPageCount || 1,
            style: {
                height: '100%',
                width: '100%',
                overflowX: 'scroll',
            }
        },
            useSortBy, 
            useBlockLayout, 
            useResizeColumns,
            usePagination,
            useSticky,
    );

    useEffect(() => {
        if(manualSortPag && props.onFilterSortChange){
            props.onFilterSortChange({pageIndex, pageSize, sortBy});
        }
    }, [manualSortPag, pageIndex, pageSize, sortBy])


    const renderHeaderMenu = (col: any, idx: number) => {
        // if(!props.onShowColumnStats){
        //     // there are no header cell menu items to list
        //     return <></>;
        // }
        
        // return (
        //     <span style={{display: 'inline-block', float: 'right'}} onClick={(e) => e.stopPropagation()}>
        //         <Dropdown className="header-menu">
        //             <Dropdown.Toggle variant="" id="dropdown-basic" size="sm" style={{padding: '0px 3px', lineHeight: '1px'}}>
        //             </Dropdown.Toggle>
        //             <Dropdown.Menu variant="dark">
        //                 { !!props.onShowColumnStats && <Dropdown.Item onClick={() => props.onShowColumnStats!(col.Header)}>Show Column Stats</Dropdown.Item> }
        //             </Dropdown.Menu>
        //         </Dropdown>
        //     </span>
        // );
        return <></>;
        
    
    }

    const renderColumnHeader = (col: any, idx: number)  => {
        return (
            <div {...col.getHeaderProps(col.getSortByToggleProps())} className="th fs-mask">
                <div className="th-content user-select-none">
                    <a role="button">{col.render('Header')}</a>
                    <div>
                        {col.isSorted
                        ? col.isSortedDesc
                            ? <i className='mdi mdi-sort-descending'></i>
                            : <i className='mdi mdi-sort-ascending'></i>
                        : ''}
                    </div>
                </div>
                
                <div
                    {...col.getResizerProps()}
                    className={`resizer ${
                    col.isResizing ? 'isResizing' : ''
                    }`}
                    // prevent resizing from tiggering sort
                    onClick={(e)=>{e.preventDefault();e.stopPropagation()}}
                />
            </div>
        );
    }

    const handleCellDoubleClick = (col: Column, row: Record<string, string>, value: any) => {
        if(props.onCellDoubleClick) {
            props.onCellDoubleClick(col, row, value)
        }
    }

    if (props.loading === true) {
        return <LoadingCard />
    }

    return (
        <div {...getTableProps()} className="table table-sm table-bordered table-striped">
            <div className="table-light thead">
                {headerGroups.map((headerGroup: any) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                        {headerGroup.headers.map((col: any, idx: number) => renderColumnHeader(col, idx))}
                    </tr>
                ))}
            </div>
            <div {...getTableBodyProps()}>
                {rows.map((row: any, idx: number) => {
                    prepareRow(row)
                    const extraClass = (props.highlightRowsByIndexes && props.highlightRowsByIndexes.includes(idx)) ? 'highlighted' : '';
                    return (
                        <div {...row.getRowProps()} className={`tr ${extraClass}`}>
                            {row.cells.map((cell: any) => {
                                return (
                                <div
                                    {...cell.getCellProps()}
                                    // onDoubleClick={() => handleCellDoubleClick(c, row, value)}
                                    title={cell.value}
                                    className="td fs-mask text-truncate cell-with-actions">
                                    <>
                                        {!!props.onCellDoubleClick && (
                                            <div className="cell-with-actions">
                                                <span className="cell-value">
                                                    {cell.render('Cell')}
                                                </span>
                                                
                                                <div className="cell-actions">
                                                    <i className="mdi mdi-lead-pencil"></i>
                                                </div>
                                            </div>
                                        )}
                                        {!props.onCellDoubleClick && (
                                            <span>{cell.render('Cell')}</span>
                                        )}
                                    </>
                                </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>

            {/* { !!props.totalPageCount && props.totalPageCount > 1 &&
                <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                    </button>{' '}
                    <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                    </button>{' '}
                    <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                    >
                    {[25, 100, 1000, 10000].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                        </option>
                    ))}
                    </select>
                </div> 
            } */}
        </div>
        
    );
}

export default DataTable;


export function SimpleDataTable({ loading, data, columns, onCellDoubleClick, onShowColumnStats, onFilterSortChange, totalPageCount, onColumnClick, highlightRowsByIndexes }: SimpleProps) {
    if (loading !== true && !data) {
        return <span></span>;
    }

    if (loading !== true && !data.length) {
        return <div className="col-12 text-center mt-4">
            <p style={{fontSize: '30px'}}><i className="mdi mdi-block-helper"></i></p>
            <p>No records match that query</p>
        </div>
            
    }

    let parsedColumns : Column[] = [];
    if (!!columns) {
        parsedColumns = columns.map(c => {
            return {
                header: c,
                key: c,
            }
        })
    } else if (!loading) {
        let keys = Object.keys(data[0]);

        parsedColumns = keys.map(k => {
            return {
                header: k,
                key: k,
            };
        });
    }

    if (!!onColumnClick) {
        const clickableKeys = Object.keys(onColumnClick);
        parsedColumns = parsedColumns.map(pk => {
            if (clickableKeys.includes(pk.key)) {
                pk.onClick = onColumnClick[pk.key];
            }
            return pk;
        })
    }
    
    return (
        <Styles>
            <DataTable 
                loading={loading} 
                columns={parsedColumns} 
                data={data} 
                onFilterSortChange={onFilterSortChange} 
                onCellDoubleClick={onCellDoubleClick} 
                onShowColumnStats={onShowColumnStats}
                totalPageCount={totalPageCount}
                highlightRowsByIndexes={highlightRowsByIndexes}
                />
        </Styles>
    );       
}

interface GroupedProps extends SimpleProps {
    groupBy: string;
}
export function GroupedDataTable({data, groupBy}: GroupedProps) {

}

export function tableDataFromRows(rows: string[][]) {
    const header = rows[0];
    const body = rows.slice(1);

    return body.map(b => {
        const entries = b.map((val: string, idx: number) => {
            return [header[idx], val];
        });
        return Object.fromEntries(entries);
    });
}