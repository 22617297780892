import BusinessObjectORM, { BusinessObject, BusinessObjectRelationshipORM } from '@models/businessObject';
import DataMartORM from '@models/dataMart';
import FileORM from '@models/file';
import PipelineORM from '@models/pipeline';
import { PipelineOrchestration } from '@models/pipelineOrchestration';
import SourceORM, { Source, SourceDataRelationship, SourceDataRelationshipORM, SourceRecordType, SourceRecordTypeORM, SourceType } from '@models/source';
import StandardizationPipelineORM, { StandardizationPipeline } from '@models/standardizationPipeline';
import ApiService, { ListRecordsResponse, SingleRecordResponse } from '@services/api/api.service';
import { parseDateString } from '@services/time.service';

import {
    useQuery,
    useQueryClient,
    useMutation,
    QueryClient,
} from 'react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

interface LineageEntry {
    ROOT_UUID: string;
    ROOT_SOURCE_ID: string;
    ROOT_SOURCE_RECORD_TYPE_ID: string;
}

interface RecordDetailsResponse {
    data: {
        [key: string]: any
    };
    lineage: LineageEntry[];
}

export const useRecord = (businessObjectId: string, recordUuid: string) => {
    return useQuery(['business_object_record', businessObjectId, recordUuid], async () => {
        const result = await ApiService.getInstance().request('GET', `/businessobjects/${businessObjectId}/record/${recordUuid}`) as RecordDetailsResponse;
        return result;
    }, {
        // 2 mins?
        staleTime: 60 * 1000 * 2
    })
}

export const useSourceRecord = (sourceId: string, sourceRecordTypeId: string, recordUuid: string) => {
    return useQuery(['source_record', sourceId, sourceRecordTypeId, recordUuid], async () => {
        const result = await ApiService.getInstance().request('GET', `/sources/${sourceId}/record-type/${sourceRecordTypeId}/record/${recordUuid}`) as SingleRecordResponse<any>;

        result.record._LOADED_AT = parseDateString(result.record._LOADED_AT);
        return result.record;
    }, {
        // 30 mins
        staleTime: 60 * 1000 * 30
    });
}





export const useSources = () => {
    return useQuery('sources', async () => {
        const { records } = await SourceORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const saveSource = async (source: Source) => {
    const result = await SourceORM.save(source);
    queryClient.invalidateQueries('sources');
    return result;
}

export const deleteSource = async (source: Source) => {
    const result = await SourceORM.delete(source);
    queryClient.invalidateQueries('sources');
    return result;
}

export const useBusinessObjects = () => {
    return useQuery('business_objects', async () => {
        const { records } = await BusinessObjectORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}

export const useBusinessObjectRelationships = (businessObjectId: string) => {
    return useQuery(['business_object_relationships', businessObjectId], async () => {
        const results = await BusinessObjectRelationshipORM.find({
            '$or': [
                {
                    'child_business_object_id': businessObjectId
                }, {
                    'parent_business_object_id': businessObjectId
                }
            ]
        });
        return results.records;
    }, {
        staleTime: 30 * 1000
    });
}

export const saveBusinessObject = async (businessObject: BusinessObject) => {
    const result = await BusinessObjectORM.save(businessObject);
    queryClient.invalidateQueries('business_objects');
    return result;
};

export const invalidateBusinessObjects = () => {
    queryClient.invalidateQueries('business_objects');
}

export const saveSourceRecordType = async (sourceRecordType: SourceRecordType) => {
    const result = await SourceRecordTypeORM.save(sourceRecordType);
    queryClient.invalidateQueries('source_record_types');
    return result;
};

export const useSourceDataRelationships = () => {
    return useQuery('source_data_relationships', async () => {
        const { records } = await SourceDataRelationshipORM.find();
        return records;
    }, {
        staleTime: 30 * 1000
    });
};

export const invalidateSourceRecordTypes = () => {
    queryClient.invalidateQueries('source_record_types');
}


export const saveSourceDataRelationship = async (rel: SourceDataRelationship) => {
    const result = await SourceDataRelationshipORM.save(rel);
    queryClient.invalidateQueries('source_data_relationships');
    return result;
}

export const deleteSourceDataRelationship = async (rel: SourceDataRelationship) => {
    const result = await SourceDataRelationshipORM.delete(rel);
    queryClient.invalidateQueries('source_data_relationships');
    return result;
}

export const useSourceRecordTypes = (sourceId: string = '') => {
    return useQuery(['source_record_types', sourceId], async () => {
        let filter = {}
        if (!!sourceId) {
            filter = {'source_id': sourceId}
        }
        const { records } = await SourceRecordTypeORM.find(filter);
        return records;
    }, {
        staleTime: 30 * 1000,  // 30 seconds stale time
    })
}

export const useSourceRecordType = (sourceRecordTypeId: string) => {
    return useQuery(['source_record_type', sourceRecordTypeId], async () => {
        return  await SourceRecordTypeORM.findById(sourceRecordTypeId);
    }, {
        staleTime: 30 * 1000,  // 30 seconds stale time
    })
}

export const usePipelines = () => {
    return useQuery('pipelines', async () => {
        const { records } = await PipelineORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}


export const usePipelineOrchestration = (pipelineOrchestrationId: string, refetchInterval: (data: any | undefined, query: any) => any) =>
    useQuery<PipelineOrchestration>({ queryKey: ['pipeline-orchestration', pipelineOrchestrationId], refetchInterval, queryFn: () => PipelineORM.getPipelineOrchestration(pipelineOrchestrationId)});



export const useSourceTypes = (enabled: boolean = true) =>
    useQuery<SourceType[]>({ queryKey: ['enabledSourceTypes'], queryFn: () => SourceORM.getEnabledSourceTypes(), enabled });


export const useStandardizationPipelines = (sourceRecordTypeId: string = '', businessObjectId: string = '') => {
    return useQuery(['standardizationpipelines', sourceRecordTypeId, businessObjectId], async () => {
        let filter = {}
        if (!!sourceRecordTypeId) {
            filter = {'source_record_type_id': sourceRecordTypeId}
        }

        if (!!businessObjectId) {
            filter = {'business_object_id': businessObjectId}
        }
        const { records } = await StandardizationPipelineORM.find(filter);
        return records;
    }, {
        staleTime: 30 * 1000,  // 30 seconds stale time
    })
} 



export const saveStandardizationPipeline = async (pipeline: StandardizationPipeline) => {
    const result = await StandardizationPipelineORM.save(pipeline);
    queryClient.invalidateQueries('standardizationpipelines');
    return result;
}

export const useFiles = (sourceId: string = '') => {
    return useQuery(['files', sourceId], async () => {
        let filter = {}
        if (!!sourceId) {
            filter = {'source_id': sourceId}
        }
        const { records } = await FileORM.find(filter);
        return records;
    }, {
        staleTime: 30 * 1000,  // 30 seconds stale time
    });
}

export const reloadFiles = () => {
    queryClient.invalidateQueries('files');
}

export const useDataMarts = () => {
    return useQuery('datamarts', async () => {
        const { records } = await DataMartORM.find();
        return records;
    }, {
        staleTime: 30 * 1000 // 30 seconds stale time
    });
}