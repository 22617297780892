import ORM, { BaseModel } from "./orm";

export interface EventActor extends BaseModel{
    id: string;
    label: string;
}

export interface AuditLogEntry extends BaseModel{
    transaction_id: string;
    actor: EventActor;
    action: string;
    model_class: string;
    model_id: string;
    model_name: string;
    meta: any;
    created_at: Date;
    source_id?: string;
    source_record_type_id?: string;
    record_uuid?: string;
}


const AuditLogEntryORM = new ORM<AuditLogEntry>('audit_log_entries', ['created_at']);
export default AuditLogEntryORM;
