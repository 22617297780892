import { TableExplorer } from "@components/datatable/TableExplorer.component";
import BigMetricCard from "@components/metric/BigMetricCard.component";
import ApiService, { ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { decimalFormatter, integerFormatter } from "@services/formatting.service";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { QAComponentProps } from "../shared";
import { SimpleDataTable } from '@components/datatable/DataTable.component';
import AsyncButton from "@components/button/AsyncButton.component";
import { BusinessObjectRelationship } from "@models/businessObject";

interface OrphanStats {
    relationship: BusinessObjectRelationship;
    foreign_key_name: string;
    orphan_count: number;
}


interface Stats {
    total: number;
    distinct_records: number;
    orphan_stats: OrphanStats[];
}

const OutputStepQA = (props: QAComponentProps) => {
    const [loadingStats, setLoadingStats] = useState(true);
    const [statsError, setStatsError] = useState('');
    const [loadingSamples, setLoadingSamples] = useState(true);
    const [samplesError, setSamplesError] = useState('');

    const [stats, setStats] = useState<Stats|undefined>(undefined);


    useEffect(() => {
        const loadStats = async () => {
            setLoadingStats(true);
            setStatsError('');
            try {
                const result = await ApiService.getInstance().request('GET', `/pipelines/${props.pipelineId}/executions/${props.pipelineExecutionId}/step/${props.stepIndex}/output_qa`) as SingleRecordResponse<Stats>;
                setStats(result.record);
            } catch (err) {
                setStatsError(getErrorMessage(err));
            } finally {
                setLoadingStats(false);
            }
        }

        

        loadStats();
    }, [props.pipelineExecutionId, props.pipelineId, props.stepIndex]);

    return (
        <>
            <div className="row border-bottom mb-3">
                { statsError && <div className="alert alert-danger">{statsError}</div>}
                {!statsError && stats && (
                    <>
                        <div className="col-4">
                            <BigMetricCard
                                icon="mdi mdi-pound-box"
                                title="Rows in Output"
                                loading={loadingStats}
                                backgroundColor="dark"
                                metric={!!stats ? integerFormatter(stats.total) : undefined}
                                    
                            />
                        </div>
                        <div className="col-4">
                            <BigMetricCard
                                icon="mdi mdi-group"
                                title="Distinct Records"
                                loading={loadingStats}
                                backgroundColor={stats.total > stats.distinct_records ? 'danger': 'success'}
                                metric={!!stats ? integerFormatter(stats.distinct_records) : undefined}
                                    
                            />
                        </div>
                        <div className="col-4">
                            <BigMetricCard
                                icon="mdi mdi-set-all"
                                title="Duplication Rate"
                                loading={loadingStats}
                                backgroundColor="dark"
                                metric={!!stats ? (decimalFormatter(stats.total/ stats.distinct_records) + 'x') : undefined}
                                    
                            />
                        </div>
                    </>
                    
                )}
                
            </div>
            {stats && (
                <div className="row">
                    <div className="col-12">
                        {stats.distinct_records < stats.total && (
                            <p className="alert alert-warning">
                                If your duplication rate is above 1x, that means that some records in your dataset are related to more than one record in the related dataset. You can (a) fix the related dataset by deduplicating, or (b) change this to a many-to-many relationship.
                            </p>
                        )}

                        {/* <h5>Stats by Relationship</h5>
                        <ul className="list-group">
                            {stats.orphan_stats.map(s => {
                                return <li className="list-group-item">
                                    <h6>{s.foreign_key_name}</h6>
                                    Orphans: {s.orphan_count} 
                                </li>
                            })}
                        </ul> */}
                    </div>
                </div>
            )}
            
            
        </>
        
    )
}

export default OutputStepQA;