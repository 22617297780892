import { deleteSourceDataRelationship, useSourceDataRelationships, useSourceRecordTypes, useSourceTypes, useSources } from "@stores/data.store";
import { Dropdown, Form, Offcanvas, ProgressBar, Spinner } from "react-bootstrap";
import './datasources.css';
import { Source, SourceDataRelationship, SourceDataRelationshipRef, SourceRecordType, SourceType } from "@models/source";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { decimalFormatter, integerFormatter, summarizeNumber } from "@services/formatting.service";
import { useImmer } from "use-immer";
import Select from 'react-select';
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import ConnectDataSources from "./ConnectDataSources.component";
import SourceDataRelationships from "./SourceDataRelationships.component";
import toast from "@services/toast.service";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import PageTitle from "@components/pageTitle/PageTitle.component";
import FeatureFlagWrapper from "@components/featureflags/FeatureFlagWrapper.component";


interface RelationshipRef {
    relationship: SourceDataRelationship;
    thisRef: SourceDataRelationshipRef;
}



interface DataSourceBoxProps {
    source: Source;
    sourceRecordTypes: SourceRecordType[]; 
    sourceType?: SourceType;
    selectedRecordTypes: string[];
    relationships?: {
        [key: string]: RelationshipRef[];
    };
    onSelect: (srtId: string) => void;
    onShowConnections: (srtId: string) => void;
}
const DataSourceBox = (props: DataSourceBoxProps) => {
    return <div className="mb-3 border datasource-box">
        <div className="box-header p-2">
            <div className="left-side">
            <img src={props.sourceType?.icon_path} className="rounded-circle avatar-sm img-thumbnail"/>
            </div>
            <div className="right-side font-poppins">
                <Link to={`/sources/${props.source.id}`}>{props.source.name}</Link>

            </div>
        </div>
        <ul className="datasets">
            {props.sourceRecordTypes.map(srt => {
                return <li className="user-select-none">
                    <div className="d-flex">
                        <FeatureFlagWrapper requiredFlags={['enable_relationships']}>
                            <div className="me-2">
                            <Form.Check
                                type="checkbox"
                                checked={props.selectedRecordTypes.includes(srt.id as string)}
                                onChange={(e) => props.onSelect(srt.id as string)}
                            />
                            </div>
                        </FeatureFlagWrapper>
                        
                        <div className="flex-fill">
                            <Link to={`/sources/${props.source.id}/data/${srt.id}`}><strong>{srt.name}</strong></Link>
                        </div>
                        <div>
                            {srt.shape && (
                                <span className="float-end">
                                    {summarizeNumber(srt.shape?.total_records)}
                                </span>
                            )}
                        </div>
                        <div>
                            <button className={`link-node ms-2 ${props.relationships?.hasOwnProperty(srt.id as string) ? 'active': ''}`} onClick={() => props.onShowConnections(srt.id as string)}>
                                <i className="mdi mdi-link"></i>
                            </button>
                        </div>
                    </div>
                    {/* <button className={`float-end link-node ms-2 ${props.selectedRecordTypes.includes(srt.id as string) ? 'selected': ''}`} onClick={() => props.onSelect(srt.id as string)}>
                        <i className="mdi mdi-plus"></i>
                    </button> */}
                    
                    
                    
                </li>
            })}
        </ul>
        
        
    </div>
}


const DataSourcesPage = () => {
    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();
    const sourceTypes = useSourceTypes();

    const [selectedRecordTypes, setSelectedRecordTypes] = useImmer<string[]>([]);

    const [showConnectionConfig, setShowConnectionConfig] = useState(false);
    const [activeConnectionSRT, setActiveConnectionSRT] = useState<SourceRecordType|undefined>(undefined);
    const [activeRelationship, setActiveRelationship] = useState<SourceDataRelationship|undefined>(undefined);

    const selectRecordType = useCallback((srtId: string) => {
        setSelectedRecordTypes(draft => {
            const idx = draft.indexOf(srtId);
            if (idx >= 0) {
                draft.splice(idx, 1);
            } else {
                draft.push(srtId);
            }
        })
    }, []);

    const relationships = useSourceDataRelationships();

    const relationshipsBySource = useMemo(() => {
        if (!relationships.data || !sourceRecordTypes.data) {
            return {};
        }

        const rv: {
            [key: string]: {
                [key: string]: RelationshipRef[];
            }
        } = {};

        relationships.data.forEach(r => {
            r.references.forEach(ref => {
                if (!rv.hasOwnProperty(ref.source_id)) {
                    rv[ref.source_id] = {};
                }
                if (!rv[ref.source_id].hasOwnProperty(ref.source_record_type_id)) {
                    rv[ref.source_id][ref.source_record_type_id] = [];
                }
                rv[ref.source_id][ref.source_record_type_id].push({
                    relationship: r,
                    thisRef: ref,
                });
            });
        });

        return rv;
    }, [relationships.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt]);

    const showConnections = useCallback((srtId: string) => {
        console.log('IN here');
        if (!sourceRecordTypes.data) {
            return;
        }

        const srt = sourceRecordTypes.data.find(srt => srt.id === srtId);
        if (!srt) {
            return;
        }

        console.log('Showing connections');
        setActiveConnectionSRT(srt);
        setShowConnectionConfig(true);
        setActiveRelationship(undefined);
    }, [relationshipsBySource, sourceRecordTypes.dataUpdatedAt]);

    const connectNewDataSources = useCallback(() => {
        setShowConnectionConfig(true);
        setActiveConnectionSRT(undefined);
        setActiveRelationship(undefined);
    }, []);

    const configureRelationship = useCallback((rel: SourceDataRelationship) => {
        setActiveConnectionSRT(undefined);
        setShowConnectionConfig(true);
        setActiveRelationship(rel);
    }, []);

    const removeRelationship = useCallback(async (rel: SourceDataRelationship) => {
        const res = await deleteSourceDataRelationship(rel);
        setActiveConnectionSRT(undefined);
        setActiveRelationship(undefined);
        setShowConnectionConfig(false);
        setSelectedRecordTypes([]);
        toast('success', 'Success', 'Connection removed');
        
    }, []);


    if (sources.isLoading || sourceRecordTypes.isLoading || sourceTypes.isLoading) {
        return <Spinner/>;
    }

    return <>
        <Offcanvas placement="end" show={showConnectionConfig} onHide={() => setShowConnectionConfig(false)}>
            {activeConnectionSRT && (
                <SourceDataRelationships 
                    sourceRecordType={activeConnectionSRT}
                    onConfigureRelationship={configureRelationship}
                    onRemoveRelationship={removeRelationship}
                />
            )}
            {!activeConnectionSRT && <ConnectDataSources selectedSourceRecordTypeIds={selectedRecordTypes} selectedRelationship={activeRelationship} onRemoveRelationship={removeRelationship}/>}
        </Offcanvas>
        <PageTitle title="Data Sources" subtitle="Here is where all of your data comes from.">
        <Link className="btn btn-sm btn-outline-secondary me-1" to="/sources/new">
                                    <i className="mdi mdi-plus-box"></i> New Source
                                </Link>
                                <button className="btn btn-sm btn-pliable"  disabled={selectedRecordTypes.length < 1} onClick={() => connectNewDataSources()}>
                                    <i className="mdi mdi-relation-many-to-one"></i> Connect Data Sources
                                </button>
                </PageTitle>
        <div className="content-inner">
        <div className="row">
            <div className="col-12">
                        
                <Masonry>
                    {sources.data && sourceRecordTypes.data && sourceTypes.data && sources.data.map(s => {
                        return <div className="grid-item p-3">
                            <DataSourceBox
                                source={s}
                                sourceRecordTypes={sourceRecordTypes.data.filter(srt => srt.source_id === s.id)}
                                sourceType={sourceTypes.data.find(st => st.id === s.type)}
                                selectedRecordTypes={selectedRecordTypes}
                                onSelect={selectRecordType}
                                relationships={relationshipsBySource[s.id as string]}
                                onShowConnections={showConnections}
                            />
                        </div>
                    })}
                </Masonry>
                    
            </div>
        </div>
        </div>
        
    </>

}

export default DataSourcesPage;