import ApiService, { ListRecordsResponse } from "./api/api.service";

export interface ColumnElement {
    KEY: string;
    PATH: string;
}

export const getColumnsForSourceRecordType = async (sourceId: string, sourceRecordTypeId: string) => {
    const results = await ApiService.getInstance().request('GET', `/sources/${sourceId}/record-type/${sourceRecordTypeId}/describe`) as ListRecordsResponse<ColumnElement>;


    return results.records;
}


export const getColumnsForPipelineStep = async (pipelineId: string, pipelineExecutionId: string, stepIndex: number) => {
    const results = await ApiService.getInstance().request('GET', `/pipelines/${pipelineId}/executions/${pipelineExecutionId}/step/${stepIndex}/describe_output`) as ListRecordsResponse<ColumnElement>;


    return results.records;
}