import { SupportOptionRange } from "prettier";
import React, { Component, createContext, useState, ReactNode } from "react";
import ApiService, { ApiConnectionError } from "../api/api.service";
import { parseDateString } from "../time.service";



interface FeatureFlags {
  [key: string]: boolean;
}

// Snake case here to be interoperable with the back end
export interface Config {
  fivetran_destination_id: string;
  fivetran_group_id: string;
  flags: FeatureFlags;
  id: string;
  io_bucket: string;
  short_id: string;
  tenant_slug: string;
  tenant_uuid: string;
}

// A "provider" is used to encapsulate only the
// components that needs the state in this context


interface Props {
  children: ReactNode;
}

interface State {
  loading: boolean;
  config: Config|null;
}

export default class ConfigService {
  private static instance: ConfigService;

  config: Config|null;
  loading: boolean;

  public static getInstance(): ConfigService {
    if (!ConfigService.instance) {
        ConfigService.instance = new ConfigService();
    }

    return ConfigService.instance;
  }

  constructor() {
    this.config = null;
    this.loading = true;
  }

  public async loadConfig() {
    try{
      const data = await ApiService.getInstance().request('GET', '/config');
      this.config = data as Config;

      console.log('Set config:', this.config);
    }catch(ex){
      if(ex instanceof ApiConnectionError){
        window.location.href= '/unable-to-connect';
        return;
      }
      console.log("Unable to load config")
      window.location.href= '/welcome'
    }
  }

  
}

// export class ConfigLoader extends Component<Props, State> {
//   constructor(props: Props) {
//     super(props);

//     this.state = {
//       loading: true,
//       config: null,
//     }
//   }

//   savePipeline(pipeline: Pipeline) {
//     const pipelines = this.state.config?.pipelines;

//   }

//   saveBusinessObject(businessObject: BusinessObject) {

//   }

//   render() {
//     return (
//       <ConfigContext.Provider value={userDetails}>
//         <ConfigDispatchContext.Provider value={setUserDetails}>
//           {props.children}
//         </ConfigDispatchContext.Provider>
//       </ConfigContext.Provider>
//     );
//   }
// }
// export function ConfigProvider2(props: Props) {
//   const [userDetails, setUserDetails] = useState({
//     username: "John Doe"
//   });

//   return (
//     <ConfigContext.Provider value={userDetails}>
//       <ConfigDispatchContext.Provider value={setUserDetails}>
//         {props.children}
//       </ConfigDispatchContext.Provider>
//     </ConfigContext.Provider>
//   );
// }