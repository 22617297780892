import ApiService from '@services/api/api.service';
import { getErrorMessage } from '@services/errors.service';
import { integerFormatter, decimalFormatter } from '@services/formatting.service';
import { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import SnowflakeLoader from '@components/loaders/SnowflakeLoader.component';

interface Props {
    url: string;

    // We'll only render the modal when column name is non-null
    // and maintain state within this component
    columnName?: string;
}

interface ColumnDistributionData {
    value: string;
    total: number;
}

interface ColumnStatsData {
    nulls: number;
    empties: number;
    uniques: number;
    total: number;
    numeric_max: number|null;
    numeric_min: number|null;
    numeric_avg: number|null;
    string_avg_length: number;
    string_max_length: number;
    string_min_length: number;
}

interface ColumnStatsResult {
    distribution: ColumnDistributionData[];
    stats: ColumnStatsData;
}

export const ColumnStatsModal = (props: Props) => {
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');

    const [columnDistribution, setColumnDistribution] = useState<ColumnDistributionData[]>([]);
    const [columnStats, setColumnStats] = useState<ColumnStatsData|undefined>(undefined);

    const [showModal, setShowModal] = useState(false);

    const loadData = async () => {
        if (!props.columnName) {
            return;
        }
        setLoading(true);
        try {
            let queryStringConnector = '?';
            if (props.url.indexOf('?') > 0) {
                queryStringConnector = '&';
            }
            const url = `${props.url}${queryStringConnector}column=${encodeURIComponent(props.columnName)}`;
            const results = await ApiService.getInstance().request('GET', url) as ColumnStatsResult;
        
            setColumnDistribution(results.distribution);
            setColumnStats(results.stats);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);

        }
    }

    useEffect(() => {
        if (!!props.columnName) {
            setShowModal(true);
            loadData();
        } else {
            setShowModal(false);
            setColumnStats(undefined);
            setColumnDistribution([]);
        }
    }, [props.columnName, props.url]);

    const onClose = () => {
        setShowModal(false);
    }

    return (
        <Modal show={showModal} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Column Details: {props.columnName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <SnowflakeLoader/>}
                {!loading && columnStats && (
                    <>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Total Records</th>
                                    <td>{integerFormatter(columnStats.total)}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Empty Values</th>
                                    <td>{integerFormatter(columnStats.empties)} records</td>
                                    <td>{decimalFormatter(100 * columnStats.empties / columnStats.total)}%</td>
                                    
                                </tr>
                                <tr>
                                    <th>Null Values</th>
                                    <td>{integerFormatter(columnStats.nulls)} records</td>
                                    <td>{decimalFormatter(100 * columnStats.nulls / columnStats.total)}%</td>
                                    
                                </tr>
                                <tr>
                                    <th>Unique Values</th>
                                    <td>{integerFormatter(columnStats.uniques)} distinct values</td>
                                    <td>{decimalFormatter(100 * columnStats.uniques / columnStats.total)}%</td>
                                    
                                </tr>
                            </tbody>
                        </table>
                        <h4>Value Distribution</h4>
                        <div className="row">
                            <div className="col-4">
                                <div style={{height: '320px', overflowY: 'scroll'}}>
                                    <table className="table table-sm" >
                                        <tbody>
                                            {columnDistribution.map(c => {
                                                return (
                                                    <tr>
                                                        <th>{c.value}</th>
                                                        <th>{decimalFormatter(100 * c.total / columnStats.total)}%</th>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                            <div className="col-8">
                                <Chart
                                    className="text-white"
                                    options={{
                                        'legend': {
                                            'show': false,
                                        },
                                        'theme': {
                                            'palette': 'palette7',
                                        },
                                        'chart': {
                                            'id': 'column-distro',
                                            'toolbar': {
                                                'show': false,
                                            }
                                        },
                                        'labels': columnDistribution.map(c => c.value),
                                        'yaxis': {
                                            'decimalsInFloat': 0,
                                        }
                                    }}
                                    series={columnDistribution.map(c => c.total)}
                                    type="pie"
                                    height="320"
                                    width="100%"
                                />
                            </div>
                        </div>
                        
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>

        </Modal>
    )

}