import BusinessObjectSelector from "@components/businessObjects/BusinessObjectSelector.component";
import { BusinessObject } from "@models/businessObject";
import { useBusinessObjects } from "@stores/data.store";
import { useState } from "react";

interface Props {
    sourceRecordTypeId: string;
}

const BasicStandardizationPipeline = ({ sourceRecordTypeId }: Props) => {
    const [selectedBusinessObject, setSelectedBusinessObject] = useState<BusinessObject|undefined>(undefined);
    const businessObjects = useBusinessObjects();
    return <>
        <h4>Let's make your data actionable.</h4>
        <hr />
        <p>
            The first step is translating the data into something anyone at your company will understand. We call these <strong>business objects</strong>. You can pick from the list of existing business objects or create a new one.
        </p>
        <div className="row">
            <div className="col-8">
                <div className="pt-3 pb-3">
                    <strong>I want to turn this data into</strong> <BusinessObjectSelector allowCreate selectedId={selectedBusinessObject ? (selectedBusinessObject.id as string) : ''} onSelect={setSelectedBusinessObject}/>
                </div>
            </div>
        </div>
        
        {selectedBusinessObject && (
            <hr />
        )}
        
        
        
    </>
}

export default BasicStandardizationPipeline;