import AsyncButton from "@components/button/AsyncButton.component";
import DataTable, { SimpleDataTable } from "@components/datatable/DataTable.component";
import SnowflakeLoader from "@components/loaders/SnowflakeLoader.component";
import BigMetricCard from "@components/metric/BigMetricCard.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import SourceRecordTypeName from "@components/sources/SourceRecordTypeName.component";
import { BusinessObject, BusinessObjectRelationship, BusinessObjectRelationshipORM } from "@models/businessObject";
import { ColumnRef } from "@models/shared";
import { SourceDataRelationshipORM } from "@models/source";
import StandardizationPipelineORM, { StandardizationPipeline } from "@models/standardizationPipeline";
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { getErrorMessage } from "@services/errors.service";
import { integerFormatter } from "@services/formatting.service";
import toast from "@services/toast.service";
import { useBusinessObjects, useStandardizationPipelines } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Node as ReactFlowNode, Edge as ReactFlowEdge, ReactFlow, Background, Controls, NodeProps, Handle, Position} from 'reactflow';
import styled from 'styled-components';

interface Node {
    source_record_type_id: string;
    business_object_id: string;
    id: string;
}

interface Edge {
    from_id: string;
    from_column_ref: ColumnRef;
    to_id: string;
    to_column_ref: ColumnRef;
}

interface GraphResponse {
    bo1_nodes: Node[];
    bo2_nodes: Node[];
    edges: Edge[];
}

interface ConnectionGraphProps {
    leftNodes: Node[];
    rightNodes: Node[];
    edges: Edge[];
    leftBusinessObject: BusinessObject;
    rightBusinessObject: BusinessObject;

    loading?: boolean;
}

const NODE_HEIGHT = 60;
const NODE_WIDTH = 700;

const NodeContainer = styled.div`
border: solid 1px var(--ct-border-color);
width: 300px;
height: 45px;
background-color: white;
padding: .5rem;
font-size: 12px;
`

const TitleNodeComponent = (node: NodeProps) => {
    return (
        <>
            <h2>{node.data.businessObject.name}</h2>
        </>
    )
}

const LeftNodeComponent = (node: NodeProps) => {
    return (
        <NodeContainer>
                <SourceRecordTypeName sourceRecordTypeId={node.data.source_record_type_id}/>
            <Handle type="source" position={Position.Right}/>
            <Handle type="target" position={Position.Right}/>
        </NodeContainer>
    );
}

const RightNodeComponent = (node: NodeProps) => {
    return (
        <NodeContainer>
            <Handle type="target" position={Position.Left}/>
            <Handle type="source" position={Position.Left}/>

                <SourceRecordTypeName sourceRecordTypeId={node.data.source_record_type_id}/>
                </NodeContainer>
    );
};

const nodeTypes = {
    'right': RightNodeComponent,
    'left': LeftNodeComponent,
    'title': TitleNodeComponent,
};

const ConnectionGraph = (props: ConnectionGraphProps) => {
    const [nodes, setNodes] = useState<ReactFlowNode[]>([]);
    const [edges, setEdges] = useState<ReactFlowEdge[]>([]);

    useEffect(() => {
        const newNodes: ReactFlowNode[] = [];
        props.leftNodes.forEach((n: Node, idx: number) => {
            newNodes.push({
                id: n.id,
                position: {
                    x: 0,
                    y: ((idx + 1) * NODE_HEIGHT),
                },
                data: n,
                type: 'left',
            });
        });

        props.rightNodes.forEach((n: Node, idx: number) => {
            newNodes.push({
                id: n.id,
                position: {
                    x: NODE_WIDTH,
                    y: ((idx + 1) * NODE_HEIGHT),
                },
                data: n,
                type: 'right',
            });
        });

        newNodes.push({
            'id': 'left-title',
            position: {
                x: 0,
                y: 0,
            },
            data: {
                businessObject: props.leftBusinessObject,
            },
            type: 'title',
        });

        newNodes.push({
            'id': 'right-title',
            position: {
                x: NODE_WIDTH,
                y: 0,
            },
            data: {
                businessObject: props.rightBusinessObject,
            },
            type: 'title',
        });

        console.log('EDGES:', props.edges);

        setEdges(props.edges.map(e => {
            return {
                id: e.from_id + ':' + e.to_id,
                source: e.from_id,
                target: e.to_id,
                label: `${e.from_column_ref.column_path} <> ${e.to_column_ref.column_path}`,
                animated: !!props.loading,
            }
        }));
        setNodes(newNodes);
    }, [props]);

    return (
        <div style={{ height: '400px' }}>
            <ReactFlow 
                nodes={nodes} 
                edges={edges} 
                proOptions={{hideAttribution: true}}
                fitView
                nodeTypes={nodeTypes}
            >
                <Background/>
            </ReactFlow>
        </div>
    );
}

interface ResolutionStats {
    AMBIGUOUS: number;
    ORPHANS: number;
    MATCHED: number;
}

interface ResolveResponse {
    stats: ResolutionStats;
    key: string;
}

interface ResolutionSample {
    UUID: string;
    DATA: any;
    FOREIGN_KEYS: string[];
    FOREIGN_DATA: any[];
}

const BusinessObjectRelationshipResolverPage = () => {
    const { businessObjectId, relationshipId } = useParams();
    const businessObjects = useBusinessObjects();
    const [relationship, setRelationship] = useState<BusinessObjectRelationship|undefined>(undefined);


    const [leftNodes, setLeftNodes] = useState<Node[]>([]);
    const [rightNodes, setRightNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);

    const [leftBusinessObject, setLeftBusinessObject] = useState<undefined|BusinessObject>(undefined);
    const [rightBusinessObject, setRightBusinessObject] = useState<undefined|BusinessObject>(undefined);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [resolving, setResolving] = useState(false);
    const [resolutionStats, setResolutionStats] = useState<ResolutionStats|undefined>(undefined);
    const [resolutionError, setResolutionError] = useState('');

    const [executionKey, setExecutionKey] = useState('');

    const [loadingSamples, setLoadingSamples] = useState(false);

    const [resolutionSamples, setResolutionSamples] = useState<ResolutionSample[]>([]);
    const loadData = useCallback(async () => {
        if (!businessObjects.data) {
            return;
        }
        setLoading(true);
        try {
            const relationship = await BusinessObjectRelationshipORM.findById(relationshipId as string);
            if (relationship) {
                setRelationship(relationship);
                const graphData = await ApiService.getInstance().request('GET', `/businessobjectrelationships/${relationship.id}/graph`) as GraphResponse;

                const parent = businessObjects.data.find(bo => bo.id === relationship.parent_business_object_id);
                const child = businessObjects.data.find(bo => bo.id === relationship.child_business_object_id);

                if (!parent || !child) {
                    throw new Error('Bad relationship!');
                }

                if (relationship.parent_business_object_id === businessObjectId) {
                    setLeftNodes(graphData.bo1_nodes);
                    setRightNodes(graphData.bo2_nodes);
                    setLeftBusinessObject(parent);
                    setRightBusinessObject(child);
                } else {
                    setRightNodes(graphData.bo1_nodes);
                    setLeftNodes(graphData.bo2_nodes);
                    setLeftBusinessObject(child);
                    setRightBusinessObject(parent);
                }
                setEdges(graphData.edges);
            }
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, [relationshipId]);


    useEffect(() => {
        if (relationshipId && businessObjects.data) {
            loadData();
        }
    }, [relationshipId, businessObjects.data]);

    const resolve = useCallback(async () => {
        if (!relationship) {
            return;
        }
        setResolutionError('');
        setResolutionSamples([]);
        setExecutionKey('');
        setResolving(true);
        try {
            const jobInfo = await ApiService.getInstance().request('POST', `/businessobjectrelationships/${relationship.id}/resolve`, {}) as JobEnqueueResponse;

            const result = await BackgroundService.getInstance().waitForJob(jobInfo.job_id) as ResolveResponse;
            setResolutionStats(result.stats);
            setExecutionKey(result.key);

            
        } catch (err) {
            setResolutionError(getErrorMessage(err));
        } finally {
            setResolving(false);
        }
        

    }, [relationship]);

    const getAmbiguousSamples = useCallback(async () => {
        if (!relationship || !executionKey) {
            return;
        }
        setLoadingSamples(true);
        try {
            const examples = await ApiService.getInstance().request('GET', `/businessobjectrelationships/${relationship.id}/resolve/${executionKey}/samples`) as ListRecordsResponse<ResolutionSample>;
            setResolutionSamples(examples.records);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingSamples(false);
        }
    }, [relationship, executionKey]);

    const [isMerging, setIsMerging] = useState(false);

    const mergeAll = useCallback(async () => {
        if (!relationship || !executionKey) {
            return;
        }

        setIsMerging(true);
        try {
            await ApiService.getInstance().request('POST', `/businessobjectrelationships/${relationship.id}/resolve/${executionKey}/merge`, {});

            toast('success', 'Success!', 'Records flagged for merging.');
        } catch (err) {
            toast('danger', 'Error!', getErrorMessage(err));
        } finally {
            setIsMerging(false);
        }
    }, [relationship, executionKey]);

    const relationshipInfo = useMemo(() => {
        if (!businessObjects.data) {
            return <></>;
        }

        if (!relationship) {
            return <></>
        }

        const thisBusinessObject = businessObjects.data.find(bo => bo.id === businessObjectId);
        let thatBusinessObjectId = '';
        let relationshipDescription = '';

        if (relationship.parent_business_object_id === businessObjectId) {
            thatBusinessObjectId = relationship.child_business_object_id;
            relationshipDescription = 'has many';
        } else {
            thatBusinessObjectId = relationship.parent_business_object_id;
            relationshipDescription = 'belongs to';
        }

        const childBusinessObject = businessObjects.data.find(bo => bo.id === relationship.child_business_object_id);
        if (!childBusinessObject) {
            return <>Error</>;
        }

        const thatBusinessObject = businessObjects.data.find(bo => bo.id === thatBusinessObjectId);

        return <>
            <h3>
                <strong>{thisBusinessObject?.name}</strong><code> {relationshipDescription} </code> <strong>{thatBusinessObject?.name}</strong>
            </h3>
            <p>We will add a new column called <code>{relationship.child_foreign_key_name}</code> to the <strong>{childBusinessObject.name}</strong> object</p>
        </>
    }, [relationship, businessObjects.dataUpdatedAt, businessObjectId]);

    if (loading) {
        return <Spinner/>;
    }

    

    return <div className="content-inner has-subnav">
        <PageTitle title="Resolve Relationship" subtitle="Pliable's AI will use your source data and other information to determine how these records are related to one another.">
            <AsyncButton loading={resolving} variant="pliable" onClick={() => resolve()} text="Resolve"/>
        </PageTitle>
        <div className="section">
            <div className="card">
                <div className="card-body">
                    {relationshipInfo}

                    {resolving && (
                        <div className="alert alert-info">
                            <SnowflakeLoader/>
                            Resolving relationships...
                        </div>
                    )}
                    {!resolving && resolutionError && (
                        <div className="alert alert-danger">
                            <h4>Error:</h4>
                            {resolutionError}
                        </div>
                    )}
                    {!resolving && resolutionStats && (
                        <div>

                            <h4>Resolution Results</h4>
                            <div className="row">
                                <div className="col-4 p-1">
                                    <BigMetricCard
                                        loading={false}
                                        icon="mdi mdi-thumb-up"
                                        title="Matched Records"
                                        backgroundColor="success"
                                        metric={integerFormatter(resolutionStats.MATCHED)}
                                    >
                                        <small>Pliable was able to match these records to exactly one related record.</small>
                                    </BigMetricCard>
                                </div>
                                <div className="col-4 p-1">
                                    <BigMetricCard
                                        loading={false}
                                        icon="mdi mdi-help-box"
                                        backgroundColor="danger"
                                        title="Ambiguous Records"
                                        metric={integerFormatter(resolutionStats.AMBIGUOUS)}
                                    >
                                        <small>Pliable matched these records to more than one related record. This typically means you have a data integrity issue.</small>
                                        <div className="mt-3">
                                            <AsyncButton
                                                loading={loadingSamples}
                                                text="Investigate"
                                                icon="mdi mdi-layers-search"
                                                onClick={() => getAmbiguousSamples()}
                                            />
                                        </div>
                                    </BigMetricCard>
                                </div>
                                <div className="col-4 p-1">
                                    <BigMetricCard
                                        loading={false}
                                        icon="mdi mdi-emoticon-sad"
                                        backgroundColor="warning"
                                        title="Orphan Records"
                                        metric={integerFormatter(resolutionStats.ORPHANS)}
                                    >
                                        <small>Pliable was unable to match these records to any related records. This may be OK, but may also be indicative of a data integrity issue.</small>
                                    </BigMetricCard>
                                </div>
                            </div>

                           
                        </div>
                    )}
                    {resolutionSamples && resolutionSamples.length > 0 && (
                        <div>
                            <AsyncButton
                                loading={isMerging}
                                text="Merge All"
                                onClick={() => mergeAll()}
                            />
                            {resolutionSamples.map(s => {

                                return (
                                    <div className="card">
                                        <div className="card-body">
                                        <div className="row">
                                        <div className="col-3">
                                            <h5>Child Object</h5>
                                            <table className="table">
                                                <tr>
                                                    <th>UUID</th>
                                                    <td>{s.UUID}</td>
                                                </tr>
                                                {Object.keys(s.DATA).map(k => {
                                                    return <tr>
                                                        <th>{k}</th>
                                                        <td>{s.DATA[k]}</td>
                                                    </tr>
                                                })}
                                            </table>
                                        </div>
                                        <div className="col-9">
                                            <h5>Maps To</h5>
                                            <SimpleDataTable data={s.FOREIGN_DATA} />
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                    
                                )
                                
                            })}
                            <hr />
                            
                        </div>
                    )}
                    {leftBusinessObject && rightBusinessObject && (
                        <ConnectionGraph 
                            leftBusinessObject={leftBusinessObject}
                            rightBusinessObject={rightBusinessObject}
                            leftNodes={leftNodes}
                            rightNodes={rightNodes}
                            edges={edges}
                            loading={resolving}
                        />
                    )}
                    
                </div>
            </div>
        </div>
    </div>
    //     <div className="row">
    //         <div className="col-12">
    //             <div className="card">
    //                 <div className="card-header">
    //                     <h4 className="mt-2 mb-0">Resolve Relationship: {relationshipInfo}</h4>
    //                     <p className="text-muted font-13 mb-0">Pliable's AI will use your source data and other information to determine which adsfadsf</p>
    //                 </div>
    //                 <div className="card-body">
    //                     <div className="row">
    //                     <div className="col-3">
    //                         Here we can show all of the data sources and relationships between them
    //                     </div>
    //                 </div>
    //                 </div>
                    
    //             </div>
    //         </div>
    //     </div>
    // </>
}

export default BusinessObjectRelationshipResolverPage;