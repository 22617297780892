import { Source, SourceRecordTypeOption } from "@models/source"
import ApiService, { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Offcanvas, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import { CopyBlock, atomOneLight } from "react-code-blocks";
import AsyncButton from "@components/button/AsyncButton.component";
import SaveButton from "@components/button/SaveButton.component";
import { useImmer } from "use-immer";
import RecordPagination from "@components/pagination/Pagination.component";
import BackgroundService from "@services/bg.service";
import { useSourceRecordTypes } from "@stores/data.store";
import RecordTypesSelector from "../RecordTypesSelector.component";
import LoadingCard from "@components/card/LoadingCard.component";

interface Params {
    source: Source;
    onChangeConfig?: (config: any) => void;
    showCtasOnDone?: boolean;
}

interface SnowflakeDetails {
    database_name: string;
    schema_name: string;
}

interface SnowflakeDatabase  {
    name: string;
}

interface SnowflakeSchema {
    database_name: string;
    name: string;
}

const SnowflakeConnectorConfig = (params: Params) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentState, setCurrentState] = useState<'SELECT_SCHEMA'|'SELECT_TABLES'|'DONE'>('SELECT_SCHEMA');
    const [databaseOptions, setDatabaseOptions] = useState<SnowflakeDatabase[]>([]);
    const [schemaOptions, setSchemaOptions] = useState<SnowflakeSchema[]>([]);
    const [showHelper, setShowHelper] = useState(false);

    const [loadingSchemas, setLoadingSchemas] = useState(false);

    const [selectedDatabase, setSelectedDatabase] = useState('');
    const [selectedSchema, setSelectedSchema] = useState('');
    const [currentRole, setCurrentRole] = useState('');


    const loadDatabaseOptions = useCallback(async () => {
        setLoading(true);

        try {
            const results = await ApiService.getInstance().request('POST', '/sql/execute', {
                'sql': 'SHOW DATABASES;',
            }) as ListRecordsResponse<SnowflakeDatabase>;
            setDatabaseOptions(results.records);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, []);
    const loadSchemaOptions = useCallback(async (database: string) => {
        setLoadingSchemas(true);
        setError('');

        try {
            const results = await ApiService.getInstance().request('POST', '/sql/execute', {
                'sql': 'SHOW SCHEMAS IN DATABASE ' + database,
            }) as ListRecordsResponse<SnowflakeSchema>;
            setSchemaOptions(results.records);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoadingSchemas(false);
        }
    

    }, [selectedDatabase]);

    const loadCurrentRole = useCallback(async () => {
        try {
            const results = await ApiService.getInstance().request('POST', '/sql/execute', {
                'sql': 'select current_role() as role',
            }) as ListRecordsResponse<any>;
            setCurrentRole(results.records[0].role);
        } catch (err) {
            toast('danger', 'Oops!', 'We were unable to get critical information from your Snowflake account! (' + getErrorMessage(err) + ')');
        }
    }, []);

    const databaseSelectOptions = useMemo(() => {
        return databaseOptions.map(opt => {
            return {
                value: opt.name,
                label: opt.name,
            }
        })
    }, [databaseOptions]);

    const selectedDatabaseOption = databaseSelectOptions.find(opt => opt.value === selectedDatabase);

    const schemaSelectOptions = useMemo(() => {
        return schemaOptions.map(opt => {
            return {
                value: opt.name,
                label: opt.name
            }
        })
    }, [schemaOptions]);

    const selectedSchemaOption = schemaSelectOptions.find(opt => opt.value === selectedSchema);

    const setupSql = useMemo(() => {
        return `SET DB = '';\nSET SCHEMA = '';\n\nUSE DATABASE IDENTIFIER($DB);\nGRANT MONITOR, USAGE ON DATABASE IDENTIFIER($DB) TO ROLE ${currentRole};\nGRANT MONITOR, USAGE ON SCHEMA IDENTIFIER($SCHEMA) TO ROLE ${currentRole};\nGRANT SELECT ON ALL VIEWS IN SCHEMA IDENTIFIER($SCHEMA) TO ROLE ${currentRole};\nGRANT SELECT ON ALL TABLES IN SCHEMA IDENTIFIER($SCHEMA) TO ROLE ${currentRole};`
    }, [currentRole]);

  
    const selectDatabase = useCallback((db: string) => {
        setSelectedDatabase(db);
        loadSchemaOptions(db);
    }, []);

    const reloadSchemaOptions = useCallback(() => {
        setShowHelper(false);
        loadDatabaseOptions();
        if (selectedDatabase) {
            loadSchemaOptions(selectedDatabase);

        }
    }, []);

    useEffect(() => {
        if (!params.source.config || !params.source.config.database_name) {
            loadDatabaseOptions();
            loadCurrentRole();
            setCurrentState('SELECT_SCHEMA');
        } else {
            setSelectedDatabase(params.source.config.database_name);
            setSelectedSchema(params.source.config.schema_name);
        }
        console.log('Source config:', params.source.config);

        if (params.source.config.database_name && params.source.config.schema_name) {
            setCurrentState('SELECT_TABLES');
        }
    }, [params.source.config]);

    const goBackToSchemaSelection = useCallback(() => {
        setCurrentState('SELECT_SCHEMA');
        loadDatabaseOptions();
        if (selectedDatabase) {
            loadSchemaOptions(selectedDatabase);
        }
    }, [selectedDatabase]);

    const saveConfig = useCallback(() => {
        if (params.onChangeConfig) {
            params.onChangeConfig({
                database_name: selectedDatabase,
                schema_name: selectedSchema,
            });
        }
    }, [params.source, selectedDatabase, selectedSchema, params.onChangeConfig]);

    const tableSelectionComplete = useCallback(async () => {
        if (params.showCtasOnDone){
            setCurrentState('DONE');
        }
        
    }, [params.source]);

    
    if (loading) {
        return <LoadingCard />;
    } else if (currentState === 'SELECT_SCHEMA') {
        return <>
            <Offcanvas show={showHelper} onHide={() => setShowHelper(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Granting access to your Snowflake data</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>You may need to grant Pliable access to see other parts of your Snowflake data.</p>
                    <p>Run the command below and set the <code>SCHEMA</code> variable to the database and schema you want Pliable to access (for example, <code>my_database.my_schema</code>)</p>
                    <CopyBlock
                        text={setupSql}
                        language="sql"
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock={true}
                    />
                    <button className="btn btn-pliable mt-3" onClick={() => reloadSchemaOptions()}>Reload options</button>
                </Offcanvas.Body>
            </Offcanvas>
            <Form.Group className="mb-3">
                <Form.Label>Choose Database</Form.Label>
                <Select options={databaseSelectOptions} value={selectedDatabaseOption} onChange={(e) => selectDatabase(!!e ? (e.value as string) : '')}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Choose Schema</Form.Label>
                {loadingSchemas && <div><Spinner size="sm"/></div>}
                {!loadingSchemas &&
                    <Select options={schemaSelectOptions} value={selectedSchemaOption} isDisabled={!selectedDatabase} onChange={(e) => setSelectedSchema(!!e ? (e.value as string) : '')} />
                }
            </Form.Group>

            <a role="button" onClick={() => setShowHelper(true)}>Don't see the one you're looking for?</a>
            <div className="mt-3">
                <button className="btn btn-pliable" onClick={saveConfig} disabled={!selectedDatabase || !selectedSchema }>Save</button>

            </div>
        </>
    } else if (currentState === 'SELECT_TABLES') {
        return (
            <>  
                <p>Choose the tables you want to access. Note that we need a <strong>primary key</strong> for each table in order to identify unique records. A primary key can be a single field (for example, <code>ID</code>), or a combination of fields.</p>
                
                <RecordTypesSelector sourceId={params.source.id as string} onSave={tableSelectionComplete} />
                
                <div className="mt-1">
                    <a role="button" onClick={() => goBackToSchemaSelection()}>Select a different schema</a>
                </div>
            </>
        );
    } else if (currentState === 'DONE') {
        return <>
            <p className="alert alert-success">
                <strong>Sweet!</strong> We've made this data accessible in Pliable.
            </p>
            {params.showCtasOnDone && (
                <div className="row">
                    <div className="col">
                        <button className="onboarding-button">
                            <i className="mdi mdi-sitemap"></i>
                            Add this data to your Data Model
                        </button>
                    </div>
                    <div className="col">
                        <button className="onboarding-button">
                            <i className="mdi mdi-database-search"></i>
                            Explore the data
                        </button>
                    </div>
                    <div className="col">
                        <button className="onboarding-button">
                            <i className="mdi mdi-plus-box"></i>
                            Add another data source
                        </button>
                    </div>
                </div>
            )}

        </>
    }
    return <></>;
}

export default SnowflakeConnectorConfig;