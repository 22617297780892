import { Component } from 'react';
import { Pagination } from 'react-bootstrap';


interface Props {
    current_page: number;
    total_pages: number;
    onPageChange: (page: number) => void;
}

export default class RecordPagination extends Component<Props> {
    render() {
        let first_page = Math.max(this.props.current_page-4, 1);
        let pages = [];
        let counter = first_page;
        while (pages.length < 6 && counter <= this.props.total_pages) {
            pages.push(counter);
            counter += 1;
        }

        console.log('pages:', pages);

        return (
            <Pagination>
                <Pagination.First disabled={this.props.current_page === 1} onClick={() => this.props.onPageChange(1)} />
                <Pagination.Prev disabled={this.props.current_page === 1} onClick={() => this.props.onPageChange(this.props.current_page-1)} />
                {
                    pages.map((page_num) => {
                        return (
                            <Pagination.Item 
                                active={page_num==this.props.current_page} 
                                onClick={() => this.props.onPageChange(page_num)}
                            >{page_num}</Pagination.Item>
                        )
                    })
                }
                <Pagination.Next disabled={this.props.current_page === this.props.total_pages} onClick={() => this.props.onPageChange(this.props.current_page+1)} />
                <Pagination.Last disabled={this.props.current_page === this.props.total_pages} onClick={() => this.props.onPageChange(this.props.total_pages)} />
            </Pagination>
        );
    }
}