import { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    loading: boolean;
    disabled?: boolean;
    onClick: () => void;

}

export default class SaveButton extends Component<Props> {
    render() {
        let saveButtonIcon = (<i className="mdi mdi-check-circle"></i>);

        if (this.props.loading) {
            saveButtonIcon = <Spinner size="sm"/>
        }
        return (
            <button className="btn btn-success" disabled={this.props.loading || this.props.disabled} onClick={(e) => this.props.onClick()}>
                                    
                {saveButtonIcon}
                <span className="ms-1">Save</span>
            </button>
        )
    }
}