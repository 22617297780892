import ORM, { BaseModel } from "./orm";
import { ColumnRef } from "./shared";

export interface FieldMap {
    source_column_name: string;
    target_column_name: string;
    target_column_id?: string;
    transformations: string[];
}

export interface FieldResolution {
    target_column_id: string;
    resolution_method: string;
    concat_separator: string;
    coalesce_order: string[];
}

export interface BusinessObjectFieldMap {
    source_columns: string[];
    concat_separator: string;
    resolution_method: string;
    raw_sql?: string;
    use_raw_sql: boolean;
}

export interface MappingConfig {
    /**
     * @deprecated
     */
    field_maps?: FieldMap[];
    
    /**
     * @deprecated
     */
    field_resolutions?: FieldResolution[];

    maps: {
        [key: string]: BusinessObjectFieldMap
    };
}

export interface Filter {
    column_ref: ColumnRef;
    comparator: string;
    value?: string;
}

export interface FilterConfig {
    filters: Filter[];
}

export interface DedupeConfig {
    shared_fields: string[];
    resolution_column: string;
    resolution_direction: string;
    fuzzy_match_threshold: number;
}
export interface StandardizationPipeline extends BaseModel {
    source_record_type_id: string;
    business_object_id: string;
    mapping_config: MappingConfig;
    dedupe_config: DedupeConfig;
    filter_config: FilterConfig;
    pipeline_id?: string;
}

const StandardizationPipelineORM = new ORM<StandardizationPipeline>('standardizationpipelines');
export default StandardizationPipelineORM;