import { useState, useEffect } from 'react';
import ConfigService from '@services/config/config.service';
import Spinner from 'react-bootstrap/Spinner';
import AuthService from '@services/auth/auth.service';
import { useAuth } from "@frontegg/react";
import { Routes, Route, useLocation } from 'react-router-dom';
import RootPage from '@pages/Root.page';
import DashboardPage from '@pages/Dashboard.page';
import PipelineConfigurationPage from '@pages/PipelineConfiguration/PipelineConfiguration.page';

import { ToastContainer } from 'react-toastify';
import PipelinesListPage from '@pages/PipelinesList.page';
import BusinessObjectsListPage from '@pages/BusinessObjectsList.page';
import ProfileSourcePage from '@pages/ProfileSource.page';
import AddonsPage from '@pages/Addons.page';
import FormEntryPage from '@pages/FormEntry.page';
import TenantSetup from '@pages/TenantSetup.page';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { useAuthUser } from "@frontegg/react";
import ErrorPage from '@pages/Error.page';
import SourcesListPage from '@pages/SourcesList.page';
import FilePage from '@pages/File.page';
import DataMartPage from '@pages/DataMart.page';
import DataMartsListPage from '@pages/DataMartList.page';
import NewSourcePage from '@pages/NewSource.page';
import SourceEditRecordTypesPage from '@pages/SourceEditRecordTypes.page';
import SetupPage from './pages/Setup.page';
import PipelineExecutionDetails from './pages/PipelineExecutionDetails.page';
import PipelineOrchestrationDetails from './pages/PipelineOrchestrationDetails.page';
import PipelineDetails from './pages/PipelineDetails.page';
import PipelineDataPage from './pages/PipelineData.page';
import PipelineExecutionStepReviewPage from './pages/PipelineExecutionStepReview.page';
import SourceDataRecordCellPage from '@pages/SourceDataRecordCellPage.page';
import TemplatesPage from '@pages/Templates.page';
import AuditLogListPage from '@pages/AuditLogList.page';
import ApiConnectionErrorPage from '@pages/ApiConnectionErrorPage.page';
import { AlertsContainer } from '@services/alert/alert.service';
import CreateBusinessObjectWizard from '@pages/CreateBusinessObjectWizard.page';
import NewPipelinePage from '@pages/NewPipeline.page';
import CreateNewAccount from '@pages/CreateNewAccount.page';

import {
  QueryClientProvider
} from 'react-query';
import { queryClient } from '@stores/data.store';

import "allotment/dist/style.css";
import NewBusinessObjectPage from '@pages/BusinessObject/NewBusinessObject.page';
import Welcome from '@pages/Welcome.page';
import SelectDestinationPage from '@pages/Onboarding/SelectDestination.page';
import BYOSnowflakePage from '@pages/Onboarding/BYOSnowflake.page';
import ManagedSnowflakePage from '@pages/Onboarding/ManagedSnowflake.page';
import OnboardingDataModelPage from '@pages/Onboarding/OnboardingDataModel.page';
import DataModelPage from '@pages/DataModel/DataModel.page';
import DataModelReviewPage from '@pages/Onboarding/DataModelReview.page';
import DataSourceSelectionPage from '@pages/Onboarding/DataSourceSelection.page';
import DataSourceSetupPage from '@pages/Onboarding/DataSourceSetup.page';
import NewSourceSetupPage from '@pages/NewSourceSetup.page';
import SourceRecordTypeDetails from '@pages/SourceRecordType/SourceRecordTypeDetails.page';
import StandardizationPipelinePage from '@pages/SourceRecordType/StandardizationPipeline.page';
import SourceDataRelationshipsPage from '@pages/SourceDataRelationships.page';
import BusinessObjectStatsPage from '@pages/BusinessObject/BusinessObjectStats.page';
import BusinessObjectAttributesPage from '@pages/BusinessObject/BusinessObjectAttributes.page';
import BusinessObjectWrapperPage from '@pages/BusinessObject/BusinessObjectWrapper.page';
import BusinessObjectSourcesPage from '@pages/BusinessObject/BusinessObjectSources.page';
import BusinessObjectRelationshipsPage from '@pages/BusinessObject/BusinessObjectRelationships.page';
import BusinessObjectDataPage from '@pages/BusinessObject/BusinessObjectData.page';
import DataSourcesPage from '@pages/Sources/DataSources.page';
import BusinessObjectRelationshipResolverPage from '@pages/BusinessObject/BusinessObjectRelationshipResolver.page';
import DataSourcePage from '@pages/Sources/DataSource.page';
import SourceRecordTypeWrapperPage from '@pages/SourceRecordType/SourceRecordTypeWrapper.page';
import SourceRecordTypeInsightsPage from '@pages/SourceRecordType/SourceRecordTypeInsights.page';
import SourceRecordTypeDataPage from '@pages/SourceRecordType/SourceRecordTypeData.page';
import SourceRecordTypeDataModelPage from '@pages/SourceRecordType/SourceRecordTypeDataModel.page';
import SourceRecordTypeDeduplicationPage from '@pages/SourceRecordType/SourceRecordTypeDeduplication.page';
import FileDetailsPage from '@pages/Sources/FileDetails.page';
import DataSourceConfigPage from '@pages/Sources/DataSourceConfig.page';
import AfterLogin from '@pages/AfterLogin.page';
import BusinessObjectRecordDetailsPage from '@pages/BusinessObject/BusinessObjectRecordDetails.page';
import SourceRecordTypeRecordDetailsPage from '@pages/SourceRecordType/SourceRecordTypeRecordDetails.page';


declare global {
  interface Window { 
    analytics: any; 
    Intercom: any;
    LogRocket: any;
  }
}

const PATHS_WITH_NO_CONFIG = [
  "/welcome",
  "/welcome/snowflake/byo",
  "/create-account",
  "/destination-setup",
  "/unable-to-connect"
]

function usePageViews(){
  const location = useLocation();
  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
}

function App() {
  // This will redirect to login in case we are not logged in
  const user = useAuthUser();
  usePageViews();

  const [loadingConfig, setLoadingConfig] = useState(true);
  const authState = useAuth();

  useEffect(() => {
    AuthService.getInstance().setAuthState(authState)
    if(!PATHS_WITH_NO_CONFIG.includes(window.location.pathname)){
      ConfigService.getInstance().loadConfig().then(() => setLoadingConfig(false));
    }else{
      setLoadingConfig(false);
    }
  }, []);

  return (
    <div className="App">
      { !loadingConfig ? (
        <>
          <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <AlertsContainer/>
            <Routes>
              <Route path="/welcome" element={<Welcome/>}>
                <Route index element={<SelectDestinationPage/>}/>
                <Route path="snowflake/byo" element={<BYOSnowflakePage/>}/>
                <Route path="snowflake/managed" element={<ManagedSnowflakePage/>}/>
                <Route path="data-model" element={<OnboardingDataModelPage/>}/>
                <Route path="data-model/review" element={<DataModelReviewPage/>}/>
                <Route path="source/select" element={<DataSourceSelectionPage/>}/>
                <Route path="source/setup/:sourceType" element={<DataSourceSetupPage/>}/>
              </Route>
              <Route path="/" element={<RootPage />} errorElement={<ErrorPage /> }>
                  <Route index element={<DashboardPage />} />
                  <Route path="setup" element={<SetupPage/>}/>
                  <Route path="addons" element={<AddonsPage />} />
                  <Route path="sources" element={<DataSourcesPage />} />
                  <Route path="sources/new" element={<NewSourcePage/>} />
                  <Route path="sources/setup/:sourceType" element={<NewSourceSetupPage/>} />
                  <Route path="sources/:sourceId" element={<DataSourcePage/>}>
                    <Route index element={<DataSourceConfigPage/>}/>
                    <Route path="files/:fileId" element={<FileDetailsPage/>}/>
                    <Route path="config/:tab" element={<DataSourceConfigPage/>}/>
                  </Route>
                  <Route path="sources/:sourceId/file" element={<DataSourcePage/>} />
                  <Route path="sources/:sourceId/record-types/edit" element={<SourceEditRecordTypesPage/>} />
                  <Route path="sources/:sourceId/data" element={<SourceRecordTypeDetails/>} />

                  <Route path="sources/:sourceId/data/:sourceRecordTypeId/standardize" element={<SourceRecordTypeDataModelPage/>} />
                  <Route path="sources/:sourceId/data/:sourceRecordTypeId/standardize/:pipelineId" element={<SourceRecordTypeDataModelPage/>} />

                  <Route path="sources/:sourceId/data/:sourceRecordTypeId" element={<SourceRecordTypeWrapperPage/>} />

                  <Route path="sources/:sourceId/data/:sourceRecordTypeId/record/:recordId" element={<SourceRecordTypeRecordDetailsPage/>}/>

                  {/* <Route path="sources/:sourceId/data/:sourceRecordTypeId/tab/:activeTab" element={<SourceRecordTypeDetails/>} />
                  <Route path="sources/:sourceId/data/:sourceRecordTypeId/dataset" element={<StandardizationPipelinePage/>}/> */}


                  {/* <Route path="sources/:sourceId/record-types/:sourceRecordTypeId/col/:columnHeader/record/:sourceRecordUuid" element={<SourceDataRecordCellPage/>} /> */}
                  <Route path="form-entry/:sourceId" element={<FormEntryPage/>} />
                  <Route path="sources/profile" element={<ProfileSourcePage />} />
                  <Route path="pipelines" element={<PipelinesListPage />} />
                  <Route path="pipelines/:pipelineId" element={<PipelineDetails />} />
                  <Route path="pipelines/new" element={<NewPipelinePage />} />

                  <Route path="pipelines/:pipelineId/configuration" element={<PipelineConfigurationPage />} />
                  <Route path="pipelines/:pipelineId/data" element={<PipelineDataPage />} />
                  <Route path="pipelines/:pipelineId/executions/:pipelineExecutionId" element={<PipelineExecutionDetails/>}/>
                  <Route path="pipelines/:pipelineId/executions/:pipelineExecutionId/step/:stepIndex" element={<PipelineExecutionStepReviewPage/>}/>

                  <Route path="pipelines/:pipelineId/orchestrations/:pipelineOrchestrationId" element={<PipelineOrchestrationDetails/>}/>

                  
                  <Route path="business-objects" element={<BusinessObjectsListPage />} />
                  <Route path="business-objects/new" element={<NewBusinessObjectPage />} />
                  <Route path="business-objects/:businessObjectId" element={<BusinessObjectWrapperPage />} >
                    <Route index element={<BusinessObjectAttributesPage/>}/>
                    <Route path="stats" element={<BusinessObjectStatsPage/>}/>
                    <Route path="attributes" element={<BusinessObjectAttributesPage/>}/>
                    <Route path="sources" element={<BusinessObjectSourcesPage/>}/>
                    <Route path="relationships" element={<BusinessObjectRelationshipsPage/>}/>
                    <Route path="relationships/:relationshipId" element={<BusinessObjectRelationshipResolverPage/>}/>
                    <Route path="data">
                      <Route index element={<BusinessObjectDataPage/>}/>
                      <Route path="record/:recordId" element={<BusinessObjectRecordDetailsPage/>}/>
                    </Route>
                  </Route>
                  <Route path="sources/:sourceId/files/:fileId" element={<FilePage/>}/>
                  <Route path="sources/:sourceId/create-business-object" element={<CreateBusinessObjectWizard/>}/>
                  <Route path="reports" element={<DataMartsListPage/>}/>
                  <Route path="reports/:dataMartId" element={<DataMartPage/>}/>
                  <Route path="audit-logs" element={<AuditLogListPage/>}/>

                  <Route path="examples" element={<TemplatesPage/>}/>
                  <Route path="unable-to-connect" element={<ApiConnectionErrorPage/>}/>

                  <Route path="data-model" element={<DataModelPage/>}/>   

                  <Route path="create-account" element={<CreateNewAccount/>}/>   
                  <Route path="afterlogin" element={<AfterLogin/>}/>                 
              </Route>
            </Routes>
          </QueryClientProvider>
          
        </>
      ) : (
        <div style={{marginTop:'40px'}}>
          <Spinner animation="border" role="status"></Spinner>
          <span>Loading...</span>
        </div>
      )}
    </div>
  );
}

export default App;
