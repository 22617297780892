import PageTitle from "@components/pageTitle/PageTitle.component";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import SourceDataStats from "./SourceDataStats.component";
import SourceORM from "@models/source";
import { useCallback, useEffect, useMemo, useState } from "react";
import { invalidateSourceRecordTypes, saveBusinessObject, saveStandardizationPipeline, useBusinessObjects, useSourceRecordTypes, useStandardizationPipelines } from "@stores/data.store";
import { Form, Modal } from "react-bootstrap";
import BusinessObjectSelector from "@components/businessObjects/BusinessObjectSelector.component";
import { BusinessObject, BusinessObjectField } from "@models/businessObject";
import FieldMapping from "./FieldMapping.component";
import StandardizationPipelineORM, { BusinessObjectFieldMap, MappingConfig, StandardizationPipeline } from "@models/standardizationPipeline";
import SaveButton from "@components/button/SaveButton.component";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import CreatableSelect from 'react-select/creatable';
import AsyncButton from "@components/button/AsyncButton.component";
import { useSourceRecordType } from "@stores/data.store";
import { useImmer } from "use-immer";
import { queryClient } from "@stores/data.store";
import { confirmation } from "@services/alert/alert.service";
import PipelineORM from "@models/pipeline";
import { BusinessObjectsIcon } from "@components/icons/PliableIcons.component";
import ApiService from "@services/api/api.service";
import produce from "immer";

interface NewBusinessObjectConfig {
    label: string;
    toggleAll: boolean;
    selectedOptions: {[key: string]: boolean};
}

interface AiMappingResults {
    mapping_config: MappingConfig;
}

const SourceRecordTypeDataModelPage = () => {
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const [searchParams] = useSearchParams();
    const { sourceId, sourceRecordTypeId, pipelineId } = useParams();
    const [loading, setLoading] = useState(false);
    const [activeBusinessObject, setActiveBusinessObject] = useState<BusinessObject|undefined>(undefined);
    const [activeStandardizationPipeline, setActiveStandardizationPipeline] = useState<StandardizationPipeline|undefined>(undefined);
    const [businessObjectOptions, setBusinessObjectOptions] = useState<any[]>([]);
    const [error, setError] = useState('');

    const [newBusinessObjectConfig, setNewBusinessObjectConfig] = useImmer<NewBusinessObjectConfig|undefined>(undefined);

    const [activeMappingConfig, setActiveMappingConfig] = useImmer<MappingConfig>({
        maps: {},
    });

    const standardizationPipelines = useStandardizationPipelines(sourceRecordTypeId);

    const {data: sourceRecordType} = useSourceRecordType(sourceRecordTypeId as string);

    const columnOptions = useMemo(() => {
        if (sourceRecordType && sourceRecordType.shape) {
            return sourceRecordType?.shape?.columns.map(c => c.key);
        }
        return [];
    }, [sourceRecordType]);

    const [businessObjectFields, setBusinessObjectFields] = useState<BusinessObjectField[]>([]);
    const [businessObjectFieldsDirty, setBusinessObjectFieldsDirty] = useState<boolean>(false);


    const [aiMapping, setAiMapping] = useState(false);
    const runAIMap = useCallback(async () => {
        if (!activeBusinessObject) {
            return;
        }
        setAiMapping(true);
        try {
            const results = await ApiService.getInstance().request('GET', `/sources/${sourceId}/record-type/${sourceRecordTypeId}/ai-map/${activeBusinessObject.id}`) as AiMappingResults;
            console.log('results', results);
            console.log('current', activeMappingConfig);

            const currentKeys = Object.keys(activeMappingConfig.maps);
            
            const newMaps: {
                [key: string]: BusinessObjectFieldMap
            } = {};

            setActiveMappingConfig((draft) => {
                Object.keys(results.mapping_config.maps).forEach(k => {
                    if (!currentKeys.includes(k) || ( 
                        !activeMappingConfig.maps[k].raw_sql && !activeMappingConfig.maps[k].source_columns.length
                    )) {
                        draft.maps[k] = results.mapping_config.maps[k];
                    }
                });
            });
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setAiMapping(false);
        }
    }, [activeMappingConfig, activeBusinessObject]);
    const businessObjects = useBusinessObjects();
    useEffect(() => {
        if (pipelineId && standardizationPipelines.data && businessObjects.data) {
            const pipeline = standardizationPipelines.data.find(sp => sp.id === pipelineId);
            if (!pipeline) {
                setError('Not found');
                return;
            }

            setActiveStandardizationPipeline(pipeline);

            const businessObject = businessObjects.data.find(bo => bo.id === pipeline.business_object_id);
            if (!businessObject) {
                setError('Business object not found');
                return;
            }

            setActiveBusinessObject(businessObject);
            setActiveMappingConfig(pipeline.mapping_config);
            const preSorted = [...businessObject.fields];
            setBusinessObjectFields(preSorted.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                return 1;
            }));
        }
    }, [pipelineId, activeBusinessObject, standardizationPipelines.dataUpdatedAt, businessObjects.dataUpdatedAt]);


    useEffect(() => {
        const bizobjId = searchParams.get('businessObjectId');
        if (bizobjId && businessObjects.data) {
            setActiveStandardizationPipeline(undefined);
            const activeBo = businessObjects.data.find(bo => bo.id === bizobjId);
            setActiveBusinessObject(activeBo);
            const preSorted = [...activeBo!.fields];
            setBusinessObjectFields(preSorted.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                return 1;
            }));

            
        }
    }, [searchParams, businessObjects.dataUpdatedAt]);


    const save = useCallback(async () => {
        setSaving(true);
        try {
            // update the BO
            if(activeBusinessObject && businessObjectFieldsDirty) {
                const bizobj = await saveBusinessObject({
                    ...activeBusinessObject,
                    fields: businessObjectFields,
                })
            }

            let currentPipeline = activeStandardizationPipeline;

            if(!currentPipeline) {
                // need to create the std pipeline
                currentPipeline = {
                    id: null,
                    source_record_type_id: sourceRecordTypeId as string,
                    business_object_id: activeBusinessObject!.id as string,
                    mapping_config: {
                        maps: {},
                    },
                    dedupe_config: {
                        shared_fields: [],
                        resolution_column: '',
                        resolution_direction: 'DESC',
                        fuzzy_match_threshold: 1.0,
                    },
                    filter_config: {
                        filters: []
                    }
                }
            }

            const savedPipeline = await saveStandardizationPipeline({
                ...currentPipeline,
                mapping_config: activeMappingConfig,
            });


            
            navigate(`/sources/${sourceId}/data/${sourceRecordTypeId}/standardize/${savedPipeline.id}`);
            toast('success', 'Success', 'Mapping saved');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [activeBusinessObject, businessObjectFields, businessObjectFieldsDirty, activeMappingConfig, activeStandardizationPipeline]);


    const doDeletePipeline = useCallback(async (pipeline: StandardizationPipeline) => {
        const result = await StandardizationPipelineORM.delete(pipeline);
        standardizationPipelines.refetch();
        navigate(`/sources/${sourceId}/data/${sourceRecordTypeId}/standardize`)
        setActiveBusinessObject(undefined)
        setActiveStandardizationPipeline(undefined);
        setActiveMappingConfig({maps: {}});
        toast('success', 'Success!', 'Mapping deleted');
    }, [])

    const confirmDeleteStdPipeline = useCallback(() => {
        confirmation({
            header: 'Delete Source Mapping?',
            confirmationButtonText: 'Delete',
            message: `To delete this mapping, please type "${activeBusinessObject!.name}" in the field below:`,
            typedValueExpectation: activeBusinessObject!.name,
            confirmationButtonVariant: 'danger',
            onConfirm: () => {
                doDeletePipeline(activeStandardizationPipeline!);
            },
            onClose: () => {}
        });
    }, [activeBusinessObject, activeStandardizationPipeline]);

    const onChangeMappingConfig = useCallback((config: MappingConfig) => {
        setActiveMappingConfig(config);
    }, []);

    const onChangeBusinessObjectFields = useCallback((fields: BusinessObjectField[]) => {
        setBusinessObjectFields(fields);
        setBusinessObjectFieldsDirty(true);
    }, []);


    useEffect(() => {
        if (!businessObjects.data || !standardizationPipelines.data) {
            return;
        }
        const stdPipelinesByBusinessObjectId: {
            [key: string]: StandardizationPipeline
        } = {};

        standardizationPipelines.data.forEach(sp => {
            stdPipelinesByBusinessObjectId[sp.business_object_id] = sp;
        });
        const mappedBusinessObjectIds = Object.keys(stdPipelinesByBusinessObjectId);

        const mappedBusinessObjects = businessObjects.data.filter(bo => mappedBusinessObjectIds?.includes(bo.id as string));

        const unmappedBusinessObjects = businessObjects.data.filter(bo => !mappedBusinessObjectIds?.includes(bo.id as string));

        const bizobjId = searchParams.get('businessObjectId');
        if(!pipelineId && !bizobjId && mappedBusinessObjects.length == 1) {
            // auto select the first pipeline if there is only one.
            navigate(`/sources/${sourceId}/data/${sourceRecordTypeId}/standardize/${stdPipelinesByBusinessObjectId[mappedBusinessObjects[0].id as string].id}`);
            return;
        }

        setBusinessObjectOptions([
            {
                label: 'Mapped Business Objects',
                options: mappedBusinessObjects.map(bo => {
                    return {label: bo.name, value: bo.id}
                })
            },
            {
                label: 'Unmapped Business Objects',
                options: unmappedBusinessObjects.map(bo => {
                    return {
                        label: bo.name,
                        value: bo.id,
                    }
                })
            }
        ]);

    }, [businessObjects.dataUpdatedAt, standardizationPipelines.dataUpdatedAt, pipelineId, searchParams]);


    const selectedBusinessObject = useCallback(async (val: string) => {
        if (!standardizationPipelines.data) {
            return;
        }

        const activePipeline = standardizationPipelines.data.find(sp => sp.business_object_id === val);


        if (activePipeline) {
            navigate(`/sources/${sourceId}/data/${sourceRecordTypeId}/standardize/${activePipeline.id}`);
        } else {
            navigate(`/sources/${sourceId}/data/${sourceRecordTypeId}/standardize?businessObjectId=${val}`);
        }

    }, [sourceRecordTypeId, standardizationPipelines.dataUpdatedAt]);


    const addBusinessObject = (label: string) => {
        const ops = Object.assign({}, ...columnOptions.map((x) => ({[x]: true})));
        setNewBusinessObjectConfig({label: label, toggleAll: true, selectedOptions: ops});
    };

    const cancelNewBOCreation = useCallback(() => {
        setNewBusinessObjectConfig(undefined)
    }, []);


    const toggleNewBOColOption = useCallback((colOption: string) => {
        setNewBusinessObjectConfig(draft => {
            draft!.selectedOptions[colOption] = !draft!.selectedOptions[colOption]
        })
    }, []);


    const toggleAllNewBOColOptions = useCallback(() => {
        setNewBusinessObjectConfig(draft => {
            draft!.toggleAll = !draft?.toggleAll;

            for (let key in draft?.selectedOptions) {
                draft!.selectedOptions[key] = draft!.toggleAll
            }
        })
    }, []);


    const saveAndUseNewBO = useCallback(async () => {
        try{
            const newFields : BusinessObjectField[] = [];

            for (let key in newBusinessObjectConfig?.selectedOptions) {
                if(newBusinessObjectConfig?.selectedOptions[key] == true){
                    newFields.push({
                        label: key,
                        name: key,
                        type: 'STRING'
                    })
                }
            }

            const newBOData = {
                id: null,
                name: newBusinessObjectConfig!.label,
                description: '',
                fields: newFields
            };

            const fieldMaps: any = {};
            const newBO = await saveBusinessObject(newBOData);
            // insert the new BO into our query data so its available right away
            queryClient.setQueryData(['business_objects'], businessObjects.data?.concat([newBO]));
            newBO.fields.forEach((field) => {
                fieldMaps[field.id!] = {
                    source_columns: [field.label],
                    concat_separator: ' ',
                    resolution_method: 'CONCAT',
                    use_raw_sql: false
                }
            })
            navigate(`/sources/${sourceId}/data/${sourceRecordTypeId}/standardize?businessObjectId=${newBO.id}`);
            setActiveStandardizationPipeline(undefined);
            setActiveMappingConfig({maps: fieldMaps});
            setNewBusinessObjectConfig(undefined);
        }catch (err) {
            console.error(err);
            toast('danger', 'Error!', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [newBusinessObjectConfig]);    


    return <div className="row">

        <Modal show={!!newBusinessObjectConfig} onHide={cancelNewBOCreation}>
            <Modal.Header closeButton>
                <Modal.Title>Business Object from Source</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Business Object Name</Form.Label>
                    <Form.Control value={newBusinessObjectConfig?.label} onChange={(e) => setNewBusinessObjectConfig((d) => {d!.label = e.target.value})}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <hr />
                    <Form.Label>Source Attributes</Form.Label>
                </Form.Group>
                <Form.Group style={{padding: '3px 7px'}}>
                    <Form.Check type="checkbox"
                        id="toggle-all"
                        label="(select all)"
                        checked={newBusinessObjectConfig?.toggleAll}
                        onChange={ () => toggleAllNewBOColOptions()}
                    />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
                {columnOptions.map((colOption) => {
                    return (
                    <Form.Group style={{padding: '3px 7px'}}>
                        <Form.Check type="checkbox"
                            id={colOption}
                            label={colOption}
                            checked={newBusinessObjectConfig?.selectedOptions[colOption] == true}
                            onChange={ () => toggleNewBOColOption(colOption)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={cancelNewBOCreation}>Close</button>
                <button className="btn btn-pliable" onClick={() => saveAndUseNewBO()} disabled={newBusinessObjectConfig && newBusinessObjectConfig!.label.length < 2}>Create</button>
            </Modal.Footer>
        </Modal>

        <div className="col-12">
            <PageTitle title="Map to Business Objects" subtitle="Add this source data to your business objects.">
                {activeBusinessObject && (
                    <span className="ms-1"><Link title={`go to ${activeBusinessObject.name} busniess object`} to={`/business-objects/${activeBusinessObject.id as string}/sources`}><BusinessObjectsIcon height='1.5em' /></Link> </span>
                )}
                <div style={{width: '300px', display: 'inline-block', textAlign: 'left'}}>

                <CreatableSelect
                    options={businessObjectOptions}
                    placeholder="Select business object"
                    onCreateOption={(inputVal) => addBusinessObject(inputVal)}
                    formatCreateLabel={(inputVal) => `Create "${inputVal}" business object`}
                    value={activeBusinessObject ? {label: activeBusinessObject.name, value: activeBusinessObject.id} : ''}
                    //@ts-ignore
                    onChange={(v) => selectedBusinessObject(!!v ? (v.value as string) : '')}
                />
                </div>
                
                {activeBusinessObject && (
                    <>
                        <span className="ms-1">
                        <AsyncButton
                            variant="danger"
                            text="Delete"
                            disabled={!activeStandardizationPipeline}
                            onClick={confirmDeleteStdPipeline}
                            loading={false}
                        />
                        </span>
                        
                        <span className="ms-1">
                            <SaveButton
                                loading={saving}
                                onClick={save}
                            />
                        </span>
                    </>
                    
                )}
                
            </PageTitle>
            <div className="content-inner">
                <div className="section">
                    {activeBusinessObject && (
                        <>
                             <h4>
                                Configure mapping to {activeBusinessObject.name}
                            </h4>
                            <div className="mb-3">
                                <AsyncButton
                                    loading={aiMapping}
                                    icon="mdi mdi-robot"
                                    onClick={() => runAIMap()}
                                    text="Map with AI"
                                    variant="pliable"
                                />
                            </div>
                            
                                
                            
                            <FieldMapping
                            businessObjectFields={businessObjectFields}
                            config={activeMappingConfig}
                            columnOptions={columnOptions}
                            onChangeMappingConfig={onChangeMappingConfig}
                            onChangeBusinessObjectFields={onChangeBusinessObjectFields}
                        />
                        </>
                        


                    )}
                    {!activeBusinessObject && (
                        <>
                            <div className="alert alert-info">Select a business object to continue.</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    </div>
}

export default SourceRecordTypeDataModelPage;