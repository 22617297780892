
import moment from 'moment';
import AuthService from './auth/auth.service';


export enum Events {
    USER_LOGIN = 'user_login',
    USER_LOGOUT_CLICK = 'user_logout_click',
    USER_TENANT_SWITCH_CLK = 'user_tenant_switch_click',
    USER_TENANT_SWITCHED = 'user_tenant_switched',
    USER_ADMIN_CLK = 'user_admin_click',
    BUSINESS_OBJECT_PROCESS_CLK = 'businessobject_process_click',
    BUSINESS_OBJECT_DOWNLOAD_ALL_CLK = 'businessobject_download_all_click',
    BUSINESS_OBJECT_DOWNLOAD_ALL_READY = 'businessobject_download_all_ready',
    BUSINESS_OBJECT_DOWNLOAD_ALL_ERROR = 'businessobject_download_all_error',
    BUSINESS_OBJECT_DOWNLOAD_ALL_DOWNLOADED = 'businessobject_download_all_downloaded',
    SRT_FILTER_TAB_CLK = 'srt_filter_tab_click',
    SRT_ADD_FILTER_CLK = 'srt_add_filter_click',
    SRT_REMOVE_FILTER_CLK = 'srt_remove_filter_click',
    SRT_APPLY_FILTER_CLK = 'srt_apply_filter_click',
    SRT_DEDUPE_TAB_CLK = 'srt_dedupe_tab_click',
    SRT_APPLY_DEDUPE_CLK = 'srt_apply_dedupe_click',
    SRT_COLUMN_PREFS_REORDERED = 'srt_column_prefs_reordered',
    SRT_COLUMN_VISIBILITY_TOGGLED = 'srt_column_visibility_toggled',
    SRT_SAVE_CONFIGURATION_CLK = 'srt_save_configuration_click',
    RUN_OVERLAP_ANALYSIS_CLK = 'run_overlap_analysis_click',
    REPORT_RUN_CLICK = 'report_run_click',
    REPORT_REVERT_CLICK = 'report_revert_click',
    REPORT_SAVE_CLICK = 'report_save_click',
    REPORT_DOWNLOAD_CLICK = 'report_download_click'
}


export default class TrackingService {
    public static identify(user_id: string, properties?: any) {
        properties = (!properties) ? {} : properties;
        window.analytics.identify(user_id, properties);
    }

    public static track_event(event_name: string, properties?: any) {
        properties = (!properties) ? {} : properties;
        properties['tenant_uuid'] = AuthService.getInstance().getTenantId()
        window.analytics.track(event_name, properties);
    }
}
