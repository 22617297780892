import useGlobalState from '@stores/global.state';
import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import Nav from '../components/nav/Nav.component';
import Sidebar from '../components/sidebar/Sidebar.component';


const RootPage = () => {
    const sidebarExpanded = useGlobalState((state:any) => state.sidebarExpanded);

    return (
        <div className="wrapper">
            <div className="content-page">
                <div className="content">
                    <Nav />
                    <Outlet/>
                    
                </div>
            </div>
        </div>
    );
}

export default RootPage;
