import { Form } from "react-bootstrap";
import { SourceConfig } from "./shared";
import { useEffect } from "react";

interface Params {
    onConfigChanged: (config: SourceConfig) => void;
    onSecureConfigChanged: (config: SourceConfig) => void;
    oauthCreds?: {
        refresh_token: string;
        access_token: string;
        expires_in?: number;
    }
}
const HubspotConnectorConfig = (params: Params) => {
    useEffect(() => {
        if (params.oauthCreds) {
            params.onConfigChanged({});
            params.onSecureConfigChanged({
                'refresh_token': params.oauthCreds.refresh_token,
            });
        }
    }, [params.oauthCreds])
   
    return <>
        <Form.Group>
            <Form.Label>OAuth2 Refresh Token</Form.Label>
            <Form.Control disabled type="text" value={params.oauthCreds?.refresh_token}/>
        </Form.Group>
    </>;
}

export default HubspotConnectorConfig;