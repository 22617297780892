import { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { AuditLogEntry } from '@models/auditLogEntry';
import { timeAgo } from '@services/time.service';
import EventActorLabel from '@components/auditLog/partials/EventActorLabel.component';
import { addEmitHelpers } from 'typescript';
import ConfirmationModal from '@components/alert/ConfirmationModal.component';

interface Props {
    entry: AuditLogEntry;
}

interface State {
}


export default class DefaultAuditLogEntry<S> extends Component<Props, State & S> {
    getIcon() {
        return "mdi-delete"
    }

    getMessage() {
        return (
            <p>{JSON.stringify(this.props.entry)}</p>
        )
    }

    getOptions() {
        return (
           <></>
        )
    }

    render() {
        return (
            <div className="card h-100">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-1 log-entry-icon">
                            <i className={`mdi ${this.getIcon()}`}></i>
                        </div>
                        <div className="col-10">
                            {this.getMessage()}
                        </div>
                        <div className="col-1">
                            {this.getOptions()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}