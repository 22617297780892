import { useParams } from "react-router";
import PipelineOrchestrationFlow from "@components/orchestration/PipelineOrchestration.component";


function PipelineOrchestrationDetails() {
    const { pipelineOrchestrationId } = useParams();

    return (
        <PipelineOrchestrationFlow edges_required={true} pipelineOrchestrationId={pipelineOrchestrationId as string} />
    )
}

export default PipelineOrchestrationDetails;