import BusinessObjectSelector from "@components/businessObjects/BusinessObjectSelector.component";
import SaveButton from "@components/button/SaveButton.component";
import { CardHeader } from "@components/card/Card.component";
import SourceSelector from "@components/sources/SourceSelector.component";
import { BusinessObject } from "@models/businessObject";
import PipelineORM, { Pipeline, PipelineStepConfig } from "@models/pipeline";
import { SourceRecordType } from "@models/source";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useImmer } from 'use-immer';

const NewPipelinePage = () => {
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);

    const [sourceRecordType, setSourceRecordType] = useState<SourceRecordType|undefined>(undefined);
    const [businessObject, setBusinessObject] = useState<BusinessObject|undefined>(undefined);

    const [pipeline, setPipeline] = useImmer<Pipeline>({
        id: null,
        name: '',
        description: '',
        pipeline_type: 'ADVANCED',
    });

    console.log('PIPELINE IS NOW:', pipeline);

    const handleUpdate = useCallback((key: 'name'|'description'|'pipeline_type', value: string) => {
        console.log('Updating', key, value);
        setPipeline(draft => {
            draft[key] = value;
        })
    }, []);

    const save = useCallback(async () => {

        setSaving(true);
        try {
            const pipelineToSave = {
                ...pipeline
            }

            console.log('Saving', pipeline.pipeline_type, sourceRecordType);
            if (pipeline.pipeline_type === 'STANDARDIZATION' && !!sourceRecordType) {
                console.log('Setting steps');
                const steps: PipelineStepConfig[] = [
                    {
                        name: 'Load source data',
                        step_type: 'SOURCE',
                        config: {
                            'source_id': sourceRecordType.source_id,
                            'source_record_type_id': sourceRecordType.id as string,
                        }
                    }, {
                        name: 'Map to data model',
                        step_type: 'OUTPUT',
                        config: {
                            'business_object_id': !!businessObject ? businessObject.id as string : ''
                        }
                    }
                ]

                pipelineToSave.steps = steps;
            }


            const result = await PipelineORM.save(pipelineToSave);

            navigate(`/pipelines/${result.id as string}/configuration`);
            

        } catch (err) {
            console.error(err);
            toast('danger', 'Error!', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
        
    }, [pipeline, sourceRecordType]);


    return <>
        <div className="row mt-5">
            <div className="col-3"></div>
            <div className="col-6">
                <div className="card">
                    <div className="card-header bg-primary text-white">
                        <h3>Create New Pipeline</h3>
                    </div>
                    <div className="card-body">
                        <Form.Group className="mb-3">
                            <Form.Label>Name your pipeline</Form.Label>
                            <Form.Control isValid={!!pipeline.name} value={pipeline.name} onChange={(e) => handleUpdate('name', e.target.value)} />
                            <Form.Text>Something unique, like "salesforce_contact_cleanup"</Form.Text>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid city.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>What type of pipeline do you want to create?</Form.Label>
                            <Form.Select onChange={(e) => handleUpdate('pipeline_type', e.target.value)} value={pipeline.pipeline_type}>
                                <option value="ADVANCED">Advanced - Full control over the entire pipeline</option>
                                <option value="STANDARDIZATION">Standardize - Convert source data so it fits into your data model</option>
                            </Form.Select>
                        </Form.Group>

                        {pipeline.pipeline_type === 'STANDARDIZATION' && (
                            <>
                                <fieldset className="mt-3">
                                    <p className="text-muted">Select the data source you want to standardize</p>
                                    <SourceSelector
                                        selectedId={sourceRecordType ? sourceRecordType.id as string: ''}
                                        onSelect={(srt: SourceRecordType|undefined) => {
                                            setSourceRecordType(srt);
                                        }}
                                    />

                                    <hr />
                                    <p className="text-muted">And now select the business object you want to generate.</p>
                                    <BusinessObjectSelector
                                        selectedId={businessObject ? businessObject.id as string : ''}
                                        onSelect={bizobj => setBusinessObject(bizobj)}
                                    />

                                </fieldset>
                            </>
                            


                        )}

                    </div>
                    <div className="card-footer">
                        <SaveButton
                            disabled={!pipeline.name}
                            loading={saving}
                            onClick={save}
                        />

                    </div>
                </div>
            </div>
            
        </div>
    </>


};

export default NewPipelinePage;