import { useQuery } from 'react-query';
import SourceORM, {SourceRecordTypeORM, SourceRecordTypeOption, SourceRecordType} from '@models/source';

export const useSourceRecordTypes = (sourceId: string) =>
    useQuery<SourceRecordType[]>({ queryKey: ['sourceRecordTypes', sourceId], queryFn: async () => {
        const resp = await SourceRecordTypeORM.find({
            'source_id': {
                '$eq': sourceId,
            }
        });
        return resp.records;
    } });


export const useSourceCatalog = (sourceId: string) =>
    useQuery<SourceRecordTypeOption[]>({ queryKey: ['sourceCatalog', sourceId], queryFn: () => SourceORM.getConnectorCatalog(sourceId) });

