import Nav from "@components/nav/Nav.component";
import { Outlet } from "react-router-dom";
import logo from '../assets/images/pliable/logo_with_text.png';

const Welcome = () => {
    return <div className="wrapper">
        <div className="content-page sidebar-disabled">
            <div className="content">
                <Nav disableSidebar={true}/>
                
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className="px-4 py-5 my-5">
                            <img src={logo} alt="" className="d-block mx-auto mb-4" style={{width:'300px'}}/>
                            
                            <div className="card my-2">
                                <div className="card-body p-onboarding">
                                    <Outlet/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
}

export default Welcome;