import { SourceRecordType } from "@models/source";
import { useSourceRecordTypes, useSources } from "@stores/data.store";
import { useEffect, useMemo, useState } from "react";

interface SourceRecordTypeNameProps {
    sourceRecordType?: SourceRecordType;
    sourceRecordTypeId?: string;
}

const SourceRecordTypeName = (props: SourceRecordTypeNameProps) => {
    const sources = useSources();

    const sourceRecordTypes = useSourceRecordTypes();

    const [sourceRecordType, setSourceRecordType] = useState<SourceRecordType|undefined>(undefined);

    useEffect(() => {
        if (props.sourceRecordType) {
            setSourceRecordType(props.sourceRecordType);
        } else if (props.sourceRecordTypeId && sourceRecordTypes.data) {
            setSourceRecordType(sourceRecordTypes.data.find(srt => srt.id === props.sourceRecordTypeId));
        }
    }, [props.sourceRecordType, props.sourceRecordTypeId, sources.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt])

    const source = useMemo(() => {
        if (!sourceRecordType) {
            return undefined;
        }
        if (sources.data) {
            return sources.data.find(s => s.id === sourceRecordType.source_id);
        }
        return undefined;
    }, [sources.dataUpdatedAt, sourceRecordType]);

    return <>
        {source?.name} - {sourceRecordType?.name}
    </>
}

export default SourceRecordTypeName;