import { ColumnRef } from "@models/shared";
import { Form } from "react-bootstrap";
import { StepComponentProps } from "../shared";
import { useEffect, useState } from "react";
import { ColumnRefForm } from "@components/sql/ColumnRef.component";
import { getColumnsForPipelineStep } from "@services/tablestructure.service";
import ColumnRefList from "@components/sql/ColumnRefList.component";

interface ColumnOrder {
    column_def: string;
    ascending: boolean;
}

interface SourcePreference {
    field_name: string;
    source_priority_order: string[];
}

interface DedupeStepConfig {
    match_on_fields?: string[];
    fields_to_match_on?: ColumnRef[];
    minimum_number_of_matched_fields: number;
    fuzzy_match_threshold: number;
    merge_resolution: 'SQUASH' | 'PICK_WINNER' | 'GROUP_WITHOUT_MERGING';
    column_prioritization: ColumnOrder[];
    source_preference_by_column?: SourcePreference[];
}
const DedupeStep = (props: StepComponentProps) => {
    const { stepConfig, onChange, stepIndex } = props;
    const parsedConfig = stepConfig.config as DedupeStepConfig;

    const [upstreamColumnOptions, setUpstreamColumnOptions] = useState<string[]>([]);

    useEffect(() => {
        const loadUpstreamColumnOptions = async () => {
            const columns = await getColumnsForPipelineStep(props.pipelineId, props.pipelineExecutionId as string, props.stepIndex - 1);

            setUpstreamColumnOptions(columns.map(c => c.PATH));
        }
        if (props.previousStepStatus === 'COMPLETE') {
            loadUpstreamColumnOptions();
        }
    }, [props.previousStepStatus])


    if (!parsedConfig.fields_to_match_on && !!parsedConfig.match_on_fields) {
        parsedConfig.fields_to_match_on = parsedConfig.match_on_fields.map(f => ({
            'raw_sql': f
        }));

        onChange('fields_to_match_on', parsedConfig.fields_to_match_on);
    }

    if (!parsedConfig.column_prioritization) {
        parsedConfig.column_prioritization = [];
        onChange('column_prioritization', []);
    }


    if (parsedConfig.fuzzy_match_threshold === undefined) {
        parsedConfig.fuzzy_match_threshold = 1.0;
        onChange('fuzzy_match_threshold', 1.0);
    }
    const updateMatchOnField = (idx: number, value: ColumnRef) => {
        const newMatchOnFields = parsedConfig.fields_to_match_on?.map((f, thisIdx) => {
            if (thisIdx === idx) {
                return value;
            }
            return f;
        });

        onChange('fields_to_match_on', newMatchOnFields);
    }

    const addMatchOnField = () => {
        const newMatchOnFields = [
            ...parsedConfig.fields_to_match_on || [],
            ''
        ];
        onChange('fields_to_match_on', newMatchOnFields);

    }

    const removeMatchOnField = (idx: number) => {
        const newMatchOnFields = [...parsedConfig.fields_to_match_on || []];
        newMatchOnFields.splice(idx, 1);
        onChange('fields_to_match_on', newMatchOnFields);

    }

    const updatePriorityOrder = (idx: number, k: keyof ColumnOrder, value: string|boolean) =>  {
        const newPriorityOrder = parsedConfig.column_prioritization.map((f, thisIdx) => {
            if (thisIdx == idx) {
                return {
                    ...f,
                    [k]: value,
                }
            }
            return f
        });

        console.log(newPriorityOrder)

        onChange('column_prioritization', newPriorityOrder);
    }

    const addPriorityOrder = () => {
        const newPriorityOrder = [
            ...parsedConfig.column_prioritization,
            {
                'column_def': '',
                'ascending': true,
            }
        ];

        onChange('column_prioritization', newPriorityOrder);
    }

    const removePriorityOrder = (idx: number) => {
        const newPriorityOrder = [...parsedConfig.column_prioritization];
        newPriorityOrder.splice(idx, 1);
        onChange('column_prioritization', newPriorityOrder);

    }


    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Match on these Columns</Form.Label>
                <div>
                    <ColumnRefList
                        disabled={!props.allowInteractivity}
                        columnRefs={parsedConfig.fields_to_match_on || []}
                        columnOptions={upstreamColumnOptions}
                        onAddColumn={addMatchOnField}
                        onRemoveColumn={removeMatchOnField}
                        onChangeColumn={updateMatchOnField}
                    />
                </div>
            </Form.Group>
            <Form.Group className="mb-3">
                    <Form.Label>Fuzzy Match Threshold</Form.Label>
                    <input 
                        className="form-range" 
                        disabled={!props.allowInteractivity}
                        type="range" 
                        max="1.0" 
                        min="0.01" 
                        step="0.01"
                        value={parsedConfig.fuzzy_match_threshold}
                        onChange={(e) => onChange('fuzzy_match_threshold', parseFloat(e.target.value))}
                    />
                    <Form.Text className="text-muted">{Math.round(parsedConfig.fuzzy_match_threshold * 100)}%</Form.Text>
                </Form.Group>
            <Form.Group className="mb-3" onChange={(e) => {
                console.log('Form gropu changed', e);
            }}>
                <Form.Label>Merge Behavior</Form.Label>
                <Form.Select
                    disabled={!props.allowInteractivity}
                    value={parsedConfig.merge_resolution}
                    onChange={(e) => onChange('merge_resolution', e.target.value)}
                >
                    <option value="PICK_WINNER">Pick a single record per group based on a priority order</option>
                    <option value="SQUASH">Squash all grouped records together based on a priority order</option>
                    <option value="GROUP_WITHOUT_MERGING">Do not merge, but add a 'group' attribute to each record</option>
                </Form.Select>
                
                
                
            </Form.Group>

            {['SQUASH', 'PICK_WINNER'].includes(parsedConfig.merge_resolution) && (
                <Form.Group>
                    <Form.Label>Prioritization</Form.Label>
                    {props.allowInteractivity && (

                
                        <div className="mb-3">
                            <button
                                className="btn btn-outline-secondary btn-sm me-1"
                                onClick={() => addPriorityOrder()}
                            >
                                <i className="mdi mdi-plus"></i> Priority
                            </button>
                            
                        </div>
                    )}
                    <table className="table table-bordered table-sm">
                    <tbody>
                        {parsedConfig.column_prioritization.map((f, idx) => {
                            return (
                                <tr>
                                    <td style={props.allowInteractivity ? {width: '60%'} : {width: '70%'}}>
                                        <Form.Control disabled={!props.allowInteractivity} type="text" className="no-border font-monospace pe-0 ps-0" placeholder="Column or SQL definition" value={f.column_def} onChange={(e) => updatePriorityOrder(idx, 'column_def', e.target.value)}/>
                                    </td>
                                    <td style={{width: '30%'}}>
                                        <button disabled={!props.allowInteractivity} className="btn btn-outline-dark btn-xs" onClick={() => {
                                            updatePriorityOrder(idx, 'ascending', !f.ascending)
                                        }}>
                                            {f.ascending && (
                                                <>
                                                    <i className="mdi mdi-arrow-up"></i> ASC
                                                </>
                                            )}
                                            {!f.ascending && (
                                                <>
                                                    <i className="mdi mdi-arrow-down"></i> DESC
                                                </>
                                            )}
                                        </button>
                                    </td>
                                    {props.allowInteractivity && (

                                    
                                        <td style={{width: '10%'}}>
                                            <button 
                                                className="btn btn-sm action-icon"
                                                onClick={(e) => removePriorityOrder(idx)}
                                            ><i className="mdi mdi-close-circle"></i>
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </Form.Group>
            )}
        </>
    )

}

export default DedupeStep;