import formatNumber from 'format-number';


export const integerFormatter = formatNumber();
export const decimalFormatter = formatNumber({
    round: 2,
});

export const summarizeNumber = (num: number) => {
    if (num < 1000) {
        return num.toString();
    } else if (num < 100000) {
        return ((Math.round(num/100)) / 10).toString() + 'k';
    } else if (num < 1000000) {
        return Math.floor(num/1000).toString() + 'k';
    } else if (num < 1000000000) {
        return (Math.round(num/100000) / 10).toString() + 'M';
    } else if (num < 1000000000000) {
        return (Math.floor(num/100000000) / 10).toString() + 'B';
    }
    return '';
}


export const formatPercentage = (num: number) => {
    // .96562
    // * 10000 = 9656,2
    // Round = 9656
    // /100 = 96.56
    return decimalFormatter(Math.round(num * 10000) / 100);
}