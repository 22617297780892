import { Component } from "react";
import { Spinner, Tabs } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../components/pageTitle/PageTitle.component";
import FileORM, { File, FilePart } from "../models/file";
import Form from 'react-bootstrap/Form';
import { CardHeader } from "../components/card/Card.component";
import { bytesToSize } from '../services/files.service';
import ApiService, { ListRecordsResponse, SingleRecordResponse, JobEnqueueResponse } from "../services/api/api.service";
import Tab from 'react-bootstrap/Tab';
import { SimpleDataTable, tableDataFromRows } from "../components/datatable/DataTable.component";
import AsyncButton from "../components/button/AsyncButton.component";
import SourceORM, { Source, SourceRecordType, SourceRecordTypeORM } from "../models/source";
import { parseDateString, timeAgo } from "../services/time.service";
import toast from "../services/toast.service";
import BackgroundService from "../services/bg.service";
import { getErrorMessage } from "../services/errors.service";
import LoadingCard from "../components/card/LoadingCard.component";

interface Props {
    fileId: string;
    navigate: (route: string) => void;

}


interface State {
    loading: boolean;
    file?: File;
    source?: Source;
    recordTypes: SourceRecordType[];
    fileParts?: FilePart[];
    activeTab: string;
    processingFile: boolean;
    selectedRecordTypeId: string;
    processingError?: string;
}
class FilePage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            file: undefined,
            recordTypes: [],
            activeTab: '',
            processingFile: false,
            selectedRecordTypeId: '',
        };
    }

    private async load() {
        this.setState({
            loading: true,
            file: undefined,
        });

        const file = await FileORM.findById(this.props.fileId);
        
        const parts = await ApiService.getInstance().request(
            'GET', 
            `/files/${this.props.fileId}/parts`
        ) as ListRecordsResponse<FilePart>;

        const recordTypes = await SourceRecordTypeORM.find({
            'source_id': {
                '$eq': file.source_id,
            }
        });

        const source = await SourceORM.findById(file.source_id);

        this.setState({
            loading: false,
            file: file,
            source: source,
            fileParts: parts.records.map(p => {
                // @ts-ignore
                p.loaded_at = parseDateString(p.loaded_at);
                return p;
            }),
            recordTypes: recordTypes.records,
            activeTab: parts.records.length ? (parts.records[0].id as string) : '',
        });

        if (parts.records.length === 0) {
            // If we haven't processed it yet, let's auto-trigger processing.
            this.processFile();
        }

    }

    async processFile() {
        this.setState({
            processingFile: true,
        })
        const results = await ApiService.getInstance().request(
            'POST',
            `/files/${this.props.fileId}/process`
        ) as JobEnqueueResponse;

        try {
            const partResults = await BackgroundService.getInstance().waitForJob(results.job_id);
            this.setState({
                processingFile: false,
                fileParts: partResults,
                activeTab: partResults[0].id as string,
            })

        } catch (err) {
            this.setState({
                processingFile: false,
                processingError: getErrorMessage(err),
            })
        }
    }

    componentDidMount() {
        this.load();
    }

    async loadFilePart(partId: string) {
        this.setState({
            processingFile: true,
        });
        const results = await ApiService.getInstance().request(
            'POST',
            `/files/${this.props.fileId}/parts/${partId}/load`,
            {
                'headers': [],
                'source_record_type_id': this.state.selectedRecordTypeId,
            }
        ) as JobEnqueueResponse;
        
        try {
            const jobResult = await BackgroundService.getInstance().waitForJob(results.job_id);
            this.setState({
                processingFile: false,
            });
            this.load();

        } catch (err) {
            this.setState({
                processingFile: false,
                processingError: getErrorMessage(err),
            })
        }

    }

    async deleteFile() {
        const result = await ApiService.getInstance().request('DELETE', `/files/${this.props.fileId}`);
        toast('info', 'File deleted', 'All derivative data has been removed from available data sources.')
        this.props.navigate(`/sources/${this.state.file?.source_id as string}`);
    }

    renderFileParts() {
        if (this.state.processingFile) {
            return (
                <LoadingCard action="Processing File" />
            )
        }else if (this.state.fileParts?.length === 0) {
            return (
                <div>
                    File has not been processed yet.
                    <button className="btn btn-primary" disabled={this.state.processingFile} onClick={() => {this.processFile()}}>Process</button>
                </div>
            )
        } else {
            return (
                <>
                    <Tabs
                        activeKey={this.state.activeTab}
                        onSelect={(k) => this.setState({activeTab: k as string})}
                        className="mb-3 nav-bordered"
                    >
                        {this.state.fileParts?.map(p => {

                            return (
                                <Tab key={p.part_identifier} className="p-0" eventKey={p.id as string} title={p.part_identifier}>
                                    <div className="table-responsive">
                                    <SimpleDataTable data={tableDataFromRows([p.headers].concat(p.sample_rows))}/>
                                    </div>
                                    
                                    <hr />
                                    
                                        {p.processing_status == 'COMPLETE' && (
                                            <>
                                                <p className="text-muted">{p.record_count.toLocaleString('en-US')} records loaded {timeAgo(p.loaded_at)}</p>
                                                <p>
                                                    <Link
                                                        className="fw-bold text-info"
                                                        to={'/pipelines/new?source_id=' + (this.state.source?.id as string) + '&source_record_type_id=' + this.state.selectedRecordTypeId}
                                                    >Create a pipeline</Link> from this data
                                                </p>
                                                
                                                {/* <p className="text-muted font-13">
                                                    You can <strong className="text-info">profile</strong> the data in this file to get a better sense for what's in it, or <strong className="text-warning">compare</strong> it to other files in this data source.
                                                </p>
                                                <button className="btn btn-outline-info me-1" disabled={this.state.processingFile}>
                                                    <i className="mdi mdi-chart-line"/> Profile
                                                </button>
                                                <button className="btn btn-outline-warning me-1" disabled={this.state.processingFile}>
                                                    <i className="mdi mdi-scale-balance"/> Compare
                                                </button> */}
                                            </>
                                        )}
                                       
                                        {p.processing_status != 'COMPLETE' && (
                                            <>
                                                <p className="text-muted">
                                                    <strong className="text-primary">Load</strong> {p.record_count.toLocaleString('en-US')} records into your database to make it available to your team.
                                                </p>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Select Record Type</Form.Label>
                                                    <Form.Select onChange={(e) => {
                                                        this.setState({selectedRecordTypeId: e.target.value})
                                                    }}>
                                                        <option>Select One</option>
                                                        {this.state.recordTypes.map(rt => {
                                                            return (
                                                                <option value={rt.id as string}>{rt.name}</option>
                                                            );
                                                        })}
                                                        {/* <option value="~NEW~">+ Add New</option> */}
                                                    </Form.Select>
                                                </Form.Group>
                                                
                                                <AsyncButton
                                                    text="Load"
                                                    icon="mdi mdi-check-circle"
                                                    loading={this.state.processingFile}
                                                    variant="primary"
                                                    onClick={() => this.loadFilePart(p.id as string)}
                                                />
                                            </>
                                            
                                        )}
                                        

                                        
                                </Tab>
                            )
                        })}
                    </Tabs>
                </>
                
            )
        }
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>
        } else if (this.state.file && this.state.source) {
            return (
                <div>
                    <PageTitle title={"File " + this.state.file.name} breadcrumbs={[
                        {
                            title: 'Data Sources',
                            path: '/sources'
                        }, {
                            title: this.state.source.name,
                            path: `/sources/${this.state.file.source_id}`
                        }, {
                            title: this.state.file.name
                        }
                    ]}/>
                    <div className="row">
                        <div className="col-3">
                            <div className="card">
                                <div className="card-body">
                                    <CardHeader title="File Details"/>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <p className="font-16">
                                            {this.state.file.name}
                                        </p>
                                        <Form.Label>Size</Form.Label>
                                        <p className="font-16">
                                            {bytesToSize(this.state.file.size)}
                                        </p>
                                        {/* <Form.Label>Records</Form.Label>
                                        <p className="font-16">
                                            123,456
                                        </p> */}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body d-grid">
                                    <button className="btn btn-primary btn-lg mb-3" disabled={this.state.processingFile} onClick={() => this.processFile()}>
                                        {this.state.processingFile && <Spinner size="sm"/>}
                                        Re-Process File
                                    </button>
                                    <button className="btn btn-danger btn-lg" onClick={() => this.deleteFile()}>Delete File</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="card">
                                <div className="card-body">
                                    {this.renderFileParts()}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>File not found</div>
            )
        }
    }
}

const WrappedFilePage = () => {
    const { fileId } = useParams();
    const navigate = useNavigate();
    return <FilePage fileId={fileId as string} navigate={navigate}></FilePage>
}
export default WrappedFilePage;