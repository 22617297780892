import { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import ORM, { BaseModel } from "./orm";
import BackgroundService from "@services/bg.service";




export interface DataMart extends BaseModel {
    name: string;
    sql: string;
    description: string;
    table_name?: string;
}


export class _DataMartORM extends ORM<DataMart>{
    public async getData(datamartId: string) {
        const result = await this.apiService.request('GET', `/${this.endpoint}/${datamartId}/data`) as ListRecordsResponse<any>;
        return result.records;
    }

    public async getShape(datamartId: string) {
        const result = await this.apiService.request('GET', `/${this.endpoint}/${datamartId}/shape`) as SingleRecordResponse<any>;
        return result.record;
    }

    public async getDownloadUrl(datamartId: string, override_query?: string) {
        const result = await this.apiService.request('POST', `/${this.endpoint}/${datamartId}/download`, {
            override_query: override_query
        }) as JobEnqueueResponse;
        return await BackgroundService.getInstance().waitForJob(result.job_id);
    }
};


const DataMartORM = new _DataMartORM('datamarts');
export default DataMartORM;
  