import { ColumnRefForm } from "@components/sql/ColumnRef.component";
import { ColumnRef } from "@models/shared";
import { getColumnsForPipelineStep } from "@services/tablestructure.service";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { StepComponentProps } from "../shared";

export interface Mapper {
    output_id: string;
    output_name: string;
    source_definition?: string;
    field_description?: string;
    column_ref?: ColumnRef;
}

export interface MapStepConfig {
    mappers: Mapper[];
}


const MapStep = (props: StepComponentProps) => {
    const { stepConfig, onChange, stepIndex } = props;
    const parsedConfig = stepConfig.config as MapStepConfig;

    const [upstreamColumnOptions, setUpstreamColumnOptions] = useState<string[]>([]);


    if (!parsedConfig.mappers) {
        parsedConfig.mappers = [];
    }
    
    const addField = (fieldName: string) => {
        const newMappers = [
            ...parsedConfig.mappers,
            {
                output_name: '',
                source_definition: '',
            }
        ];

        onChange('mappers', newMappers);
    }

    const updateMapperColumnRef = (idx: number, value: ColumnRef) => {
        const newMappers = parsedConfig.mappers.map((m, thisIdx) => {
            if (idx === thisIdx) {
                return {
                    ...m,
                    column_ref: value,
                }
            }
            return m;
        });
        onChange('mappers', newMappers);
    }

    const updateMapperOutputName = (idx: number, value: string) => {
        const newMappers = parsedConfig.mappers.map((m, thisIdx) => {
            if (idx === thisIdx) {
                return {
                    ...m,
                    output_name: value,
                }
            }
            return m;
        });
        onChange('mappers', newMappers);
    }

    const removeField = (idx: number) => {
        const newMappers = [...parsedConfig.mappers];
        newMappers.splice(idx, 1);
        onChange('mappers', newMappers);
    }

    useEffect(() => {
        const loadUpstreamColumnOptions = async () => {
            const columns = await getColumnsForPipelineStep(props.pipelineId, props.pipelineExecutionId as string, props.stepIndex - 1);

            setUpstreamColumnOptions(columns.map(c => c.PATH));
        }
        if (props.previousStepStatus === 'COMPLETE') {
            loadUpstreamColumnOptions();
        }
    }, [props.previousStepStatus])
    

    return (
        <>
            
            {props.allowInteractivity && (

           
                <div className="mb-3">
                    <button
                        className="btn btn-outline-secondary btn-sm me-1"
                        onClick={() => addField('')}
                    >
                        <i className="mdi mdi-plus"></i> Field
                    </button>
                    
                </div>
            )}
            
            {parsedConfig.mappers.map((m: Mapper, idx: number) => {
                let columnRef: ColumnRef;

                if (!!m.column_ref) {
                    columnRef = m.column_ref;
                } else if (!!m.source_definition) {
                    columnRef = {
                        'raw_sql': m.source_definition as string,
                    }
                } else {
                    columnRef = {
                        'raw_sql': '',
                    }
                }
                return <Form.Group className="mb-3">
                    <div className="row">
                        <div className={props.allowInteractivity ? 'col-11' : 'col-12'}>
                            <Form.Control disabled={!props.allowInteractivity} type="text" className="mb-1" placeholder="Column name" value={m.output_name} onChange={(e) => updateMapperOutputName(idx, e.target.value)}/>

                            <ColumnRefForm
                                disabled={!props.allowInteractivity}
                                columnOptions={upstreamColumnOptions}
                                columnRef={columnRef}
                                onChange={(newRef: ColumnRef) => {
                                    updateMapperColumnRef(idx, newRef);
                                }}
                            />
                            
                        </div>
                        {props.allowInteractivity && (

                        
                            <div className="col-1">
                                <button 
                                    className="btn btn-lg action-icon m-0 p-0"
                                    onClick={(e) => removeField(idx)}
                                ><i className="mdi mdi-close-circle"></i>
                                </button>
                            </div>
                        )}
                    </div>
                    
                </Form.Group>
            })}
        </>
    )


}

export default MapStep;