import excelImg from '@assets/images/connectors/excel_wide.png';
import snowflakeImg from '@assets/images/connectors/snowflake_wide.png';
import styled from 'styled-components';
import { Form, Spinner } from "react-bootstrap";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import { saveSource, useSourceTypes } from "@stores/data.store";
import LoadingCard from '@components/card/LoadingCard.component';
import { SourceType } from '@models/source';
import Select from 'react-select';


const SourceSelectOption = (props: any) => {
    return (
        <div {...props.innerProps} className="d-flex flex-row">
            <div style={{margin: '7px'}}>
                <img src={props.value.icon_path} style={{width: '50px', height: '50px'}}/>
            </div>
            <div style={{margin: '5px 7px'}}>
                <strong>{props.value.title}</strong><br />
                <p>{props.value.description}</p>
            </div>
        </div>
    );
}

const SourceContainer = styled.div`
div.selector {
    border: solid 1px var(--ct-border-color);
    height: 300px;
    text-align: center;
    padding-top: 80px;
    cursor: pointer;

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    img {
        height: 100px;
        max-height: 100px;
        max-width: 100%;
        margin-bottom: 2rem;
    }

    &:hover {
        background-color: var(--ct-light);
    }

    &.selected {
        background-color: var(--ct-primary);

        img {
            filter: brightness(0) invert(1);

        }

        p {
            color: white;
        }
    }
}
`
const NewSourcePage = () => {
    const [selectedSourceType, setSelectedSourceType] = useState<SourceType>();
    const [dataSourceName, setDataSourceName] = useState('');
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);

    const {isLoading, data: sourceTypes } = useSourceTypes();



    const createDataSource = useCallback(async () => {
        setSaving(true);
        try {
            const newSource = await saveSource({
                id: null,
                name: dataSourceName,
                type: selectedSourceType!.id,
            });
            navigate(`/sources/${newSource.id}`)
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [selectedSourceType, dataSourceName]);

    if (isLoading) {
        return (
            <LoadingCard />
        );
    }
    return <>
        <div className="content-inner">
            <div className="section spaced row justify-content-md-center">
                <div className="col-6 ">
                    <h2>Choose a Data Source Location</h2>
                    <p>Once you import data you'll be able to create business objects and relationships across your data.</p>


                    <div className="row mb-2">
                        <Select
                        placeholder="Where is your data coming from?"
                        value={ (selectedSourceType)? {label: selectedSourceType?.title as string, value: selectedSourceType} : null}
                        options={sourceTypes?.map((st) => {return {label: st.title, value: st}})} 
                        onChange={(op) => {
                                if(op){
                                    setSelectedSourceType(op.value as SourceType)
                                }
                            }
                        }
                        components={{ Option: SourceSelectOption }} />

                    </div>

                    {selectedSourceType && (
                        <div className="row">
                            <div className="col-12"><hr />
                            </div>
                            <div className="col-12">
                                <Form.Group className="mb-2">
                                    <Form.Label>
                                        Name your {selectedSourceType!.title} data source
                                    </Form.Label>
                                    <Form.Control value={dataSourceName} onChange={(e) => setDataSourceName(e.target.value)}>

                                    </Form.Control>
                                    <Form.Text>
                                        Try "Files from Joe" or "Salesforce Export"
                                    </Form.Text>
                                </Form.Group>

                                <button onClick={() => createDataSource()} disabled={!selectedSourceType || !dataSourceName || saving} className="btn btn-pliable">
                                    Create Data Source
                                </button>
                                {saving && <Spinner size="sm"/>}
                            </div>
                        </div>
                    )}
                </div>
                
            
            </div>
        </div>
    </>
    
}

export default NewSourcePage;