import { Component } from 'react';
import Select from 'react-select';
import { SourceRecordTypeColumn, SourceRecordTypeOption } from '@models/source';
import {Form, Col, Row} from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';


interface Props {
    recordTypeOptions: SourceRecordTypeOption[];
    selectedOptions: any;
    primaryKeys: any;
    onToggle: (optionName: string) => void
    onPrimaryKeyChange: (optionName: string, change: any) => void
}

interface State {}   


export default class RecordTypeOptionsList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            recordTypeOptions: [],
        }
    }

    toggle(optionName: string) {
        this.props.onToggle(optionName);
    }

    onPrimaryKeyChange(optionName: string, change: any) {
        this.props.onPrimaryKeyChange(optionName, change);
    }

    renderOption(recordTypeOption: SourceRecordTypeOption) {
        const record_type = recordTypeOption;

        const options = [...record_type.columns];

        const optionIds = options.map(o => o.value);
        if (this.props.primaryKeys[record_type.name]) {
            this.props.primaryKeys[record_type.name].forEach((pk: SourceRecordTypeColumn) => {
                if (!optionIds.includes(pk.value)) {
                    options.push(pk);
                }
            })
            
        }

        return (
            <tr key={recordTypeOption.name}>
                <td>
                <Form.Group>
                        <Form.Check type="checkbox"
                            id={record_type.name}
                            label={record_type.label}
                            checked={this.props.selectedOptions[record_type.name] == true}
                            onChange={ () => this.toggle(record_type.name)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </td>
                <td>
                    <Form.Group>
                        <CreatableSelect
                            isMulti
                            name={`${record_type.name}_primary_key`}
                            defaultValue={this.props.primaryKeys[record_type.name]}
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Primary Key Column(s)"
                            onChange={(change) => this.onPrimaryKeyChange(recordTypeOption.name, change)}
                        />
                    </Form.Group>
                </td>
            </tr>
        )
    }
    
    render() {
        if (this.props.recordTypeOptions.length == 0) {
            return (
                <div className="alert alert-info">No Resources Found.</div>
            )
        }

        return (
            <table className="table table-centered">
                <thead className="table-light">
                    <tr>
                        <th>Resource Name</th>
                        <th>Primary Key</th>
                    </tr>
                </thead>
                <tbody>
                {this.props.recordTypeOptions.map((rto, idx) => { return this.renderOption(rto)})}

                </tbody>
            </table>
           
        )
    }
}