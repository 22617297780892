import { Source, SourceRecordType } from "@models/source"
import { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useSourceRecordTypes, useSources } from "@stores/data.store";
import Select, { SingleValue } from 'react-select';

export interface Option {
    label: string | undefined;
    value: string | null;
}
interface SourceSelectorProps {
    selectedId: string;
    inline?: boolean;
    disabled?: boolean;
    onSelect: (srt: SourceRecordType|undefined) => void;
}
const SourceSelector = (props: SourceSelectorProps) => {
    const [selectedSourceRecordType, setSelectedSourceRecordType] = useState<SourceRecordType|undefined>(undefined);

    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();


    useEffect(() => {
        if (!sourceRecordTypes.data) {
            return;
        }
        setSelectedSourceRecordType(sourceRecordTypes.data.find(f => f.id === props.selectedId));
    }, [props.selectedId, sourceRecordTypes.dataUpdatedAt]);



    const groupedOptions = (!!sources.data && !!sourceRecordTypes.data) ? sources.data.map(s => {
        return {
            label: s.name,
            options: sourceRecordTypes.data.filter(srt => srt.source_id === s.id).map(srt => {
                return { label: `${s.name} - ${srt.name}`, value: srt.id }
            })
        }
    }) : [];

    const allOptions = groupedOptions.reduce((previous: Option[], current) => {
        return previous.concat(current.options);
    }, []);

    

    const selectedOption = allOptions.find(o => o.value === props.selectedId);


   
    const onSelect = (srtId: string) => {
        const srt = sourceRecordTypes.data?.find(s => s.id === srtId);
        props.onSelect(srt);
    }

    let wrapperClass = '';
    if (props.inline) {
        wrapperClass = 'inline-form-control';
    }

    return <div className={wrapperClass}>
        <Select isDisabled={!!props.disabled} options={groupedOptions} value={selectedOption} onChange={(e) => onSelect(!!e ? (e.value as string) : '')}/>
        
    </div>
}

export default SourceSelector;