import { Form } from "react-bootstrap";
import { StepComponentProps } from "../shared";

interface ModelFilter {
    column_def: string;
    comparator: string;
    value?: string;
    name: string;
    raw_sql?: string;
}

interface FilterStepConfig {
    filters: ModelFilter[];
}

const FilterStep = (props: StepComponentProps) => {
    const parsedConfig = props.stepConfig.config as FilterStepConfig;

    if (!parsedConfig.filters) {
        parsedConfig.filters = [];
    }

    const updateFilter = (idx: number, key: keyof ModelFilter, value: string) => {
        const newFilters = parsedConfig.filters.map((f, thisIdx) => {
            if (thisIdx === idx) {
                return {
                    ...f,
                    [key]: value,
                };
            }
            return f;
        });

        props.onChange('filters', newFilters);
    }

    const addFilter = () => {
        const newFilters = [
            ...parsedConfig.filters,
            {
                raw_sql: '',
            }
        ];
        props.onChange('filters', newFilters);

    }

    const removeFilter = (idx: number) => {
        const newFilters = [...parsedConfig.filters];
        newFilters.splice(idx, 1);
        props.onChange('filters', newFilters);

    }

    return <>
            <Form.Group className="mb-3">
                <Form.Label>Filters</Form.Label>
                <p className="text-muted font-13">Only records that pass all of the rules below will be passed on to the next step.</p>
                {props.allowInteractivity && (
                    <div className="mb-3">
                        <button
                            className="btn btn-outline-secondary btn-sm me-1"
                            onClick={() => addFilter()}
                        >
                            <i className="mdi mdi-plus"></i> Field
                        </button>
                        
                    </div>
                )}
                <table className="table table-bordered table-sm">
                    <tbody>
                        {parsedConfig.filters.map((f, idx) => {
                            return (
                                <tr>
                                    <td style={props.allowInteractivity ? {width: '90%'} : {width: '100%'}}>
                                        <Form.Control type="text" className="no-border font-monospace pe-0 ps-0" placeholder="data:foo::string = 'bar'" value={f.raw_sql} onChange={(e) => updateFilter(idx, 'raw_sql', e.target.value)}/>
                                    </td>
                                    {props.allowInteractivity && (

                                    
                                        <td style={{width: '10%'}}>
                                            <button 
                                                className="btn btn-sm action-icon"
                                                onClick={(e) => removeFilter(idx)}
                                            ><i className="mdi mdi-close-circle"></i>
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Form.Group>
        </>

}

export default FilterStep;