import { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AuditLogEntryORM, { AuditLogEntry } from '@models/auditLogEntry';
import RecordPagination from '@components/pagination/Pagination.component';

import DefaultAuditLogEntry from '@components/auditLog/entry_types/DefaultAuditLogEntry.component';
import CreateResourceLogEntry from '@components/auditLog/entry_types/CreateResourceLogEntry.component';
import UpdateResourceLogEntry from '@components/auditLog/entry_types/UpdateResourceLogEntry.component';
import DeleteResourceLogEntry from '@components/auditLog/entry_types/DeleteResourceLogEntry.component';
import EditRecordColumnEntry from '@components/auditLog/entry_types/EditRecordColumnEntry.component';


const ITEMS_PER_PAGE = 25;

interface RowProps {
    entry: AuditLogEntry;
}


class LogEntryRow extends Component<RowProps> {
    render() {
        const entry = this.props.entry;
        switch(entry.action) {
            case 'update_record_column_value':
                return <EditRecordColumnEntry entry={entry} />
            case 'create':
                return <CreateResourceLogEntry entry={entry} />
            case 'update':
                return <UpdateResourceLogEntry entry={entry} />
            case 'delete':
                return <DeleteResourceLogEntry entry={entry} />
            default:
                return <DefaultAuditLogEntry entry={entry} />
        }
    }
}

interface Props {
    filter: object|null
}

interface State {
    loading: boolean;
    page: number;
    entries: AuditLogEntry[];
    totalResults: number;
    totalPages: number;
}


export class AuditLogEntriesList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            entries: [],
            totalResults: 0,
            totalPages: 0,
            page: 1
        }
    }

    private async load() {
        this.setState({
            loading: true,
            entries: [],
        });
        
        const resp = await AuditLogEntryORM.find(this.props.filter, ITEMS_PER_PAGE, this.state.page, '-created_at');
        
        this.setState({
            loading: false,
            entries: resp.records,
            totalResults: resp.total_records,
            totalPages: resp.total_pages
        })
    
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        this.load();
    }

    goToPage(page: number) {

        console.log(`Go to: ${page}`)
        this.setState({
            page: page
        });
        this.load();
    }
    
    render() {
        if (this.state.loading) {
            return <Spinner/>
        } else if (this.state.entries.length === 0) {
            return (
                <p>
                    No data log entries.
                </p>
            )
        } else {
            return (
                <>
                    <div className='d-flex justify-content-center'>
                        <RecordPagination current_page={this.state.page} total_pages={this.state.totalPages} onPageChange={(p) => this.goToPage(p)} />
                    </div>
                    <div className='row'>
                        {this.state.entries.map(e => {
                            return (<LogEntryRow entry={e} />);
                        })}
                    </div>
                    <div className='d-flex justify-content-center'>
                        <RecordPagination current_page={this.state.page} total_pages={this.state.totalPages} onPageChange={(p) => this.goToPage(p)} />
                    </div>
                    
                </>
            )
        }
    }
}

const WrappedAuditLogEntriesList = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const filter = searchParams.get('filter');
    let filterObj = null;
    if (filter) {
        filterObj = JSON.parse(filter);
        console.log(filter);
    }

    return <AuditLogEntriesList filter={filterObj}/>;
}
export default WrappedAuditLogEntriesList;