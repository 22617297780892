export function getWilsonConfidenceInterval(sampleSize: number, successes: number): number[] {
        if (sampleSize === 0) {
            return [0, 0];
        }
    
        // phat is the proportion of successes
        // in a Bernoulli trial process
        const phat = successes / sampleSize;
    
        // z is 1-alpha/2 percentile of a standard
        // normal distribution for error alpha=5%
        const z = 1.96;
    
        // implement the algorithm
        // (http://goo.gl/kgmV3g)
        const a = phat + z * z / (2 * sampleSize);
        const b = z * Math.sqrt((phat * (1 - phat) + z * z / (4 * sampleSize)) / sampleSize);
        const c = 1 + z * z / sampleSize;
    
        return [(a - b) / c, (a + b) / c];
    };