import React, { Component, ReactElement, ReactNode, useMemo } from 'react';
import { Source } from '@models/source';
import { useSourceTypes } from '@stores/data.store';
import { useNavigate } from 'react-router-dom';

interface Props {
    source: Source;
}

const SourceInfoCard = (props: Props) => {
    const navigate = useNavigate();
    const sourceTypes = useSourceTypes();

    const sourceType = useMemo(() => {
        if (sourceTypes.data) {
            return sourceTypes.data.find(s => s.id === props.source.type);

        } return undefined;
    }, [sourceTypes.dataUpdatedAt, props.source]);

    const gotoSource = () => {
        navigate(`/sources/${props.source.id}`)
    }
    
    return (
        <div className="card">
            <div className="card-body" onClick={gotoSource} style={{'cursor':'pointer'}}>
                {sourceType && (
                    <img src={sourceType.icon_path} style={{maxWidth: '100%'}}/>
                )}
                <h5>{props.source.name}</h5>
                <p className="font-13 text-muted">{props.source.description}</p>
            </div>
        </div>
    )
}

export default SourceInfoCard;






