import { Component, ReactNode, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import PageTitle from "../components/pageTitle/PageTitle.component";
import ProgressBar from 'react-bootstrap/ProgressBar';
import ApiService, { AnyRecordsResponse, ApiError, ListRecordsResponse, SingleRecordResponse } from "../services/api/api.service";
import Spinner from "react-bootstrap/Spinner";
import { decimalFormatter, integerFormatter } from "../services/formatting.service";
import Badge from "react-bootstrap/esm/Badge";
import DataTable from "../components/datatable/DataTable.component";
import { CardHeader } from "../components/card/Card.component";
import PipelineORM, { Pipeline, PipelineStepConfig } from "../models/pipeline";
import PipelineExecutionORM, { ExecutionStep, PipelineExecution } from "../models/pipelineExecution";
import { getErrorMessage } from "../services/errors.service";
import { Form } from "react-bootstrap";
import { timeAgo } from "../services/time.service";
import { sleep } from "../services/bg.service";
import { Link } from "react-router-dom";
import PipelineStepsComponent from "@components/pipelines/PipelineSteps.component";
import PipelineExecutionStatus from "@components/pipelines/PipelineExecutionStatus.component";
import FinalReview from "@components/pipelines/review/FinalReview.component";
import toast from "@services/toast.service";


function PipelineExecutionDetails() {
    const { pipelineExecutionId, pipelineId } = useParams();
    const [pipeline, setPipeline] = useState<Pipeline|undefined>(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [promoting, setPromoting] = useState(false);
    const [execution, setExecution] = useState<PipelineExecution|undefined>(undefined);

    const intervalTracker = useRef<boolean|null>(null);

    useEffect(() => {
        setLoading(true);
        
        const waitForExecution = async () => {
            const execution = await PipelineExecutionORM.findById(pipelineExecutionId as string);
            setExecution(execution);
            
            if (!intervalTracker.current) {
                return;
            }
            if (!['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(execution.status)) {
                await sleep(1000);
                waitForExecution();
            }
        }
        const loadData = async () => {
            try {
                intervalTracker.current = true;
                waitForExecution();

                const pipeline = await PipelineORM.findById(pipelineId as string);
                setPipeline(pipeline);
                
                setLoading(false);
            } catch (err) {
                const errMessage = getErrorMessage(err);
                setLoading(false);
                setError(errMessage);
            }
        };

        loadData();

        return () => {
            intervalTracker.current = null;
        }
    }, []);

    const promoteData = async () => {
        if (!pipeline || !execution) {
            return;
        }
        setPromoting(true);

        try {
            const promotionResponse = await ApiService.getInstance().request('POST', `/pipelines/${pipeline.id as string}/execution/${execution.id as string}/approve`) as SingleRecordResponse<PipelineExecution>;
            setExecution(PipelineExecutionORM.convertDates(promotionResponse.record));
            toast('success', 'Success!', 'Pipeline data promoted.');

        } catch (err) {
            const errMessage = getErrorMessage(err);
            toast('danger', 'Error', errMessage);
        } finally {
            setPromoting(false);
        }
    }

    

    if (loading) {
        return <Spinner/>
    } else if (pipeline && execution) {
        return (
            <>
                <PageTitle title="Pipeline Execution" breadcrumbs={[
                    {
                        path: '/pipelines',
                        title: 'Pipelines'
                    }, {
                        path: '/pipelines/' + (pipeline.id as string),
                        title: pipeline.name as string
                    }, {
                        title: 'Execution Details'
                    }
                ]}/>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Form.Group className="mb-3">
                                    <Form.Label>Pipeline</Form.Label>
                                    <p className="font-16">{pipeline.name}</p>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Started At</Form.Label>
                                    <p className="font-16">{timeAgo(execution?.executed_at)}</p>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Started By</Form.Label>
                                    <p className="font-16">{execution?.executed_by}</p>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <p className="font-20">
                                        <PipelineExecutionStatus execution={execution as PipelineExecution}/>
                                    </p>
                                </Form.Group>
                                {execution.status === 'ERROR' && (
                                    <p className="alert alert-danger">{execution.error}</p>
                                )}
                                {!!execution.approved_or_rejected_at && execution.status === 'COMPLETE' && (
                                    <>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Approved At</Form.Label>
                                            <p className="font-16">{timeAgo(execution?.approved_or_rejected_at)}</p>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Approved By</Form.Label>
                                            <p className="font-16">{execution?.approved_or_rejected_by}</p>
                                        </Form.Group>
                                    </>
                                    
                                    
                                )}
                                {!!execution.approved_or_rejected_at && execution.status === 'REJECTED' && (
                                    <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Rejected At</Form.Label>
                                        <p className="font-16">{timeAgo(execution?.approved_or_rejected_at)}</p>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Rejected By</Form.Label>
                                        <p className="font-16">{execution?.approved_or_rejected_by}</p>
                                    </Form.Group>
                                </>
                                )}
                                {execution.status === 'COMPLETE' && (
                                    <div className="mb-3">
                                        <Link to={`/pipelines/${pipelineId as string}/data`} className="btn btn-primary">View Output Data</Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PipelineStepsComponent
                            pipelineId={execution.pipeline_id}
                            pipelineExecution={execution}
                            allowEdits={false}
                            autoLoadOutput
                        />
                    </div>
                </div>
                {execution && pipeline && execution.status === 'IN_REVIEW' && (
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h1>Review Output</h1>
                                    <p className="text-muted">Review the final output of your pipeline before promoting it. The resulting data will go into a Snowflake table called <code>datasets.{pipeline.name}</code> and be available for use in other pipelines.</p>
                                    {promoting && <Spinner/>}
                                    {!promoting && (
                                        <FinalReview
                                            pipeline={pipeline}
                                            execution={execution}
                                            onApprove={promoteData}
                                        />
                                    )}
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                )}
            </>
        );
    }
    return <p>Error!</p>
    
}

export default PipelineExecutionDetails;