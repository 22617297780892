import ApiService from "../services/api/api.service";

function SetupPage() {
    const setup = async () => {
        const result = await ApiService.getInstance().request('GET', '/setup');
        console.log(result);
    }

    return <button className="btn btn-primary" onClick={() => setup()}>Run Setup</button>
}

export default SetupPage;