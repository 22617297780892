import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Uppy, { UppyFile } from '@uppy/core'
import AwsS3, { AwsS3UploadParameters } from '@uppy/aws-s3'
import { DragDrop } from '@uppy/react'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import ApiService from '../../services/api/api.service';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { File } from '../../models/file';

interface Props {
    label: string;
    parallelUploads: number;
    maxUploads: number;
    sourceId: string;
    onUploadComplete?: (file: File) => void;
}

interface State {
    file: UppyFile<Record<string, unknown>, Record<string, unknown>>|null;
    uploadStatus: null|'UPLOADING'|'ERROR'|'COMPLETE';
    fileObject?: File;
}

interface UploadRequestResponse {
    record: File
    signed_upload_url: string;
}

export default class S3Uploader extends Component<Props, State> {
    uppy: Uppy;
    
    constructor(props: Props) {
        super(props);
        this.state = {
            file: null,
            uploadStatus: null,
        }

        this.uppy = new Uppy({
            meta: { type: 'avatar' },
            restrictions: { maxNumberOfFiles: 100 },
            autoProceed: true,
          })
          
        this.uppy.use(AwsS3, {
            limit: 5,
            timeout: 60000,
            getUploadParameters: async (file) => {
                /**
                 * File looks like this:
                 * {
                        "source": "react:DragDrop",
                        "id": "uppy-brokers/sample/csv-2v-1e-text/csv-12496-1672868674331",
                        "name": "BROKERS_SAMPLE.csv",
                        "extension": "csv",
                        "meta": {
                            "type": "text/csv",
                            "relativePath": null,
                            "name": "BROKERS_SAMPLE.csv"
                        },
                        "type": "text/csv",
                        "data": {},
                        "progress": {
                            "uploadStarted": 1673403950393,
                            "uploadComplete": false,
                            "percentage": 0,
                            "bytesUploaded": 0,
                            "bytesTotal": 12496
                        },
                        "size": 12496,
                        "isRemote": false,
                        "remote": ""
                    }
                 */
                const response = await ApiService.getInstance().request('POST', '/files/init-upload', {
                    name: file.name,
                    size: file.size,
                    mime_type: file.type,
                    source_id: this.props.sourceId,
                }) as UploadRequestResponse;
                
                this.setState({
                    file: file,
                    fileObject: response.record,
                });

                console.log('RESPONSE:', response);

                // Return upload parameters
                const returnVal: AwsS3UploadParameters = {
                    method: 'PUT',
                    url: response.signed_upload_url,
                    headers: {
                        'Content-Type': file.type as string,
                    }
                }


                return returnVal; 
            },
        });

        this.uppy.on('upload-progress', (file, progress) => {
            console.log('Got progress', file, progress);
            const theFile = this.state.file as UppyFile;
            theFile.progress = progress;
            theFile.progress.percentage = (progress.bytesUploaded/progress.bytesTotal) * 100;

            this.setState({
                file: theFile,
            });
                
        });

        this.uppy.on('upload-success', (file, response) => {
            console.log('GOT RESPONSE:', response, file);
            this.setState({
                uploadStatus: 'COMPLETE',
            });

            if (this.props.onUploadComplete) {
                this.props.onUploadComplete(this.state.fileObject as File);
            }
        });

        this.uppy.on('upload-error', (file, error, response) => {
            console.log('GOT ERROR', file, error, response);
            this.setState({
                uploadStatus: 'ERROR',
            })
        });

        //   this.uppy.on('upload-success', (file, response) => {
        //         let file_updates = Object.assign({}, this.props.files[file.id]);
        //         file_updates.uploadURL = response.uploadURL;
        //         file_updates.progress.uploadComplete = true;
        //         fetch('/file-ingestion/source-file-uploaded', {
        //             method: 'POST',
        //             headers: {
        //                 'Accept': 'application/json',
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify(file_updates)
        //         })
        //         let updates = Object.assign({}, this.props.files);
        //         updates[file.id] = file_updates;
        //         this.props.setProps({
        //             "files": updates
        //         })
        //    });

        //    this.uppy.on('upload-error', (file, error, response) => {
        //         let file_updates = Object.assign({}, this.props.files[file.id]);
        //         file_updates.error = error;
        //         file_updates.uploadError = true;
        //         fetch('/file-ingestion/source-file-upload-error', {
        //             method: 'POST',
        //             headers: {
        //                 'Accept': 'application/json',
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify(file_updates)
        //         })
        //         let updates = Object.assign({}, this.props.files);
        //         updates[file.id] = file_updates;
        //         this.props.setProps({
        //             "files": updates
        //         })
        //     });

    }

    renderFileDrop() {
        return (
            <div style={{height: '200px'}}>
                { this.props.sourceId &&
                <DragDrop
                    height='200px'
                    uppy={this.uppy}
                    locale={{
                    strings: {
                        // Text to show on the droppable area.
                        // `%{browse}` is replaced with a link that opens the system file selection dialog.
                        dropHereOr: this.props.label,
                        // Used as the label for the link that opens the system file selection dialog.
                        browse: 'browse',
                    },
                    }}
                /> }
            </div>
        );
    }

    render() {
        if (this.state.file) {
            let progressBarColor: string = 'primary';
            if (this.state.uploadStatus === 'COMPLETE') {
                progressBarColor = 'success';
            } else if (this.state.uploadStatus === 'ERROR') {
                progressBarColor = 'error';
            }

            return (
                <div>
                    <h6>Uploading File</h6>
                    <h4>{this.state.file.name}</h4>
                    <ProgressBar variant={progressBarColor} now={this.state.file.progress?.percentage} />;
                    { this.state.uploadStatus === 'COMPLETE' && (
                        <>
                            <p className="text-muted">Upload complete!</p>
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    file: null
                                });
                            }}>Upload Another</a>
                        </>
                        
                    )}
                </div>
            )
        }
        return (
            <div>
                {this.renderFileDrop()}
            </div>
        );
    }
}

