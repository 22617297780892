


export enum DataTypeIcons { 
    STRING_ICON_CLASS   = 'mdi mdi-alphabetical',
    INTEGER_ICON_CLASS  = 'mdi mdi-numeric',
    DECIMAL_ICON_CLASS  = 'mdi mdi-decimal',
    BOOLEAN_ICON_CLASS  = 'mdi mdi-checkbox-marked',
    DATE_ICON_CLASS     = 'mdi mdi-calendar',
    DATETIME_ICON_CLASS = 'mdi mdi-clock-outline'
} 

export default DataTypeIcons;