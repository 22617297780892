import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthService from '@services/auth/auth.service';
import GlobalDndContext from './dnd-context';
import { FronteggProvider } from '@frontegg/react';



const contextOptions = {
  baseUrl: AuthService.getInstance().authUrl
};

const authOptions = {
  keepSessionAlive: true,
  routes: {
    authenticatedUrl: '/afterlogin'
  }
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GlobalDndContext>
    <FronteggProvider 
      contextOptions={contextOptions} 
      hostedLoginBox={false}
      authOptions={authOptions}>
      <App />
    </FronteggProvider>
  </GlobalDndContext>
);


