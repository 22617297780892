import React from 'react';
import { getBezierPath, EdgeProps } from 'reactflow';
import './flowchart.css';

const foreignObjectSize = 40;

const onEdgeClick = (evt: any, id: string) => {
  evt.stopPropagation();
  alert(`remove ${id}`);
};

interface RemoveableEdgeData {
    onRemove: () => void;
}

export default function RemoveableEdge(props: EdgeProps<RemoveableEdgeData>) {
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        markerEnd,
    } = props;

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const onRemove = () => {
        props.data?.onRemove();
    }

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={labelX - foreignObjectSize / 2}
                y={labelY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <div>
                    <button className="edgebutton" onClick={() => onRemove()}>
                        ×
                    </button>
                </div>
            </foreignObject>
        </>
  );
}