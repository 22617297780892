import DataModel from "@components/businessObjects/DataModel.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import {CardHeader} from '@components/card/Card.component';
import { Link } from "react-router-dom";

const DataModelPage = () => {
    return <div>
        <PageTitle title="Data Model"></PageTitle>
        <div className="row">
            <div className="col-3">
                <div className="card">
                    <div className="card-body">
                        <CardHeader title="Your Data Goals"/>

                        <ul className="list-group">
                            <li className="list-group-item">
                                <h5>Return on Ad Spend</h5>
                                <div className="progress progress-md mb-3">
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '50%'}}></div>
                                </div>
                                <div className="text-end font-13">
                                    <Link to="/goals/123/analyze">Analyze</Link> | <Link to="/goals/123/todos">To-Dos</Link>

                                </div>

                            </li>
                            <li className="list-group-item">
                                <h5>Customer Journey</h5>
                                <div className="progress progress-md mb-3">
                                    <div className="progress-bar bg-info" role="progressbar" style={{width: '20%'}}></div>
                                </div>
                                <div className="text-end font-13">
                                    <Link to="/goals/123/analyze">Analyze</Link> | <Link to="/goals/123/todos">To-Dos</Link>

                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-pliable">New Goal</button>
                    </div>
                </div>
            </div>
            <div className="col-9">
                <DataModel/>

            </div>
        </div>
       
    </div>
}

export default DataModelPage;