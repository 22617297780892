import ORM, { BaseModel } from "./orm";
import ApiService from "../services/api/api.service";

export interface File extends BaseModel {
    id: string;
    name: string;
    size: number;
    source_id: string;
    mime_type: string;
    uploaded_at: Date;
    uploaded_by_id: string;
    processing_status: string;
    storage_key: string;
    record_count: number;
}

export interface FilePart extends BaseModel {
    file_id: string;
    part_identifier: string;
    object_type: string;
    headers: string[];
    sample_rows: string[][];
    record_count: number;
    processing_status: string;
    loaded_at: Date;
    source_record_type_id: string;

    ai_description?: string;
}


export class _FileORM extends ORM<File>{

    public async downloadOriginal(id: string) {
        const result : any = await ApiService.getInstance().request('GET', `/${this.endpoint}/${id}/original-file`);
        window.open(result.location_url, '_blank');
    }
}


const FileORM = new _FileORM('files', ['uploaded_at']);
export default FileORM;
  