import { Link } from "react-router-dom";

const SelectDestinationPage = () => {
    return <>
        <h4>Welcome to Pliable.</h4>
        <p>The first thing we need is a place to store and process your data. Pliable is built on top of Snowflake, so you'll need a Snowflake instance in order to continue.</p>

        <div className="row my-5">
            <div className="col">
                <div className="d-grid gap-2">
                    <Link to="/welcome/snowflake/byo" className="btn btn-outline-primary">I already have Snowflake</Link>
                </div>
            </div>
            <div className="col">
                <div className="d-grid gap-2">
                    <button className="btn btn-outline-primary" onClick={() => {window.Intercom('showNewMessage', 'I do not have a Snowflake instance');}}>Can you set one up for me?</button>
                </div>
            </div>
        </div>




    </>
}

export default SelectDestinationPage;