import React, { Component, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './pagetitle.css';

interface Breadcrumb {
    title: string;
    path?: string;
}

interface Props {
    title: string;
    icon?: string;
    subtitle?: string;
    breadcrumbs?: Breadcrumb[];
    buttons?: ReactNode;
    children?: ReactNode;
}

export default class PageTitle extends Component<Props> {
    renderRightSide() {
        if (this.props.breadcrumbs) {
            const crumbs = this.props.breadcrumbs as Breadcrumb[];
            const items = crumbs.map((bc: Breadcrumb, idx: number) => {
                if (idx === (crumbs.length - 1)) {
                    return <li className="breadcrumb-item active" key={idx}>{bc.title}</li>;
                }
                return <li className="breadcrumb-item" key={idx}>
                    <Link to={bc.path as string}>{bc.title}</Link>
                </li>;
            });
    
            return <ol className="breadcrumb m-0">{items}</ol>;
        } else if (this.props.buttons) {
            return this.props.buttons;
        }
        return <></>
        
    }

    render() {
        return (
            <div className="row border-bottom">
                <div className="col-12">
                    <div className="page-title-box">
                    {this.props.icon && (
                        <div className="page-title-icon">
                            <img src={this.props.icon} className="rounded-circle avatar-xs img-thumbnail"/>
                        </div>
                    )}
                    <div className="page-title-left">
                            <h1 className="page-title">
                                {this.props.title}
                                
                            </h1>
                            <div className="page-subtitle">
                                {this.props.subtitle}
                            </div>
                            
                        </div>
                        <div className="page-title-right">
                            {this.props.children}
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        );
    }
}