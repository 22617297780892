import { Component, ReactNode, ChangeEvent } from "react";
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import FormService from '../services/form.service';
import {ReactFormGenerator} from '../modules/rfb';
import { getErrorMessage } from '../services/errors.service';
import SourceORM from '../models/source';
import AuthService from '../services/auth/auth.service';

import 'react-form-builder2/dist/app.css';


interface Props {
    sourceId: string
}


interface State {
    loading: boolean
    source: any
    submissionComplete: boolean
    error?: string;
}


class FormEntryPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            source: null,
            submissionComplete: false
        };
    }

    componentDidMount() {
        this.load();
    }

    private async load() {
        this.setState({
            loading: true,
            source: null,
            submissionComplete: false
        });

        try {
            const source = await SourceORM.findById(this.props.sourceId);
            
            this.setState({
                loading: false,
                source: source,
            });

        } catch (err) {
            this.setState({
                loading: false,
                error: getErrorMessage(err),
            });
        }
        
    }

    async onSubmit(data: any) {
        this.setState({
            loading: true,
        });

        const user = AuthService.getInstance().authState?.user;
        const submission: any = {
            summitted_by: {
                id: user?.id,
                email: user?.email
            },
            form: {}
        };

        data.forEach((element: any) => {
            submission['form'][element.custom_name] = element.value;
        });

        await FormService.getInstance().saveFormSubmission(this.state.source.id, submission);
        this.setState({
            loading: false,
            submissionComplete: true
        });

    }

    render() {
        if (this.state.loading) {
            return <Spinner/>
        }

        if (this.state.submissionComplete) {
            return (

                <div className="row justify-content-center">
                    <div className="col-6" style={{marginTop: '25px'}}>
                        <Alert variant="secondary">Your submission has been recivied</Alert>
                    </div>
                    <div className="col-2" style={{marginTop: '25px'}}>
                        <Button variant="link" onClick={() => this.load()}>Submit Another</Button>
                    </div>
                </div>
                
            )
        }


            return (
                <div className="row justify-content-center">
                    <div className="col-6" style={{marginTop: '25px'}}>
                        <ReactFormGenerator
                            onSubmit={(d:any) => this.onSubmit(d)}
                            data={this.state.source.config.elements} 
                        />
                    </div>
                </div>
            );
        
        
    }
}


const WrappedFormEntryPage = () => {
    const { sourceId } = useParams();
    // const navigate = useNavigate();

    return <FormEntryPage sourceId={sourceId as string}/>;
}
export default WrappedFormEntryPage;
