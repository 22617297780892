import ConfirmationModal from "@components/alert/ConfirmationModal.component";
import SaveButton from "@components/button/SaveButton.component";
import LoadingCard from "@components/card/LoadingCard.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import SourceDetails from "@components/sources/SourceDetails.component"
import SourceIcon from "@components/sources/SourceIcon.component";
import SourceRecordTypesList from "@components/sources/SourceRecordTypesList.component";
import { ExcelDetails } from "@components/sources/connectors/ExcelConnector.component";
import SnowflakeConnectorConfig from "@components/sources/connectors/SnowflakeConnectorConfig.component";
import SourceORM, { Source, SourceType } from "@models/source";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { deleteSource, useSourceRecordTypes, useSourceTypes } from "@stores/data.store";
import { useCallback, useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";

const DataSourcePage = () => {
    const navigate = useNavigate();
    const { sourceId } = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [source, setSource] = useState<Source|undefined>(undefined);
    const [error, setError] = useState('');
    const sourceTypes = useSourceTypes();
    const [sourceType, setSourceType] = useState<SourceType|undefined>(undefined);

    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const sourceRecordTypes = useSourceRecordTypes(sourceId);

    const [sourceName, setSourceName] = useState('');
    const [sourceDescription, setSourceDescription] = useState('');

    const [processing, setProcessing] = useState(false);

    const displayMode = searchParams.get('mode');

    

    
    useEffect(() => {
        if (!sourceTypes.data || !source) {
            return;
        }
        setSourceType(sourceTypes.data.find(st => st.id == source.type));
    }, [sourceTypes.dataUpdatedAt, source]);

    const loadData = async () => {
        setLoading(true);
        try {
            const response = await SourceORM.findById(sourceId as string);
            setSource(response);
            setSourceName(response.name);
            setSourceDescription(!!response.description ? response.description : '');
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [sourceId]);

    const initConnector = useCallback(async () => {
        setProcessing(true);
        console.log('Running initialize');
        try {
            const response = await ApiService.getInstance().request('POST', `/sources/${sourceId}/init`, {}) as SingleRecordResponse<any>;
            console.log(response);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setProcessing(false);
        }
    }, [source]);

    const [saving, setSaving] = useState(false);
    const updateSourceDetails = useCallback(async () => {
        if (!source) {
            return;
        }
        setSaving(true);
        try {
            const updatedSource = {
                ...source,
                name: sourceName,
                description: sourceDescription,
            };

            const result = await SourceORM.save(updatedSource);
            setSource(result);
            toast('success', 'Success', 'Details saved');
        } catch (err) {
            toast('danger', 'Oops!', "We couldn't save your changes. " + getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [source, sourceName, sourceDescription]);

    const doDeleteSource =  useCallback(async () => {
        const result = await deleteSource(source as Source);
        navigate('/sources');
    }, [source]);


    if (loading) {
        return <LoadingCard  />;
    } else if (error) {
        return <p className="alert alert-danger">{error}</p>
    } else if (source && sourceType) {
        // Normal page
        return <>
            <div className="row ">
                <div className="col-3 border-right">
                <div className="content-inner">

                    <div className="section border-bottom" style={{position: 'relative'}}>
                        <div style={{position: 'absolute', top: '.5rem', right: '.5rem'}}>
                            <SourceIcon source={source} className="avatar-sm rounded-circle img-thumbnail float-right"/>
                        </div>
                            <h2>Data Source Details</h2>
                                <Form.Group className="mb-3">
                                    <Form.Label>Source Name</Form.Label>
                                    <Form.Control type="text" value={sourceName} onChange={(e) => setSourceName(e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" value={sourceDescription} onChange={(e) => setSourceDescription(e.target.value)}/>
                                </Form.Group>
                                <SaveButton loading={saving} onClick={updateSourceDetails}/>
                        </div>
                        <div className="section">
                            <SourceRecordTypesList sourceId={sourceId as string}/>
                        </div>
                        <div className="section">
                            <hr />
                            <ConfirmationModal
                                header="Delete data source?"
                                confirmationButtonText="Delete"
                                message={`To delete this data source, please type "${source?.name}" in the field below:`}
                                show={!!confirmDelete}
                                typedValueExpectation={source?.name}
                                onClose={() => setConfirmDelete(false)}
                                confirmationButtonVariant="danger"
                                onConfirm={() => doDeleteSource()}
                            />
                            <a role="button" className="btn btn-danger" onClick={() => setConfirmDelete(true)}>Delete Source</a>
                        </div>
                    </div>
                           
                </div>
                <div className="col-9">
                    <Outlet/>
               
                
                </div>
            </div>
            
        </>
    }
    return <></>;

}

export default DataSourcePage;