import S3Uploader from '@components/s3uploader/S3Uploader.component';
import { Source, SourceRecordType, SourceRecordTypeORM } from '@models/source';
import excelLogo from '../../../assets/images/connectors/excel_logo.png';
import FileORM, { File, FilePart } from '@models/file';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FilesList from '@components/files/FilesList.component';
import { emit } from '@nextcloud/event-bus'
import ApiService, { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from '@services/api/api.service';
import BackgroundService from '@services/bg.service';
import { getErrorMessage } from '@services/errors.service';
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SimpleDataTable, tableDataFromRows } from '@components/datatable/DataTable.component';
import CreatableSelect from 'react-select/creatable';
import { useSourceRecordTypes } from '@stores/data.store';
import toast from '@services/toast.service';
import AsyncButton from '@components/button/AsyncButton.component';
import LoadingCard from '@components/card/LoadingCard.component';

interface Params {
}

const ExcelConnect = (params: Params) => {
    return <p>You'll be able to upload your files on the next screen.</p>
    // return <>

    //     <div className="row">
    //         <div className="col-4">
    //             <S3Uploader 
    //                 label="Upload Files" 
    //                 maxUploads={1} 
    //                 parallelUploads={1} 
    //                 sourceId={params.source.id as string}
    //                 onUploadComplete={(file: File) => emit('ws:files:reload', {})}
    //             />
    //         </div>
    //         <div className="col-8">
    //             <FilesList sourceId={params.source.id as string}/>
    //         </div>
    //     </div>
    //     <p className="mt-3">
            
        

    //     </p>
    // </>;
}

interface ExcelDetailsParams {
    source: Source;

    // Optionally provide a file so we can skip the upload phase
    file?: File;
}
export const ExcelDetails = (params: ExcelDetailsParams) => {
    const navigate = useNavigate();

    const sourceRecordTypes = useSourceRecordTypes(params.source.id as string);



    const sourceRecordTypeOptions = useMemo(() => {

        if (!sourceRecordTypes.data) {
            return [];
        }
        return sourceRecordTypes.data.map(srt => {
            return {
                label: srt.name,
                value: srt.id,
            }
        });
    }, [sourceRecordTypes.dataUpdatedAt]);

    const fileUploadCompleted = useCallback((file: File) => {
        setTimeout(() => {
            navigate(`/sources/${params.source.id as string}/files/${file.id}`)
            // processFilePostUpload(file);
        }, 1000)
    }, []);

    return <>

        <div className="mb-2">
        <S3Uploader 
                label="Upload Files" 
                maxUploads={1} 
                parallelUploads={1} 
                sourceId={params.source.id as string}
                onUploadComplete={(file: File) => fileUploadCompleted(file)}
            />

        </div>
        <FilesList sourceId={params.source.id as string}/>

            
        </>;
    
}

export default ExcelConnect;
