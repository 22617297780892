import AsyncButton from "@components/button/AsyncButton.component";
import ExcelConnect from "@components/sources/connectors/ExcelConnector.component";
import HubspotConnectorConfig from "@components/sources/connectors/HubspotConnectorConfig.component";
import { SourceConfig } from "@components/sources/connectors/shared";
import SourceORM, { Source, SourceType } from "@models/source";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { useSourceTypes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"

interface Props {
    sourceTypeName: string;
    onboarding?: boolean;
}
const SetupDataSource = (props: Props) => {
    const { sourceTypeName, onboarding } = props;
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState<any>(undefined);

    const [sourceType, setSourceType] = useState<SourceType|undefined>(undefined);
    const [sourceName, setSourceName] = useState('');

    const sourceTypes = useSourceTypes();

    const [saving, setSaving] = useState(false);

    const [sourceConfig, setSourceConfig] = useState<SourceConfig>({});
    const [secretSourceConfig, setSecretSourceConfig] = useState<SourceConfig>({});

    const getCredentials = async (accessId: string) => {
        setLoading(true);
        try {
            const response = await ApiService.getInstance().request('GET', `/sources/connector/oauth/${accessId}/exchange-access-id`) as SingleRecordResponse<any>;
            
            if (response.record) {
                setCredentials(response.record);

            } else {
                throw new Error('Oops! Your credentials may have expired. Please re-authenticate and try again.');
            }
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!sourceTypes.data) {
            return;
        }
        setSourceType(sourceTypes.data.find(st => st.id == sourceTypeName));
    }, [sourceTypes.dataUpdatedAt]);

    useEffect(() => {
        if (sourceType && !sourceName) {
            setSourceName(sourceType.title);
        }
    }, [sourceType])

    useEffect(() => {
        const cAccessId = searchParams.get('cAccessId');

        if (cAccessId) {
            getCredentials(cAccessId);
        } else {
            setLoading(false);
        }
    }, [searchParams.toString()]);

    const saveSource = useCallback(async () => {
        if (!sourceType) {
            return;
        }
        const source = {
            id: null,
            type: sourceType.id,
            secureConfig: {
                'secure_json': JSON.stringify(secretSourceConfig),
            },
            config: sourceConfig,
            name: sourceName,
        };

        setSaving(true);
        try {
            const savedSource = await SourceORM.save(source);
            navigate(`/sources/${savedSource.id}?mode=wizard`);
        } catch (err) {
            console.error(err);
        } finally {
            setSaving(false);
        }
    }, [sourceName, sourceConfig, secretSourceConfig])


    

    if (loading) {
        return <Spinner/>;
    } else if (error) {
        return <>
            <p className="alert alert-danger">{error}</p>
            <Link to="/source/select">Go back</Link>
        </>
    } else if(sourceType) {
        return <>
            <div className="mb-3">
                <img src={sourceType.icon_path} style={{maxWidth: '30%'}}/>
            </div>
            {credentials && (
                <div className="alert alert-success">
                    Successfully connected to <strong>{sourceType.title}</strong>
                </div>
            )}
            
            <hr />

            
            <Form.Group className="mb-3">
                <Form.Label>Name your data source</Form.Label>
                <Form.Control type="text" value={sourceName} onChange={(e) => setSourceName(e.target.value)} isValid={!!sourceName}/>
                <Form.Text>Make this unique and recognizable. For example "Corporate Salesforce" or "2018 Survey Data"</Form.Text>
            </Form.Group>

            <fieldset className="mb-3">
                {sourceType.id === 'hubspot' && <HubspotConnectorConfig 
                    oauthCreds={credentials}
                    onConfigChanged={c => setSourceConfig(c)}
                    onSecureConfigChanged={c => setSecretSourceConfig(c)}
                />}
                {sourceType.id === 'flat_file' && <ExcelConnect/>}
            </fieldset>
            <div className="mb-1">
                <AsyncButton
                    variant="pliable"
                    text="Start loading data"
                    loading={saving}
                    onClick={() => saveSource()}
            />
            </div>
            
            <Link to="/source/select">Use a different data source</Link>

        </>
    }
    return <></>;
}

export default SetupDataSource;