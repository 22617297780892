import { Link } from "react-router-dom";

const DataModelReviewPage = () => {
    return <>
        <h4>Here's your data model.</h4>
        <p className="text-muted font-13">We used common names for these objects, but you can rename them to whatever you call them in your business. You can always change this or add more objects later.</p>
        <div className="mt-3 mb-3 font-20 p-3">
            <p>
                You sell <a className="inline-editable">products</a> to <a className="inline-editable">customers</a>.
            </p>
            <p>
                Customers go through your sales cycle as an <a className="inline-editable">opportunity</a>.
            </p>
            <p>
                You can have many <a className="inline-editable">interactions</a> with your customers (orders, emails, website visits).
            </p>
            <p>
                Interactions can be attributed to an <a className="inline-editable">advertising campaign</a>.
            </p>
        </div>

        <p>Structuring your data like this will help you analyze things like <strong>return on ad spend</strong>, <strong>customer journey</strong>, and <strong>marketing attribution</strong>.</p>

        <button className="btn btn-pliable">This looks good!</button><br />
        <Link to="/">Let me set this up from scratch.</Link>
        
    </>;
}

export default DataModelReviewPage;