import SourceIcon from "@components/sources/SourceIcon.component";
import SourceRecordTypeName from "@components/sources/SourceRecordTypeName.component";
import { summarizeNumber } from "@services/formatting.service";
import { timeAgo } from "@services/time.service";
import { useSourceRecordTypes, useSources, useStandardizationPipelines } from "@stores/data.store";
import { Link } from "react-router-dom";

interface Props {
    businessObjectId: string;
}

const BusinessObjectSourcesList = (props: Props) => {
    const standardizationPipelines = useStandardizationPipelines('', props.businessObjectId);
    
    // XXX Need to fix this in case we hit more than 50
    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();

    return <>
        {standardizationPipelines.data?.map(sp => {
            const sourceRecordType = sourceRecordTypes.data?.find(srt => srt.id === sp.source_record_type_id);
            const source = sources.data?.find(s => s.id === sourceRecordType?.source_id);

            if (!source || !sourceRecordType) {
                return <></>
            }
            return (
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="d-flex">
                            <SourceIcon source={source} className="rounded-circle avatar-sm img-thumbnail"/>
                            <div className="flex-1 ps-2">
                                <h4 className="my-1">
                                    <Link to={`/sources/${source.id}/data/${sourceRecordType.id}`}>
                                        <SourceRecordTypeName sourceRecordType={sourceRecordType}/>
                                    </Link>
                                    
                                </h4>
                                <p className="mb-0 text-muted">
                                    {sourceRecordType.shape && (
                                        <>
                                            {summarizeNumber(sourceRecordType.shape.total_records)} records
                                        </>
                                    )}
                                    
                                </p>
                                
                            </div>

                        </div>
                        <Link to={`/sources/${source.id}/data/${sourceRecordType.id}/standardize/${sp.id}`} className="mt-1 btn btn-sm w-100 btn-light">
                                        Configure
                                    </Link>
                    </div>
                    
                </div>
            )
            
        })}
    </>
}

export default BusinessObjectSourcesList;