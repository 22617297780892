import { create } from 'zustand';

const useGlobalState = create(set => ({
    sidebarExpanded: true,
    toggleSidebar: () => {
        console.log('Toggling sidebar');
        set((state: any) => {
            console.log('Current state is:', state);
            return {
                sidebarExpanded: !state.sidebarExpanded,
            }
        });
    },
}));

export default useGlobalState;