import { BusinessObject } from "@models/businessObject";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { saveBusinessObject } from "@stores/data.store";
import { useCallback, useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";

interface Props {
    businessObject: BusinessObject;
    onComplete?: (businessObject: BusinessObject) => void;
}

const BusinessObjectForm = (props: Props) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(props.businessObject.name);
        setDescription(props.businessObject.description);
    }, [props.businessObject]);

    const [loading, setLoading] = useState(false);
    const onSave = useCallback(async () => {
        setLoading(true);
        try {
            const newBizObj = await saveBusinessObject({
                ...props.businessObject,
                name: name,
                description: description,
            });

            setName(props.businessObject.name);
            setDescription(props.businessObject.description);
            toast('success', 'Success', 'Business object saved');

            if (props.onComplete) {
                props.onComplete(newBizObj);
            }
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setLoading(false);
        }

    }, [props.businessObject, props.onComplete, name, description]);

    return <>
        <Form.Group className="mb-2">
            <Form.Label>Name</Form.Label>
            <Form.Control isValid={!!name} value={name} onChange={(e) => setName(e.target.value)}/>
        </Form.Group>
        <Form.Group className="mb-2">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" value={description} onChange={(e) => setDescription(e.target.value)}/>
        </Form.Group>
        <button disabled={loading || !name} onClick={() => onSave()} className="btn btn-pliable w-100">
            {loading && <Spinner size="sm"/>}
            Save
        </button>
    </>
}

export default BusinessObjectForm;