import { Source, SourceDataRelationship, SourceRecordType } from "@models/source";
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { saveSourceDataRelationship, useSourceRecordTypes, useSources } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import { useImmer } from "use-immer";
import Select from 'react-select';
import { decimalFormatter, integerFormatter } from "@services/formatting.service";
import toast from "@services/toast.service";

interface ConnectDataSourcesProps {
    selectedSourceRecordTypeIds: string[];
    selectedRelationship?: SourceDataRelationship;
    onRemoveRelationship: (rel: SourceDataRelationship) => void;
}

interface SelectedDataSource {
    source: Source;
    sourceRecordType: SourceRecordType;
    columnOptions: {
        label: string;
        value: string;
    }[];
    selectedColumn?: string;
}

interface OverlapAnalysisResult {
    GRAND_TOTAL: number;
    PROPORTION: number;

    // This is the source record type ID
    RUNID: string;
    TOTAL: number;
}

const ConnectDataSources = (props: ConnectDataSourcesProps) => {
    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();

    const [selectedDataSources, setSelectedDataSources] = useImmer<SelectedDataSource[]>([]);

    useEffect(() => {
        if (!sourceRecordTypes.data || !sources.data) {
            return;
        }
        
        // XXX todo remove this copy pasta
        if (props.selectedRelationship) {
            setSelectedDataSources(props.selectedRelationship.references.map(ref => {
                const srt = sourceRecordTypes.data.find(srt => srt.id === ref.source_record_type_id);
                const source = sources.data.find(s => s.id === srt?.source_id);
    
                if (!srt || !source) {
                    throw new Error('Source not found');
                }
    
                let columnOptions: {
                    label: string;
                    value: string;
                }[] = [];
    
                if (srt.shape) {
                    columnOptions = srt.shape?.columns.map(c => {
                        return {
                            label: c.key,
                            value: c.key,
                        }
                    });
                }
                return {
                    source: source as Source,
                    sourceRecordType: srt as SourceRecordType,
                    columnOptions: columnOptions,
                    selectedColumn: ref.column_ref.column_path,
                }
            }));
        } else {
            setSelectedDataSources(props.selectedSourceRecordTypeIds.map(srtId => {
                const srt = sourceRecordTypes.data.find(srt => srt.id === srtId);
                const source = sources.data.find(s => s.id === srt?.source_id);
    
                if (!srt || !source) {
                    throw new Error('Source not found');
                }
    
                let columnOptions: {
                    label: string;
                    value: string;
                }[] = [];
    
                if (srt.shape) {
                    columnOptions = srt.shape?.columns.map(c => {
                        return {
                            label: c.key,
                            value: c.key,
                        }
                    });
                }
                return {
                    source: source as Source,
                    sourceRecordType: srt as SourceRecordType,
                    columnOptions: columnOptions,
                    selectedColumn: '',
                }
            }));
        }
        
    }, [sources.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt, props.selectedRelationship, props.selectedSourceRecordTypeIds]);

    const selectColumn = useCallback((idx: number, column: string) => {
        setSelectedDataSources(draft => {
            draft[idx].selectedColumn = column;
        });
    }, []);

    const [analysisResults, setAnalysisResults] = useState<OverlapAnalysisResult[]>([]);
    const [runningAnalysis, setRunningAnalysis] = useState(false);
    const [analysisError, setAnalysisError] = useState('');
    const analysisResultsDisplay = useMemo(() => {
        if (sourceRecordTypes.data) {
            return analysisResults.map(ar => {
                return {
                    sourceRecordTypes: ar.RUNID.split('|').map(srtid => sourceRecordTypes.data.find(srt => srt.id === srtid)),
                    result: ar,
                }
            })
        }
        return [];
        
    }, [analysisResults, sourceRecordTypes.dataUpdatedAt]);

    const runOverlapAnalysis = useCallback(async () => {
        setRunningAnalysis(true);
        setAnalysisError('');
        try {
            const result = await ApiService.getInstance().request('POST', '/sourcedatarelationships/overlap-analysis', {
                'refs': selectedDataSources.map(sd => {
                    return {
                        source_id: sd.source.id,
                        source_record_type_id: sd.sourceRecordType.id,
                        column_ref: {
                            column_path: sd.selectedColumn,
                        }
                    }
                }),
            }) as ListRecordsResponse<OverlapAnalysisResult>;
    
            setAnalysisResults(result.records);
        } catch (err) {
            setAnalysisError(getErrorMessage(err));
        } finally {
            setRunningAnalysis(false);
        }
        
    }, [selectedDataSources]);

    const [saving, setSaving] = useState(false);

    const saveRelationship = useCallback(async () => {
        const refs = selectedDataSources.map(sd => {
            return {
                source_id: sd.source.id as string,
                source_record_type_id: sd.sourceRecordType.id as string,
                column_ref: {
                    column_path: sd.selectedColumn,
                }
            }
        });

        const relToSave = {
            id: props.selectedRelationship ? props.selectedRelationship.id : null,
            name: '',
            references: refs,
        };
        setSaving(true);
        try {
            const result = await saveSourceDataRelationship(relToSave);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
            toast('success', 'Success', 'Connection saved');
        }

    }, [props.selectedRelationship, selectedDataSources]);
    
    return <>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>Connect Data Sources</Offcanvas.Title>
            
        </Offcanvas.Header>
        <Offcanvas.Body>
        <p className="text-muted font-13">Connecting data sources helps Pliable understand how your data is related to other data. </p>
            <table className="table table-fixed table-centered">
                <tbody>
                    {selectedDataSources.map((s, idx) => {
                        const valid = !!s.selectedColumn;
                        return <tr>
                            <td width="50">
                                {valid &&  <i className="mdi mdi-check-circle text-success"></i>}
                                {!valid && <i className="mdi mdi-alert text-danger"/>}
                            </td>
                            <th>
                                {s.source?.name} - {s.sourceRecordType?.name}
                            </th>
                            <td>
                                <Select
                                    placeholder="Select column"
                                    options={s.columnOptions}
                                    value={s.columnOptions.find(o => o.value === s.selectedColumn)}
                                    onChange={(val) => selectColumn(idx, val ? val.value : '')}
                                />
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <button onClick={() => runOverlapAnalysis()} className="btn btn-pliable" disabled={runningAnalysis || selectedDataSources.length < 2}>Run Overlap Analysis</button>
            <button className="btn btn-success ms-1" disabled={saving} onClick={() => saveRelationship()}>
                {saving && <Spinner size="sm"/>}
                Save
            </button>
            {props.selectedRelationship && <button className="btn btn-danger ms-1" onClick={() => props.onRemoveRelationship(props.selectedRelationship as SourceDataRelationship)}>Remove Connection</button>}
            <hr />
            {runningAnalysis && <Spinner/>}
            {!runningAnalysis && analysisError && <div className="alert alert-danger">{analysisError}</div>}
            {!runningAnalysis && !analysisError && (analysisResults.length > 0) && (
                <>
                    <h5 className="fw-normal">There are <b>{integerFormatter(analysisResults[0].GRAND_TOTAL)}</b> total records across all selected datasets.</h5>
                    <ul className="list-group">
                    {analysisResultsDisplay.map(ar => {
                        const srts = ar.sourceRecordTypes as SourceRecordType[];
                        return (
                            <li className="list-group-item">
                                <strong>{decimalFormatter(ar.result.PROPORTION * 100)}% </strong> ({integerFormatter(ar.result.TOTAL)} records) exist in 
                                {srts.length === 1 && (
                                    <>
                                        &nbsp;only the <b>{srts[0].name}</b> dataset.
                                    </>
                                )}
                                {srts.length === selectedDataSources.length && (
                                    <>
                                        &nbsp;<strong>all selected datasets</strong>.
                                    </>
                                )}
                                <div>
                                    <a role="button">View samples</a>
                                </div>
                                
                            </li>
                        )
                    })}
                    </ul>
                </>
                
            )}
        </Offcanvas.Body>
    </>
}

export default ConnectDataSources;