import DataModel from "@components/businessObjects/DataModel.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import BusinessObjectORM, { BusinessObject } from "@models/businessObject";
import { getErrorMessage } from "@services/errors.service";
import { useBusinessObjects } from "@stores/data.store";
import { useEffect, useMemo, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import BusinessObjectForm from "./BusinessObjectForm.component";
import BusinessObjectSourcesList from "./BusinessObjectSourcesList.component";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import toast from "@services/toast.service";
import { PipelineExecution } from "@models/pipelineExecution";
import FeatureFlagWrapper from "@components/featureflags/FeatureFlagWrapper.component";
import TrackingService, {Events} from "@services/tracking.service";



const BusinessObjectWrapperPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const { businessObjectId } = useParams();

    const businessObjects = useBusinessObjects();

    const navigate = useNavigate();

    const location = useLocation();
    const [queuingPipeline, setQueuingPipeline] = useState(false);

    const runOrchestratedPipeline = async () => {
        try {
            setQueuingPipeline(true);
            const execution = await ApiService.getInstance().request('POST', `/pipelines/${businessObject?.consolidation_pipeline_id as string}/run-orchestrated`, {}) as SingleRecordResponse<PipelineExecution>;
            navigate(`/pipelines/${businessObject?.consolidation_pipeline_id as string as string}/orchestrations/${execution.record.id as string}`);
            TrackingService.track_event(Events.BUSINESS_OBJECT_PROCESS_CLK, {
                business_object_id: businessObjectId
            });
        }  catch (err) {
            const errMessage = getErrorMessage(err);
            toast('danger', 'Error', errMessage);
            setQueuingPipeline(false);
        }

    }

    const businessObject = useMemo(() => {
        if (!!businessObjects.data) {
            return businessObjects.data.find(bo => bo.id === businessObjectId);
        }
    }, [businessObjects.dataUpdatedAt, businessObjectId]);

    if (businessObjects.isLoading) {
        return <Spinner/>;
    }

    if (!businessObject) {
        return <p>Not found</p>
    }

    return <div className="row">
        <div className="col-3 border-right">
            <div className="content-inner">
                <div className="section">
                    <div className="back-breadcrumb">
                        <Link to="/business-objects">&larr; Back to Business Objects</Link>
                    </div>
                    <h2>{businessObject.name}</h2>
                    <BusinessObjectForm businessObject={businessObject}/>
                    <hr />
                    <h3>Data Sources</h3>
                    <BusinessObjectSourcesList businessObjectId={businessObject.id as string}/>
                </div>
            </div>
        </div>
        <div className="col-9" style={{position: 'relative'}}>
            <nav className="nav subnav">
        
                {/* <Link className={`nav-link ${location.pathname.endsWith('/stats') ? 'active': ''}`} to="stats">
                    <i className="mdi mdi-chart-bar"></i>
                    <span className="ms-1">Stats</span>
                </Link> */}
                <Link className={`nav-link ${location.pathname.endsWith('/attributes') ? 'active': ''}`} to="attributes">

                    <i className="mdi mdi-view-list"></i>
                    <span className="ms-1">Attributes</span>
                </Link>
                <Link className={`nav-link ${location.pathname.endsWith('/sources') ? 'active': ''}`} to="sources">
                    <i className="mdi mdi-database"></i>
                    <span className="ms-1">Data Sources</span>
                </Link>
                {/* <Link className={`nav-link ${location.pathname.endsWith('/relationships') ? 'active': ''}`} to="relationships">

                    <i className="mdi mdi-relation-many-to-one"></i>
                    <span className="ms-1">Relationships</span>
                </Link> */}
                <Link className={`nav-link ${location.pathname.endsWith('/data') ? 'active': ''}`} to="data">

                    <i className="mdi mdi-table"></i>
                    <span className="ms-1">View Data</span>
                </Link>
                <FeatureFlagWrapper requiredFlags={['enable_relationships']}>
                    <Link className={`nav-link ${location.pathname.endsWith('/relationships') ? 'active': ''}`} to="relationships">

                        <i className="mdi mdi-relation-many-to-one"></i>
                        <span className="ms-1">Relationships</span>
                    </Link>
                </FeatureFlagWrapper>

                <div className="actions">
                <Dropdown className="d-inline ms-2" drop="down-centered">
                <Dropdown.Toggle variant="primary" disabled={queuingPipeline}>
                    { (queuingPipeline) ? <Spinner size="sm"/> :  <span>Process Data</span> }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => runOrchestratedPipeline()}>Run Now</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
                </div>
                
            </nav>
            <Outlet/>
        </div>
    </div>
   
}

export default BusinessObjectWrapperPage;