import { Link } from "react-router-dom";

const ManagedSnowflakePage = () => {
    return <>
        <div className="alert alert-info mb-3" >You've indicated that you'd like us to set up a Snowflake account and manage it for you. If you'd like to use your own Snowflake instead, <Link to="/welcome/snowflake/byo">click here</Link> instead.</div>

        <h4>Managed Snowflake</h4>
    </>
}

export default ManagedSnowflakePage;