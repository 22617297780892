import React, { Component, ReactNode } from 'react';
import { useLocation, useParams } from 'react-router';
import PageTitle from '../components/pageTitle/PageTitle.component';
import ApiService, { AnyRecordsResponse, ListRecordsResponse } from '../services/api/api.service';
import { getErrorMessage } from '../services/errors.service';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SimpleDataTable } from '../components/datatable/DataTable.component';
import Spinner from 'react-bootstrap/Spinner';
import { decimalFormatter, integerFormatter } from '../services/formatting.service';
import Chart from 'react-apexcharts';

interface ColumnStat {
    key: string;
    total_records: number;
    non_empty: number;
    distinct_values: number;
    percent_non_empty: number;
    cardinality: number;
    max_value: number;
    min_value: number;
}

interface ProfileResponse {
    records: {
        column_stats: ColumnStat[];
        sample_data: Record<string, string>[];
    }
    
}

interface RecordDistribution {
    value: string;
    total: number;
}

interface ColumnStatsTableProps {
    stats: ColumnStat[];
    sourceType: string;
    source: string;
}

interface ColumnStatsTableState {
    loading: boolean;
    distribution: RecordDistribution[];
    error?: string;
}

class ColumnStatsTable extends Component<ColumnStatsTableProps, ColumnStatsTableState> {
    constructor(props: ColumnStatsTableProps) {
        super(props);
        this.state = {
            loading: false,
            distribution: [],
        };
    }

    private async loadDistro(column: string) {
        this.setState({
            loading: true,
        });

        try {
            const response = await ApiService.getInstance().request(
                'GET', `/profile/distribution?source=${this.props.source}&source_type=${this.props.sourceType}&column=${column}`
            ) as ListRecordsResponse<any>;
            
            console.log(response.records);
            this.setState({
                loading: false,
                distribution: response.records,
            });
        } catch (err) {
            this.setState({
                loading: false,
                error: getErrorMessage(err),
            });
        }
        
    }

    renderDistroPane() {
        if (this.state.loading) {
            return <Spinner/>
        } else if (this.state.error) {
            return <pre>{this.state.error}</pre>
        }

        return (
            
            <Chart
                options={{
                    'chart': {
                        'toolbar': {
                            'show': false,
                        }
                    },
                    'labels': this.state.distribution.map(d => {
                        if (!d.value) {
                            return '(empty)';
                        } 
                        return d.value;
                    }),
                    
                
                }}
                series={this.state.distribution.map(d => d.total)}
                type="pie"
                // height="400px"
                width="100%"
            />
        )


    }

    render() {
        return (
            <div className="row">
                <div className="col-7">
                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <tbody>
                                {this.props.stats.map(s => {
                                    let label = s.key;
                                    if (!s.key) {
                                        label = '(empty)';
                                    }
                                    return (
                                        <tr>
                                            <td>
                                                <h5 className="font-14 my-1">{label}</h5>
                                                <span className="text-muted font-13">asdf</span>
                                            </td>
                                            <td>
                                                <span className="text-muted font-13">Distinct Values</span>
                                                <h5 className="font-14 mt-1 fw-normal">{integerFormatter(s.distinct_values)}</h5>
                                            </td>
                                            <td>
                                                <span className="text-muted font-13">Uniqueness</span>
                                                <h5 className="font-14 mt-1 fw-normal">{decimalFormatter((s.distinct_values/s.total_records) * 100)}%</h5>
                                            </td>
                                            <td className="table-action">
                                                <button 
                                                    className="btn btn-secondary btn-sm"
                                                    onClick={(e) => this.loadDistro(s.key)}
                                                >Distribution</button>
                                            </td>
                                        </tr>
                                    );
                                })};
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-5">
                    {this.renderDistroPane()}
                </div>
            </div>
            
        );
    }
}

interface Props {
    sourceType: string;
    source: string;
}

interface State {
    loading: boolean;
    sampleData: Record<string, string>[];
    columnStats: ColumnStat[];
    error?: string;
    activeTab: string;
}


class ProfileSourcePage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            sampleData: [],
            columnStats: [],
            activeTab: 'data',
        }
    }

    private async loadData() {
        this.setState({
            loading: true,
            sampleData: [],
            columnStats: [],
        });

        try {
            const sampleData = await ApiService.getInstance().request('GET', `/source_data?source=${this.props.source}&source_type=${this.props.sourceType}`) as ListRecordsResponse<any>;

            const profileData = await ApiService.getInstance().request('GET', `/profile?src=${this.props.source}&src_type=${this.props.sourceType}`) as ProfileResponse;

            this.setState({
                loading: false,
                sampleData: sampleData.records,
                columnStats: profileData.records.column_stats,
            });
        } catch (err) {
            const errMsg = getErrorMessage(err);
            this.setState({
                loading: false,
                error: errMsg,
            });
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        let sampleData: ReactNode = <Spinner/>;
        let columnStats: ReactNode = <Spinner/>;

        if (!this.state.loading) {
            sampleData = <SimpleDataTable data={this.state.sampleData}/>;
            columnStats = <ColumnStatsTable stats={this.state.columnStats} source={this.props.source} sourceType={this.props.sourceType}/>
        }
        return (
            <>
                <PageTitle title="Profile Data Source"/>
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <Tabs
                                    activeKey={this.state.activeTab}
                                    onSelect={(k) => this.setState({activeTab: k as string})}
                                    className="mb-3 nav-bordered"
                                >
                                    <Tab eventKey="data" title="Sample Data">
                                        {sampleData}
                                    </Tab>
                                    <Tab eventKey="columns" title="Column Stats">
                                        {columnStats}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </>
        )
    }
}

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const WrappedPage = () => {
    const query = useQuery();
    return <ProfileSourcePage sourceType={query.get('srcType') as string} source={query.get('src') as string}/>;
}
export default WrappedPage;