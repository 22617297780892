import { Component, ReactNode, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactFlow, { Background, Controls, Handle, MiniMap, NodeProps, Position, ProOptions } from 'reactflow';

import 'reactflow/dist/style.css';
import { Link } from "react-router-dom";
import dagre, { graphlib } from 'dagre';
import BusinessObjectORM, { BusinessObject } from "@models/businessObject";
import SourceORM, { Source, SourceRecordType, SourceRecordTypeORM, SourceType } from "@models/source";
import PipelineORM, { Pipeline } from "@models/pipeline";
import { getErrorMessage } from "@services/errors.service";
import { DataMart } from "@models/dataMart";
import './lineagegraph.css';
import { useSourceTypes } from "@stores/data.store";


interface Edge {
    from: string;
    to: string;
    id: string;
}

interface NodeInfo {
    source_type?: string;
}

interface Node {
    id: string;
    label: string;
    title: string;
    record_type: 'source'|'source_record_type'|'pipeline'|'datamart'|'report';
    record_id: string;
    info?: NodeInfo;
}


export interface NodeData<T> {
    label?: string;
    object: T;
}
export interface ReactFlowNode {
    id: string;
    position: {
        x: number;
        y: number;
    };
    data: NodeData<any>;
    type: string;
    sourcePosition?: Position;
    targetPosition?: Position;
};

export interface ReactFlowEdge {
    id: string;
    source: string;
    target: string;
    label?: string;
    type: string | undefined;
    animated: boolean;
}

interface State {
    loading: boolean;
    edges: Edge[];
    nodes: Node[];

}

interface LineageAPIResponse {
    edges: Edge[];
    nodes: Node[];
}

function PipelineNode(node: NodeProps<NodeData<Pipeline>>) {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <Link className="text-white" to={`/pipelines/${node.data.object.id}`}>
                <div className="card bg-primary mb-0">
                    <div className="card-body p-1">
                        <i className="mdi mdi-pipe text-white"></i> {node.data.label}
                    </div>
                </div>
            </Link>
           
            <Handle type="source" position={Position.Right} />
        </>
    )
}

function BusinessObjectNode(node: NodeProps<NodeData<BusinessObject>>) {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <div className="card border">
                <div className="card-body p-1">
                    <div>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <div className="avatar-sm flow-node-img">
                                    <i className="uil-box"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-2">
                                <h5>{node.data.object.name}</h5>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                <div className="card-footer p-1">
                    <div className="text-end">
                        <Link
                            to={'/business-objects/' + node.data.object.id as string}
                            className="action-icon">
                            <i className="mdi mdi-eye"></i>
                        </Link>
                    </div>
                </div>
            </div>
            <Handle type="target" position={Position.Right} />
        </>
    )
}

function SourceNode(node: NodeProps<NodeData<Source>>) {
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes();
    const sourceType = soureTypeOptions?.find((st: SourceType) => st.id == node.data.object.type);

    return (
        <>
            {/* <Handle type="target" position={Position.Left} /> */}
            <div className="card border">
                <div className="card-body p-1">
                    <div>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <div className="avatar-sm flow-node-img">
                                    <img src={sourceType?.icon_path}/>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-2">
                                <h4>{node.data.label}</h4>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="card-footer p-1">
                    <div className="text-end">
                        <Link
                            to={'/sources/' + node.data.object.id as string}
                            className="action-icon">
                            <i className="mdi mdi-eye"></i>
                        </Link>
                        <a className="action-icon" href="#">
                            <i className="mdi mdi-download"></i>
                        </a>
                    </div>
                </div>
            </div>
           
            <Handle type="source" position={Position.Right} />
        </>
    )
}

const AddNewSourceNode = (node: NodeProps) => {
    return (
        <>
            <Link to="/sources/new"  className="btn btn-lg btn-outline-primary">Add Data Source</Link>
        </>
    )
}

const SourceRecordTypeNode = (node: NodeProps) => {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <div className="card border">
                <div className="card-body p-1">
                    <h4>{node.data.label}</h4>
                    <p className="font-13 text-muted">1,234 records</p>
                </div>
            </div>
           
            <Handle type="source" position={Position.Right} />
        </>
    )
}

function DatamartNode(node: NodeProps<NodeData<DataMart>>) {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <Link className="text-white" to={`/datamarts/${node.data.object.id}`}>
                <div className="card bg-success mb-0">
                    <div className="card-body p-1">
                        <i className="mdi mdi-cart text-white"></i> {node.data.label}
                    </div>
                </div>
            </Link>
           
            <Handle type="source" position={Position.Right} />
        </>
    )
}

const SourceRecordTypeCTANode = (node: NodeProps) => {
    return (
        <>
            <Handle type="target" position={Position.Left} />
            <div className="card">
                <div className="card-header pt-1 pb-1 bg-info text-white">
                    <h6>Todo</h6>
                </div>
                <div className="card-body">
                    You need to integrate this data into your data model.

                </div>
                <div className="card-footer">
                <button className="btn btn-primary">Go</button>

                </div>
            </div>
        </>
    )
};

export interface Action {
    icon: string;
    linkTo: string;
}

const FlowNode = (node: NodeProps) => {
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes();
    

    const getIcon = () :ReactNode => {
        
        switch (node.type) {
            case 'businessObject':
                return <i className="uil-box text-primary"></i>;
            case 'source':
                const sourceType = soureTypeOptions?.find((st: SourceType) => st.id == node.data.object.type);
                return <img src={sourceType?.icon_path}/>;
            case 'sourceRecordType':
                return <i className="uil-database"></i>;

        }
        return <></>;
    };

    const getActions = () :Action[] => {
        console.log('actions for type', node.type);

        switch (node.type) {
            case 'businessObject':
                return [
                    {
                        icon: 'mdi mdi-eye',
                        linkTo: `/business-objects/${node.data.object.id as string}/attributes`
                    }
                ]
            case 'source':
                return [
                    {
                        icon: 'mdi mdi-eye',
                        linkTo: `/sources/${node.data.object.id as string}`
                    }
                ];
            case 'sourceRecordType':
                return [
                    {
                        icon: 'mdi mdi-eye',
                        linkTo: `/sources/${node.data.object.source_id}/record-types/${node.data.object.id}`
                    }
                ];
        }

        return [];
    }

    const actions = getActions();

    return (
        <>
            {node.type !== 'source' && <Handle type="target" position={Position.Left} />}
            <div className="card border flow-card">
                <div className="card-body p-1">
                    <div>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <div className="avatar-sm flow-node-img">
                                    {getIcon()}
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-2">
                                <h5 className="m-0">{node.data.label}</h5>
                                {/* <p className="font-13 text-muted m-0">Subtitle</p> */}
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="card-footer p-1">
                    <div className="text-end">
                        {actions.map(a => {
                            return <Link to={a.linkTo} className="action-icon">
                                <i className={a.icon}/>
                            </Link>
                        })}
                        
                    </div>
                </div>
            </div>
           
            <Handle type="source" position={Position.Right} />
        </>
    )
}

export function autoLayout(nodes: ReactFlowNode[], edges: ReactFlowEdge[]) {
    const g = new graphlib.Graph();
    g.setGraph({
        rankdir: 'LR'
    });
    g.setDefaultEdgeLabel(() => {
        return {};
    });

    nodes.map(n => {

        g.setNode(n.id, {
            label: n.id,
            width: 250,
            height: 100,
        });
    });

    edges.map(e => {
        g.setEdge(e.source, e.target);
    });

    dagre.layout(g);

    // Now set the position on the nodes
    g.nodes().forEach(nodeId => {
        const nodeData = g.node(nodeId);

        const nodeToUpdate = nodes.find(ntu => ntu.id === nodeId);
        if (nodeToUpdate) {
            nodeToUpdate.position.x = nodeData.x;
            nodeToUpdate.position.y = nodeData.y;
        }

        
    });
}


const nodeTypes = {
    'source': FlowNode,
    'sourceRecordType': FlowNode,
    'datamart': DatamartNode,
    'sourceRecordTypeCTA': SourceRecordTypeCTANode,
    'addSource': AddNewSourceNode,
    'businessObject': FlowNode,
}


interface DataFlowProps {
    edges_required?: boolean;
    business_object_ids?: string[];
}

const DataFlow = (props: DataFlowProps) => {
    const [loading, setLoading] = useState(true);
    const [businessObjects, setBusinessObjects] = useState<BusinessObject[]>([]);
    const [sources, setSources] = useState<Source[]>([]);
    const [sourceRecordTypes, setSourceRecordTypes] = useState<SourceRecordType[]>([]);
    const [error, setError] = useState('');

    const [nodes, setNodes] = useState<ReactFlowNode[]>([]);
    const [edges, setEdges] = useState<ReactFlowEdge[]>([]);

    const load = async () => {
        setLoading(true);

        try {
            const businessObjects = [await BusinessObjectORM.findById("6434639edee7ed8c1093d0d4")];
            const sources = (await SourceORM.find()).records;
            const sourceRecordTypes = (await SourceRecordTypeORM.find()).records;
            const pipelines = (await PipelineORM.find()).records;
            
            // Figure out nodes and edges. What we want to do with this screen is prompt the user
            // to connect more data sources, use more data destinations, or add more business objects
            // in the center.
            const nodes: ReactFlowNode[] = [];
            const edges: ReactFlowEdge[] = [];

            const sourceRecordTypeUsedStatus: {
                [key: string]: boolean;
            } = {};

            let sourcesById: {
                [key: string]: Source;
            } = {};
            let sourceRecordTypesById: {
                [key: string]: SourceRecordType;
            } = {};

            let businessObjectsById: {
                [key: string]: BusinessObject;
            } = {};

            pipelines.forEach(p => {
                if (p.steps && p.steps.length >= 2) {
                    if (!!p.steps[0].config.source_record_type_id) {
                        if (p.steps[p.steps.length - 1].config.business_object_id != "6434639edee7ed8c1093d0d4") {
                            return;
                        }                       

                        const source = `srt|${p.steps[0].config.source_record_type_id}`;
                        const target = `bo|${p.steps[p.steps.length - 1].config.business_object_id}`;

                        // Check if there's an already an edge
                        const found = edges.find(e => e.source === source && e.target === target);
                        if (!found) {
                            console.log('Making edge:', source, target, edges.length);
                            edges.push({
                                id: `edge${edges.length.toString()}`,
                                source: source,
                                target: target,
                                type: 'default',
                                animated: false,
                            });
                        }
                    }
                    
                    
                }
            });

            sourceRecordTypes.forEach((srt: SourceRecordType) => {
                if(props.edges_required && edges.find((e) => e.source == `srt|${srt.id}`) == undefined ) {
                    return;
                }

                nodes.push({
                    id: `srt|${srt.id as string}`,
                    position: {
                        x: 0,
                        y: 0,
                    },
                    data: {
                        label: srt.name,
                        object: srt,

                    },
                    type: 'sourceRecordType',
                });

                edges.push({
                    id: `edge${edges.length.toString()}`,
                    source: `source|${srt.source_id}`,
                    target: `srt|${srt.id as string}`,
                    type: 'default',
                    animated: false,
                });

                sourceRecordTypeUsedStatus[srt.id as string] = false;
            });

            sources.forEach((s: Source) => {
                if(props.edges_required && edges.find((e) => e.source == `source|${s.id}`) == undefined ) {
                    return;
                }

                nodes.push({
                    id: `source|${s.id as string}`,
                    position: {
                        x: 0,
                        y: 0,
                    },
                    data: {
                        label: s.name,
                        object: s,
                    },
                    type: 'source',
                });
            });

            // nodes.push({
            //     id: 'addsource',
            //     position: {
            //         x: 0,
            //         y: 0,
            //     },
            //     data: {},
            //     type: 'addSource',
            // });

            

            businessObjects.forEach(bo => {
                nodes.push({
                    id: `bo|${bo.id as string}`,
                    position: {
                        x: 0,
                        y: 0,
                    },
                    data: {
                        label: bo.name,
                        object: bo,

                    },
                    type: 'businessObject',
                });

            });

            autoLayout(nodes, edges);
            setNodes(nodes);
            setEdges(edges);
        } catch (err) {
            console.error(err);
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        load();
    }, []);

    if (loading) {
        return <Spinner/>;
    } else if (error) {
        return <div className="alert alert-danger">{error}</div>
    }
    return (
        <div style={{ height: 'calc(100vh - 207px)' }}>
            <ReactFlow 
                nodes={nodes} 
                edges={edges} 
                nodeTypes={nodeTypes} 
                proOptions={{hideAttribution: true}}
                fitView
            >
                <Background/>
                <Controls showInteractive={false}/>
                    <MiniMap pannable/>
            </ReactFlow>
        </div>
    );
}


export default DataFlow;