import ORM, { BaseModel } from "./orm";
import { MergeConfig } from "./pipeline";
import { ColumnRef } from "./shared";



export interface BusinessObjectField {
    id?: string; // id set by backend
    name: string;
    label: string;
    type: string;
    description?: string;
    source_pipeline_priority?: string[];
}


export interface ConsolidationPipelineConfig {
    merge_using_shared_identifiers: boolean;
    merge_using_shared_fields: boolean;
    shared_fields: ColumnRef[];
}

export interface BusinessObject extends BaseModel {
    name: string;
    description: string;
    fields: BusinessObjectField[];
    table_name?: string;
    consolidation_pipeline_id?: string;
    consolidation_settings?: ConsolidationPipelineConfig;
}

const BusinessObjectORM = new ORM<BusinessObject>('businessobjects');
export default BusinessObjectORM;


export interface BusinessObjectRelationship extends BaseModel {
    name?: string;
    description?: string;
    parent_business_object_id: string;
    child_business_object_id: string;
    allow_many_children: boolean;
    child_foreign_key_name?: string;
}

export const BusinessObjectRelationshipORM = new ORM<BusinessObjectRelationship>('businessobjectrelationships');
