import DataModel from "@components/businessObjects/DataModel.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import BusinessObjectORM, { BusinessObject } from "@models/businessObject";
import { getErrorMessage } from "@services/errors.service";
import { useBusinessObjects, useSourceRecordType, useSourceRecordTypes, useSources } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Offcanvas, Spinner, Modal } from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import SourceRecordTypeColumnSelector from "./SourceRecordTypeColumnSelector.component";
import { ColumnPreference, SourceRecordTypeORM } from "@models/source";
import { Column } from "react-table";
import { TableExplorer, TableLoadResponse } from "@components/datatable/TableExplorer.component";
import { DedupeConfig, FilterConfig } from "@models/standardizationPipeline";
import FilterBadData from "./FilterBadData.component";
import DedupeConfigForm from "./DedupeConfigForm.component";
import ApiService, { ApiError, JobEnqueueResponse, SingleRecordResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import toast from "@services/toast.service";
import SnowflakeLoader from "@components/loaders/SnowflakeLoader.component";
import AsyncButton from '@components/button/AsyncButton.component';
import { decimalFormatter, formatPercentage, integerFormatter } from "@services/formatting.service";
import { useSource } from "@stores/sources.store";
import TrackingService, {Events} from "@services/tracking.service";
import { SimpleDataTable } from "@components/datatable/DataTable.component";
import LoadingCard from "@components/card/LoadingCard.component";
import { apiurl } from "@services/url.service";

interface TableRef {
    schema: string;
    table: string;
}

export interface SourceRecordTypeOutletContext {
    columnPreferences: ColumnPreference[];
}

export interface ExecutionResultsInfo {
    total_input: number;
    final_total: number;
    total_after_filter: number;
    total_after_dedupe: number;
    result_table: TableRef;
    dupes_table: string;
    filtered_out_table: string;
    executed_at?: string;
}

const createTablePath = (tableRoot: string, table_ref?: TableRef, promoteData: boolean = true, cache_bust: string = '') => {
    let rootPath = `${tableRoot}v=${(!cache_bust) ? (new Date()).toString() : cache_bust }`;
        if (table_ref) {
            rootPath = rootPath + `&schema=${table_ref.schema}&table=${table_ref.table}&promote=${(promoteData) ? 'true': 'false'}`;
        }
        return rootPath;
}


const SourceRecordTypeWrapperPage = () => {
    const [saving, setSaving] = useState(false);

    const { sourceId, sourceRecordTypeId } = useParams();
    const [ recordTypeName, setRecordTypeName] = useState('');
    const [ recordTypeDescription, setRecordTypeDescription] = useState('');

    const { isLoading: sourceLoading, data: theSource} = useSource(sourceId as string);
    const { isLoading: sourceRecordTypeLoading, data: theSourceRecordType, refetch: refetchSourceRecordType} = useSourceRecordType(sourceRecordTypeId as string);

    const location = useLocation();

    const [columnPreferences, setColumnPreferences] = useState<ColumnPreference[]>([]);

    const [resultsInfo, setResultsInfo] = useState<ExecutionResultsInfo|undefined>(undefined);

    const [tablePath, setTablePath] = useState('');

    useEffect(() => {
        if (theSourceRecordType) {
            setRecordTypeName(theSourceRecordType.name);
            setRecordTypeDescription(theSourceRecordType.description);
        }
      }, [theSourceRecordType]);

    const columnOptions = useMemo(() => {
        if (columnPreferences && columnPreferences.length > 0) {
            return columnPreferences.filter(c => c.visible).map(c => c.key);
        } else if (theSourceRecordType && theSourceRecordType.shape) {
            return theSourceRecordType?.shape?.columns.map(c => c.key);
        }
        return [];
    }, [theSourceRecordType, columnPreferences]);

    const tableRoot: string = useMemo(() => {
        return `/sources/${sourceId}/record-type/${sourceRecordTypeId}/data?`;
    }, [sourceId, sourceRecordTypeId])

    useEffect(() => {
        if (theSourceRecordType) {
            // Account for transitionary period where they might not be there
            if (theSourceRecordType.column_preferences && theSourceRecordType.column_preferences.length > 0) {
                setColumnPreferences(theSourceRecordType.column_preferences);

            } else if (theSourceRecordType.shape) {
                setColumnPreferences(theSourceRecordType.shape.columns.map(c => {
                    return {
                        key: c.key,
                        visible: true,
                    }
                }));
            }

            if (theSourceRecordType.default_dedupe) {
                setDedupeConfig(theSourceRecordType.default_dedupe);
            }

            if (theSourceRecordType.default_filter) {
                setFilterConfig(theSourceRecordType.default_filter);
            }

            const fetchMostRecentExecution = async () => {
                try {
                    
                    const result = await ApiService.getInstance().request('GET', `/sources/${sourceId}/record-type/${sourceRecordTypeId}/most-recent-execution`) as SingleRecordResponse<any>;
                    const info: ExecutionResultsInfo = result.record.results_info;
                    if(info){
                        setResultsInfo(info);
                        setTablePath(createTablePath(tableRoot, info.result_table, false, info.executed_at));
                    }else{
                        // show raw source data
                        setTablePath(createTablePath(tableRoot, undefined, true));
                    }

                }catch(ex: any){
                    if(ex.code == 404){
                        // show raw source data
                        setTablePath(createTablePath(tableRoot, undefined, true));
                    }else{
                        throw ex;
                    }
                }
              }

            fetchMostRecentExecution();

        }
    }, [theSourceRecordType]);

    const onChangeColumnPreferences = useCallback((newPrefs: ColumnPreference[]) => {
        setColumnPreferences([...newPrefs]);
    }, []);

    const [activeDrawer, setActiveDrawer] = useState<''|'filter'|'dedupe'|'map'>('');

    const [filterConfig, setFilterConfig] = useState<FilterConfig>({
        filters: []
    });

    const onChangeFilterConfig = useCallback((config: FilterConfig) => {
        setFilterConfig(config);
    }, []);

    const [dedupeConfig, setDedupeConfig] = useState<DedupeConfig>({
        shared_fields: [],
        fuzzy_match_threshold: 1.0,
        resolution_column: '',
        resolution_direction: '',
    });

    const onChangeDedupeConfig = useCallback((config: DedupeConfig) => {
        setDedupeConfig(config);
    }, []);


    const toggleActiveDrawer = useCallback((drawer: ''|'filter'|'dedupe'|'map') => {
        if (activeDrawer === drawer) {
            setActiveDrawer('');
        } else {
            setActiveDrawer(drawer);
        }
    }, [activeDrawer]);

    const [loadingData, setLoadingData] = useState(false);

    const updateData = useCallback(async () => {
        setLoadingData(true);
        setActiveDrawer('');

        try {
            const initResult = await ApiService.getInstance().request('POST', `/sources/${sourceId}/record-type/${sourceRecordTypeId}/data`, {
                'filter_config': filterConfig,
                'dedupe_config': dedupeConfig,
            }) as JobEnqueueResponse;

            const info = await BackgroundService.getInstance().waitForJob(initResult.job_id) as ExecutionResultsInfo;

            setResultsInfo(info);
            setTablePath(createTablePath(tableRoot, info.result_table, true, info.executed_at));

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setLoadingData(false);
        }
    }, [filterConfig, dedupeConfig, sourceId, sourceRecordTypeId]);

    const saveConfiguration = useCallback(async () => {
        setSaving(true);

        const response = await SourceRecordTypeORM.save({
            ...theSourceRecordType!, 
            name: recordTypeName,
            description: recordTypeDescription,
            default_filter: filterConfig,
            default_dedupe: dedupeConfig,
            column_preferences: columnPreferences
        });

        refetchSourceRecordType();
        setSaving(false);
        TrackingService.track_event(Events.SRT_SAVE_CONFIGURATION_CLK, {
            'source_id': sourceId as string,
            'source_record_type_id': sourceRecordTypeId as string
        })
    }, [filterConfig, dedupeConfig, sourceId, sourceRecordTypeId, columnPreferences, recordTypeName, recordTypeDescription]);


    const [showDuplicates, setShowDuplicates] = useState(false);
    const [loadingDuplicates, setLoadingDuplicates] = useState(false);
    const [exampleDuplicates, setExampleDuplicates] = useState<any[]>([]);

    const loadDuplicates = useCallback(async () => {
        if (removedDuplicateRecords == 0 || !resultsInfo ) {
            return;
        }

        setShowDuplicates(true);
        setLoadingDuplicates(true);

        const dupesRef = {
            schema: resultsInfo.result_table.schema,
            table: resultsInfo.dupes_table
        }
        let dupesTablePath = createTablePath(tableRoot, dupesRef, false, resultsInfo.executed_at);

        const tableParams = {
            query: '',
            page: 1,
            limit: 100,
            sort: 'group_id,-winner',
            promote: 'false'
        }
        const url = apiurl(dupesTablePath, tableParams);
        const dupeRecordsResp = await ApiService.getInstance().request('GET', url) as TableLoadResponse;
        const dupes_by_group_id = dupeRecordsResp.records.reduce((current: any, r: any) => {
            const group_id = r.group_id;
            delete r.group_id;
            if(!current[group_id]){
                current[group_id] = {
                    group_id,
                    source_data: []
                }
            }
            const data = JSON.parse(r['data'])
            current[group_id].source_data.push(data);
            return current;
        }, {})

        setExampleDuplicates(Object.keys(dupes_by_group_id).map(function(key){
            return dupes_by_group_id[key];
        }));
        setLoadingDuplicates(false);

    }, [resultsInfo, filterConfig, dedupeConfig, sourceId, sourceRecordTypeId]);

    const [showFilteredOutRecords, setShowFilteredOutRecords] = useState(false);
    const [filteredOutTablePath, setFilteredOutTablePath] = useState<string>('');

    const loadFilteredOutRecords = useCallback(async () => {
        if( !resultsInfo) {
            return;
        }

        setShowFilteredOutRecords(true);
        try{
            const dupesRef = {
                schema: resultsInfo.result_table.schema,
                table: resultsInfo.filtered_out_table
            }
            let filteredOutTablePath = createTablePath(tableRoot, dupesRef, true, resultsInfo.executed_at);

            setFilteredOutTablePath(filteredOutTablePath);
            setShowFilteredOutRecords(true);
        }catch(ex){
            setShowFilteredOutRecords(false);
            console.log(ex);
        }
    }, [resultsInfo, filterConfig, dedupeConfig, sourceId, sourceRecordTypeId]);

    const totalRecords = useMemo(() => {
        if (resultsInfo) {
            return resultsInfo.final_total;
        } else if (theSourceRecordType && theSourceRecordType.shape) {
            return theSourceRecordType?.shape.total_records;
        }
        return 0;
    }, [resultsInfo, theSourceRecordType]);

    const totalInput = useMemo(() => {
        if (resultsInfo) {
            return resultsInfo.total_input;
        } else if (theSourceRecordType && theSourceRecordType.shape) {
            return theSourceRecordType?.shape.total_records;
        }
        return 0;
    }, [resultsInfo, theSourceRecordType]);

    const filteredOutRecords = useMemo(() => {
        if (resultsInfo && resultsInfo.total_after_filter) {
            return totalInput - resultsInfo.total_after_filter;
        }
        return 0;
    }, [resultsInfo, theSourceRecordType, totalInput]);

    const removedDuplicateRecords = useMemo(() => {
        if (resultsInfo && resultsInfo.total_after_dedupe) {
            return totalInput - filteredOutRecords - resultsInfo.total_after_dedupe;
        }
        return 0;
    }, [filteredOutRecords, resultsInfo, theSourceRecordType]);

    const displayingRecords = useMemo(() => {
        if (totalRecords < 50) {
            return totalRecords;
        }
        return 50;
    }, [totalRecords]);

    if (sourceLoading || sourceRecordTypeLoading) {
        return <Spinner/>;
    }

    if (!theSource || !theSourceRecordType) {
        return <p>Not found</p>
    }



    return <div className="row">
        <div className="col-3 border-right">
            <div className="content-inner">
                <div className="section">
                    <div className="back-breadcrumb">
                        <Link to={`/sources/${theSource.id}`}>&larr; Back to {theSource.name}</Link>
                    </div>
                    <h2>Data Source Info</h2>
                    <Form.Group className="mb-2">
                        <Form.Label>Name</Form.Label>
                        <Form.Control value={recordTypeName} onChange={(e) => setRecordTypeName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" value={recordTypeDescription} onChange={(e) => setRecordTypeDescription(e.target.value)} />
                    </Form.Group>
                    {theSourceRecordType && theSourceRecordType.shape && (
                        <SourceRecordTypeColumnSelector
                            columnPreferences={columnPreferences}
                            onChangeColumnPreferences={onChangeColumnPreferences}
                        />
                    )}
                    
                </div>
            </div>
        </div>
        <div className="col-9" style={{position: 'relative'}}>
            <nav className="nav subnav toolbar">
                <div className={`nav-link ${activeDrawer === 'filter' ? 'active': ''} ${filterConfig.filters.length > 0 ? 'highlight': ''}`}  >
                    <a role="button" onClick={() => {
                            toggleActiveDrawer('filter')
                            TrackingService.track_event(Events.SRT_FILTER_TAB_CLK, {
                                'source_id': sourceId as string,
                                'source_record_type_id': sourceRecordTypeId as string
                            });
                        }
                        }>
                        <i className="mdi mdi-filter"></i>
                        <span className="ms-1">Filter</span>
                    </a>
                    
                    <div className="shadow-hider"></div>
                    <div className="subnav-drawer">
                    <FilterBadData
                        onApply={updateData}
                        config={filterConfig} 
                        sourceId={sourceId as string} 
                        sourceRecordTypeId={sourceRecordTypeId as string}
                        columnOptions={columnOptions}
                        totalRecords={theSourceRecordType.shape ? theSourceRecordType?.shape.total_records : 0}
                        onChange={onChangeFilterConfig}
                    />
                    </div>
                </div>
                <div className={`nav-link ${activeDrawer === 'dedupe' ? 'active': ''} ${dedupeConfig.shared_fields.length > 0 ? 'highlight': ''}`} >
                    <a role="button" onClick={() => {
                            toggleActiveDrawer('dedupe');
                            TrackingService.track_event(Events.SRT_DEDUPE_TAB_CLK, {
                                'source_id': sourceId as string,
                                'source_record_type_id': sourceRecordTypeId as string
                            });
                        }
                        }>
                        <i className="mdi mdi-merge"></i>
                        <span className="ms-1">Remove Duplicates</span>
                    </a>

                    <div className="shadow-hider"></div>
                    <div className="subnav-drawer">
                        <DedupeConfigForm 
                            onApply={updateData}
                            config={dedupeConfig} 
                            sourceId={sourceId as string} 
                            sourceRecordTypeId={sourceRecordTypeId as string}
                            sourceColumns={columnOptions}
                            
                            onChange={onChangeDedupeConfig}
                        />
                    </div>
                </div>
                <a role="button" href={`/sources/${sourceId}/data/${sourceRecordTypeId}/standardize`} className={`nav-link ${location.pathname.includes('/standardize') ? 'active': ''}`}>
                    <i className="mdi mdi-table"></i>
                    <span className="ms-1">Map to Business Objects</span>
                </a>
                {/* <div className="search">
                    <input type="text" placeholder="Search..."/>
                </div> */}
                <div className="actions">
                    <AsyncButton
                        icon="mdi mdi-content-save"
                        variant="success"
                        text="Save Configuration"
                        loading={saving}
                        onClick={saveConfiguration}
                    />
                </div>
                
                
                
            </nav>
            <div className="content-inner has-footer no-scroll has-subnav">
                {loadingData && <SnowflakeLoader/>}
                {!loadingData && tablePath &&  (
                    <TableExplorer
                        tablePath={tablePath}
                        columnOrder={columnPreferences.filter(cp => cp.visible).map(cp => cp.key)}
                    />
                )}
            
            </div>

            <footer className="footer">
                {!loadingData && (
                    <div className="row">
                    <div className="col-6">
                        <span onClick={loadFilteredOutRecords} role={filteredOutRecords>0?"button": ''}>
                            {integerFormatter(filteredOutRecords)} <span>record{filteredOutRecords === 1 ? '' : 's'}</span>
                            {theSourceRecordType.shape && (
                                <span> ({formatPercentage(filteredOutRecords / theSourceRecordType.shape?.total_records)}%) </span>
                            )}
                            filtered out
                        </span>
                        <Modal size="xl" show={showFilteredOutRecords} onHide={() => setShowFilteredOutRecords(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Filtered Out Records - {integerFormatter(filteredOutRecords)} <span>record{filteredOutRecords === 1 ? '' : 's'}</span>
                                    {theSourceRecordType.shape && (
                                        <span> ({formatPercentage(filteredOutRecords / theSourceRecordType.shape?.total_records)}%) </span>
                                    )}
                                    filtered out
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <TableExplorer tablePath={filteredOutTablePath! as string} columnOrder={columnPreferences.filter(cp => cp.visible).map(cp => cp.key)} />
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary" onClick={() => setShowFilteredOutRecords(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                        <span className="ms-3 me-3">&bull;</span>
                        <span onClick={loadDuplicates} role={removedDuplicateRecords>0?"button": ''}>
                            {integerFormatter(removedDuplicateRecords)} duplicates removed
                        </span>
                        <Modal size="xl" show={showDuplicates} onHide={() => setShowDuplicates(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Source Duplicates</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            {loadingDuplicates && <LoadingCard />}
                            {!loadingDuplicates && exampleDuplicates.map((s: any, idx: number) => {
                                return (
                                    <div>
                                        <h5>Example #{idx + 1}</h5>
                                        <SimpleDataTable data={s.source_data} highlightRowsByIndexes={[0]}/>
                                    </div>
                                )
                            })}
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary" onClick={() => setShowDuplicates(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div className="col-6 text-end">
                        Showing {integerFormatter(displayingRecords)} of {integerFormatter(totalRecords)} records
                    </div>
                </div>
                )}
                
            </footer>
            
        </div>
        
    </div>;
   

        
}

export default SourceRecordTypeWrapperPage;