import { ReactNode, useEffect, useRef, useState } from "react";
import ReactFlow, { Background, Controls, Handle, MiniMap, NodeProps, Position } from 'reactflow';
import 'reactflow/dist/style.css';
import { Link } from "react-router-dom";
import SourceORM, { Source, SourceRecordType } from "@models/source";
import { getErrorMessage } from "@services/errors.service";
import '../lineage/lineagegraph.css';
import { useImmer } from 'use-immer';
import { OrchestratedExecution } from "@models/pipelineOrchestration";
import PipelineExecutionStatus from "@components/pipelines/PipelineExecutionStatus.component";
import { PipelineExecution } from "@models/pipelineExecution";
import LoadingCard from "@components/card/LoadingCard.component";
import { autoLayout, ReactFlowNode, ReactFlowEdge, Action } from "@components/lineage/LineageGraph.component";
import {usePipelineOrchestration, useSources, useSourceRecordTypes, useBusinessObjects, usePipelines, useSourceTypes} from '@stores/data.store';

const FlowNode = (node: NodeProps) => {
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes();
    const getIcon = () :ReactNode => {
        switch (node.type) {
            case 'businessObject':
                return <i className="uil-box text-primary"></i>;
            case 'source':
                const sourceType = soureTypeOptions?.find((st) => st.id == node.data.object.type);
                return <img src={sourceType?.icon_path}/>;
            case 'sourceRecordType':
                return <i className="uil-database"></i>;

        }
        return <></>;
    };

    const getActions = () :Action[] => {
        // console.log('actions for type', node.type);

        switch (node.type) {
            case 'businessObject':
                return [
                    {
                        icon: 'mdi mdi-eye',
                        linkTo: `/business-objects/${node.data.object.id as string}/data`
                    }
                ]
            case 'source':
                return [
                    {
                        icon: 'mdi mdi-eye',
                        linkTo: `/sources/${node.data.object.id as string}`
                    }
                ];
            case 'sourceRecordType':
                return [
                    {
                        icon: 'mdi mdi-eye',
                        linkTo: `/sources/${node.data.object.source_id}/data/${node.data.object.id}`
                    }
                ];
        }

        return [];
    }

    const actions = getActions();

    return (
        <>
            {node.type !== 'source' && <Handle type="target" position={Position.Left} />}
            <div className="card border flow-card">
                <div className="card-body p-1">
                    <div>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <div className="avatar-sm flow-node-img">
                                    {getIcon()}
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-2">
                                <h5 className="m-0">{node.data.label}</h5>
                                {/* <p className="font-13 text-muted m-0">Subtitle</p> */}
                            </div>
                            {node.data.object.execution && <div className="flex-grow-1 ms-2">
                                <Link to={`/pipelines/${node.data.object.execution.pipeline_id}/executions/${node.data.object.execution.id}`}> <PipelineExecutionStatus execution={node.data.object.execution as PipelineExecution}/></Link>
                                {/* <p className="font-13 text-muted m-0">Subtitle</p> */}
                            </div>}
                        </div>
                    </div>
                    
                    
                </div>
                <div className="card-footer p-1">
                    <div className="text-end">
                        {actions.map(a => {
                            return <Link to={a.linkTo} className="action-icon">
                                <i className={a.icon}/>
                            </Link>
                        })}
                        
                    </div>
                </div>
            </div>
           
            {node.type !== 'businessObject' && <Handle type="source" position={Position.Right} />}
        </>
    )


   
}


const nodeTypes = {
    'source': FlowNode,
    'sourceRecordType': FlowNode,
    'businessObject': FlowNode,
}


function setEdgeVars(edge: ReactFlowEdge, orchestratedExecution: OrchestratedExecution) {
    if (!orchestratedExecution.execution || !['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(orchestratedExecution.execution.status)) {
        edge.animated = true;
    }else{
        edge.animated = false;
    }

}

interface PipelineOrchestrationFlowProps {
    edges_required?: boolean;
    pipelineOrchestrationId: string;
}

const PipelineOrchestrationFlow = (props: PipelineOrchestrationFlowProps) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    // const [orchestration, setOrchestration] = useState<PipelineOrchestration|undefined>(undefined);
    const intervalTracker = useRef<boolean|null>(null);

    const [nodes, setNodes] = useImmer<ReactFlowNode[]>([]);
    const [edges, setEdges] = useImmer<ReactFlowEdge[]>([]);
    const [shouldRefetch, setShouldRefetch] = useState(false);

    const { data: orchestration } = usePipelineOrchestration(props.pipelineOrchestrationId, () => {
        return (shouldRefetch) ? 1000 : false;
    });

    const { data: sources } = useSources();
    const { data: sourceRecordTypes } = useSourceRecordTypes();
    const { data: businessObjects } = useBusinessObjects();
    const { data: pipelines } = usePipelines();


    useEffect(() => {
        if (!!orchestration && ( !orchestration.pipeline_executions[0].execution || !['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(orchestration.pipeline_executions[0].execution.status) )) {
            setShouldRefetch(true);
        }else{
            setShouldRefetch(false);
        }
    }, [orchestration]);


    useEffect(() => {
        if(!orchestration || !pipelines || !businessObjects || !sourceRecordTypes || !sources) {
            return;
        }

        try {
            const bo_ids = orchestration!.pipeline_executions.map((oe) => oe.business_object_id).filter((v) => !!v);
            const filteredBusinessObjects = businessObjects!.filter((bo) => bo_ids.includes(bo.id as string));
            
            // Figure out nodes and edges. What we want to do with this screen is prompt the user
            // to connect more data sources, use more data destinations, or add more business objects
            // in the center.
            const nodes: ReactFlowNode[] = [];
            const edges: ReactFlowEdge[] = [];

            const sourceRecordTypeUsedStatus: {
                [key: string]: boolean;
            } = {};

            pipelines!.forEach(p => {
                if (p.steps && p.steps.length >= 2) {
                    if (!!p.steps[0].config.source_record_type_id) {
                        if (!bo_ids.includes(p.steps[p.steps.length - 1].config.business_object_id)) {
                            return;
                        }                       

                        const source = `srt|${p.steps[0].config.source_record_type_id}`;
                        const target = `bo|${p.steps[p.steps.length - 1].config.business_object_id}`;

                        // Check if there's an already an edge
                        const found = edges.find(e => e.source === source && e.target === target);
                        if (!found) {
                            console.log('Making edge:', source, target, edges.length);
                            edges.push({
                                id: `edge${edges.length.toString()}`,
                                source: source,
                                target: target,
                                type: 'default',
                                animated: false,
                            });
                        }
                    }
                    
                    
                }
            });

            sourceRecordTypes!.forEach((srt: SourceRecordType) => {
                if(props.edges_required && edges.find((e) => e.source == `srt|${srt.id}`) == undefined ) {
                    return;
                }

                nodes.push({
                    id: `srt|${srt.id as string}`,
                    position: {
                        x: 0,
                        y: 0,
                    },
                    data: {
                        label: srt.name,
                        object: srt,

                    },
                    type: 'sourceRecordType',
                });

                edges.push({
                    id: `edge${edges.length.toString()}`,
                    source: `source|${srt.source_id}`,
                    target: `srt|${srt.id as string}`,
                    type: 'default',
                    animated: false,
                });

                sourceRecordTypeUsedStatus[srt.id as string] = false;
            });

            sources!.forEach((s: Source) => {
                if(props.edges_required && edges.find((e) => e.source == `source|${s.id}`) == undefined ) {
                    return;
                }

                nodes.push({
                    id: `source|${s.id as string}`,
                    position: {
                        x: 0,
                        y: 0,
                    },
                    data: {
                        label: s.name,
                        object: s,
                    },
                    type: 'source',
                });
            });

            filteredBusinessObjects.forEach(bo => {
                nodes.push({
                    id: `bo|${bo.id as string}`,
                    position: {
                        x: 0,
                        y: 0,
                    },
                    data: {
                        label: bo.name,
                        object: bo,

                    },
                    type: 'businessObject',
                });

            });

            autoLayout(nodes, edges);
            setNodes(nodes);
            setEdges(edges);
        } catch (err) {
            console.error(err);
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, [orchestration, sourceRecordTypes, pipelines, sources, businessObjects]);

    useEffect(() => {
        // this can all be refactored to be more effecient looping but just trying to get it functional at the moment
        setEdges((edgesDraft) =>{
                orchestration?.pipeline_executions.forEach((oe) => {
                    edges.forEach((e, edgeIdx) => {
                        if (e.target == `bo|${oe.business_object_id}`) {
                            setEdgeVars(edgesDraft[edgeIdx], oe);
                        }
                    });

                    oe.dependent_executions.forEach((de) => {
                        const source_id = de.execution?.steps[0].step_config.config.source_id;
                        edges.forEach((e, edgeIdx) => {
                            if (e.source == `source|${source_id}`) {
                                setEdgeVars(edgesDraft[edgeIdx], de);
                            }
                        });
                    })
                })
            
        });

        setNodes((nodesDraft) => {
            orchestration?.pipeline_executions.forEach((oe) => {
                if(!!oe.execution) {
                    const nodeIdx = nodes.findIndex((n) => n.id == `bo|${oe.business_object_id}`);
                    if(nodeIdx > -1) {
                        nodesDraft[nodeIdx].data.object.execution = oe.execution;
                    }
                }

                oe.dependent_executions.forEach((de) => {
                    const source_record_type_id = de.execution?.steps[0].step_config.config.source_record_type_id;

                    if(!!de.execution) {
                        const nodeIdx = nodes.findIndex((n) => n.id == `srt|${source_record_type_id}`);
                        if(nodeIdx > -1) {
                            nodesDraft[nodeIdx].data.object.execution = de.execution;
                        }
                    }


                })
            });
        })
    }, [orchestration, nodes, edges]);


    if (loading) {
        return <LoadingCard action="Loading run info..." />;
    } else if (error) {
        return <div className="alert alert-danger">{error}</div>
    }
    return (
        <div style={{ height: 'calc(100vh - 207px)' }}>
            <ReactFlow 
                nodes={nodes} 
                edges={edges} 
                nodeTypes={nodeTypes} 
                proOptions={{hideAttribution: true}}
                fitView
            >
                <Background/>
                <Controls showInteractive={false}/>
                    <MiniMap pannable/>
            </ReactFlow>
        </div>
    );
}


export default PipelineOrchestrationFlow;