import BusinessObjectORM, { BusinessObject, BusinessObjectRelationship, BusinessObjectRelationshipORM } from "@models/businessObject";
import { getErrorMessage } from "@services/errors.service";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { Node, Edge, Background, Controls, Connection, ConnectionLineComponentProps, Handle, MarkerType, NodeProps, OnConnectStartParams, Position, ReactFlow, ReactFlowState, useStore, useNodesState, useEdgesState, Panel, ReactFlowInstance, MiniMap } from "reactflow";
import dagre, { graphlib } from 'dagre';
import { Link } from "react-router-dom";
import { Badge, Button, Form, Modal, Spinner } from "react-bootstrap";
import { CardHeader } from "@components/card/Card.component";
import ConfirmationModal from '@components/alert/ConfirmationModal.component';
import RemoveableEdge from "@components/flowchart/RemoveableEdge.component";
import { confirmation } from "@services/alert/alert.service";
import 'reactflow/dist/style.css';
import './dataModel.css';
import toast from "@services/toast.service";
import { useBusinessObjects } from "@stores/data.store";

function autoLayout(nodes: Node[], edges: Edge[]): Node[] {
    const g = new graphlib.Graph();
    g.setGraph({
        rankdir: 'TB'
    });
    g.setDefaultEdgeLabel(() => {
        return {};
    });

    nodes.map(n => {

        g.setNode(n.id, {
            label: n.id,
            width: 250,
            height: 200,
        });
    });

    edges.map(e => {
        g.setEdge(e.source, e.target);
    });

    dagre.layout(g);

    // Now set the position on the nodes
    const newNodes = g.nodes().map(nodeId => {
        const nodeData = g.node(nodeId);

        const nodeToUpdate = nodes.find(ntu => ntu.id === nodeId);
        if (nodeToUpdate) {
            return Object.assign({}, nodeToUpdate, {
                position: {
                    x: nodeData.x,
                    y: nodeData.y,
                }
            });
        }
        throw Error('Could not find node');
    });


    return newNodes;
   
}





const ConnectionLine = (props: ConnectionLineComponentProps) => {
    const { fromX, fromY, toX, toY } = props;
    return (
      <g>
        <path
          fill="none"
          stroke="#222"
          strokeWidth={1.5}
          className="animated"
          d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
        />
        <circle cx={toX} cy={toY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
      </g>
    );
};

const addCtaNodes = (nodes: Node[], edges: Edge[]): [Node[], Edge[]] => {

    const finalNodes = [{
        id: 'add_new',
        type: 'add_new',
        position: {
            x: 0,
            y: 0,
        },
        data: {},
    }, ...nodes]
    return [finalNodes, edges];
    
}

interface PromptModalProps {
    inputLabel: string;
    show: boolean;
    header: string;
    onConfirm: (value: string) => void;
    onClose: () => void;
}

const PromptModal = (props: PromptModalProps) => {
    const [value, setValue] = useState('');

    const confirm = () => {
        props.onConfirm(value);
        setValue('');
    };

    const close = () => {
        props.onClose();
        setValue('');
    }
    return (
        <Modal show={props.show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{props.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>{props.inputLabel}</Form.Label>
                    <Form.Control type="text" onChange={(e) => setValue(e.target.value)}/>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
                <Button 
                    variant="primary"
                    disabled={!value}
                    onClick={() => confirm()}
                >Save</Button>
            </Modal.Footer>
        </Modal>
    )
}



const DataModel = () => {
    const instance = useRef<ReactFlowInstance|null>(null);
    const [loading, setLoading] = useState(true);
    const [businessObjects, setBusinessObjects] = useState<BusinessObject[]>([]);
    const [relationships, setRelationships] = useState<BusinessObjectRelationship[]>([]);
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const [error, setError] = useState('');

    const [currentConnectionSource, setCurrentConnectionSource] = useState<OnConnectStartParams|undefined>(undefined);

    const [showNewModal, setShowNewModal] = useState(false);

    const [deletingBusinessObject, setDeletingBusinessObject] = useState<BusinessObject|undefined>(undefined);


    const [activeHandleType, setActiveHandleType] = useState('');


    const defaultEdgeOptions = {
        type: 'default',
        animated: false,
    }

    const nodeFromBusinessObject = (bo: BusinessObject) => {
        return {
            id: bo.id as string,
            position: {
                x: 0,
                y: 0,
            },
            data: bo,
            type: 'business_object',
        };
    }



    const onRemoveConnection = (parentId: string, childId: string, currentRelationships: BusinessObjectRelationship[]) => {
        console.log('Removing connection. Relationships in function are', currentRelationships);
        confirmation({
            header: 'Remove Relationship?',
            message: 'Are you sure you want to remove this relationship?',
            onConfirm: async () => {
                // Find the relationship to remove
                const idx = currentRelationships.findIndex(r => {
                    console.log('Checking', r);
                    return r.parent_business_object_id == parentId && r.child_business_object_id == childId
                });

                console.log('Looking for relationship between', parentId, childId);
                console.log(currentRelationships);

                if (idx >= 0) {
                    const relationshipToDelete = currentRelationships[idx];
                    try {
                        await BusinessObjectRelationshipORM.delete(relationshipToDelete);
                    } catch (err) {
                        console.error(err);
                    }

                    const newRelationships = [...currentRelationships];
                    newRelationships.splice(idx, 1);
                    
                    setNodesAndEdges(businessObjects, newRelationships);
                    setRelationships(newRelationships);
                }
                console.log('IDX OF REMOVING:', idx);
            },
            onClose: () => {
                console.log('canceled');
            },
            confirmationButtonText: 'Remove',
        });
    };

    const setNodesAndEdges = (businessObjects: BusinessObject[], relationships: BusinessObjectRelationship[], focusOnLastNode: boolean = false) => {
        const nodes: Node[] = businessObjects.map(nodeFromBusinessObject);

        const edges = relationships.map(r => {
            return {
                id: r.id as string,
                source: r.parent_business_object_id,
                target: r.child_business_object_id,
                type: 'removeable',
                data: {
                    onRemove: () => {
                        onRemoveConnection(r.parent_business_object_id, r.child_business_object_id, relationships);
                    }
                },
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                },
                animated: false,
            }
        });

        const laidOutNodes = autoLayout(nodes, edges);

        setNodes(laidOutNodes)
        setEdges(edges);

        if (focusOnLastNode) {
            setTimeout(() => {
                if (instance.current) {
                    const lastNodePosition = laidOutNodes[laidOutNodes.length - 1].position;
                    instance.current.setCenter(lastNodePosition.x, lastNodePosition.y);
                    console.log('Should have set center to', lastNodePosition);
                } else {
                    console.log('no current instance');
                }
            });
        }
    }
    const loadData = async () => {
        setLoading(true);

        try {
            const loadedBusinessObjects = (await BusinessObjectORM.find()).records;
            const loadedRelationships = (await BusinessObjectRelationshipORM.find()).records;
            
            setBusinessObjects(loadedBusinessObjects);
            console.log('Setting relationships on line 265 to', loadedRelationships);
            setRelationships(loadedRelationships);

            setNodesAndEdges(loadedBusinessObjects, loadedRelationships);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }



    useEffect(() => {
        console.log('LOADING DATA');
        loadData();
        // setLoading(false);
    }, []);

    const BusinessObjectNode = (node: NodeProps) => {
        const bizobj = node.data as BusinessObject;
        const connectionNodeId = useStore((state: ReactFlowState) => state.connectionNodeId);

        let belongsToHandleClass = '';
        let hasManyHandleClass = '';

        if (!!currentConnectionSource) {
            console.log('current connection source:', currentConnectionSource);
            belongsToHandleClass = (currentConnectionSource.nodeId !== node.id && currentConnectionSource.handleType === 'source') ? 'is-connectable' : 'not-connectable';
            hasManyHandleClass = (currentConnectionSource.nodeId !== node.id && currentConnectionSource.handleType === 'target') ? 'is-connectable' : 'not-connectable';
        }

        
        return (
            <>
                <Handle className="reactflow-handle-interactive top" type="target" position={Position.Top}>
                    Belongs To
                </Handle>
                <div className="card border mb-0 datamodel-node">
                    <div className="card-header bg-pliable text-black fw-bold">
                        {/* <div className="float-end">
                            <Link to="/"><Badge bg="info">1.2M</Badge></Link>
                        </div> */}
                        {bizobj.name}
                    </div>
                    <div className="card-body" style={{width: '250px'}}>
                        <div className="row">
                            <div className="col-4 p-1">
                            <Link className="btn btn-icon btn-outline-secondary w-100" title="Attributes" to={'/business-objects/' + bizobj.id + '/attributes'}>
                                <i className="mdi mdi-pencil-circle"></i>
                            </Link>
                            </div>
                            <div className="col-4 p-1">
                            <Link className="btn btn-icon btn-outline-secondary w-100" to={'/business-objects/' + bizobj.id + '/data'}>
                                <i className="mdi mdi-database-search"></i>
                            </Link>
                            </div>
                            <div className="col-4 p-1">
                            <button className="btn btn-icon  btn-outline-secondary w-100" onClick={() => setDeletingBusinessObject(bizobj)}>
                                <i className="mdi mdi-delete"></i>
                            </button>
                            </div>
                        </div>
                        <div className="text-center">
                            
                            
                            
                        </div>
                        
                        {/* <div className="font-13">
                            <Link to="/">Configure</Link><br />
                            <Link to="/sources/new">Connect Data</Link><br />
                            <Link to="/sources/new">View Sources</Link><br />
                            <Link to="/sources/new">Explore</Link><br />
                            <Link to="/">Relationships</Link>

                        </div> */}
                        
                    </div>
                </div>
                <Handle className="reactflow-handle-interactive bottom" type="source" position={Position.Bottom}>
                    Has Many
                </Handle>
            </>
        )
    }

    // const isValidBelongsToConnection = (connection: Connection) => {
    //     return activeHandleType === 
    // }

    // const isValidHasManyConnection = (connection: Connection) => {

    // }
    
    const CTANode = (node: NodeProps) => {
        return (
            <>
                <Handle type="target" position={Position.Top} className="invisible" isConnectable={false}>
                </Handle>
                <button className="datamodel-addnew-cta" onClick={() => setShowNewModal(true)}>Add New</button>
                <Handle type="source" position={Position.Bottom} className="invisible" isConnectable={false}>
                </Handle>
            </>
        )
    }
    
    
    const nodeTypes = {
        'business_object': BusinessObjectNode,
        'add_new': CTANode
    };

    const edgeTypes = {
        'removeable': RemoveableEdge,
    }

    const onConnect = useCallback(
        async (connection: Connection) => {
            console.log('Creating relationships')
            try {
                const relationship = await BusinessObjectRelationshipORM.save({
                    id: null,
                    name: '',
                    description: '',
                    parent_business_object_id: connection.source as string,
                    child_business_object_id: connection.target as string,
                    allow_many_children: true,
                });
    
                const newRelationships = [...relationships, relationship];
    
                setRelationships(newRelationships);
                console.log('Setting relationships on line 369 to', newRelationships);
                setNodesAndEdges(businessObjects, newRelationships);
            } catch (err) {
                console.log(err);
            }
            
        }
    , [businessObjects, relationships]);

    const onConnectStart = (_: any, params: OnConnectStartParams ) => {
        console.log('setting connection source to', params);
        setCurrentConnectionSource(params);
    }

    const onConnectEnd = (_: any ) => {
        setCurrentConnectionSource(undefined);
    }


    const onInit = useCallback((theInstance: ReactFlowInstance) => {
        instance.current = theInstance;
    }, []);

    const getContent = () => {
        if (loading) {
            return <Spinner/>;
        } else if (error) {
            return <div className="alert alert-danger">{error}</div>
        }
        return (
            <div style={{ height: '100%' }}>
                <ReactFlow 
                    nodes={nodes} 
                    edges={edges} 
                    nodeTypes={nodeTypes} 
                    edgeTypes={edgeTypes}
                    proOptions={{hideAttribution: true}}
                    connectionLineComponent={ConnectionLine}
                    onConnect={onConnect}
                    defaultEdgeOptions={defaultEdgeOptions}
                    onInit={onInit}
                    panOnDrag
                    elementsSelectable
                    nodesDraggable={false}
                >
                    <Background/>
                    <Controls showInteractive={false}/>
                    <MiniMap pannable/>
                </ReactFlow>
            </div>
        );
    }

    const addNewObject = async (name: string) => {
        setShowNewModal(false);

        try {
            const newObject = await BusinessObjectORM.save({
                id: null,
                name: name,
                fields: [],
                description: '',
            });

            const newBusinessObjects = [...businessObjects, newObject];
            
            setBusinessObjects(newBusinessObjects);
            setNodesAndEdges(newBusinessObjects, relationships, true);

        } catch (err) {
            console.log('ERROR:', err);
            setError(getErrorMessage(err));
        }
    }

    const deleteBusinessObject = async () => {
        if (!!deletingBusinessObject) {
            const id = deletingBusinessObject.id as string;
            await BusinessObjectORM.delete(deletingBusinessObject);

            // Delete edges referencing this one and the single biz obj
            const idx = businessObjects.findIndex(bo => bo.id === id);
            const newBusinessObjects = businessObjects.filter(bo => bo.id != id);
            const newRelationships = relationships.filter(r => r.parent_business_object_id != id && r.child_business_object_id != id);
            
            setBusinessObjects(newBusinessObjects);
            setRelationships(newRelationships);
            console.log('Setting relationships on line 456 to', newRelationships);
            setNodesAndEdges(newBusinessObjects, newRelationships);
            setDeletingBusinessObject(undefined);

            toast('success', 'Success!', 'Business object deleted');
        }
        

    }

    



    return <>
            <PromptModal
                show={showNewModal}
                onClose={() => setShowNewModal(false)}
                onConfirm={addNewObject}
                header="New Object"
                inputLabel="Object Name"
            />
            <ConfirmationModal
                header="Delete object?"
                confirmationButtonText="Delete"
                message={`To delete this object, please type "${deletingBusinessObject?.name}" in the field below:`}
                show={!!deletingBusinessObject}
                typedValueExpectation={deletingBusinessObject?.name}
                onClose={() => setDeletingBusinessObject(undefined)}
                confirmationButtonVariant="danger"
                onConfirm={() => deleteBusinessObject()}
            />
        <>
        {getContent()}
        </>
    </>

    
    
}

export default DataModel;