import PageTitle from "@components/pageTitle/PageTitle.component";
import SetupDataSource from "@components/sources/setup/SetupDataSource.component";
import { useParams } from "react-router-dom";


const NewSourceSetupPage = () => {
    const { sourceType } = useParams();
    return <>
        <PageTitle title="Setup Data Source"></PageTitle>
        <div className="card">
            <div className="card-body p-onboarding">
                <SetupDataSource sourceTypeName={sourceType as string}/>
            </div>
        </div>
    </>
    
}

export default NewSourceSetupPage;