import ORM, { BaseModel } from "./orm";
import { PipelineStepConfig } from "./pipeline";

export interface ExecutionStep {
    status: string;
    started_at?: Date;
    completed_at?: Date;
    error?: string;
    result_info?: string;
    result_refs?: {
        [key: string]: any;
    }
    result_stats?: {
        [key: string]: any;
    }
    step_config: PipelineStepConfig;

}

export interface PipelineExecution extends BaseModel {
    pipeline_id: string;
    job_id: string;

    status: string;
    executed_at?: Date;
    executed_by?: string;
    approved_or_rejected_at?: Date;
    approved_or_rejected_by?: string;

    error?: string;

    steps: ExecutionStep[];
}

const PipelineExecutionORM = new ORM<PipelineExecution>('pipelineexecutions', ['executed_at', 'approved_or_rejected_at']);
export default PipelineExecutionORM;
  