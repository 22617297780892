import { Component } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageTitle from "../components/pageTitle/PageTitle.component";
import FileORM, { File } from "../models/file";
import ApiService, { ListRecordsResponse } from "../services/api/api.service";
import SourceORM, { Source } from "../models/source";
import { parseDateString, timeAgo } from "../services/time.service";
import toast from "../services/toast.service";
import BackgroundService from "../services/bg.service";
import { getErrorMessage } from "../services/errors.service";
import LoadingCard from "../components/card/LoadingCard.component";
import SourceSchemaTree from '@components/sources/SourceSchemaTree.component';
import { Form, Alert } from 'react-bootstrap';


export interface ColumnElement {
    name: string;
    key: string;
    children: ColumnElement[]
}


interface Props {
    sourceId: string;
    fileId: string;
    navigate: (route: string) => void;

}

interface JobEnqueueResponse {
    job_id: string;
}

interface State {
    currentStep: string;
    loading: boolean;
    file?: File;
    source?: Source;
    sourceElements?: ColumnElement[];
    processingFile: boolean;
    selectedRecordTypeId: string;
    processingError?: string;
}

class CreateBusinessObjectWizard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            currentStep: 'createNewBusinessObject',
            file: undefined,
            processingFile: false,
            selectedRecordTypeId: '',
        };
    }

    private async load() {
        this.setState({
            loading: true,
            file: undefined,
        });

        const source = await SourceORM.findById(this.props.sourceId);
        

        // todo  bring file id back into fold if its set
        // const file = await FileORM.findById(this.props.fileId);
        // fileId=${this.props.fileId}

        const resp = await ApiService.getInstance().request(
            'GET', 
            `/sources/${this.props.sourceId}/describe`
        ) as ListRecordsResponse<ColumnElement>;

        this.setState({
            loading: false,
            // file: file,
            source: source,
            sourceElements: resp.records
        });

    }

    componentDidMount() {
        this.load();
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>
        } else if (this.state.source) {
            return (
                <div>
                    <PageTitle title={"Create Business Object from source"} breadcrumbs={[
                        {
                            title: 'Data Sources',
                            path: '/sources'
                        }, {
                            title: this.state.source.name,
                            path: `/sources/${this.props.sourceId}`
                        }, {
                            title: 'Record'
                        }
                    ]}/>
                    <div className="row">
                        <div className="col-6">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Object Name</Form.Label>
                                                    <Form.Control type="email" className="form-control-lg" placeholder="Enter an object name" />
                                                    <Form.Text className="text-muted"></Form.Text>
                                                </Form.Group>
                                            </Form>
                                            <hr />
                                        </div>
                                        <div className="col-12">
                                            <p>Choose the source fields that should map to this business object.</p>
                                            <Alert variant="secondary">
                                                Dont worry, you can always bring in additional fields, or create other business objects from this source in the future.
                                            </Alert>
                                        </div>
                                        <div className="col-12">
                                            <SourceSchemaTree treeData={this.state.sourceElements} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>File not found</div>
            )
        }
    }
}

const WrappedCreateBusinessObjectWizard = () => {
    const { sourceId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const fileId = searchParams.get('fileId');
    const navigate = useNavigate();
    return <CreateBusinessObjectWizard fileId={fileId as string} navigate={navigate} sourceId={sourceId as string} />
}
export default WrappedCreateBusinessObjectWizard;