import ApiService from "./api/api.service";


export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export default class BackgroundService {
  private static instance: BackgroundService;

  public static getInstance(): BackgroundService {
    if (!BackgroundService.instance) {
        BackgroundService.instance = new BackgroundService();
    }

    return BackgroundService.instance;
  }

  constructor() {
  }


  public async waitForJob(jobId: string) {
    let jobStatus = 'queued';
    let resp : any;
    let iteration = 1;

    while (['queued', 'started', 'deferred', 'scheduled'].includes(jobStatus)){
      if (iteration > 50) {
        throw new Error('Max wait for job exceeded')
      }
      await sleep(iteration * 1000);
      resp = await ApiService.getInstance().request('GET', `/get-job-status/${jobId}`);
      jobStatus = resp.job_status;
      iteration += 1;
    }

    if (resp.job_status === 'failed') {
      throw new Error('Job failed');
    }

    return resp.job_result;
  }
}
