
import { ReactNode, useEffect, useState } from "react";
import { Collapse, Form, Spinner, Tab, Tabs } from "react-bootstrap";
import { timeAgo } from "../../services/time.service";
import DedupeStep from "./dedupe/DedupeStep.component";
import FilterStep from "./filter/FilterStep.component";
import MapStep from "./map/MapStep.component";
import OutputStep from "./output/OutputStep.component";
import { StepComponentProps } from "./shared";
import SourceStep from "./source/SourceStep.component";
import StepResults from "./StepResults";










const PipelineStepComponent = (props: StepComponentProps) => {
    const [open, setOpen] = useState(false);

    const [activeTab, setActiveTab] = useState('output');
    let className = "card border-default mb-0";
    if (props.active) {
        className="card border-primary mb-0";
    }

    const renderStatusIndicator = () => {
        let icon: ReactNode = <></>;
        let infoText: ReactNode = <></>;
        switch (props.status) {
            case 'PENDING':
                icon = <i className="mdi mdi-clock-time-three"/>;
                infoText = 'Pending';
                break;
            case 'RUNNING':
                icon = <Spinner size="sm"/>;
                infoText = 'Running';
                break;
            case 'IN_REVIEW':
                icon = <i className="mdi mdi-pause-circle text-info"/>;
                break;
            case 'COMPLETE':
                icon = <i className="mdi mdi-checkbox-marked-circle text-success"/>;
                break;
            case 'ERROR':
                icon = <i className="mdi mdi-alert-circle text-danger"/>;
                infoText = 'Step execution failed!';
                break;
        }

        if (['IN_REVIEW', 'COMPLETE'].includes(props.status as string)) {
            if (props.lastRunAt) {
                infoText = <>Completed {timeAgo(props.lastRunAt)}</>;
            }
        }
        
        
        return <>
            {icon}
            <span className="text-muted ms-1">{infoText}</span>
        </>;
    }

    

    const renderOpenCloseButtons = () => {
        return (
            <div className="btn-group me-1">
                {open && (
                    <button onClick={() => setOpen(false)} className="btn btn-info pb-0 pt-0"><i className="mdi mdi-menu-up-outline"></i> Hide Details</button>
                )}
                {!open && (
                    <button onClick={() => setOpen(true)} className="btn btn-info pb-0 pt-0"><i className="mdi mdi-menu-down-outline"></i> Show Details</button>

                )}
            </div>
        )
    }
    const renderActionButtons = () => {
        if (props.allowInteractivity) {
            return (

                    <div className="btn-group">

                        
                        <button disabled={!(props.allowInteractivity && props.allowMoveUp)}onClick={() => props.onMoveUp && props.onMoveUp()} className="btn btn-outline-dark pb-0 pt-0">
                            <i className="mdi mdi-arrow-up-bold"></i>
                        </button>
        
                        <button disabled={!(props.allowInteractivity && props.allowMoveDown)} onClick={() => props.onMoveDown && props.onMoveDown()} className="btn btn-outline-dark pb-0 pt-0">
                            <i className="mdi mdi-arrow-down-bold"></i>
                        </button>
        
        
                        <button disabled={!(props.allowInteractivity && props.allowDelete)} onClick={() => props.onRemove && props.onRemove()} className="btn btn-outline-dark pb-0 pt-0">
                            <i className="mdi mdi-delete"></i>
                        </button>
                    </div>
                    
                    
            );
        }
        return <></>;

        
    }
    return <div className={className}>
        <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
                <div className="col-2">
                    {renderOpenCloseButtons()}
                </div>
                <div className="col-5 border-end">
                    <h5 className="header-title mb-0">
                        Step {props.stepIndex + 1} ({props.stepConfig.step_type})
                    </h5>

                </div>
                <div className="col border-end ps-3">
                    {renderStatusIndicator()}

                </div>
                <div className="col text-end ps-3">
                    {/* <div style={{display: 'inline-flex'}} className="me-3" >

                    </div> */}
                    {renderActionButtons()}

                </div>
                
            </div>
        </div>
        <Collapse in={open}>
            <div className="m-0 p-0">
                <div className="card-body">
                    <div className="row">
                        <div className="col-4 border-end">
                            <Form.Group className="mb-3">
                                <Form.Label>Step Name</Form.Label>
                                <Form.Control disabled={!props.allowInteractivity} className="input" value={props.stepConfig.name} onChange={(e) => props.onChange('name', e.target.value)}/>
                            </Form.Group>
                            {props.stepConfig.step_type === 'SOURCE' && <SourceStep {...props}/>}
                            {props.stepConfig.step_type === 'MAP' && <MapStep {...props}/>}
                            {props.stepConfig.step_type === 'DEDUPE' && <DedupeStep {...props}/>}
                            {props.stepConfig.step_type === 'OUTPUT' && <OutputStep {...props}/>}
                            {props.stepConfig.step_type === 'FILTER' && <FilterStep {...props}/>}

                        </div>
                        <div className="col-8">
                            {!!props.totalRecordsOutput && (
                                <h3>Total Records: {props.totalRecordsOutput}</h3>
                            )}
                            <StepResults
                                stepType={props.stepConfig.step_type}
                                previousStepStatus={props.previousStepStatus}
                                thisStepStatus={props.status}
                                thisStepIndex={props.stepIndex}
                                thisStepError={props.error}
                                allowInteractivity={!!props.allowInteractivity}
                                pipelineId={props.pipelineId}
                                pipelineExecutionId={props.pipelineExecutionId}
                                loading={!!props.loadingResults}
                                onPreviewInput={() => !!props.onReloadInput ? props.onReloadInput() : null}
                                onPreviewOutput={() => !!props.onReloadOutput ? props.onReloadOutput() : null}

                            />


                            {/* This view will also be where we go when we're just running the pipeline, with read-only form fields. */}
                            {/* QA view for mapping will be like we had for title foundry */}
                            {/* QA view for dedupe will be 1 table per group where you can look at what will happen */}
                            {/* QA view for filtering will be a list of example records that made it and example records that didn't */}

                            {/* We need an indicator on each step card that it's running or it ran. And we also need to keep track of the current execution in the state so we know how to query for results. */}

                            
                        </div>
                    </div>
                </div>
                
                
            </div>
            
        </Collapse>
    </div>
}

export default PipelineStepComponent;