import { TableExplorer } from "@components/datatable/TableExplorer.component";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom"
import { SimpleDataTable } from "../components/datatable/DataTable.component";
import PageTitle from "../components/pageTitle/PageTitle.component";
import PipelineORM, { Pipeline } from "../models/pipeline";
import ApiService, { ListRecordsResponse } from "../services/api/api.service";

const PipelineDataPage = () => {
    const { pipelineId } = useParams();
    const [pipeline, setPipeline] = useState<Pipeline|undefined>(undefined);
    const [dataURL, setDataURL] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            setPipeline(await PipelineORM.findById(pipelineId as string));
            setDataURL(`/pipelines/${pipelineId as string}/data`);
            setLoading(false);
        };

        loadData();
    }, [pipelineId]);

    if (loading) {
        return <Spinner/>;
    } else if (pipeline) {
        return (
            <>
                <PageTitle title="Pipeline Output" breadcrumbs={[
                    {
                        path: '/pipelines',
                        title: 'Pipelines',
                    }, {
                        title: pipeline.name as string,
                        path: `/pipelines/${pipelineId as string}`
                    }, {
                        title: 'Output Data',
                    }
                ]}></PageTitle>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {dataURL && (
                                    <TableExplorer tablePath={dataURL} defaultOrderBy="uuid"/>
                                )}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </>
        )
    } else {
        return <>Error</>
    }
}

export default PipelineDataPage;