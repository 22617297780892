import BusinessObjectSelector from "@components/businessObjects/BusinessObjectSelector.component";
import SaveButton from "@components/button/SaveButton.component";
import { CardHeader } from "@components/card/Card.component";
import SourceSelector from "@components/sources/SourceSelector.component";
import { BusinessObject } from "@models/businessObject";
import PipelineORM, { Pipeline, PipelineStepConfig } from "@models/pipeline";
import { SourceRecordType } from "@models/source";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { saveBusinessObject } from "@stores/data.store";
import { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useImmer } from 'use-immer';
import Select, { SingleValue } from 'react-select';

const NewBusinessObjectPage = () => {
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);

    const [useTemplate, setUseTemplate] = useState(true);

    const [sourceRecordType, setSourceRecordType] = useState<SourceRecordType|undefined>(undefined);

    const [businessObject, setBusinessObject] = useImmer<BusinessObject>({
        id: null,
        name: '',
        description: '',
        fields: [],
    });


    const handleUpdate = useCallback((key: 'name'|'description', value: string) => {
        setBusinessObject(draft => {
            draft[key] = value;
        })
    }, []);

    const save = useCallback(async () => {

        setSaving(true);
        try {
            const bizobjToSave = {
                ...businessObject
            }

            const result = await saveBusinessObject(bizobjToSave);

            navigate(`/business-objects/${result.id as string}/attributes`);            

        } catch (err) {
            console.error(err);
            toast('danger', 'Error!', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
        
    }, [businessObject]);

    


    return <>
        <div className="row mt-5">
            <div className="col-3"></div>
            <div className="col-6">
                <div className="card">
                    <div className="card-header bg-primary text-white">
                        <h3>Create a Business Object</h3>
                        <p className="font-13">
                            A business object represents something in your business, in the way <b>you</b> want to view it.
                        </p>
                    </div>
                    <div className="card-body">
                        <Form.Group className="mb-3">
                            <Form.Label>How do you want to create this object?</Form.Label>
                                <Form.Select onChange={(e) => setUseTemplate(e.target.value === 'TEMPLATE')}>
                                    <option value="TEMPLATE">Use a template</option>
                                    <option value="CUSTOM">Start from scratch</option>
                                </Form.Select>
                        </Form.Group>
                        
                        {!useTemplate && (
                            <fieldset>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name your object</Form.Label>
                                    <Form.Control isValid={!!businessObject.name} value={businessObject.name} onChange={(e) => handleUpdate('name', e.target.value)} />
                                    <Form.Text>E.g. "Customer", "Product", "Invoice"</Form.Text>

                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your object</Form.Label>
                                    <Form.Control as="textarea" value={businessObject.description} onChange={(e) => handleUpdate('description', e.target.value)}/>
                                </Form.Group>
                            </fieldset>
                        )}
                        
                    </div>
                       
                    <div className="card-footer">
                        <SaveButton
                            disabled={!businessObject.name}
                            loading={saving}
                            onClick={save}
                        />

                    </div>
                </div>
            </div>
            
        </div>
    </>


};

export default NewBusinessObjectPage;