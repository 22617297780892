import AsyncButton from "@components/button/AsyncButton.component";
import LoadingCard from "@components/card/LoadingCard.component";
import { SimpleDataTable, tableDataFromRows } from "@components/datatable/DataTable.component";
import FileORM, { FilePart, File } from "@models/file";
import { SourceRecordTypeORM } from "@models/source";
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { reloadFiles, useFiles, useSourceRecordTypes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';

const FileDetailsPage = () => {
    const { fileId, sourceId } = useParams();

    const [loading, setLoading] = useState(false);

    const [file, setFile] = useState<File|undefined>(undefined);



    const [error, setError] = useState('');

    const [processing, setProcessing] = useState(false);
    const sourceRecordTypes = useSourceRecordTypes(sourceId);
    const [fileParts, setFileParts] = useState<FilePart[]>([]);
    const [activeFilePart, setActiveFilePart] = useState<FilePart|undefined>(undefined);
    const [loadingIntoSnowflake, setLoadingIntoSnowflake] = useState(false);

 
    const loadData = useCallback(async () => {
        if (!fileId) {
            return;
        }
        console.log('in here');
        setLoading(true);

        try {
            const file = await FileORM.findById(fileId);

            setFile(file);

            if (!processing && ['UPLOADING', 'PENDING'].includes(file.processing_status)) {
                processFile();
            }
            const parts = await ApiService.getInstance().request(
                'GET', 
                `/files/${file.id}/parts`
            ) as ListRecordsResponse<FilePart>;

            setFileParts(parts.records);

            console.log('PARTS', parts.records);

            setActiveFilePart(parts.records[0]);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, [fileId, processing]);
    

    const sourceRecordTypeOptions = useMemo(() => {

        if (!sourceRecordTypes.data) {
            return [];
        }
        return sourceRecordTypes.data.map(srt => {
            return {
                label: srt.name,
                value: srt.id,
            }
        });
    }, [sourceRecordTypes.dataUpdatedAt]);
    const [selectedSourceRecordTypeId, setSelectedSourceRecordTypeId] = useState('');

    const [backgroundLoading, setBackgroundLoading] = useState(false);

    const selectedOption = useMemo(() => {
        return sourceRecordTypeOptions.find(o => o.value === selectedSourceRecordTypeId);
    }, [sourceRecordTypeOptions, selectedSourceRecordTypeId]);
    const processFile = useCallback(async () => {
        setProcessing(true);


        try {

            const results = await ApiService.getInstance().request(
                'POST',
                `/files/${fileId}/process`
            ) as JobEnqueueResponse;


            const partResults = await BackgroundService.getInstance().waitForJob(results.job_id);

            if(partResults === null) {
                setError(`There was an error processing your file`);
                return;
            }


            loadData();


        } catch (err) {
            setError(getErrorMessage(err));

        } finally {
            setProcessing(false);
        }
    }, [fileId]);

    useEffect(() => {
        console.log('Running', fileId);
        if (fileId) {
            loadData();
        }
        

    }, [fileId]);

    const selectedSourceRecordType = useCallback(async (e: any) => {
        if (!e) {
            setSelectedSourceRecordTypeId('');
        } else if (e.__isNew__) {
            setBackgroundLoading(true);
            try {
                const response = await SourceRecordTypeORM.save({
                    id: null,
                    source_id: sourceId as string,
                    name: e.value,
                    resource_name: e.value,
                    description: '',
                    column_preferences: [],
                });

                sourceRecordTypes.refetch();
                setSelectedSourceRecordTypeId(response.id as string);
            } catch (err) {
                toast('danger', 'Oops!', getErrorMessage(err));
            } finally {
                setBackgroundLoading(false);
            }
        } else {
            setSelectedSourceRecordTypeId(e.value);
        }
       
    }, []);


    const loadDataIntoSnowflake = useCallback(async () => {
        if (!fileId || !activeFilePart) {
            return;
        }

        
        setLoadingIntoSnowflake(true);
        try {
            const results = await ApiService.getInstance().request(
                'POST',
                `/files/${fileId}/parts/${activeFilePart.id}/load`,
                {
                    'headers': [],
                    'source_record_type_id': selectedSourceRecordTypeId,
                }
            ) as JobEnqueueResponse;
            const jobResult = await BackgroundService.getInstance().waitForJob(results.job_id);

            sourceRecordTypes.refetch();
            toast('success', 'Success', 'Data loaded into Snowflake');
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoadingIntoSnowflake(false);
        }
    }, [sourceId, activeFilePart, selectedSourceRecordTypeId, fileId]);

    if (!file) {
        return <Spinner/>;
    }

    if (processing) {
        return <>
            <LoadingCard action="Taking a look at your data"  />
        </>
    } else if (activeFilePart) {
        return <div className="content-inner">
            <div className="section">
            <div className="back-breadcrumb">
                        <Link to={`/sources/${sourceId}`}>&larr; Back to files</Link>
                    </div>
                <h2>File Details: {file.name}</h2>
                
            
                    {fileParts.length > 1 && (
                        <Form.Group className="mb-3">
                            <Form.Label>Select Tab</Form.Label>
                            <Form.Select value={activeFilePart.part_identifier} onChange={(e) => setActiveFilePart(fileParts.find(p => p.part_identifier === e.target.value))}>
                                {fileParts.map(p => {
                                    return <option value={p.part_identifier}>{p.part_identifier}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    )}
                    <h4>Sample Data</h4>
                    <div className="border mb-2">
                        
                        <SimpleDataTable data={tableDataFromRows([activeFilePart.headers].concat(activeFilePart.sample_rows))}/>
                    </div>
                    < hr/ >
                    <h4>Load into Snowflake</h4>
                    {file.processing_status === 'LOADED' && (
                        <p className="text-muted font-13">Note: This file has already been loaded into Snowflake.</p>
                    )}
                    {file.processing_status === 'PRE_PROCESSED' && (
                        <p className="text-muted font-13"><strong>Nice!</strong> We're ready to bring in your data.</p>

                    )}
                    <Form.Group className="mb-3">
                        <Form.Label>What's in this tab?</Form.Label>
                        <CreatableSelect
                            isClearable
                            isDisabled={loadingIntoSnowflake}
                            placeholder="Select one or create new"
                            options={sourceRecordTypeOptions}
                            value={selectedOption}
                            onChange={selectedSourceRecordType}
                        />
                        <Form.Text>
                            Try "people", "customers", or "products".
                        </Form.Text>
                        {backgroundLoading && <Spinner size="sm"/>}
                    </Form.Group>

                    <div>
                    
                    

                        <AsyncButton
                            text="Load data"
                            variant="pliable"
                            loading={loadingIntoSnowflake}
                            onClick={loadDataIntoSnowflake}
                            disabled={!selectedSourceRecordTypeId}
                        />
                        {loadingIntoSnowflake && <span className="ms-3">Loading your data into Snowflake. This may take a few minutes if the file is large.</span>}
                        
                    </div>
            </div>
        </div>
    }

    return <></>
}

export default FileDetailsPage;