import { ColumnRef } from "@models/shared";
import { ColumnRefForm } from "./ColumnRef.component";

export interface ColumnRefListProps {
    columnRefs: ColumnRef[];
    columnOptions: string[];
    disabled?: boolean;
    onAddColumn: () => void;
    onRemoveColumn: (idx: number) => void;
    onChangeColumn: (idx: number, ref: ColumnRef) => void;
}

const ColumnRefList = (props: ColumnRefListProps) => {
    return (
        <>
            <button className="btn btn-sm btn-outline-secondary mb-1" disabled={props.disabled} onClick={() => props.onAddColumn()}>
                <i className="mdi mdi-plus"></i> Column
            </button>
            <table className="table table-bordered table-sm table-centered mb-0">
                <tbody>
                    {props.columnRefs.map((cr: ColumnRef, idx: number) => {
                        return (
                            <tr>
                                <td>
                                    <ColumnRefForm
                                        columnRef={cr}
                                        disabled={props.disabled}
                                        columnOptions={props.columnOptions}
                                        onChange={(ref: ColumnRef) => props.onChangeColumn(idx, ref)}
                                    />
                                </td>
                                <td className="table-action text-end">
                                    <a role="button" className="action-icon" onClick={() => props.onRemoveColumn(idx)}>
                                        <i className="mdi mdi-delete"></i>
                                    </a>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
        
    )
}

export default ColumnRefList;