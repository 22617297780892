import { SimpleDataTable } from "@components/datatable/DataTable.component";
import { DedupeConfig } from "@models/standardizationPipeline";
import ApiService, { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { decimalFormatter, integerFormatter } from "@services/formatting.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Select from 'react-select';
import {
    useQuery,
} from 'react-query';
import BackgroundService from "@services/bg.service";
import TrackingService, {Events} from "@services/tracking.service";


interface Props {
    sourceColumns: string[];
    sourceId: string;
    sourceRecordTypeId: string;
    config: DedupeConfig;
    onChange: (config: DedupeConfig) => void;
    onApply: () => void;
}



const DedupeConfigForm = (props: Props) => {
    const columnOptions = useMemo(() => {
        return props.sourceColumns.map(c => {
            return {
                label: c,
                value: c,
            }
        })
    }, [props.sourceColumns]);



    const updateSharedFields = useCallback((fields: string[]) => {
        console.log('Updating shared fields...', fields);
        props.onChange({
            ...props.config,
            shared_fields: fields,
        });
    }, [props.config, props.onChange]);

    const updateResolutionColumn = useCallback((column: string) => {
        props.onChange({
            ...props.config,
            resolution_column: column
        });
    }, [props.config, props.onChange]);

    const updateResolutionDirection = useCallback((dir: string) => {
        props.onChange({
            ...props.config,
            resolution_direction: dir
        });
    }, [props.config, props.onChange]);

    const updateFuzzyMatchThreshold = useCallback((threshold: number) => {
        console.log('Updating fuzzy match to', threshold);
        props.onChange({
            ...props.config,
            fuzzy_match_threshold: threshold,
        })
    }, [props.config, props.onChange]);


    
   
    return <>
            <Form.Group className="mb-3">
                <Form.Label>Which columns should we use to detect duplicates?</Form.Label>
                <Select
                    options={columnOptions}
                    isMulti
                    value={columnOptions.filter(co => props.config.shared_fields.includes(co.value))}
                    onChange={(e) => {
                        if (!!e) {
                            updateSharedFields(e.map(c => c.value));
                        } else {
                            updateSharedFields([]);
                        }
                    }}
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        input: (styles) => {
                            return {
                                ...styles,
                                padding: '0px',
                                margin: '0px',
                            }
                        },
                        option: (styles) => {
                            return {
                                ...styles,
                                margin: '0px',
                                padding: '0px 8px',
                            }
                        },
                        multiValue: (styles) => {
                            return {
                                ...styles,
                                lineHeight: '18px',
                            }
                        }
                    }}
                />
                <Form.Text>We'll look for records with the same values in all of these columns.</Form.Text>
            </Form.Group>
            {props.config.shared_fields.length > 0 && (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Fuzzy Match Threshold</Form.Label>
                        <p className="text-muted font-13">Use this to detect duplicates using close matches instead of exact matches.</p>
                    <div className="form-control">
                    <input 
                            className="form-range" 
                            type="range" 
                            max="1.0" 
                            min="0.01" 
                            step="0.01"
                            value={props.config.fuzzy_match_threshold}
                            onChange={(e) => updateFuzzyMatchThreshold(parseFloat(e.target.value))}
                        />
                        <Form.Text className="text-muted">Columns {(props.config.fuzzy_match_threshold == 1.0) ? "need to match" : "only match"} {Math.round(props.config.fuzzy_match_threshold * 100)}%</Form.Text>
                    
                    </div>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>What should we do if we find duplicates?</Form.Label>
                        <div className="form-control">
                            Pick the record with the <select value={props.config.resolution_direction} onChange={(e) => updateResolutionDirection(e.target.value)}>
                                <option value="">select one</option>
                                <option value="DESC">highest</option>
                                <option value="ASC">lowest</option>
                            </select> value in column 
                            <Select
                            options={columnOptions}
                            value={columnOptions.filter(co => co.value === props.config.resolution_column)}
                            onChange={(e) => {
                                if (!!e) {
                                    updateResolutionColumn(e.value);
                                } else {
                                    updateResolutionColumn('');
                                }
                            }}
                        />
                        
                        </div>
            </Form.Group>
                </>
            )}
            

            <button onClick={() => {
                    props.onApply();
                    TrackingService.track_event(Events.SRT_APPLY_DEDUPE_CLK, {
                        'source_id': props.sourceId,
                        'source_record_type_id': props.sourceRecordTypeId,
                        'fuzzy_match_threshold' : props.config.fuzzy_match_threshold,
                        'num_shared_fields': props.config.shared_fields.length,
                        'resolution_direction': props.config.resolution_direction
                    })

                }} className="btn btn-sm btn-pliable">
                    Apply
                </button>
    </>
}

export default DedupeConfigForm;