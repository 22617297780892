import PipelineSelector from "@components/pipelines/PipelineSelector.component";
import SourceSelector from "@components/sources/SourceSelector.component";
import PipelineORM, { Pipeline } from "@models/pipeline";
import SourceORM, { Source, SourceRecordType, SourceRecordTypeORM } from "@models/source";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { StepComponentProps } from "../shared";

interface SourceStepConfig {
    source_id?: string;
    source_record_type_id?: string;
    source_pipeline_ids?: string[];
}



const SourceStep = (props: StepComponentProps) => {
    const [sourceType, setSourceType] = useState('');
    const { stepConfig, onChange, stepIndex } = props;
    const parsedConfig = stepConfig.config as SourceStepConfig;

    useEffect(() => {
        if (parsedConfig.source_record_type_id) {
            setSourceType('SOURCES');
        } else if (parsedConfig.source_pipeline_ids?.length) {
            setSourceType('PIPELINES');
        }

    }, [stepConfig]);


    const [pipelines, setPipelines] = useState<Pipeline[]>([]);
    const [sources, setSources] = useState<Source[]>([]);
    const [sourceRecordTypes, setSourceRecordTypes] = useState<SourceRecordType[]>([]);

    useEffect(() => {
        const loadData = async () => {
            if (sourceType === 'PIPELINES') {
                const resp = await PipelineORM.find();
                setPipelines(resp.records);
            } else if (sourceType === 'SOURCES') {
                const resp = await SourceORM.find();
                setSources(resp.records);
            }
        }
        loadData();
    }, [sourceType]);

    useEffect(() => {
        const loadData = async () => {
            const resp = await SourceRecordTypeORM.find({
                'source_id': {
                    '$eq': parsedConfig.source_id,
                }
            })
            setSourceRecordTypes(resp.records);
        }
        loadData();
        
    }, [stepConfig]);


    return <div>
        <p className="text-muted">
            Configure where your pipeline pulls its data from.
        </p>
        
        <Form.Group className="mb-3">
            <Form.Label>Pull data from</Form.Label>
            <Form.Select
                className="input-sm"
                name="selectedSourceType"
                value={sourceType}
                disabled={!props.allowInteractivity}
                onChange={(e) => setSourceType((e.target as HTMLSelectElement).value)}
            >
                <option value="">Select One</option>
                <option value="SOURCES">A data source</option>
                <option value="PIPELINES">Other pipelines</option>
            </Form.Select>
        </Form.Group>
        {sourceType === 'SOURCES' && (
            <>
                <Form.Label>Select Source</Form.Label>
                <SourceSelector
                    selectedId={parsedConfig.source_record_type_id || ''}
                    disabled={!props.allowInteractivity}
                    onSelect={(srt) => {
                        onChange('source_record_type_id', srt?.id as string);
                    }}
                />
               
            </>
            
        )}
        {sourceType === 'PIPELINES' && (
            <>
                <Form.Label>Select Pipelines</Form.Label>
                <PipelineSelector
                    selectedIds={parsedConfig.source_pipeline_ids || []}
                    disabled={!props.allowInteractivity}
                    onChange={(pipelines) => {
                        onChange('source_pipeline_ids', pipelines.map(p => p.id as string))
                    }}
                    ignorePipelineIds={[props.pipelineId]}
                />
            </>
        )}
        
        
    </div>
}

export default SourceStep;