import { Carousel, Spinner } from 'react-bootstrap';
import { useCallback, useState, useMemo } from 'react';
import debounce from 'lodash.debounce';
import AsyncButton from '@components/button/AsyncButton.component';
import { SourceType } from '@models/source';
import { useNavigate } from 'react-router-dom';
import ApiService, { SingleRecordResponse } from '@services/api/api.service';
import { useSourceTypes } from '@stores/data.store';


interface AuthUrlResponse {
    authorization_url: string;
}


const emptyConnector = {
    title: '',
    id: '',
    provider: '',
    description: '',
    icon_path: '',
    oauth: false
}

interface Props {
    onboarding?: boolean;
}


const SelectDataSource = (props: Props) => {
    const [selectedSourceType, setSelectedSourceType] = useState<SourceType>(emptyConnector);

    const { isLoading: loading, data: soureTypeOptions } = useSourceTypes();
    const [error, setError] = useState('');

    const { isLoading: loadingSourceTypes, data: sourceTypes } = useSourceTypes();



    const navigate = useNavigate();
    const [index, setIndex] = useState(0);

    const [processingSource, setProcessingSource] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const [sourceName, setSourceName] = useState('');

    const selectSourceType = useCallback((sourceType: SourceType) => {
        setSelectedSourceType(sourceType);
        if (!!sourceType.oauth) {
            setTimeout(() => {
                setIndex(1);
            }, 500);
        } else if (props.onboarding) {
            navigate('/welcome/source/setup/' + sourceType.id);
        } else {
            navigate('/sources/setup/' + sourceType.id);
        }
        
    }, [selectedSourceType, index]);

    const searchResults = useMemo(() => {
        if (searchQuery) {
            return soureTypeOptions!.filter(ds => ds.title.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        if(loadingSourceTypes){
            return []
        }

        return soureTypeOptions!.slice(0, 3);
    }, [searchQuery, soureTypeOptions]);

    console.log('Search results:', searchResults);

    const handleSearchQuery = (val: string) => {
        setSearchQuery(val);
    }


    const debouncedSearchQueryHandler = debounce(handleSearchQuery, 300);

    const reset = useCallback(() => {
        setSearchQuery('');
        setSelectedSourceType(emptyConnector);
        setIndex(0);
    }, []);

    const goToAuthorizeURL = useCallback(async () => {
        setProcessingSource(true);
        try {
            const response = await ApiService.getInstance().request('GET', `/sources/connectors/${selectedSourceType.id}/authorization-url`) as SingleRecordResponse<AuthUrlResponse>;

           
            window.location.href = response.record.authorization_url;
        } catch (err) {
            console.error(err);
        } finally {
            setProcessingSource(false);
        }
    }, [selectedSourceType]);

   

    if (loading) {
        return <Spinner/>;
    } else if (error) {
        return <p className="alert alert-danger">{error}</p>
    }


    return <div>
        
            

        <Carousel activeIndex={index} controls={false} indicators={false}>
            <Carousel.Item>
                {/* <h4>Let's start bringing in your data!</h4>
                <input type="text" style={{width: '60%'}} onChange={(e) => debouncedSearchQueryHandler(e.target.value)} className="form-control" placeholder="Search for data sources..."/>
                <hr />
                {searchQuery && <p>Here are the data sources that match your query.</p>}
                {!searchQuery && <p>Here are the most popular data sources for your use case.</p>} */}
                <div className="row">
                    {searchResults.map(sr => {
                        return (
                            <div className="col-4">
                                <button className={"onboarding-button " + (selectedSourceType.id === sr.id ? 'active' : '')} onClick={() => selectSourceType(sr)}>
                                    <div className="img-container">
                                    <img  src={sr.icon_path} className="rounded-circle avatar-md img-thumbnail"/>

                                    </div>
                                </button>
                            </div>
                        )
                    })}
                    
                </div>
            </Carousel.Item>
            <Carousel.Item className="text-start">
                <div className="mb-3">
                    <img src={selectedSourceType.icon_path} className="rounded-circle avatar-md img-thumbnail"/>

                </div>

                {!!selectedSourceType.oauth && (
                    <AsyncButton
                        text={'Connect to ' + selectedSourceType.title}
                        variant="pliable"
                        loading={processingSource}
                        onClick={() => goToAuthorizeURL()}
                    />
                )}

                <div className="mt-3">
                    <button className="btn btn-link" onClick={() => reset()}>Choose a different source</button>
                        
                </div>

                
            </Carousel.Item>
        </Carousel>
        
    </div>;
}

export default SelectDataSource;