import BusinessObjectSelector from "@components/businessObjects/BusinessObjectSelector.component";
import SourceORM, { Source, SourceRecordType, SourceRecordTypeORM } from "@models/source";
import { Form, Spinner } from "react-bootstrap";
import { useState } from "react";
import AsyncButton from "@components/button/AsyncButton.component";
import { useSourceRecordTypes } from "@stores/data.store";


interface Props {
    sourceRecordType: SourceRecordType;
}

const SourceRecordTypeSettings = ({ sourceRecordType }: Props) => {
    const [saving, setSaving] = useState(false);
    const [recordTypeName, setRecordTypeName] = useState(sourceRecordType.name);

    const { refetch } = useSourceRecordTypes(sourceRecordType.source_id);

    const saveSettings = () => {
        setSaving(true);
        const updates = {...sourceRecordType};
        updates.name = recordTypeName;
        SourceRecordTypeORM.save(updates);
        refetch();
        setSaving(false);
    }

    return <>
        <Form.Group className="mb-3">
            <Form.Label>Record Type Name</Form.Label>
            <Form.Control type="text" value={recordTypeName} onChange={(e) => setRecordTypeName(e.target.value.trim())} required={true} minLength={2} />
            <Form.Text>
                Try "people", "customers", or "products".
            </Form.Text>
        </Form.Group>

        <AsyncButton
            variant="primary"
            text="Save"
            loading={saving}
            disabled={sourceRecordType.name == recordTypeName || recordTypeName.length < 2}
            onClick={() => saveSettings()}
        />
    </>
}

export default SourceRecordTypeSettings;