import ORM, { BaseModel } from "./orm";
import ApiService, {  ListRecordsResponse, SingleRecordResponse } from "../services/api/api.service";
import { PipelineOrchestration } from "./pipelineOrchestration";

export interface ModelFilter {
    column_def: string;
    comparator: string;
    value: string;
    name?: string;
}

export interface MergeConfig {
    match_on_fields: string[];
    fuzzy_match_threshold?: number;
    merge_resolution: string;
    merge_type: string;
}

export interface PipelineStepConfig {
    id?: string;
    name: string;
    step_type: 'SOURCE'|'DEDUPE'|'FILTER'|'MAP'|'OUTPUT'
    config: {
        [key: string]: any
    }
}
  

export interface Pipeline extends BaseModel {
    name?: string;
    business_object_id?: string;
    description?: string;
    source_file?: string;
    source_cluster?: string;
    map_fields?: Record<string, string>;
    source_datasets?: string[];
    filters?: ModelFilter[];
    merge_config?: MergeConfig;
    last_execution?: Date;
    new_records?: number;
    rules?: ModelFilter[];
    auto_approve?: boolean;
    pipeline_type?: string;

    managed?: boolean;

    steps?: PipelineStepConfig[];


    source_id?: string;
    source_record_type_id?: string;
    pipeline_id?: string;
    source_pipeline_ids?: string[];
}


export class _PipelineORM extends ORM<Pipeline>{

    public async getPipelineOrchestration(pipelineOrchestrationId: string) : Promise<PipelineOrchestration> {
        const result = await ApiService.getInstance().request('GET', `/${this.endpoint}/orchestrated/execution/${pipelineOrchestrationId}`) as SingleRecordResponse<PipelineOrchestration>;
        return result.record;
    }
}




const PipelineORM = new _PipelineORM('pipelines', ['last_execution']);
export default PipelineORM;
  