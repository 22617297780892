import { saveSource, useSourceTypes, useSources } from "@stores/data.store";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SourceORM, { Source, SourceType } from "@models/source";
import { ExcelDetails } from "@components/sources/connectors/ExcelConnector.component";
import SnowflakeConnectorConfig from "@components/sources/connectors/SnowflakeConnectorConfig.component";
import FivetranConnectorConfig from "@components/sources/connectors/FivetranConnectorConfig.component";
import { useSource } from "@stores/sources.store";

const DataSourceConfigPage = () => {
    const { sourceId } = useParams();
    const sourceTypes = useSourceTypes();

    const { isLoading: loadingSource, data: source, dataUpdatedAt: sourceDataUpdatedAt, refetch: refetchSource} = useSource(sourceId as string);
    const [sourceType, setSourceType] = useState<SourceType|undefined>(undefined);

    useEffect(() => {
        if (sourceId && source && sourceTypes.data) {
            setSourceType(sourceTypes.data.find(st => st.id === source?.type));
        }
    }, [sourceId, sourceDataUpdatedAt, sourceTypes.dataUpdatedAt]);


    const onConfigChange = useCallback(async (updatedConfig: any) => {
        if (!source) {
            return;
        }
        const newConfig = {
            ...source.config,
            ...updatedConfig
        };

        const sourceToSave = {
            ...source,
            config: newConfig,
        };

        const result = await saveSource(sourceToSave);
        refetchSource();
    }, [source]);

    if (!source || !sourceType) {
        return <></>
    }
    return (
    <div className="content-inner">

        <div className="section">
            
            
                {sourceType.provider === 'pliable' && sourceType.id === 'flat_file' && (
                    <>
                        <h2>Configuration</h2>
                        <ExcelDetails source={source}/>
                    </>
                )}
                {sourceType.provider === 'pliable' && sourceType.id ===  'snowflake' && (
                    <>
                        <h2>Configuration</h2>
                        <SnowflakeConnectorConfig onChangeConfig={onConfigChange} source={source}/>
                    </>
                )}
                {sourceType.provider === 'fivetran' && (
                    <>
                        <FivetranConnectorConfig onChangeConfig={onConfigChange} source={source} />
                    </>
                )}
    </div>

    </div>
    );
}

export default DataSourceConfigPage;