import { useEffect, useCallback, useMemo } from "react";
import { useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import DataTable, { Column, SimpleDataTable, FetchDataParams } from "./DataTable.component";
import { apiurl } from '@services/url.service';
// import produce from "immer"
import ApiService, { ListRecordsResponse } from "../../services/api/api.service";
import { getErrorMessage } from "../../services/errors.service";
import { ColumnStatsModal } from "./ColumnStatsModal.component";
import { summarizeNumber } from "@services/formatting.service";

export interface TableLoadResponse {
    records: {
        [key: string]: any
    }[];
    total_pages: number;
    total_records: number;
}

interface Props {
    tablePath: string;
    columnStatsUrl?: string;
    defaultOrderBy?: string;
    maxHeight?: string;
    columnOrder?: string[];
    onColumnClick?: {
        [key: string]: (value: any) => void;
    }
    onCellDoubleClick?: (col: Column, row: Record<string, string>, value: any) => void;
    onDataLoaded?: (response: TableLoadResponse) => void;
}

export const TableExplorer = (props: Props) => {   
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [tablePageData, setTablePageData] = useState<ListRecordsResponse<any>>({records:[], total_pages: 0, total_records: 0});
    const [error, setError] = useState('');
    const [activeColumn, setActiveColumn] = useState<string|undefined>(undefined);
    const [fetchParams, setFetchParams] = useState<FetchDataParams>();


    const fetchData = useCallback(async () => {
        try {
            setError('');

            const sortParam = fetchParams!.sortBy.map((sort: any) => {
                return `${sort['desc'] ? "-" : ""}${sort['id']}`;
            }).join(",")

            const tableParams = {
                query: searchQuery,
                page: fetchParams!.pageIndex+1,
                limit: fetchParams!.pageSize,
                sort: sortParam
            }
            const url = apiurl(props.tablePath, tableParams);

            console.log('URL in table explorer is', url, props.tablePath, tableParams);
        
            setLoading(true);
            const listRecordsResp = await ApiService.getInstance().request('GET', url) as TableLoadResponse;
            console.log(listRecordsResp);
            setTablePageData(listRecordsResp);



            if (props.onDataLoaded) {
                props.onDataLoaded(listRecordsResp);
            }
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
      }, [props.tablePath, fetchParams, searchQuery]);
 

    useEffect(() => {
        if(!!fetchParams){
            fetchData();
        }
    }, [props.tablePath, fetchParams]);

    const onFilterSortChange = (params: FetchDataParams) => {
        setFetchParams(params);
    }

    const doSearch = () => {
        if(!!fetchParams){
            fetchData();
        }
    };

    const onSearchKeyUp = (event:any) => {
        if (event.key === 'Enter') {
            doSearch();
        }
    }

    let containerStyle: React.CSSProperties = {
        height: '100%'
    };
    if (props.maxHeight) {
        containerStyle.maxHeight = props.maxHeight;
    }

    const showColumnStats = (columnKey: string) => {
        // Kind of a hack to get the modal to reset until I can figure out a more reduxy pattern
        setActiveColumn(undefined);
        setTimeout(() => {
            setActiveColumn(columnKey);
        });
        
    }


    const doubleClickedCell = (col: Column, row: Record<string, string>, value: any) => {
        if (props.onCellDoubleClick) {
            props.onCellDoubleClick(col, row, value);
        }
    }



    return (
        <>
            {props.columnStatsUrl && (
                <ColumnStatsModal
                    url={props.columnStatsUrl}
                    columnName={activeColumn}
                />
            )}
            {/* <div className="row">
                <div className="col-8">
                    <div className="app-search mb-3">
                    <div className="input-group">
                        <input type="text" className="form-control" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} onKeyUp={onSearchKeyUp} />
                        <span className="mdi mdi-magnify search-icon"/>
                        <button className="input-group-text btn-primary" onClick={doSearch} disabled={loading}>Search</button>
                    </div>
                </div>
                </div>
                <div className="col-4">
                    {fetchParams && tablePageData && <div className="text-end" style={{paddingTop: 15}}>
                        {loading && <span>Loading</span>}
                        {!loading && tablePageData.total_records == 0 && <span>No Results</span>}
                        {!loading && tablePageData.total_records != 0 && <span>Showing {summarizeNumber(Math.min(fetchParams.pageSize, tablePageData.total_records))} of {summarizeNumber(tablePageData.total_records)}</span>}
                    </div>}
                </div>
            </div> */}
            {!loading && error && (
                <div className="card">
                    <div className="card-body">
                        <div className="alert alert-danger">{error}</div>
                    </div>
                </div>
            )}
            {!error && (
                <div style={containerStyle}>
                    <SimpleDataTable 
                        loading={loading}
                        onColumnClick={props.onColumnClick}
                        columns={props.columnOrder}
                        data={tablePageData!.records}
                        totalPageCount={tablePageData!.total_pages}
                        onFilterSortChange={(fdp: FetchDataParams) => setFetchParams(fdp)}
                        onShowColumnStats={showColumnStats}
                        onCellDoubleClick={props.onCellDoubleClick ? doubleClickedCell : undefined}
                    />
                </div>
            )}
        </>
    )
    return <Spinner/>;
}
