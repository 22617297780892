import { ReactComponent as DataSourcesSVG } from '../../assets/images/pliable/data_sources_icon.svg';
import { ReactComponent as BusinessObjectsSVG } from '../../assets/images/pliable/business_objects_icon.svg';

export interface IconProps {
    height: string;
}

export const DataSourcesIcon = (props: IconProps) => {
    return (
        <div style={{height: props.height, display: 'inline-block', verticalAlign: 'text-bottom'}}>
            <DataSourcesSVG style={{maxHeight: props.height, maxWidth: props.height}} fill='currentColor' />
        </div>
    );
};

export const BusinessObjectsIcon = (props: IconProps) => {
    return (
        <div style={{height: props.height, display: 'inline-block', verticalAlign: 'text-bottom'}}>
            <BusinessObjectsSVG style={{maxHeight: props.height, maxWidth: props.height}} fill='currentColor' />
        </div>
    );
};