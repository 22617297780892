import SourceORM, { Source } from "@models/source"
import ApiService, { JobEnqueueResponse, SingleRecordResponse } from "@services/api/api.service";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast from "@services/toast.service";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import BackgroundService from "@services/bg.service";
import AsyncButton from '@components/button/AsyncButton.component';
import RecordTypesSelector from "../RecordTypesSelector.component";
import LoadingCard from "@components/card/LoadingCard.component";
import DataLoadList from "@components/dataload/DataLoadList.component";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSourceRecordTypes } from "@stores/data.store";
import { useSource } from "@stores/sources.store";
import { useSourceCatalog } from "@stores/recordTypes.store";

interface Params {
    source: Source;
    onChangeConfig?: (config: any) => void;
    showCtasOnDone?: boolean;
}

const MS_PER_MINUTE = 60000;


const FivetranConnectorConfig = (params: Params) => {
    const { tab = 'tables' } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [loadingConnectorCard, setLoadingConnectorCard] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [lastActivity, setLastActivity] = useState<number|null>(null);
    const [currentState, setCurrentState] = useState<'SETUP_CONNECTOR'|'SELECT_TABLES'>('SETUP_CONNECTOR');
  
    const {refetch: refetchSource} = useSource(params.source.id as string);
    const {refetch: refetchSourceRecordTypes} = useSourceRecordTypes(params.source.id as string);
    const { refetch: forceSchemaRefetch } = useSourceCatalog(params.source.id as string);

    const forceSchemaReload = () => {
        SourceORM.getConnectorCatalog(params.source.id as string, true);
        forceSchemaRefetch();
    }
    

    // page load
    useEffect(() => setLastActivity(new Date().getTime()), []);

    useEffect(() => {
        if(tab == "fivetran_return") {
            const returning_connector_id = searchParams.get('id');
            const forceFetch = async () => {
                // if we are returning from fivetran - we wanna force pliable to pull latest connector data
                const postResult = await ApiService.getInstance().request('POST', `/sources/${params.source.id}/pbf-check-connector`);
                refetchSource();


                // todo - init defaults for connectors that we wanna pick all the tables for

                // if (params.source.type == "fivetran_email") {
                //     // fivetran sets this when it returns from the connector page save
                //     const returning_connector_id = searchParams.get('id');
                //     if(returning_connector_id) {
                //         // since we are returning from fivetran config, and its a type that we know that tables/columns we want (rather than letting the user decide)
                //         // we will init the connector with those tables
                //         const defaults: any[] = [];
    
                //         defaults.push({
                //             "name": "data",
                //             "composite_key": ['_file', '_line', '_modified']
                //         })
    
                //         const initDefaults = async (choosenElements: any) => {
                //             const putResult = await ApiService.getInstance().request('PUT', `/sources/${params.source.id}/record-types`, choosenElements);
                //             const initResult = await ApiService.getInstance().request('POST', `/sources/${params.source.id}/init`, {}) as SingleRecordResponse<JobEnqueueResponse>;
                //             const jobResults = await BackgroundService.getInstance().waitForJob(initResult.record.job_id);
                //             return jobResults;
                //           }
    
                //           initDefaults(defaults);
                //     }
                // }else{
                //     // let them choose tables
                //     setCurrentState('SELECT_TABLES');
                // }

                navigate(`/sources/${params.source.id}/config/tables`)
                setLastActivity(new Date().getTime());
            }
            forceFetch();
        }
    },[tab]);

    useEffect(() => {  
        if(tab == "fivetran_return") {
            // do nothing here
            return;
        }


        if (!params.source.config || !params.source.config.fivetran_connector_id ||  params.source.fivetran?.connector.status.setup_state != "connected") {
            // if we dont have a connector that is connected
            setCurrentState('SETUP_CONNECTOR');
        } else {
            setCurrentState('SELECT_TABLES');
        }
    }, [params.source]);

    
    useEffect(() => {
        // check for updates
        if(params.source.fivetran && params.source.fivetran?.connector.status.setup_state == "connected") {
            setSyncing(params.source.fivetran!.connector.status.sync_state === 'syncing');

            if (lastActivity && lastActivity > Date.now() - 60 * MS_PER_MINUTE) {
                // check for updates for 60 minutes from last actvity
                const interval = setInterval(async () => {
                    const postResult = await ApiService.getInstance().request('POST', `/sources/${params.source.id}/pbf-check-connector`);
                    refetchSource();
                }, 1000 * 30);
        
                //Clearing the interval
                return () => clearInterval(interval);
            }
        }
        
    }, [params.source]);


    useEffect(() => {
        if(!syncing) {
            // refetch record types when we complete a sync
            refetchSourceRecordTypes();
        }
    },[syncing])


    const loadConnectorConfigCard = useCallback(async () => {
        try {
            setLoadingConnectorCard(true);
            const resp = (await SourceORM.getConnectorCardUrl(params.source.id as string)).record;
            window.location.href = resp.connect_card_url as string;
        }catch(ex) {
            toast('danger', 'Error Loading Connector Card', `${ex}`);
            setLoadingConnectorCard(false);
        }
    }, []);


    const triggerSync = async () => {
        await ApiService.getInstance().request('POST', `/sources/${params.source.id}/pbf-trigger-sync`)
        toast('success', 'Success!', 'Sync Queued');
        setLastActivity(new Date().getTime());
    }

    const toggleSyncEnabled = async () => {
        toast('success', 'Success!', (!params.source.fivetran?.connector.paused) ? "Disabling Sync" : "Enabling Sync" );
        await ApiService.getInstance().request('POST', `/sources/${params.source.id}/pbf-enable-sync`, {paused: !params.source.fivetran?.connector.paused})
        refetchSource();
        setLastActivity(new Date().getTime());
    }

    const renderConfigBar = () => {
        let bar = null;
        if(currentState !== 'SETUP_CONNECTOR') {
            bar = <div className="d-flex justify-content-end align-items-center">
                    

                    <AsyncButton
                        icon="mdi mdi-table-edit"
                        variant="outline"
                        text="Settings"
                        loading={loadingConnectorCard}
                        onClick={loadConnectorConfigCard}
                    />

                    <AsyncButton
                        icon="mdi mdi-sync"
                        variant="outline"
                        text={`${(syncing) ? 'Syncing': 'Sync Now'}`}
                        loading={syncing}
                        onClick={triggerSync}
                    />
                    <Form.Check style={{display: 'inline-block'}} inline checked={!params.source.fivetran?.connector.paused} onChange={toggleSyncEnabled} type="switch" label={params.source.fivetran?.connector.paused ? "Paused" : "Enabled"}/>

                    <AsyncButton
                        icon="mdi mdi-reload"
                        variant="outline"
                        text="Reload Schema"
                        loading={false}
                        onClick={() => forceSchemaReload()}
                    />
            </div>
        }

        return <>
            <div className="row">
                <div className="col-4"><h2>Configuration</h2></div>
                <div className="col-8 ">{bar}</div>
            </div>
        </>
    }

    
    if (loading) {
        return <LoadingCard />;
    } else if (currentState === 'SETUP_CONNECTOR') {
        return <>
            <div className="d-flex justify-content-center mt-5">
                <AsyncButton
                    icon="mdi mdi-table-edit"
                    variant="pliable"
                    text={`Connect ${params.source.name}`}
                    loading={loadingConnectorCard}
                    onClick={loadConnectorConfigCard}
                />
            </div>
        </>
    } else {
        if (syncing && params.source.fivetran!.connector.status.is_historical_sync){
            return <LoadingCard action="Syncing All Data" subTitle="If you have a lot of data this may take a while" />
        }


        return (<>
            {renderConfigBar()}
            <Tabs
                id="controlled-tab-example"
                activeKey={tab}
                // @ts-ignore
                onSelect={(k) => {
                        navigate(`/sources/${params.source.id}/config/${k}`)
                    }
                }
                className="mb-3"
                >
                <Tab eventKey="dataloads" title="Syncs">
                    <DataLoadList sourceId={params.source.id as string}/>
                </Tab>
                <Tab eventKey="tables" title="Tables">
                    <p>Choose the tables you want to access.</p>
                    
                    <p>Note that we need a <strong>primary key</strong> for each table in order to identify unique records. A primary key can be a single field (for example, <code>ID</code>), or a combination of fields.</p>

                    <div className="alert alert-warning">In some cases, the system may not be able to tell Pliable about the data structure before we sync it. In that case, you may need to review the documentation for that system in order to specify the primary key.</div>
                    <RecordTypesSelector sourceId={params.source.id as string} />
                </Tab>
            </Tabs>
        </>);
    }
}

export default FivetranConnectorConfig;