import React, { Component, ReactElement, ReactNode } from 'react';
import { Spinner, Col } from "react-bootstrap";

interface Props {
    action?: string;
    subTitle?: string;
}

export default class LoadingCard extends Component<Props> {
    render() {
        return (
            <div className="m-2">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '40vh'}}>
                        <div><Spinner/></div>
                        { this.props.action && <div><h2 className="mt-2">{this.props.action}...</h2></div>}
                        { this.props.subTitle && <div><p>{this.props.subTitle}...</p></div>}
                </div>
            </div>);
    }
}

