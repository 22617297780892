import { TableExplorer } from "@components/datatable/TableExplorer.component";
import BigMetricCard from "@components/metric/BigMetricCard.component";
import ApiService, { ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { decimalFormatter, integerFormatter } from "@services/formatting.service";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { QAComponentProps } from "../shared";
import { SimpleDataTable } from '@components/datatable/DataTable.component';
import AsyncButton from "@components/button/AsyncButton.component";

interface SampleRecord {
    GROUP_ID: string;
    UUID: string;
}

interface SampleGroup {
    groupId: string;
    samples: SampleRecord[];
}

interface Stats {
    total_records: number;
    total_groups: number;
}

const DedupeStepQA = (props: QAComponentProps) => {
    const [loadingStats, setLoadingStats] = useState(true);
    const [statsError, setStatsError] = useState('');
    const [loadingSamples, setLoadingSamples] = useState(true);
    const [samplesError, setSamplesError] = useState('');

    const [stats, setStats] = useState<Stats|undefined>(undefined);
    const [samples, setSamples] = useState<SampleGroup[]>([]);

    const loadSamples = async () => {
        setLoadingSamples(true);
        setSamplesError('');
        try {
            const result = await ApiService.getInstance().request('GET', `/pipelines/${props.pipelineId}/executions/${props.pipelineExecutionId}/step/${props.stepIndex}/dedupe_qa`) as ListRecordsResponse<SampleRecord>;

            const groupsById: {
                [key: string]: SampleRecord[];
            } = {};

            result.records.forEach(r => {
                if (!groupsById.hasOwnProperty(r.GROUP_ID)) {
                    groupsById[r.GROUP_ID] = [];
                }
                groupsById[r.GROUP_ID].push(r);
            });

            console.log('GROUPS BY ID', groupsById);


            setSamples(Object.keys(groupsById).map(k => {
                return {
                    groupId: k,
                    samples: groupsById[k],
                }
            }));
        } catch (err) {
            setSamplesError(getErrorMessage(err));
        } finally {
            setLoadingSamples(false);
        }
    }

    useEffect(() => {
        const loadStats = async () => {
            setLoadingStats(true);
            setStatsError('');
            try {
                const result = await ApiService.getInstance().request('GET', `/pipelines/${props.pipelineId}/executions/${props.pipelineExecutionId}/step/${props.stepIndex}/dedupe_stats`) as SingleRecordResponse<Stats>;
                setStats(result.record);
            } catch (err) {
                setStatsError(getErrorMessage(err));
            } finally {
                setLoadingStats(false);
            }
        }

        

        loadStats();
        loadSamples();
    }, [props.pipelineExecutionId, props.pipelineId, props.stepIndex]);

    return (
        <>
            <div className="row border-bottom">
                { statsError && <div className="alert alert-danger">{statsError}</div>}
                {!statsError && (
                    <>
                        <div className="col-4">
                            <BigMetricCard
                                icon="mdi mdi-pound-box"
                                title="Total Records"
                                loading={loadingStats}
                                backgroundColor="primary"
                                metric={!!stats ? integerFormatter(stats.total_records) : undefined}
                                    
                            />
                        </div>
                        <div className="col-4">
                            <BigMetricCard
                                icon="mdi mdi-group"
                                title="Total Deduplicated Records"
                                loading={loadingStats}
                                backgroundColor="success"
                                metric={!!stats ? integerFormatter(stats.total_groups) : undefined}
                                    
                            />
                        </div>
                        <div className="col-4">
                            <BigMetricCard
                                icon="mdi mdi-filter"
                                title="Reduction"
                                loading={loadingStats}
                                backgroundColor="info"
                                metric={!!stats ? (decimalFormatter(100-(stats.total_groups * 100 / stats.total_records)) + '%') : undefined}
                                    
                            />
                        </div>
                    </>
                    
                )}
                
            </div>
            <div className="row pt-3">
                <div className="col-12">
                    <div className="row ">
                        <div className="col-8">
                            <h4>Sample Duplicates</h4>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <AsyncButton
                                    loading={loadingSamples}
                                    text="Load Samples"
                                    icon="mdi mdi-refresh-circle"
                                    variant="info"
                                    onClick={(() => loadSamples())}
                                />
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="table-responsive">
                        {!loadingSamples && samplesError && <div className="alert alert-danger">{samplesError}</div>}
                        {!loadingSamples && !samplesError && <>
                            {samples.map(s => {
                                return (
                                    <div className="pt-3">
                                        <SimpleDataTable data={s.samples}/>

                                    </div>
                                )
                                
                            })}

                        </>}
                    </div>
                        
                </div>
            </div>
        </>
        
    )
}

export default DedupeStepQA;