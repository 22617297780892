import { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    loading: boolean;
    text: string;
    icon?: string;
    variant?: string;
    onClick: () => void;
    disabled?: boolean;
}

export default class AsyncButton extends Component<Props> {
    render() {
        const { 
            variant = 'primary', 
            icon = 'mdi mdi-arrow-right-bold-circle-outline' 
        } = this.props;

        let buttonIcon = (<i className={icon}></i>);
        if (this.props.loading) {
            buttonIcon = <Spinner size="sm"/>
        }

        let className = 'btn btn-' + variant;
        // if (['danger', 'warning', 'dark'].includes(this.props.variant)) {
        //     className = className + ' text-white';
        // }

        return (
            <button className={className} disabled={this.props.loading || this.props.disabled} onClick={(e) => this.props.onClick()}>
                                    
                {buttonIcon}
                <span className="ms-1">{this.props.text}</span>
            </button>
        )
    }
}