import { SimpleDataTable } from "@components/datatable/DataTable.component";
import { ColumnRefForm } from "@components/sql/ColumnRef.component";
import { ColumnRef } from "@models/shared";
import { Filter, FilterConfig } from "@models/standardizationPipeline";
import ApiService from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { decimalFormatter, integerFormatter } from "@services/formatting.service";
import { useCallback, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import TrackingService, {Events} from "@services/tracking.service";



interface Props {
    config: FilterConfig;
    sourceId: string;
    sourceRecordTypeId: string;
    columnOptions: string[];
    totalRecords: number;
    onChange: (config: FilterConfig) => void;
    onApply: () => void;
}

const comparatorOptions = [
    {
        label: 'is equal to',
        value: 'EQUALS',
    }, 
    {
        label: 'is not equal to',
        value: 'NOT_EQUALS',
    },
    {
        label: 'is greater than',
        value: 'GT',
    }, {
        label: 'is greater than or equal to',
        value: 'GTE'
    }, {
        label: 'is less than',
        value: 'LT'
    }, {
        label: 'is less than or equal to',
        value: 'LTE'
    }, {
        label: 'is blank',
        value: 'EMPTY'
    }, {
        label: 'is not blank',
        value: 'NOT_EMPTY'
    }
];

const comparatorsWithValues = [
    'EQUALS', 'NOT_EQUALS', 'GT', 'GTE', 'LT', 'LTE'
];

interface FilterResponse {
    filtered_out: any[];
    filtered_in: any[];
    count: number;
}

const FilterBadData = (props: Props) => {
    const [filterResponse, setFilterResponse] = useState<FilterResponse|undefined>(undefined);
    const changedColumnRef = useCallback((idx: number, ref: ColumnRef) => {
        const updatedFilters = [...props.config.filters];
        updatedFilters[idx] = {
            ...updatedFilters[idx],
            column_ref: ref,
        }

        props.onChange({
            ...props.config,
            filters: updatedFilters,
        });
    }, [props.config, props.onChange]);

    const changeComparator = useCallback((idx: number, comparator: string) => {
        const updatedFilters = [...props.config.filters];
        updatedFilters[idx] = {
            ...updatedFilters[idx],
            comparator: comparator,
        }

        props.onChange({
            ...props.config,
            filters: updatedFilters,
        });
    }, [props.config, props.onChange]);

    const changeValue = useCallback((idx: number, value: string) => {
        const updatedFilters = [...props.config.filters];
        updatedFilters[idx] = {
            ...updatedFilters[idx],
            value: value,
        }

        props.onChange({
            ...props.config,
            filters: updatedFilters,
        });
    }, [props.config, props.onChange]);

    const addFilter = useCallback(() => {
        const updatedFilters = [...props.config.filters];
        updatedFilters.push({
            column_ref: {},
            comparator: 'EQUALS',
            value: '',
        });

        props.onChange({
            ...props.config,
            filters: updatedFilters,
        });

    }, [props.config, props.onChange]);

    const removeFilter = useCallback((idx: number) => {
        const updatedFilters = [...props.config.filters];
        updatedFilters.splice(idx, 1);
        props.onChange({
            ...props.config,
            filters: updatedFilters,
        });
    }, [props.config, props.onChange]);

    const filterIsValid = useCallback((f: Filter) => {
        if (!f.column_ref || !f.column_ref.column_path) {
            return false;
        } if (!f.comparator || (comparatorsWithValues.includes(f.comparator) && !f.value)) {
            return false;
        }
        return true;
    }, []);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const testFilters = useCallback(async () => {
        setError('');
        setLoading(true);

        try {
            const response = await ApiService.getInstance().request('POST', `/sources/${props.sourceId}/record-type/${props.sourceRecordTypeId}/test-filters`, {
                'filter_config': props.config
            }) as FilterResponse;
            setFilterResponse(response);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, [props.sourceId, props.sourceRecordTypeId, props.config])

        return <div>
                <p className="text-muted">Exclude records you don't need for any of your data use cases.</p>
                <table className="table table-fixed table-centered table-fixed no-border">
                    <tbody>
                        {props.config.filters.map((f, idx) => {
                            const valid = filterIsValid(f);
                            return (
                                <tr>
                                    <td style={{width: '30%'}}>
                                        <ColumnRefForm
                                            disableAdvanced
                                            columnRef={f.column_ref}
                                            columnOptions={props.columnOptions}
                                            onChange={(ref) => changedColumnRef(idx, ref)}
                                        />
                                    </td>
                                    <td style={{width: '30%'}}>
                                        <Select
                                            options={comparatorOptions}
                                            styles={{
                                                // Fixes the overlapping problem of the component
                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                                input: (styles) => {
                                                    return {
                                                        ...styles,
                                                        padding: '0px',
                                                        margin: '0px',
                                                    }
                                                },
                                                option: (styles) => {
                                                    return {
                                                        ...styles,
                                                        margin: '0px',
                                                        padding: '0px 8px',
                                                    }
                                                }
                                            }}
                                            value={comparatorOptions.find(o => o.value === f.comparator)}
                                            onChange={(val) => changeComparator(idx, !!val ? val.value : '')}
                                        />
                                    </td>
                                    <td style={{width: '30%'}}>
                                        {comparatorsWithValues.includes(f.comparator) && (
                                            <Form.Control placeholder="enter value" type="text" value={f.value} onChange={(e) => changeValue(idx, e.target.value)}/>
                                        )}
                                    </td>
                                    <td className="text-end" style={{width: '5%'}}>
                                        <a role="button" className="action-icon" onClick={() => removeFilter(idx)}>
                                            <i className="mdi mdi-delete"></i>
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}
                        
                    </tbody>
                </table>
                <button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addFilter();
                }} className="btn btn-sm">
                    <i className="mdi mdi-plus"></i> Add Filter
                </button>
                <button onClick={() => {
                    props.onApply()
                    TrackingService.track_event(Events.SRT_APPLY_FILTER_CLK, {
                        'source_id': props.sourceId,
                        'source_record_type_id': props.sourceRecordTypeId
                    })
                }} className="btn btn-sm btn-pliable">
                    Apply Filters
                </button>

               
            
            </div>
}

export default FilterBadData;