import ApiService from "@services/api/api.service";
import { summarizeNumber } from "@services/formatting.service";
import { useSourceRecordTypes } from "@stores/data.store";
import { useCallback, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props {
    sourceId: string;
    selectedId?: string;
}

const SourceRecordTypesList = (props: Props) => {
    const sourceRecordTypes = useSourceRecordTypes(props.sourceId);
    if (sourceRecordTypes.data) {
        return <div>
            <h2>All Record Types</h2>
            <div className="list-group">
                {sourceRecordTypes.data.map(srt => {
                    const active = srt.id === props.selectedId;
                    return (
                        <Link key={srt.id} to={`/sources/${props.sourceId}/data/${srt.id}`} className={"list-group-item list-group-item-action" + (active ? ' active text-white' : '')}>
                            <b>{srt.name}</b>
                            {srt.shape && (
                                <div className={"font-13" + (active ? ' text-white' : ' text-muted')}>
                                    {summarizeNumber(srt.shape!.total_records)} records
                                </div>
                            )}
                        </Link>
                    )
                })}
            </div>
        </div>
    } else if (sourceRecordTypes.isLoading) {
        return <Spinner/>
    }
    return <></>;
}

export default SourceRecordTypesList;