import ORM, { BaseModel } from "./orm";


export interface DataLoad extends BaseModel {
    source_id: string;
    started_at: Date;
    completed_at?: Date;
    status: 'QUEUED'|'COMPLETE'|'ERROR'|'IN_PROGRESS';
    job_id: string;
    total_records: number;
    error_message: string;
}


const DataLoadORM = new ORM<DataLoad>('dataloads', ['started_at', 'completed_at']);
export default DataLoadORM;