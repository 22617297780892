import { getErrorMessage } from '@services/errors.service';
import TenantService from '@services/tenant/tenant.service';
import TenantConfigService from '@services/tenantConfig.service';
import AsyncButton from '@components/button/AsyncButton.component';
import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CopyBlock, atomOneLight } from "react-code-blocks";
import { Link } from 'react-router-dom';
import BackgroundService from '@services/bg.service';

interface SnowflakeDetails {
    host: string;
    username: string;
    password: string;
    database: string;
    loading_password: string;
    loading_database: string;
    role: string;
    loading_warehouse: string;
    query_warehouse: string;
}

function isValidSnowflakeURL(url: string): boolean {
    if(!!url){
        // The URL should contain the account name followed by ".snowflakecomputing.com/"
        const regex = /^([^.]+\.)?([^.]+)\.snowflakecomputing\.com$/;
        const match = url.match(regex);
        if (match !== null) {
        return true;
        }
    }
    return false;
  }

const BYOSnowflakePage = () => {
    const [snowflakeDetails, setSnowflakeDetails] = useState<SnowflakeDetails>({
        host: '',
        username: 'PLIABLE_USER',
        password: '',
        database: 'PLIABLE_DATABASE',
        loading_password: '',
        loading_database: 'PLIABLE_LOADING_DATABASE',
        role: 'PLIABLE_ROLE',
        loading_warehouse: 'PLIABLE_LOADING_WAREHOUSE',
        query_warehouse: 'PLIABLE_QUERY_WAREHOUSE',
    });

    const [tenantConfigId, setTenantConfigId] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const loadData = async () => {
        setLoading(true);
        try {
            const {
                tenantRegistration,
                tenant_config,
                connection_creds,
                loader_creds
            } : any = await TenantService.getInstance().getTenantRegistration();
    
            setTenantConfigId(tenant_config.id);
    
            setSnowflakeDetails(produce(snowflakeDetails, draft => {
                return {
                    host: connection_creds.host,
                    username: connection_creds.username,
                    password: connection_creds.password,
                    database: connection_creds.database,
                    loading_password: loader_creds.password,
                    loading_database: loader_creds.database,
                    role: connection_creds.role,
                    loading_warehouse: connection_creds.loading_warehouse,
                    query_warehouse: connection_creds.query_warehouse,
                };
            }));
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        loadData();
    }, []);

    const [advanced, setAdvanced] = useState(false);

    const [generatingScript, setGeneratingScript] = useState(false);

    const [setupSql, setSetupSql] = useState('');
    const [generatingScriptError, setGeneratingScriptError] = useState('');

    const [testingCredentials, setTestingCredentials] = useState(false);
    const [credentialsError, setCredentialsError] = useState('');
    const [credentialsAreValid, setCredentialsAreValid] = useState(false);


    const validHost = useMemo(() => {
        const isValid = isValidSnowflakeURL(snowflakeDetails.host);
        console.log('is valid:', isValid);
        return isValid;
    }, [snowflakeDetails.host]);

    const formIsValid = useMemo(() => {
        return validHost && !!snowflakeDetails.username && !!snowflakeDetails.database && !!snowflakeDetails.role && !!snowflakeDetails.loading_warehouse && !!snowflakeDetails.query_warehouse;
    }, [snowflakeDetails, validHost]);

    const setSnowflakeValue = (key: keyof SnowflakeDetails, value: string) => {
        setSnowflakeDetails(produce(snowflakeDetails, draft => {
            draft[key] = value;
        }));
    }

    const saveSnowflakeDetails = useCallback(async () => {
        setGeneratingScript(true);

        try {
            const { snowflake_setup_sql } : any = await TenantConfigService.getInstance().saveSnowflakeDetails(tenantConfigId, snowflakeDetails);
            setSetupSql(snowflake_setup_sql);
        } catch (err) {
            setGeneratingScriptError(getErrorMessage(err));
        } finally {
            setGeneratingScript(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const testCredentials = useCallback(async () => {
        setTestingCredentials(true);
        try {
            const { has_db_access } : any = await TenantConfigService.getInstance().verifySnowflakeAccess(tenantConfigId);

            if (!has_db_access) {
                throw new Error('Unable to connect to Snowflake.');
            } 
            const {job_id} : any = await TenantConfigService.getInstance().initInfrustructure(tenantConfigId);
            const {infra} : any = await BackgroundService.getInstance().waitForJob(job_id);
            if (infra.is_ready) {
                setCredentialsAreValid(true);
            } else {
                throw new Error('Error setting up infrustructure.');
            }
        } catch (err) {
            setCredentialsError(getErrorMessage(err));
            setCredentialsAreValid(false);
        } finally {
            setTestingCredentials(false);
        }
    }, [snowflakeDetails, tenantConfigId]);

    if (loading) {
        return <>
            <Spinner/>
        </>
    }
    return <>

        <div className="row">
            <div className="col">
                <div className="alert alert-info mb-3" >You've indicated that you already have a Snowflake account. If you'd like us to create one for you instead, <Link to="/welcome/snowflake/managed">click here</Link> instead.</div>
                <Form>
                    <h4>Tell us about your Snowflake account.</h4>
                    <p className="text-muted font-13">We'll use these details to generate a script for you to run on your Snowflake account. This script will create all of the resources Pliable needs to operate.</p>

                    
                    <Form.Group className="mb-3">
                        <Form.Label>Host</Form.Label>
                        <Form.Control
                            name="host"
                            type="text"
                            placeholder="your-account.snowflakecomputing.com"
                            value={snowflakeDetails.host}
                            isValid={validHost} 

                            onChange={(e) => setSnowflakeValue('host', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Create a query Password</Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder=""
                            value={snowflakeDetails.password}
                            isValid={!!snowflakeDetails.password} 

                            onChange={(e) => setSnowflakeValue('password', e.target.value)}
                        />
                        <Form.Text>This is the password that Pliable will use to access your Snowflake instance. Make sure this is secure, and <strong>hold onto it for the next step.</strong></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Create a loading Password</Form.Label>
                        <Form.Control
                            name="loading_password"
                            type="password"
                            placeholder=""
                            value={snowflakeDetails.loading_password}
                            isValid={!!snowflakeDetails.loading_password} 

                            onChange={(e) => setSnowflakeValue('loading_password', e.target.value)}
                        />
                        <Form.Text>This is the password that Pliable will use to load data into your Snowflake instance. Make sure this is secure, and <strong>hold onto it for the next step.</strong></Form.Text>
                    </Form.Group>

                    {!advanced && (
                        <p>We'll generate a script with our standard parameters. If you use your Snowflake for other things and need more fine-grained control, you can <a role="button" onClick={() => setAdvanced(true)}>edit the default settings</a>.</p>

                    )}

                    {advanced && (
                        <div className="pt-3">
                            <h5>Edit Default Settings</h5>
                            <Form.Group className="mb-3">
                                <Form.Label>Pliable Username</Form.Label>
                                <Form.Control
                                    name="username"
                                    type="text"
                                    placeholder=""
                                    isValid={!!snowflakeDetails.username} 
                                    value={snowflakeDetails.username}
                                    onChange={(e) => setSnowflakeValue('username', e.target.value)}
                                />
                                <Form.Text>Pliable will access your Snowflake instance with this username.</Form.Text>
                            </Form.Group>

                            
                            <Form.Group className="mb-3">
                                <Form.Label>Pliable Database</Form.Label>
                                <Form.Control
                                    name="database"
                                    type="text"
                                    placeholder=""
                                    value={snowflakeDetails.database}
                                    isValid={!!snowflakeDetails.database} 

                                    onChange={(e) => setSnowflakeValue('database', e.target.value)}
                                />
                                <Form.Text>This is the database that Pliable will put all of your data.</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Pliable Role</Form.Label>
                                <Form.Control
                                    name="role"
                                    type="text"
                                    placeholder=""
                                    value={snowflakeDetails.role}
                                    isValid={!!snowflakeDetails.role} 

                                    onChange={(e) => setSnowflakeValue('role', e.target.value)}
                                />
                                <Form.Text>The script will create a role for Pliable to use in order to access to all of the necessary resources.</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Loading Warehouse</Form.Label>
                                <Form.Control
                                    name="loading_warehouse"
                                    type="text"
                                    placeholder=""
                                    value={snowflakeDetails.loading_warehouse}
                                    isValid={!!snowflakeDetails.loading_warehouse} 
                                    onChange={(e) => setSnowflakeValue('loading_warehouse', e.target.value)}
                                />
                                <Form.Text>This is the name of the Snowflake warehouse that Pliable will use for loading data from your data sources.</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Processing Warehouse</Form.Label>
                                <Form.Control
                                    name="query_warehouse"
                                    type="text"
                                    placeholder=""
                                    value={snowflakeDetails.query_warehouse}
                                    isValid={!!snowflakeDetails.query_warehouse} 
                                    onChange={(e) => setSnowflakeValue('query_warehouse', e.target.value)}
                                />
                                <Form.Text>This is the name of the Snowflake warehouse that Pliable will use for processing your data</Form.Text>
                            </Form.Group>
                        </div>
                    )}
                    <AsyncButton
                        variant="pliable"
                        icon='mdi mdi-xml'
                        disabled={!formIsValid}
                        loading={generatingScript}
                        onClick={() => saveSnowflakeDetails()}
                        text="Generate Script"
                    />

                    {setupSql && (
                        <>
                            <hr />
                            <p>Now, log into your Snowflake account and run the following script in a new worksheet. Make sure "All Queries" is checked, and click on "I've run the query" when you're done.</p>
                            <Alert variant="secondary">
                                Be sure to replace "YOUR_PASSWORD_HERE" with the password you provided in the previous step.
                            </Alert>

                            <div style={{maxHeight: 400, overflowX: 'scroll'}} className="mb-3">
                                <CopyBlock
                                    text={setupSql}
                                    language="sql"
                                    showLineNumbers={false}
                                    theme={atomOneLight}
                                    codeBlock={true}
                                />
                            </div>
                            <AsyncButton
                                variant="pliable"
                                icon='mdi mdi-xml'
                                loading={testingCredentials}
                                onClick={() => testCredentials()}
                                text="I've run the query."
                            />

                            {credentialsAreValid && (
                                <>
                                    <hr />
                                    <p>
                                        <strong className="text-success">Success!</strong> We connected to your Snowflake. <Link to="/sources/new">Start loading data.</Link>
                                    </p>
                                    
                                </>
                            )}
                            {credentialsError && (
                                <>
                                    <p>
                                        <strong className="text-danger">Oops!</strong> We were unable to connect to your Snowflake.
                                    </p>
                                </>
                            )}
                        </>
                    )}
                    
                    
                </Form>
            </div>
        </div>
    </>
}

export default BYOSnowflakePage;