import { Component, MouseEvent, RefObject, useEffect, useRef, useCallback, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TenantPicker from '@components/tenant/TenantPicker.component';
import AuthService from "../../services/auth/auth.service";
import useGlobalState from '@stores/global.state';
import logoLarge from '../../assets/images/pliable/logo_with_text_black.png';
import { DataSourcesIcon, BusinessObjectsIcon } from '@components/icons/PliableIcons.component';
import { AuthorizedContent, AdminPortal } from "@frontegg/react";
import { Role } from "../../constants/roles";
import TrackingService, {Events} from '@services/tracking.service';

function useComponentVisible(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLLIElement>(null);

    const handleClickOutside = (event: any) => {
        // console.log('Click outside:', event);
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}

export interface NavParams {
    disableSidebar?: boolean;
}

const Nav = (params: NavParams = {}) => {
    const navigate = useNavigate();
    const logout = () => {
        navigate('/account/logout');
    }

    const location = useLocation();

    const { sidebarExpanded, toggleSidebar} = useGlobalState((state: any) => state);

    let extraClass = '';
    if (params.disableSidebar) {
        extraClass = 'sidebar-disabled';
    } else if (!sidebarExpanded) {
        extraClass = 'sidebar-compact';
    }

    let toggleClass = '';
    if (params.disableSidebar) {
        toggleClass = 'invisible';
    }

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const username = AuthService.getInstance().authState?.user?.email;
    const tenant_name = AuthService.getInstance().getTenantName()

    const showAdminPortal = useCallback(() => {
        AdminPortal.show();
        TrackingService.track_event(Events.USER_ADMIN_CLK);
    }, []);

    const trackLogout = useCallback(() => {
        TrackingService.track_event(Events.USER_LOGOUT_CLICK);
    }, [])

    return (
        <div className={`navbar navbar-light navbar-top ${extraClass}`}>
            
                <span className="logo-lg">
                <img height="32" src={logoLarge}/>
                </span>
                <ul className="list-unstyled topbar-menu mb-0 ps-3 flex-1">
                    <li>
                        <Link className={`nav-link ps-1 pe-1 ${location.pathname == '/' ? 'active': ''}`} to="/">Home</Link>
                    </li>
                    <li>
                        <Link className={`nav-link ps-1 pe-1 ${location.pathname.startsWith('/sources') ? 'active': ''}`} to="/sources"><DataSourcesIcon height='1.5em' /> Data Sources</Link>
                    </li>
                    <li>
                        <Link className={`nav-link ps-1 pe-1 ${location.pathname.startsWith('/business-objects') ? 'active': ''}`} to="/business-objects"><BusinessObjectsIcon height='1.5em' /> Business Objects</Link>
                    </li>
                    <li>
                        <Link className={`nav-link ps-1 pe-1 ${location.pathname.startsWith('/reports') ? 'active': ''}`} to="/reports"><BusinessObjectsIcon height='1.5em' /> Reports</Link>
                    </li>
                    {/* <li>
                        <Link className={`nav-link ps-1 pe-1 ${location.pathname.startsWith('/reports') ? 'active': ''}`} to="/reports">Destinations</Link>
                    </li> */}
                </ul>
            
                <ul className="list-unstyled topbar-menu float-end mb-0">
                <li className="dropdown notification-list" ref={ref}>
                    <a role="button" className="nav-link dropdown-toggle nav-user arrow-none me-0" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                        {/* <span className="account-user-avatar">
                            <img src="" alt="" className="rounded-circle" />
                        </span> */}
                        <span className="account-user-name">{username}</span>
                        <span className="account-position">{tenant_name}</span>
                    </a>
                    <div  className={`dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown ${isComponentVisible ? 'show' : ''}`}>
                        <AuthorizedContent requiredRoles={[Role.Admin]} key="tenant-admin-wrap" >
                            <a role="button" className="dropdown-item notify-item" onClick={showAdminPortal}><i className="mdi mdi-security"></i> User Admin</a>
                        </AuthorizedContent>
                        <Link to="/account/logout" className="dropdown-item notify-item" onClick={trackLogout}>
                            <i className="mdi mdi-logout me-1"></i>
                            <span>Logout</span>
                        </Link>
                        <TenantPicker/>
                    </div>
                </li>
            </ul>
            {/* <div className="container-fluid">
                <button className="button-menu-mobile open-left">
                    <i className="mdi mdi-menu"></i>
                </button>
                
                <div>
                    <h3>{tenant_name}</h3>
                </div>
                <ul className="list-unstyled topbar-menu float-end mb-0">
                    <li className="dropdown notification-list">
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {username}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Link to="/audit-logs" className='dropdown-item'><i className="mdi mdi-archive"></i> Audit Log</Link>
                            <Dropdown.Item href="#" onClick={logout}><i className="mdi mdi-logout"></i> Logout</Dropdown.Item>
                            <TenantPicker />
                        </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
            </div> */}
            
        </div>
    )


}


export default Nav;