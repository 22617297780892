import BusinessObjectSelector from "@components/businessObjects/BusinessObjectSelector.component";
import { CardHeader } from "@components/card/Card.component";
import { TableExplorer } from "@components/datatable/TableExplorer.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import SourceInfoCard from "@components/sources/SourceInfoCard.component";
import SourceSelector from "@components/sources/SourceSelector.component";
import { BusinessObject } from "@models/businessObject";
import SourceORM, { Source, SourceRecordType } from "@models/source";
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import toast from "@services/toast.service";
import { useBusinessObjects, useSourceRecordTypes, useSources, useStandardizationPipelines } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Spinner, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SourceDataStats from "./SourceDataStats.component";
import BasicStandardizationPipeline from "./BasicStandardizationPipeline.component";
import SourceRecordTypesList from "@components/sources/SourceRecordTypesList.component";
import SourceRecordTypeSettings from "./SourceRecordTypeSettings.component";
import { useSource } from "@stores/sources.store";




const SourceRecordTypeDetails = () => {
    const { sourceId, sourceRecordTypeId, activeTab = 'insights' } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const sourceRecordTypes = useSourceRecordTypes(sourceId as string);
    const {data: activeSource, dataUpdatedAt} = useSource(sourceId as string);
    const [activeSourceRecordType, setActiveSourceRecordType] = useState<SourceRecordType|undefined>(undefined);
    const standardizationPipelines = useStandardizationPipelines(sourceRecordTypeId);
    const businessObjects = useBusinessObjects();


    useEffect(() => {
        if (sourceRecordTypeId && sourceRecordTypes.data) {
            setActiveSourceRecordType(sourceRecordTypes.data.find(s => s.id === sourceRecordTypeId));
        }

        if (activeSource && sourceRecordTypes.data) {
            setLoading(false);
        }

    }, [sourceId, sourceRecordTypeId, dataUpdatedAt, sourceRecordTypes.dataUpdatedAt]);

    useEffect(() => {
        if (!sourceRecordTypeId && sourceRecordTypes.data) {
            const first = sourceRecordTypes.data.find(s => s.source_id === sourceId);
            if (first) {
                navigate(`/sources/${sourceId}/data/${first.id}`);
            }
        }
    }, [sourceRecordTypes.dataUpdatedAt, sourceId, sourceRecordTypeId]);

    


    const [selectedBusinessObject, setSelectedBusinessObject] = useState<undefined|BusinessObject>(undefined);

    const businessObjectSelected = useCallback((bo: BusinessObject|undefined) => {
        console.log('selected', bo);
        setSelectedBusinessObject(bo);
    }, []);
    const initiateStandardizationPipeline = useCallback(() => {
        if (selectedBusinessObject) {
            navigate(`/sources/${sourceId}/data/${sourceRecordTypeId}/standardize?businessObjectId=${selectedBusinessObject.id}`);
        } else {
            console.log('No business object selected');
        }
    }, [sourceId, sourceRecordTypeId, selectedBusinessObject]);

    const initiateRelationship = useCallback((srt: SourceRecordType|undefined) => {
        console.log(srt);
    }, []);

    const setActiveTab = (tab: string) => {
        if(tab != activeTab) {
            navigate(`/sources/${sourceId}/data/${sourceRecordTypeId}/tab/${tab}`);
        }

    }

    const recalcShape = async () => {
        setLoading(true);
        await SourceORM.recalculateRecordTypeShape(sourceId!, sourceRecordTypeId!);
        sourceRecordTypes.refetch();
        setLoading(false);
    }

    const standardizationPipelineText = useMemo(() => {
        if (standardizationPipelines.data && businessObjects.data) {
            if (standardizationPipelines.data.length === 0) {
                return <p>This data is not yet part of any of your datasets.</p>
            }
            const data = standardizationPipelines.data.map(sp => {
                const bo = businessObjects.data.find(bo => bo.id === sp.business_object_id);
                if (!bo) {
                    return {
                        'text': 'Unknown',
                        'link': '/'
                    };
                }
                return {
                    'text': bo.name,
                    'link': `/sources/${sourceId}/data/${sourceRecordTypeId}/standardize/${sp.id}`
                };
            });
            return <>
                <p>
                    This data is part of your {data.map((d, idx: number) => {
                        return (
                            <>
                                {idx > 0 && <span>, </span>}
                                {idx === (data.length - 1) && idx > 0 && <span>and </span>}
                                <Link to={d.link} className="action-link">{d.text}</Link>
                            </>
                        )
                    })} <span>{data.length === 1 ? 'dataset' : 'datasets'}</span>
                </p>
            </>;
        }
    }, [standardizationPipelines.dataUpdatedAt, businessObjects.dataUpdatedAt, sourceId, sourceRecordTypeId]);
   
    if (loading) {
        return <Spinner/>;
    } else if (activeSource && sourceRecordTypes.data) {
        return (
            <>
                <PageTitle title="View Source Data" breadcrumbs={[
                    {title: 'Home', path: '/'},
                    {title: 'Data Sources', path: '/sources'},
                    {title: activeSource.name, path: `/sources/${sourceId}`},
                    {title: !!activeSourceRecordType ? activeSourceRecordType.name : 'Loading'}
                    
                ]}/>
                <div className="row">
                    <div className="col-2">
                        <SourceInfoCard source={activeSource}/>
                        <SourceRecordTypesList sourceId={sourceId as string} selectedId={sourceRecordTypeId} />
                    </div>
                    <div className="col-10">
                        {activeSourceRecordType && (
                            <>
                                <ul className="nav nav-pills bg-nav-pills mb-3">
                                    <li className="nav-item">
                                        <a role="button" className={"nav-link rounded-0" + (activeTab === 'insights' ? ' active' : '')}  onClick={() => setActiveTab('insights')}>Insights</a>
                                    </li>
                                    <li className="nav-item">
                                        <a role="button" className={"nav-link rounded-0" + (activeTab === 'data' ? ' active' : '')} onClick={() => setActiveTab('data')}>Explore Data</a>
                                    </li>
                                    <li className="nav-item">
                                        <a role="button" className={"nav-link rounded-0" + (activeTab === 'pipeline' ? ' active' : '')}  onClick={() => setActiveTab('pipeline')}>Data Model</a>
                                    </li>
                                    <li className="nav-item">
                                        <a role="button" className={"nav-link rounded-0" + (activeTab === 'settings' ? ' active' : '')}  onClick={() => setActiveTab('settings')}>Settings</a>
                                    </li>

                                </ul>
                                {activeTab === "data" && (
                                    <div className="card">
                                        <div className="card-body">
                                            <TableExplorer
                                                tablePath={`/sources/${sourceId}/record-type/${sourceRecordTypeId}/data`}
                                                columnStatsUrl={`/sources/${sourceId}/record-type/${sourceRecordTypeId}/column-stats`}
                                                defaultOrderBy="random()"
                                                
                                            />
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'insights' && (
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>Here's what our AI found in your data.</h6>
                                            <SourceDataStats sourceId={sourceId as string} sourceRecordTypeId={sourceRecordTypeId as string}/>
                                            <div>
                                                <a role="button" onClick={recalcShape} style={{float: 'right'}}><i className="mdi mdi-refresh"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'deduplicate' && (
                                    <div className="card">
                                        <div className="card-body">
                                            <BasicStandardizationPipeline sourceRecordTypeId={sourceRecordTypeId as string}/>
                                        </div>
                                    </div>
                                )}
                                {activeTab === "pipeline" && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <CardHeader title="Datasets" subtitle="Turn this source data into actionable data assets"></CardHeader>
                                                        {standardizationPipelines.isLoading && <Spinner size="sm"/>}
                                                        {standardizationPipelines.data && standardizationPipelineText}
                                                        
                                                        <hr />
                                                        <Form.Group>
                                                            <Form.Label>Add to dataset:</Form.Label>
                                                            <BusinessObjectSelector selectedId={selectedBusinessObject ? (selectedBusinessObject.id as string) : ''} onSelect={businessObjectSelected}/>
                                                            <button onClick={() => initiateStandardizationPipeline()} className="btn btn-pliable mt-1">Go</button>

                                                        </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <CardHeader title="Relationships" subtitle="Define how this data is related to your other data"></CardHeader>
                                                    <p>
                                                        This data is related to <Link to="/" className="action-link">Salesforce - Accounts</Link> and <Link to="/" className="action-link">Snowflake - Company</Link>
                                                    </p>
                                                    <hr />
                                                       
                                                    <button className="btn btn-pliable mt-1">Edit Relationships</button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                )}
                                {activeTab === "settings" && (
                                    <div className="card">
                                        <div className="card-body">
                                            <SourceRecordTypeSettings sourceRecordType={activeSourceRecordType} />
                                        </div>
                                    </div>
                                )}
                            </>
                            
                                
                              
                        )}
                        
                    </div>
                    
                </div>
            </>
            
        )
    }
    return <></>;
    


}

export default SourceRecordTypeDetails;