import { Component, useCallback, useEffect, useMemo, useState } from 'react';
import BusinessObjectsList from '../components/businessObjects/BusinessObjectsList.component';
import BigMetricCard from '../components/metric/BigMetricCard.component';
import PageTitle from '../components/pageTitle/PageTitle.component';
import Search from '../components/search/Search.component';
import ApiService, { SingleRecordResponse } from '../services/api/api.service';
import ConfigService, { Config } from '../services/config/config.service';
import Chart from "react-apexcharts";
import { CardHeader } from '../components/card/Card.component';
import Spinner from 'react-bootstrap/esm/Spinner';
import PipelinesList from '../components/pipelines/PipelinesList.component';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FilesList from '../components/files/FilesList.component';
import { integerFormatter, summarizeNumber } from '../services/formatting.service';
import LineageGraph from '../components/lineage/LineageGraph.component';
import { Link } from 'react-router-dom';
import { invalidateBusinessObjects, useBusinessObjects, useSourceRecordTypes, useSources } from '@stores/data.store';
import BusinessObjectORM, { BusinessObject } from '@models/businessObject';
import { confirmation } from '@services/alert/alert.service';
import { Offcanvas } from 'react-bootstrap';
import BusinessObjectForm from './BusinessObject/BusinessObjectForm.component';
import SourceIcon from '@components/sources/SourceIcon.component';
import { getErrorMessage } from '@services/errors.service';


const SnowflakeUsageChart = () => {
    const [snowflakeUsage, setSnowflakeUsage] = useState<any[]>([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    

    const loadData = useCallback(async () => {
        setLoading(true);
        try {
            const dashboardData = await ApiService.getInstance().request('GET', '/dashboard_stats') as SingleRecordResponse<any>;
            setSnowflakeUsage(dashboardData.record.snowflake_usage);
            setError(dashboardData.record.snowflake_usage_error);

        } catch(err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadData();
    }, [])

    if (loading) {
        return <Spinner/>;
    } else if (error) {
        return <p className="alert alert-danger">{error}</p>
    }
    return <Chart
                    options={{
                        'chart': {
                            'id': 'snowflake-usage',
                            'toolbar': {
                                'show': false,
                            }
                        },
                        'xaxis': {
                            'categories': snowflakeUsage.map(u => u.date),
                            'type': 'datetime',
                        },
                        'yaxis': {
                            'decimalsInFloat': 0,
                        }
                    }}
                    series={[
                        {
                            name: 'Credits Used',
                            'data': snowflakeUsage.map(u => u.credits_used)
                        }
                    ]}
                    type="line"
                    height="320"
                    width="100%"
                />;

}

interface SourceStats {
    totalRecords: number;
    totalRecordTypes: number;
}

const DashboardPage = () => {
    const businessObjects = useBusinessObjects();
    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();

    // Hack alert: copied from BusinessObjectsListPage
    const deleteBusinessObject = useCallback((businessObject: BusinessObject) => {
        confirmation({
            header: 'Delete business object?',
            confirmationButtonText: 'Delete',
            message: `To delete this object, please type "${businessObject.name}" in the field below.`,
            typedValueExpectation: businessObject.name,
            onClose: () => null,
            confirmationButtonVariant: 'danger',
            onConfirm: async () => {
                await BusinessObjectORM.delete(businessObject);
                invalidateBusinessObjects();
            }
        });
    }, []);

    const [creatingNewBusinessObject, setCreatingNewBusinessObject] = useState(false);

    const statsBySource = useMemo(() => {
        if (!sources.data || !sourceRecordTypes.data) {
            return {};
        }

        const rv: {
            [key: string]: SourceStats
        } = {};

        sourceRecordTypes.data.forEach(srt => {
            if (!rv.hasOwnProperty(srt.source_id)) {
                rv[srt.source_id] = {
                    totalRecords: 0,
                    totalRecordTypes: 0,
                };
            }

            if (srt.shape) {
                rv[srt.source_id].totalRecords = rv[srt.source_id].totalRecords + srt.shape.total_records;
            }
            rv[srt.source_id].totalRecordTypes++;

        });

        return rv;

    }, [sources.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt])



    return <div className="content-inner">
        <Offcanvas placement="end" show={creatingNewBusinessObject} onHide={() => setCreatingNewBusinessObject(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>New Business Object</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <BusinessObjectForm businessObject={{
                    id: null,
                    name: '',
                    description: '',
                    fields: [],
                }}
                onComplete={(bo: BusinessObject) => {
                    setCreatingNewBusinessObject(false);
                }}
                />
            </Offcanvas.Body>
        </Offcanvas>
        <div className="row">
            <div className="col-12">
                <div className="section">
                <h1>Dashboard</h1>

                </div>
            </div>
        </div>
        <div className="row h-100">
            <div className="col-6">
                <div className="section">
                    <div className="card">
                        <div className="card-header">
                        <button className="btn btn-pliable float-right" onClick={() => setCreatingNewBusinessObject(true)}>Add New</button>

                            <h3>Business Objects</h3>
                            <p className="font-13 text-muted mb-0">
                                Business objects are the building blocks for your data. They represent tangible things that you interact with in order to operate your business, such as customers and products.
                            </p>
                        </div>
                        <table className="table-centered table no-border mb-0">
                        <tbody>
                            {businessObjects.data && businessObjects.data.map(b => {
                            return (
                                <tr key={b.id}>
                                    <td>
                                        <h5 className="font-14 my-1 mb-0">{b.name}</h5>
                                        <span className="text-muted font-13">{b.description}</span>
                                    </td>
                                    {/* <td>
                                        <span className="text-muted">1,234 records</span>
                                    </td> */}
                                    <td>
                                        <Link to={`/business-objects/${b.id}/attributes`}>{b.fields.length} attributes</Link>
                                    </td>
                                    <td className="text-end">
                                        <a
                                            className="action-icon"
                                            role="button"
                                            onClick={() => deleteBusinessObject(b)}
                                        >
                                            <i className="mdi mdi-delete"></i>
                                        </a>
                                        <Link 
                                            to={'/business-objects/' + b.id + '/attributes'}
                                            className="action-icon"
                                        >
                                            <i className="mdi mdi-pencil-box"/>
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    </div>
                    <div className="card mt-3">
                        <div className="card-header">
                            <h3>Snowflake Usage</h3>
                        </div>
                        <div className="card-body">
                            <SnowflakeUsageChart/>
                        </div>
                    </div>
                    
                    
                    
                    

                    
                </div>
            </div>
            <div className="col-6">
                <div className="section">
                    <div className="card">
                        <div className="card-header">
                        <Link className="btn btn-pliable float-right" to="/sources/new">Add New</Link>

                            <h3>Data Sources</h3>
                            <p className="font-13 text-muted mb-0">
                                Data sources can be files, databases, or systems that contain your business data. Pliable helps you turn that disparate source data into a pristine, actionable data asset.
                            </p>
                        </div>
                        <table className="table-centered table no-border mb-0">
                            <tbody>
                                {sources.data && sources.data.map(s => {
                                    const stats = statsBySource[s.id as string];
                                    return <tr>
                                        <td>
                                            <SourceIcon source={s} className="avatar-sm rounded-circle img-thumbnail"/>
                                        </td>
                                        <td>
                                            <h5 className="font-14 my-1 mb-0">{s.name}</h5>
                                            {stats && (
                                                <span className="text-muted font-13">
                                                    {summarizeNumber(stats.totalRecords)} total records
                                                </span>
                                            )}
                                            
                                        </td>
                                        <td className="text-end">
                                        {/* <a
                                            className="action-icon"
                                            role="button"
                                            // onClick={() => deleteBusinessObject(b)}
                                        >
                                            <i className="mdi mdi-delete"></i>
                                        </a> */}
                                        <Link 
                                            to={'/sources/' + s.id}
                                            className="action-icon"
                                        >
                                            <i className="mdi mdi-pencil-box"/>
                                        </Link>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>

                    
                </div>
            </div>
            
        </div>
    </div>
    return <></>
}

export default DashboardPage;

interface State {
    loading: boolean;
    activeRecords: number;
    snowflakeUsage: any[];
    snowflakeUsageError: string | null;
    activeTab: string;
    showUploader: boolean;
}

// interface Props {

// }

// export default class DashboardPage extends Component<Props, State> {
//     constructor(props: Props) {
//         super(props);
//         this.state = {
//             loading: true,
//             activeRecords: 0,
//             snowflakeUsage: [],
//             activeTab: 'sources',
//             showUploader: false,
//             snowflakeUsageError: null,
//         };
//     }

//     async componentDidMount() {
//         this.setState({
//             loading: true,
//         });

//         const dashboardData = await ApiService.getInstance().request('GET', '/dashboard_stats') as SingleRecordResponse<any>;

//         this.setState({
//             loading: false,
//             activeRecords: dashboardData.record.active_records,
//             snowflakeUsage: dashboardData.record.snowflake_usage,
//             snowflakeUsageError: dashboardData.record.snowflake_usage_error,
//         });
//     }

//     render() {
//         let chart;

//         if (this.state.loading) {
//             chart = <Spinner/>
//         } else if (this.state.snowflakeUsageError) {
//             chart = <p className="alert alert-danger">{this.state.snowflakeUsageError as string}</p>;
//         } else {
//             chart = <Chart
//                 options={{
//                     'chart': {
//                         'id': 'snowflake-usage',
//                         'toolbar': {
//                             'show': false,
//                         }
//                     },
//                     'xaxis': {
//                         'categories': this.state.snowflakeUsage.map(u => u.date),
//                         'type': 'datetime',
//                     },
//                     'yaxis': {
//                         'decimalsInFloat': 0,
//                     }
//                 }}
//                 series={[
//                     {
//                         name: 'Credits Used',
//                         'data': this.state.snowflakeUsage.map(u => u.credits_used)
//                     }
//                 ]}
//                 type="line"
//                 height="320"
//                 width="100%"
//             />;
//         }
//         return (
//             <div>
                
//                 <PageTitle title="Dashboard"/>
//                 <div className="row">
//                     <div className="col-4">
//                         <div className="card">
//                             <div className="card-body">
//                                 <CardHeader title="Business Objects">
//                                     <Link to="/business-objects/new" className="btn btn-xs btn-outline-dark">
//                                         <i className="mdi mdi-plus"></i> Add New
//                                     </Link>
//                                 </CardHeader>
//                                 <BusinessObjectsList layout="compact"/>

//                             </div>
//                         </div>
//                         <div className="card">
//                             <div className="card-body">
//                                 <CardHeader title="Snowflake Usage"/>
//                                 {chart}
//                             </div>
//                         </div>
                    
//                     </div>
//                     <div className="col-8">
//                         <div className="card">
//                             <div className="card-body">
//                                 <Tabs
//                                     id="controlled-tab-example"
//                                     activeKey={this.state.activeTab}
//                                     onSelect={(k) => this.setState({activeTab: k as string})}
//                                     className="mb-3 nav-bordered"
//                                 >
//                                     <Tab eventKey="sources" title="Data Sources">
//                                         <SourcesList/>
//                                     </Tab>
//                                 </Tabs>
//                             </div>
//                         </div>
                    
//                     </div>
//                 </div>
//             </div>
            
//         );
//     }
// }