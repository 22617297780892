import { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../components/pageTitle/PageTitle.component';
import PipelinesList from '../components/pipelines/PipelinesList.component';
import DataMartORM, { DataMart } from '../models/dataMart';
import { timeAgo } from '../services/time.service';

interface Props {}

interface State {
    loading: boolean;
    dataMarts: DataMart[];
}
export default class DataMartsListPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            dataMarts: [],
        }
    }
    
    private async load() {
        this.setState({
            loading: true,
        });

        const resp = await DataMartORM.find();
        this.setState({
            loading: false,
            dataMarts: resp.records,
        });
    }

    componentDidMount() {
        this.load();
    }

    renderInnerContent() {
        if (this.state.loading) {
            return <Spinner/>;
        }
        return (
            <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                    <tbody>
                        {this.state.dataMarts.map(dm => {
                            return (
                                <tr>
                                    <td>
                                        <h5 className="font-14 my-1"><Link 
                                            className="action-icon"
                                            to={'/reports/' + dm.id}
                                        >{dm.name}</Link></h5>
                                        <span className="text-muted font-13">
                                            Last modified {timeAgo(dm.last_updated as Date)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-muted font-13">Description</span>
                                        <h5 className="font-14 mt-1 fw-normal">{dm.description}</h5>
                                    </td>
                                    <td className="table-action">
                                        <Link 
                                            className="action-icon"
                                            to={'/reports/' + dm.id}
                                        >
                                            <i className="mdi mdi-cog"></i>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        return (
            <div>
                <PageTitle title="Reports" breadcrumbs={[
                    {
                        title: 'Reports',
                    }
                ]}></PageTitle>
                <p>
                    <Link className="btn btn-primary" to="/reports/new">New Report</Link>

                </p>
                <div className="card">
                    <div className="card-body">
                        {this.renderInnerContent()}

                    </div>
                </div>
            </div>
        );
            
    }
}