import { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from '@services/api/api.service';
import ORM, { BaseModel } from "./orm";
import BackgroundService from '@services/bg.service';
import { parseDateString } from '@services/time.service';
import { ColumnRef } from './shared';
import { DedupeConfig, FilterConfig } from './standardizationPipeline';


export interface FivetranConnectorStatus {
    is_historical_sync: boolean;
    schema_status: string;
    setup_state: string;
    sync_state: string;
}

export interface FivetranConnector {
    status: FivetranConnectorStatus;
    paused: boolean;
}

export interface FivetranSourceConnector {
    connector: FivetranConnector;
    config: any;
    setup_complete: boolean;
}


export interface Source extends BaseModel{
    name: string;
    type: string;
    description?: string;
    connector_name?: string;
    config?: any;
    secureConfig?: any;
    last_loaded?: Date | null;
    fivetran?: FivetranSourceConnector;
}



export interface Identifier {
    reference: 'SELF'|'RELATED';
    column_ref: ColumnRef;
    related_source_record_type_id?: string;
    related_source_record_type_column?: ColumnRef;
}

export interface ColumnStats {
    key: string
    distinct_values: number;
    empty_values: number;
    uniqueness: number;
    density: number;
    samples: any[];
}


export interface RecordTypeShape {
    total_records: number;
    columns: ColumnStats[];
    updated_at: Date;
}

export interface ColumnPreference {
    key: string;
    visible: boolean;
}

export interface SourceRecordType extends BaseModel {
    name: string;
    source_id: string;
    description: string;
    resource_name: string;
    column_preferences: ColumnPreference[];
    composite_key?: string[];
    identifiers?: Identifier[];
    shape?: RecordTypeShape; 
    default_filter?: FilterConfig;
    default_dedupe?: DedupeConfig;
}


export interface SourceRecordTypeColumn {
    value: string;
    label: string;
}

export interface SourceRecordTypeOption extends BaseModel {
    type: string;
    name: string;
    label: string;
    columns: SourceRecordTypeColumn[];
    default_primary_key?: string[];
}

export interface ConfigField {
    type: string;
    label: string;
    key: string;
    description: string;
}


export interface SourceType {
    id: string;
    title: string;
    provider: string;
    description: string;
    icon_path: string;
    // config_fields: ConfigField[];
    // secure_config_fields: ConfigField[];
    // category: 'file_upload'|'api'|'form'|'snowflake_table'|'connector';
    oauth: boolean;
}

export interface SourceDataRelationshipRef {
    source_id: string;
    source_record_type_id: string;
    column_ref: ColumnRef;
}

export interface SourceDataRelationship extends BaseModel {
    name: string;
    references: SourceDataRelationshipRef[];
}

export interface FivetranConnectorCard extends BaseModel {
    connect_card_url: string;
}

interface RecordTypeOptionsResponse {
    records?: SourceRecordTypeOption[];
    job_id: string;
}



export class _SourceORM extends ORM<Source>{

    public async getEnabledSourceTypes() {
        const result = await this.apiService.request('GET', `/${this.endpoint}/source-types`) as ListRecordsResponse<SourceType>;
        return result.records;
    }

    public async getConnectorCatalog(sourceId: string, forceSchemaReload: boolean = false) {
        const sourceRecordTypeOptionsResp = await this.apiService.request('GET', `/sources/${sourceId}/record-type-options`, 
            {
                force_schema_reload: forceSchemaReload
            }
        ) as RecordTypeOptionsResponse;

        if (sourceRecordTypeOptionsResp.records) {
            return sourceRecordTypeOptionsResp.records;
        }

        const jobResults = await BackgroundService.getInstance().waitForJob(sourceRecordTypeOptionsResp.job_id);

        return jobResults.record_type_options;
        
    }

    public async recalculateRecordTypeShape(sourceId: string, sourceRecordTypeId: string) {
        const job = await this.apiService.request('POST', `/sources/${sourceId}/record-type/${sourceRecordTypeId}/shape`) as JobEnqueueResponse;
        const jobResult = await BackgroundService.getInstance().waitForJob(job.job_id);
    }

    public async getConnectorCardUrl(sourceId: string){
        const sourceRecordTypeOptionsResp = await this.apiService.request('POST', `/sources/${sourceId}/pbf-connector-card`) as SingleRecordResponse<FivetranConnectorCard>;
        return sourceRecordTypeOptionsResp;
    }
}

const SourceORM = new _SourceORM('sources', ['last_loaded']);
export default SourceORM;

class _SourceRecordTypeORM extends ORM<SourceRecordType>{
    public convertDates(record: SourceRecordType) {
        const rv = super.convertDates(record);
        if (rv.shape) {
            // @ts-ignore
            rv.shape.updated_at = parseDateString(rv.shape.updated_at);
        }
        return rv;
    }
}

export const SourceRecordTypeORM = new _SourceRecordTypeORM('sourcerecordtypes');

export const SourceDataRelationshipORM = new ORM<SourceDataRelationship>('sourcedatarelationships');