import ConfigService from "@services/config/config.service";
import { ReactNode, useMemo, useState } from "react";

interface FeatureFlagWrapperProps {
    requiredFlags: string[];
    children: ReactNode;
}

const FeatureFlagWrapper = (props: FeatureFlagWrapperProps) => {
    const config = ConfigService.getInstance().config;

    const shouldShow = useMemo(() => {
        if (!config || !config.flags) {
            return false;
        }

        const keys = Object.keys(config.flags);

        return props.requiredFlags.every(f => {
            return keys.includes(f) && config.flags[f] === true;
        });
    }, [config, config?.flags, props.requiredFlags]);

    if (shouldShow) {
        return <>{props.children}</>
    }

    return <></>;
    
}

export default FeatureFlagWrapper;