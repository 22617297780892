import { Component } from 'react';
import { Source } from '@models/source';
import LoadingCard from '@components/card/LoadingCard.component';
import { useState, useEffect } from 'react';
import produce from "immer"
import ApiService, { JobEnqueueResponse, SingleRecordResponse } from '@services/api/api.service';
import RecordTypeOptionsList from '@components/sources/RecordTypeOptionsList.component';
import AsyncButton from "@components/button/AsyncButton.component";
import toast from '@services/toast.service';
import { SourceType } from '@models/source';
import { useSourceTypes } from '@stores/data.store';
import { useSource } from '@stores/sources.store';
import { useSourceRecordTypes, useSourceCatalog } from '@stores/recordTypes.store';
import BackgroundService from "@services/bg.service";


interface RecordTypesSelectorProps {
    sourceId: string;
    onSave?: () => void;
    onCancel?: () => void;
}

function RecordTypesSelector(props: RecordTypesSelectorProps){
    const [saving, setSaving] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [primaryKeys, setPrimaryKeys] = useState<any>({});

    const { isLoading: loadingSource, error, data: source} = useSource(props.sourceId);
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes(!!source);
    const { isLoading: loadingRecordTypes, data: recordTypes, refetch: refetchRecordTypes} = useSourceRecordTypes(props.sourceId);

    const { isLoading: loadingCatalog, isRefetching: refetchingCatalog, data: sourceRecordTypeOptions } = useSourceCatalog(props.sourceId);

    const sourceType = soureTypeOptions?.find((st: SourceType) => st.id == source?.type);
    const pageLoading = loadingCatalog || refetchingCatalog || !Object.keys(primaryKeys).length;

    useEffect(() => {
            setPrimaryKeys(produce(primaryKeys, (draft: any) => {
                console.log('Looking for PKs', recordTypes);
                sourceRecordTypeOptions?.forEach((rto) => {
                    const found = recordTypes?.find(rt => rt.resource_name == rto.name);
                    if(!found) {
                        const pks : any[] = [];
                        if(rto.default_primary_key && rto.default_primary_key.length > 0) {
                            rto.default_primary_key.forEach((option_val) => {
                                const key_option = rto.columns.find(c => c.value == option_val);
                                if (key_option) {
                                    pks.push(key_option)
                                }
                            });
                        }
                        draft[rto.name] = pks;
                    }else{
                        const pks : any[] = [];
                        if(found.composite_key){
                            found.composite_key.forEach((option_val) => {
                                const key_option = rto.columns.find(c => c.value == option_val);
                                if (key_option) {
                                    pks.push(key_option)
                                } else {
                                    // Handle manually-specified keys
                                    pks.push({
                                        value: option_val,
                                        label: option_val,
                                    })
                                }
                            });
                        }
                        draft[rto.name] = pks;
                    }
                });
            }));

            setSelectedOptions(produce(selectedOptions, (draft: any) => {
                sourceRecordTypeOptions?.forEach((rto) => {
                    const found = recordTypes?.find(rt => rt.resource_name == rto.name);
                    draft[rto.name] = (!found) ? false  : true;
                });
            }));
        
    }, [sourceRecordTypeOptions, recordTypes]);


    const cancelEdit = () => {
        // navigate(`/sources/${sourceId}`)
    }

    const save = async () => {
        if (!source) {
            return;
        }

        setSaving(true);


        const choosenElements : any[] = [];

        Object.keys(selectedOptions).forEach(record_name => {
            let selected = selectedOptions[record_name];
            if(selected) {
                const composite_key = Array.from(primaryKeys[record_name].map((i:any) => i.value));
                choosenElements.push({
                    "resource_name": record_name,
                    "composite_key": composite_key
                })
            }
        });

        
        let missing_key = choosenElements.find((e) => { return e.composite_key.length == 0});

        if (missing_key) {
            toast('danger', 'Error Saving', `${missing_key.name} requires a primary key`);
            setSaving(false)
            return;
        }

        try{
            const putResult = await ApiService.getInstance().request('PUT', `/sources/${props.sourceId}/record-types`, choosenElements);
            const initResult = await ApiService.getInstance().request('POST', `/sources/${props.sourceId}/init`, {}) as SingleRecordResponse<JobEnqueueResponse>;
            const jobResults = await BackgroundService.getInstance().waitForJob(initResult.record.job_id);
            setSaving(false);
            toast('success', 'Success!', 'Configuartion Saved');
            if(props.onSave){
                props.onSave();
            }
        }catch(ex){
            setSaving(false);
            toast('danger', 'Error Saving', `${ex}`)
        }
    }


    const onPrimaryKeyChange = (optionName: string, change: any) => {
        setPrimaryKeys(produce(primaryKeys, (draft: any) => {
            draft[optionName] = change;

            console.log('Setting primary key for ', optionName, change);
        }));
    }

    const toggleOption = (optionName: string) => {
        setSelectedOptions(produce(selectedOptions, (draft: any) => {
            draft[optionName] = !draft[optionName]

        }))
    }


    if (loadingSourceTypes) {
        return <LoadingCard />
    } else if (error) {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {error as string}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (source) {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        { pageLoading && 
                            <LoadingCard action={`Loading available objects`}  />
                        } 

                        { !pageLoading && 
                            <RecordTypeOptionsList 
                                recordTypeOptions={sourceRecordTypeOptions!} 
                                selectedOptions={selectedOptions} 
                                primaryKeys={primaryKeys}
                                onToggle={(optionName: string) => toggleOption(optionName)}
                                onPrimaryKeyChange={(optionName: string, change: any) => onPrimaryKeyChange(optionName, change)}
                            />
                        }      
                    </div>
                    
                    <div className="col-12">
                        { props.onCancel && <button className="btn btn-outline-primary me-1" onClick={() => cancelEdit()}>Cancel</button> }
                            <AsyncButton
                                text="Save Table Preferences"
                                variant="pliable"
                                disabled={pageLoading}
                                loading={saving}
                                onClick={() => save()}
                            />
                        
                    </div>
                </div>
            </>
        )
    }
    return <></>
}



export default RecordTypesSelector


