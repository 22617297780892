import { ColumnRef } from "@models/shared";
import { getErrorMessage } from "@services/errors.service";
import { ColumnElement, getColumnsForPipelineStep, getColumnsForSourceRecordType } from "@services/tablestructure.service";
import { useEffect, useState } from "react";
import { Dropdown, Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';

interface ColumnRefFormProps {
    columnRef: ColumnRef;
    disabled?: boolean;
    columnOptions: string[];
    // Whenever the ref changes pass the updated version back
    onChange: (ref: ColumnRef) => void;
    disableAdvanced?: boolean;
}

export const ColumnRefForm = (props: ColumnRefFormProps) => {
    const [advancedMode, setAdvancedMode] = useState(false);

    // Only infer advanced mode when first rendered, otherwise manage w/ internal state
    useEffect(() => {
        if (props.columnRef.raw_sql) {
            setAdvancedMode(true);
        } else {
            setAdvancedMode(false);
        }
    }, []);

    const onChangeRawSQL = (sql: string) => {
        props.onChange({
            ...props.columnRef,
            raw_sql: sql,
        });
    }

    const onChangeSelectedColumn = (columnName: string) => {
        props.onChange({
            ...props.columnRef,
            column_path: columnName
        });
    }

    const toggleTransformation = (transformation: string) => {
        const currentTransformations = props.columnRef.transformations ? props.columnRef.transformations : [];
        
        const idx = currentTransformations.indexOf(transformation);
        let newTransformations: string[];
        if (idx >= 0) {
            newTransformations = [...currentTransformations];
            newTransformations.splice(idx, 1);
        } else {
            newTransformations = [...currentTransformations, transformation];
        }
        props.onChange({
            ...props.columnRef,
            transformations: newTransformations,
        });
    }

    const options = props.columnOptions.map(o => {
        return {
            label: o,
            value: o,
        }
    });

    const transformations = props.columnRef.transformations ? props.columnRef.transformations : [];

    const menuPopover = <Popover id="popover-basic">
        <Popover.Header as="h3" className="bg-primary text-white">Column Settings</Popover.Header>
        <Popover.Body className="bg-dark text-white">
            <Form.Check 
                type="switch"
                label="Advanced Mode"
                checked={advancedMode}
                onChange={(evt) => setAdvancedMode(evt.target.checked)}
            />
            {!advancedMode && (
                <>
                    <h5>Transformations</h5>
                    <Form.Check 
                        type="switch"
                        label="TRIM"
                        checked={transformations.includes('TRIM')}
                        onChange={(evt) => toggleTransformation('TRIM')}
                    />
                    <Form.Check 
                        type="switch"
                        label="LOWERCASE"
                        checked={transformations.includes('LOWERCASE')}
                        onChange={(evt) => toggleTransformation('LOWERCASE')}
                    />
                    <Form.Check 
                        type="switch"
                        label="UPPERCASE"
                        checked={transformations.includes('UPPERCASE')}
                        onChange={(evt) => toggleTransformation('UPPERCASE')}
                    />
                    <Form.Check 
                        type="switch"
                        label="CAPITALIZE_WORDS"
                        checked={transformations.includes('CAPITALIZE_WORDS')}
                        onChange={(evt) => toggleTransformation('CAPITALIZE_WORDS')}
                    />
                    <Form.Check 
                        type="switch"
                        label="STRIP_LEADING_ZEROS"
                        checked={transformations.includes('STRIP_LEADING_ZEROS')}
                        onChange={(evt) => toggleTransformation('STRIP_LEADING_ZEROS')}
                    />
                </>
            )}
        </Popover.Body>
    </Popover>

    const renderMenu = () => {
        if (advancedMode) {
            return <button title="Basic Mode" className="btn btn-outline-secondary" onClick={() => setAdvancedMode(!advancedMode)}>
                <i className="mdi mdi-close-circle"></i>
            </button>
        } else {
            return <button title="Advanced Mode" className="btn btn-outline-secondary" onClick={() => setAdvancedMode(!advancedMode)}>
                <i className="mdi mdi-code-braces-box"></i>
            </button>
        }
        
    }



    const renderForm = () => {
        if (advancedMode) {
            return <Form.Control disabled={!!props.disabled} type="text" className="font-monospace" placeholder="function(data:foo)" value={props.columnRef.raw_sql as string} onChange={(e) => onChangeRawSQL(e.target.value)}/>
        } else {
            return <Select placeholder="Select column" styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 }),
                input: (styles) => {
                    return {
                        ...styles,
                        padding: '0px',
                        margin: '0px',
                    }
                },
                option: (styles) => {
                    return {
                        ...styles,
                        margin: '0px',
                        padding: '0px 8px',
                    }
                }
            }}
            options={options} value={{label: props.columnRef.column_path as string, value: props.columnRef.column_path as string}} onChange={(selected) => onChangeSelectedColumn(selected ? selected.value : '')}/>;
        }
    }

    if (props.disableAdvanced) {
        return renderForm();
    }
    return <div className="row">
        <div className="col-10 pe-0">
            {renderForm()}
        </div>
        <div className="col-2 ps-1">
            {renderMenu()}
        </div>
    </div>
}



interface SourceDataColumnRefProps {
    sourceId: string;
    disabled?: boolean;
    columnRef: ColumnRef;
    sourceRecordTypeId: string;
    // Whenever the ref changes pass the updated version back
    onChange: (ref: ColumnRef) => void;

}

export const SourceDataColumnRef = (props: SourceDataColumnRefProps) => {
    console.log('REF IN WRAPPER IS:', props);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [columnOptions, setColumnOptions] = useState<string[]>([]);

    const loadData = async () => {
        setLoading(true);
        setError('');


        /**
         * Grabs terminator nodes with no children
         */

        try {
            const columns = await getColumnsForSourceRecordType(props.sourceId, props.sourceRecordTypeId);

            ;
            setColumnOptions(columns.map(c => c.PATH));




        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log('Got change to source or source record type id');
        loadData();
    }, [props.sourceId, props.sourceRecordTypeId]);

    if (loading) {
        return <Spinner size="sm"/>;
    } else if (error) {
        return <p className="alert alert-error">{error}</p>
    }

    return <ColumnRefForm columnRef={props.columnRef} disabled={props.disabled} onChange={props.onChange} columnOptions={columnOptions}/>
}

