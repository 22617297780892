import { Component } from 'react';
import PageTitle from '../components/pageTitle/PageTitle.component';


// For now we'll hard-code import all the images
import AWSTextractImage from '../assets/images/addons/aws-textract.png';
import Smarty from '../assets/images/addons/smarty.png';
import Twilio from '../assets/images/addons/twilio.jpg';
import Form from 'react-bootstrap/Form';

interface AddonProps {
    title: string;
    image: string;
    description: string;
    type: string;
}

class AddonComponent extends Component<AddonProps> {
    render() {
        return (
            <div className="col-md-6 col-xxl-3">
                <div className="card d-block h-100">
                    <img className="card-img-top" src={this.props.image} style={{height: '190px', objectFit: 'cover'}}/>
                    <div className="card-img-overlay">
                        <div className="badge bg-success">{this.props.type}</div>

                    </div>
                    <div className="card-body position-relative">
                        {/* <div className="dropdown card-widgets">
                            <a href="#" className="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="dripicons-dots-3"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a href="#" className="dropdown-item"><i className="mdi mdi-pencil me-1"></i>Edit</a>
                                <a href="#" className="dropdown-item"><i className="mdi mdi-delete me-1"></i>Delete</a>
                                <a href="#" className="dropdown-item"><i className="mdi mdi-email-outline me-1"></i>Invite</a>
                                <a href="#" className="dropdown-item"><i className="mdi mdi-exit-to-app me-1"></i>Leave</a>
                            </div>
                        </div> */}
                        <h4 className="mt-0">
                            <a href="apps-projects-details.html" className="text-title">{this.props.title}</a>
                        </h4>

                        <p className="text-muted font-14 my-3">{this.props.description}
                        </p>
                        
                        <Form.Check 
                            type="switch"
                            label="Enabled"
                        />


                    </div>
                    
                </div>
            </div>
            
        )
    }
}

export default class AddonsPage extends Component {
    render() {
        return (
            <div>
                <PageTitle title="Addons"></PageTitle>
                <div className="row">
                    <AddonComponent 
                        title="CSV Ingestion" 
                        image={AWSTextractImage}
                        type="Data Source"
                        description="Extracts data from CSV files"
                    />
                    <AddonComponent 
                        title="AWS Textract" 
                        image={AWSTextractImage}
                        type="Pre-Processor"
                        description="Extracts form and tabular data from PDFs and images."
                    />
                    <AddonComponent 
                        title="Smarty Address Standardization" 
                        image={Smarty}
                        type="Data Enrichment"
                        description="Verifies and standardizes address data using Smarty's Address Verification service."
                    />
                    <AddonComponent 
                        title="Twilio Phone Number Lookup" 
                        image={Twilio}
                        type="Data Enrichment"
                        description="Validates and provides additional information like carrier and line type for phone numbers."
                    />
                </div>
            </div>
        )
    }
}