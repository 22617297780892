import { Source } from "@models/source";
import { useSourceTypes } from '@stores/data.store';

interface SourceIconProps {
    source?: Source;
    className?: string;
}

const SourceIcon = (props: SourceIconProps) => {
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes();
    if (loadingSourceTypes || !props.source){
        return <></>;
    }
    

    const sourceType = soureTypeOptions?.find((st) => st.id == props.source?.type);

    if (!sourceType) {
        return <></>
    }
    return <img className={props.className} src={sourceType!.icon_path}/>

}

export default SourceIcon;