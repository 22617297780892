import { AuthState } from '@frontegg/redux-store';

export default class AuthService {
    private static instance: AuthService;

    authState: AuthState | null;
    authUrl: string;
    tenantName: string | null;

    constructor() {
        this.authState = null;
        this.tenantName = null;

        this.authUrl = '';
        if (window.location.host.startsWith('local.') || window.location.host.startsWith('dev-')) {
            this.authUrl = "https://auth-dev.whetstonedata.com";
        }else if (window.location.host == "snowflake.pliable.co"){
            this.authUrl = `https://auth.app.pliable.co`;
        }else{
            this.authUrl = `https://auth.${window.location.host}`;
        }
    }

    setAuthState(authState: AuthState) {
        this.authState = authState;

        let found = this.authState?.tenantsState.tenants.find((t) => t.tenantId == this.authState?.user?.tenantId);
        if (found) {
            this.tenantName = found.name;
        }else {
            this.tenantName = 'Tenant';
        }

        try{
            // Log rocket needs an identify call on each page load or the session shows up anonymous
            window.analytics.identify(this.authState.user?.id);
        }catch(ex){
            console.log(ex);
            //pass
        }
    }

    getAccessToken() {
        return this.authState?.user?.accessToken;
    }

    getTenantName() {
        return this.tenantName;
    }

    getTenantId() {
        return this.authState?.user?.tenantId;
    }

    hasRole(role: string){
        if (!this.authState?.isAuthenticated) {
            return false;
        }
        
        return this.authState?.user?.roles.some(r => r.key === role)
    }

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }

        return AuthService.instance;
    }

    async getUserTenants() {
        const response = await fetch(this.authUrl + '/identity/resources/users/v2/me/tenants', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.getAccessToken(),
            },
            mode: 'cors',
            credentials: 'include',
        });
        return await response.json();
    }

    
}
