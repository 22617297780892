import { ApiError } from "./api/api.service";

export function getErrorMessage(err: any) {
    let errMsg = err.toString();
    if (err instanceof ApiError) {
        errMsg = err.payload?.msg as string;
    }

    if (errMsg.indexOf('PROD_DATASETS') >= 0 && errMsg.indexOf('does not exist or not authorized') >= 0) {
        errMsg = "Looks like you're trying to use data from a pipeline that hasn't been run yet. Do you need to run it first?\n\n" + errMsg;
    }

    return errMsg;
}