import React, { Component, useCallback, useEffect, useState } from 'react';
import ApiService, { ListRecordsResponse, SingleRecordResponse } from '../../services/api/api.service';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import { Link, useNavigate } from "react-router-dom";
import { timeAgo, parseDateString } from '../../services/time.service';
import moment from 'moment';
import PipelineORM, { Pipeline } from '../../models/pipeline';
import BusinessObjectORM, { BusinessObject } from '../../models/businessObject';
import SourceORM, { Source, SourceRecordType, SourceRecordTypeORM } from '../../models/source';
import ConfirmationModal from '../alert/ConfirmationModal.component';
import { PipelineExecution } from '../../models/pipelineExecution';
import { getErrorMessage } from '../../services/errors.service';
import toast from '../../services/toast.service';
import LoadingCard from '@components/card/LoadingCard.component';
import { confirmation } from '@services/alert/alert.service';
import SourceIcon from '@components/sources/SourceIcon.component';

interface RowProps {
    pipeline: Pipeline;
    businessObject?: BusinessObject;
    layout: 'wide'|'compact'|'sources';
    source?: Source;
    sourceRecordType?: SourceRecordType;
    clickedDelete: () => void;

}

function PipelineRow({pipeline, businessObject, layout, source, sourceRecordType, clickedDelete}: RowProps) {
    const navigate = useNavigate();

    const badges = []
    let sources: string[] = [];

    
    const copyPipeline = async () => {
        const newPipeline = Object.assign({}, pipeline);
        newPipeline.id = null;
        newPipeline.name = pipeline.name + '_copy';
        const result = await PipelineORM.save(newPipeline);
        navigate(`/pipelines/${result.id as string}`)
    }

    const runPipeline = async () => {
        try {
            const execution = await ApiService.getInstance().request('POST', `/pipelines/${pipeline.id as string}/run`, {}) as SingleRecordResponse<PipelineExecution>;
            navigate(`/pipelines/${pipeline.id as string}/executions/${execution.record.id as string}`);
        }  catch (err) {
            const errMessage = getErrorMessage(err);
            toast('danger', 'Error', errMessage);
        }

    }

    const deletePipeline = async () => {
        
    }

    const getOutputNode = () => {
        if (businessObject) {
            return <Link to={'/business-objects/' + (businessObject.id as string)}>{businessObject.name}</Link>
        }
        return <span>-</span>;
    }

    if (pipeline.new_records && pipeline.new_records > 0) {
        badges.push(<Badge bg="info">{pipeline.new_records?.toString() + ' new records'}</Badge>);
    } else {
        badges.push(<Badge bg="success">Up to date</Badge>);
    }

    let lastExecution;

    if (pipeline.last_execution) {
        lastExecution = pipeline.last_execution.toString();
    } else {
        lastExecution = 'Never';
    }

    if (layout === 'wide') {
        return (
            <tr>
                <td>
                    <h5 className="font-14 my-1">{pipeline.name}</h5>
                    <span className="text-muted font-13">Loads from <strong><Link to={`/sources/${source?.id as string}`}>{source?.name}</Link></strong></span>
                </td>
                <td>
                    <span className="text-muted font-13">Last Run</span>
                    <h5 className="font-14 mt-1 fw-normal">{timeAgo(pipeline.last_execution)}</h5>
                </td>
                <td>
                    <span className="text-muted font-13">Outputs</span>
                    <h5 className="font-14 mt-1 fw-normal">
                        <strong>{getOutputNode()}</strong>
                    </h5>
                </td>
                <td className="table-action text-end">
                    <Link to={'/pipelines/' + pipeline.id} className="action-icon">
                        <i className="mdi mdi-eye"></i>
                    </Link>
                    <Link 
                        to={'/pipelines/' + pipeline.id + '/configuration'}
                        className="action-icon"
                    >
                        <i className="mdi mdi-cog"/>
                    </Link>
                    <a
                        className="action-icon"
                        href="#"
                        onClick={copyPipeline}
                    >
                        <i className="mdi mdi-content-copy"></i>
                    </a>
                    <a 
                        
                        className="action-icon"
                        href="#"
                        onClick={runPipeline}
                    >
                        <i className="mdi mdi-play-box"/>
                    </a>
                    <a
                        className="action-icon"
                        href="#"
                        onClick={clickedDelete}
                    >
                        <i className="mdi mdi-delete"></i>
                    </a>
                </td>
            </tr>
        );
    } else if (layout === 'compact') {
        return (
            <tr>
                <td>
                    <h5 className="font-14 my-1">{pipeline.name}</h5>
                    <span className="text-muted font-13">
                        Outputs: 
                        {getOutputNode()}
                    </span>
                </td>
                <td className="table-action text-end">
                    <Link to={'/pipelines/' + pipeline.id} className="action-icon">
                        <i className="mdi mdi-eye"></i>
                     </Link>
                </td>
            </tr>
        );
    } else if (layout === 'sources' && source) {
        return (
            <tr>
                <td>
                    <div className="avatar-sm">
                        <SourceIcon source={source}/>
                    </div>
                </td>
                <td>
                    <h5 className="font-14 mb-0">
                        <Link to={`/sources/${source?.id as string}`}>{source?.name}</Link>
                    </h5>
                    <p className="text-muted font-13 mb-0">
                        <Link to={'/sources/' + (source?.id as string) + '/data/' + (sourceRecordType?.id as string)}>{sourceRecordType?.name}</Link>
                    </p>

                </td>
                <td className="text-end">
                    <Link className="action-icon" to={'/sources/' + (source?.id as string) + '/data/' + (sourceRecordType?.id as string) + '/standardize/' + (pipeline.id as string) }>
                        <i className="mdi mdi-cog"></i>
                    </Link>
                </td>
            </tr>
        )
    }
    return <></>;
    
}

interface PipelinesListDumbComponentProps {
    pipelines: Pipeline[];
    allBusinessObjects: BusinessObject[];
    allSources: Source[];
    allSourceRecordTypes: SourceRecordType[];
    layout: 'wide' | 'compact' | 'sources';
    onDeletePipeline?: (pipeline: Pipeline) => void;
}
export const PipelinesListDumbComponent = (props: PipelinesListDumbComponentProps) => {
    const [selectedPipelineForDeletion, setSelectedPipelineForDeletion] = useState<Pipeline|undefined>(undefined);

    const getBusinessObject = (pipeline: Pipeline): BusinessObject|undefined =>  {
        if (pipeline.steps) {
            const outputStepConfig = pipeline.steps.find(s => s.step_type === 'OUTPUT')?.config;
            if (!outputStepConfig) {
                return undefined;
            }
            const businessObjectId = outputStepConfig.business_object_id;
            return props.allBusinessObjects.find(b => b.id === businessObjectId);
        }else if(!!pipeline.business_object_id) {
            // std pipeline
            return props.allBusinessObjects.find(b => b.id === pipeline.business_object_id);
        }
        return undefined;
        
    }

    const getSource = (pipeline: Pipeline): Source|undefined => {
        if (pipeline.steps && pipeline.steps.length) {
            const sourceRecordTypeId = pipeline.steps[0].config?.source_record_type_id;
            if (sourceRecordTypeId) {
                const srt = props.allSourceRecordTypes.find(s => s.id === sourceRecordTypeId);
                if (srt) {
                    return props.allSources.find(s => s.id === srt.source_id);
                }

            }
        }else if(pipeline.source_record_type_id) {
            // std pipeline
            const srt = props.allSourceRecordTypes.find(s => s.id === pipeline.source_record_type_id);
            if (srt) {
                return props.allSources.find(s => s.id === srt.source_id);
            }  
        }
        return undefined;
    }

    const getSourceRecordType = (pipeline: Pipeline): SourceRecordType|undefined => {
        if (pipeline.steps && pipeline.steps.length) {
            const sourceRecordTypeId = pipeline.steps[0].config?.source_record_type_id;
            if (sourceRecordTypeId) {
                return props.allSourceRecordTypes.find(s => s.id === sourceRecordTypeId);

            }
        }else if(pipeline.source_record_type_id) {
            // std pipeline
            return props.allSourceRecordTypes.find(s => s.id === pipeline.source_record_type_id); 
        }
        return undefined;
    }


    const confirmDeletePipeline = useCallback((pipeline: Pipeline) => {
        console.log('Confiurmed delete', props.onDeletePipeline, pipeline);
        if (props.onDeletePipeline) {
            props.onDeletePipeline(pipeline);
        }
    }, [props.onDeletePipeline]);

    const deletePipeline = (pipeline: Pipeline) => {
        console.log('Setting pipeline for deletion', pipeline);
        setSelectedPipelineForDeletion(pipeline);
        confirmation({
            header: 'Delete pipeline?',
            confirmationButtonText: 'Delete',
            message: `To delete this pipeline, please type "${pipeline.name}" in the field below:`,
            typedValueExpectation: pipeline.name,
            confirmationButtonVariant: 'danger',
            onConfirm: () => {
                confirmDeletePipeline(pipeline);
            },
            onClose: () => {
                console.log('RUNNING ON CLOSE');
                setSelectedPipelineForDeletion(undefined);
            }
        });
    }

    // throw Error('DELETING PIPELIENS DOES NOT WORK');

    const render = () => {
        return (
            <>
                <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                        <tbody>
                            {props.pipelines.map(p => {

                                return (<PipelineRow 
                                    key={p.id}
                                    source={getSource(p)}
                                    sourceRecordType={getSourceRecordType(p)}
                                    pipeline={p} 
                                    businessObject={getBusinessObject(p)} 
                                    layout={props.layout}
                                    clickedDelete={() => deletePipeline(p)}
                                />);
                                
                            })}
                        </tbody>
                        
                    </table>
                </div>
            </>
            
        );
    }
    return render();


        
}

interface Props {
    sourceId?: string;
    businessObjectId?: string;
    layout: 'wide'|'compact'|'sources';
}

interface PipelinesResponse {
    pipelines: Pipeline[];
    source_record_types: SourceRecordType[];
    sources: Source[];
}


const PipelinesList = (props: Props) => {
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [pipelines, setPipelines] = useState<Pipeline[]>([]);
    const [sources, setSources] = useState<Source[]>([]);
    const [sourceRecordTypes, setSourceRecordTypes] = useState<SourceRecordType[]>([]);
    const [businessObjects, setBusinessObjects] = useState<BusinessObject[]>([]);
    const [error, setError] = useState('');

    const loadData = async () => {
        setLoading(true);
        try {
            if (props.businessObjectId) {
                const pipelinesResponse = await ApiService.getInstance().request('GET', `/businessobjects/${props.businessObjectId}/pipelines`) as PipelinesResponse;
                setPipelines(pipelinesResponse.pipelines);
                setSources(pipelinesResponse.sources);
                setSourceRecordTypes(pipelinesResponse.source_record_types);

                const businessObject = await BusinessObjectORM.findById(props.businessObjectId);
                setBusinessObjects([businessObject]);
            } else {
                let pipelines: ListRecordsResponse<Pipeline>;
                if (props.sourceId) {
                    pipelines = await PipelineORM.find({
                        'source_id': {
                            '$eq': props.sourceId as string,
                        }
                    });
                } else {
                    pipelines = await PipelineORM.find();
                }
    
                const sources = await SourceORM.find();
                const sourceRecordTypes = await SourceRecordTypeORM.find();
                const businessObjects = await BusinessObjectORM.find();
    
                setPipelines(pipelines.records);
                setSources(sources.records);
                setSourceRecordTypes(sourceRecordTypes.records);
                setBusinessObjects(businessObjects.records);
            }
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [props.sourceId, props.businessObjectId]);

    const deletePipeline = async (pipeline: Pipeline) => {
        setLoading(true);
        try {
            const idx = pipelines.findIndex(p => p.id === pipeline.id);
            const result = await PipelineORM.delete(pipeline);
            const newPipelines = [...pipelines];
            newPipelines.splice(idx, 1);
            setPipelines(newPipelines);
            toast('success', 'Success!', 'Pipeline deleted');

        } catch (err) {
            toast('danger', 'Failed to delete pipeline!', getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return <LoadingCard action="Loading pipelines..."/>
    } else if (error) {
        return <div className="alert alert-danger">{error}</div>
    }
    return <PipelinesListDumbComponent
        pipelines={pipelines}
        allSources={sources}
        allSourceRecordTypes={sourceRecordTypes}
        allBusinessObjects={businessObjects}
        layout={props.layout}
        onDeletePipeline={deletePipeline}

    />
}

export default PipelinesList;
