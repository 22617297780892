import { Component } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../components/pageTitle/PageTitle.component';
import PipelinesList from '../components/pipelines/PipelinesList.component';

export default class PipelinesListPage extends Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-8">
                        <PageTitle title="Pipelines">

                            <Link
                                to="/pipelines/new"
                                className="btn btn-primary btn-sm ms-3"
                            >
                                <i className="mdi mdi-plus"></i>&nbsp;
                                New Pipeline
                            </Link>
                        </PageTitle>
                    </div>
                   
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <PipelinesList layout="wide"/>

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
            
    }
}