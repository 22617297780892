import { useState, useEffect } from 'react';
import produce from "immer"
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageTitle from '@components/pageTitle/PageTitle.component';
import ApiService, { ListRecordsResponse } from '@services/api/api.service';
import { getErrorMessage } from '@services/errors.service';
import SourceInfoCard from '@components/sources/SourceInfoCard.component';
import RecordTypeOptionsList from '@components/sources/RecordTypeOptionsList.component';
import AsyncButton from "@components/button/AsyncButton.component";
import toast from '@services/toast.service';
import LoadingCard from '@components/card/LoadingCard.component';
import { SourceType } from '@models/source';
import { useSourceTypes } from '@stores/data.store';
import { useSource } from '@stores/sources.store';
import { useSourceRecordTypes, useSourceCatalog } from '@stores/recordTypes.store';



export function SourceEditRecordTypesPage() {
    const { sourceId = '' } = useParams();
    const navigate = useNavigate();
    const [showNewRecordTypeModal, setShowNewRecordTypeModal] = useState(false);
    const [ recordTypeSearchFilter, setRecordTypeSearchFilter] = useState('');
    const [runningDataLoad, setRunningDataLoad] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [primaryKeys, setPrimaryKeys] = useState<any>({});

    const { isLoading: loadingSource, error, data: source} = useSource(sourceId);
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes(!!source);
    const { isLoading: loadingRecordTypes, data: recordTypes, refetch: refetchRecordTypes} = useSourceRecordTypes(sourceId);

    const { isLoading: loadingCatalog, data: sourceRecordTypeOptions } = useSourceCatalog(sourceId);

    const sourceType = soureTypeOptions?.find((st: SourceType) => st.id == source?.type);

    const pageLoading = loadingCatalog || !Object.keys(primaryKeys).length;
    

    useEffect(() => {
            setPrimaryKeys(produce(primaryKeys, (draft: any) => {
                sourceRecordTypeOptions?.forEach((rto) => {
                    const found = recordTypes?.find(rt => rt.name == rto.name);
                    if(!found) {


                        draft[rto.name] = []
                    }else{
                        const pks : any[] = []
                        if(found.composite_key){
                            found.composite_key.forEach((option_val) => {
                                const key_option = rto.columns.find(c => c.value == option_val);
                                if (key_option) {
                                    pks.push(key_option)
                                }
                            });
                        }
                        draft[rto.name] = pks;
                    }
                });
            }));

            setSelectedOptions(produce(selectedOptions, (draft: any) => {
                sourceRecordTypeOptions?.forEach((rto) => {
                    const found = recordTypes?.find(rt => rt.name == rto.name);
                    draft[rto.name] = (!found) ? false  : true;
                });
            }));
        
    }, [sourceRecordTypeOptions, recordTypes]);


    const cancelEdit = () => {
        navigate(`/sources/${sourceId}`)
    }

    const save = async () => {
        if (!source) {
            return;
        }

        setSaving(true);


        const choosenElements : any[] = [];

        Object.keys(selectedOptions).forEach(record_name => {
            let selected = selectedOptions[record_name];
            if(selected) {
                const composite_key = Array.from(primaryKeys[record_name].map((i:any) => i.value));
                choosenElements.push({
                    "name": record_name,
                    "composite_key": composite_key
                })
            }
        });

        
        let missing_key = choosenElements.find((e) => { return e.composite_key.length == 0});

        if (missing_key) {
            toast('danger', 'Error Saving', `${missing_key.name} requires a primary key`);
            setSaving(false)
            return;
        }

        try{
            const putResult = await ApiService.getInstance().request('PUT', `/sources/${sourceId}/record-types`, choosenElements);
            setSaving(false);
            navigate(`/sources/${source.id}`)
        }catch(ex){
            setSaving(false);
            toast('danger', 'Error Saving', `${ex}`)
        }
    }


    const onPrimaryKeyChange = (optionName: string, change: any) => {
        setPrimaryKeys(produce(primaryKeys, (draft: any) => {
            draft[optionName] = change;
        }));
    }

    const toggleOption = (optionName: string) => {
        setSelectedOptions(produce(selectedOptions, (draft: any) => {
            draft[optionName] = !draft[optionName]

        }))
    }


    if (loadingSourceTypes) {
        return <LoadingCard />
    } else if (error) {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            {error as string}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (source) {
        return (
            <>
                <PageTitle title="Data Source Record Types - Edit"
                    breadcrumbs={[
                        {
                            title: 'Data Sources',
                            path: '/sources',
                        }, {
                            title: source.name
                        }
                    ]}
                />
                <div className="row">
                    <div className="col-3">
                        <SourceInfoCard source={source} />
                    </div>
                    <div className="col-9">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8">
                                        <h4>Choose the Record Types you'd like to read from:</h4>
                                    </div>
                                
                                    <div className="col-4">
                                        
                                        <form action="" className="app-search mb-3">
                                            <div className="input-group">
                                                <input type="text" className="form-control" onChange={(e) => setRecordTypeSearchFilter(e.target.value as string)} />
                                                <span className="mdi mdi-magnify search-icon"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <hr/>
                                    { pageLoading && 
                                        <LoadingCard action={`Loading ${sourceType!.title} objects`}  />
                                    } 

                                    { !pageLoading && 
                                        <RecordTypeOptionsList 
                                            recordTypeOptions={sourceRecordTypeOptions!} 
                                            selectedOptions={selectedOptions} 
                                            primaryKeys={primaryKeys}
                                            onToggle={(optionName: string) => toggleOption(optionName)}
                                            onPrimaryKeyChange={(optionName: string, change: any) => onPrimaryKeyChange(optionName, change)}
                                        />
                                    }      
                                </div>
                                
                                <div className="col-12">
                                    <div className="text-end">
                                    <button className="btn btn-outline-primary me-1" onClick={() => cancelEdit()}>Cancel</button>
                                        <AsyncButton
                                            icon="mdi mdi-content-save"
                                            text="Save Changes"
                                            variant="success"
                                            loading={saving}
                                            onClick={() => save()}
                                        />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return <></>

}



export default SourceEditRecordTypesPage;