import SourceRecordTypeName from "@components/sources/SourceRecordTypeName.component";
import { SourceDataRelationship, SourceRecordType } from "@models/source";
import { useSourceDataRelationships } from "@stores/data.store";
import { Offcanvas } from "react-bootstrap";

interface SourceDataRelationshipsProps {
    sourceRecordType: SourceRecordType;
    onConfigureRelationship: (rel: SourceDataRelationship) => void;
    onRemoveRelationship: (rel: SourceDataRelationship) => void;
}

const SourceDataRelationships = (props: SourceDataRelationshipsProps) => {
    const relationships = useSourceDataRelationships();
    return <>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Connections: <SourceRecordTypeName sourceRecordType={props.sourceRecordType}/></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <table className="table table-centered table-fixed">
                <tbody>
                {relationships.data && relationships.data.map(r => {
                    const includeThisOne = r.references.find(ref => ref.source_record_type_id === props.sourceRecordType.id);
                    if (includeThisOne) {
                        return <tr>
                            <td>
                                Connected to {r.references.filter(ref => ref.source_record_type_id !== props.sourceRecordType.id).map((ref, idx) => {
                                        return (<>
                                            <strong><SourceRecordTypeName sourceRecordTypeId={ref.source_record_type_id}/></strong>
                                            {idx < (r.references.length - 2) && <span>, </span>}
                                        </>
                                            
                                        )
                                    })}
                            </td>
                            <td width="100" className="text-end">
                            <button className="btn btn-pliable btn-sm" onClick={() => props.onConfigureRelationship(r)}>Configure</button>
                            </td>
                        </tr>
                           
                    }
                    return <></>
                })}
                </tbody>
            </table>

        </Offcanvas.Body>
    </>
}

export default SourceDataRelationships;