import { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import csvIcon from '../../assets/images/csv-icon.png';
import ApiService, { ListRecordsResponse } from '../../services/api/api.service';
import { integerFormatter } from '../../services/formatting.service';
import { timeAgo, parseDateString } from '../../services/time.service';
import FileORM, { File } from '../../models/file';
import { subscribe, unsubscribe } from '@nextcloud/event-bus'
import { Badge } from 'react-bootstrap';
import ConfirmationModal from '@components/alert/ConfirmationModal.component';
import DataLoadORM, { DataLoad } from '@models/dataload';



interface RowProps {
    load: DataLoad;
}

const removeExtension = (v: string) => {
    const spl = v.split('.');
    spl.pop();
    return spl.join('.');
}

class DataLoadRow extends Component<RowProps> {

    async downloadOriginalFile(file_id: string) {
        await FileORM.downloadOriginal(file_id);
    }
    
    render() {
        const load = this.props.load;
        

        return (
            <tr>
                <td>
                    <span className="text-muted font-13">Started</span>
                    <h5 className="font-14 mt-1 fw-normal">{timeAgo(load.started_at)}</h5>
                </td>
                <td>
                    {load.status && <>
                        <span className="text-muted font-13">Status</span>
                        <h5 className="font-14 mt-1 fw-normal">{load.status}</h5>
                    </>}
                </td>
                <td>
                    {load.completed_at && <>
                        <span className="text-muted font-13">Completed At</span>
                        <h5 className="font-14 mt-1 fw-normal">{timeAgo(load.completed_at)}</h5>
                    </>}
                </td>
                {/* <td>
                    {load.processing_status === 'UPLOADING' && (
                        <Badge
                            bg="warning"
                        >Requires Processing!</Badge>
                    )}
                    {file.processing_status === 'PRE_PROCESSED' && (
                        <Badge
                            bg="primary"
                        >Processed - needs loading</Badge>
                    )}
                    {file.processing_status === 'LOADED' && (
                        <Badge
                            bg="success"
                        >Loaded</Badge>
                    )}
                </td> */}
                <td className="table-action text-end">
                </td>
                
                
            </tr>
        );
    }
}

interface State {
    loading: boolean;
    loads: DataLoad[];
    error: string|null;
    showUploader: boolean;
    confirmDeleteFile?: File;
}
interface Props {
    sourceId?: string;
}

export default class DataLoadList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            loads: [],
            error: null,
            showUploader: false,
            confirmDeleteFile: undefined
        };
    }

    render() {
        let innerContent;
        if (this.state.loading) {
            innerContent = (
                <div>
                    <Spinner animation="border" role="status">
                    </Spinner>
                    <span>Loading...</span>
                </div>
                
            );
        } else if (this.state.loads.length) {
            innerContent = (
                <>
                <h4 className="mt-4">Syncs</h4>
                <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                        <tbody>
                            {this.state.loads.map(dl => {
                                return (<DataLoadRow load={dl} key={dl.id} />);
                            })}
                        </tbody>
                        
                    </table>
                </div>
                </>
            )
        } else {
            innerContent = (
                <div className="alert alert-info">You haven't synced this source yet.</div>
            )
        }

        return (
            <>
                {innerContent}
            </>
        )

    }

    componentDidMount() {
        this.loadDataLoads();

    }


    reloadTriggered = (data: any) => {
        this.loadDataLoads();
    }
    

    private async loadDataLoads(reloadSourceData: boolean = false) {
        this.setState({
            loading: true,
        });

        let filter;

        if (this.props.sourceId) {
            filter = {
                'source_id': {
                    '$eq': this.props.sourceId as string,
                }
            }
        }

        // get the latest 10 data loads
        const resp = await DataLoadORM.find(filter, 10, 1, '-started_at');
        this.setState({
            loading: false,
            loads: resp.records.sort((a, b) => {
                if (a.started_at > b.started_at) {
                    return -1;
                }
                return 1;
            }),
        });
    }
}