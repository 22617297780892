import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { number, string } from "prop-types";
import { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { PipelineStepConfig } from "../../models/pipeline";
import { PipelineExecution } from "../../models/pipelineExecution"
import PipelineStepComponent from "../../pages/PipelineConfiguration/Step.component";
import { parseDateString } from "../../services/time.service";
import { CardHeader } from "../card/Card.component";

interface Props {
    pipelineId: string;
    // Optionally override the step configuration we use (otherwise we fall back to the pipeline execution). This allows us to handle the edit/reorder use cases where another component can manage the state of the steps
    pipelineExecution?: PipelineExecution;
    stepConfiguration?: PipelineStepConfig[];

    // Indicates whether we allow users to edit the configuration, move steps around, etc.
    allowEdits: boolean;

    running?: boolean;

    autoLoadOutput?: boolean;


    onConfigurationChange?: (stepIdx: number, key: string, value: any) => void;
    onAddStep?: (atIndex: number, stepType: 'DEDUPE' | 'FILTER' | 'MAP') => void;
    onDeleteStep?: (idx: number) => void;
    onMoveUp?: (idx: number) => void;
    onMoveDown?: (idx: number) => void;

    // When editing the pipeline config we want to allow the user to trigger a run from any step.
    onRunPipeline?: (toIndex: number, callback?: (execution: PipelineExecution) => void) => void;
}


const PipelineStepsComponent = (props: Props) => {
    let stepConfiguration: PipelineStepConfig[] = [];

    const [loadingResults, setLoadingResults] = useState(false);
    const [resultsError, setResultsError] = useState('');

    const [newPipelineStepIdx, setNewPipelineStepIdx] = useState(-1);
    

    if (props.stepConfiguration) {
        stepConfiguration = props.stepConfiguration;
    } else if (props.pipelineExecution) {
        stepConfiguration = props.pipelineExecution.steps.map(s => s.step_config);
    } else {
        throw Error('PipelineStepsComponent must either have a step configuration or pipeline execution passed in.');
    }

    

    const updateStep = (stepIdx: number, key: string, value: any) => {
        console.log('Updating', stepIdx, key, value);
        if (props.onConfigurationChange) {
            props.onConfigurationChange(stepIdx, key, value);
        }
    }

    const getStatus = (idx: number) => {
        if (props.pipelineExecution) {
            try {
                const step = props.pipelineExecution.steps[idx];
                return step.status;
            } catch (err) {
                return undefined;
            }
        }
        return undefined;
    }

    const getLastRun = (idx: number) => {
        if (props.pipelineExecution) {
            try {
                const step = props.pipelineExecution.steps[idx];
                return parseDateString(step.completed_at as string|undefined);
            } catch (err) {
                return undefined;
            }
        }
        return undefined;
    }

    const getTotalRecords = (idx: number) => {
        if (props.pipelineExecution) {
            try {
                const step = props.pipelineExecution.steps[idx];
                if (step.result_stats) {
                    return step.result_stats['count'];
                }
                return undefined;
            } catch (err) {
                console.error(err);
                return undefined;
            }
        }
        return undefined;
    }

    const getError = (idx: number) => {
        if (props.pipelineExecution) {
            try {
                const step = props.pipelineExecution.steps[idx];
                return step.error;
            } catch (err) {
                return undefined;
            }
        }
        return undefined;
    }

    const reloadOutput = (idx: number) => {
        if (idx >= stepConfiguration.length) {
            throw Error('Cannot run up to non existent index');
        }
        setLoadingResults(true);
        if (props.onRunPipeline) {
            props.onRunPipeline(idx, (execution: PipelineExecution) => {
                setLoadingResults(false);
            });
        }
    }

    const addStep = (idx: number) => {
        setNewPipelineStepIdx(idx);
    }

    const deleteStep = (idx: number) => {
        if (props.onDeleteStep) {
            props.onDeleteStep(idx);
        }
    }

    const confirmAddStep = (stepType: 'DEDUPE' | 'FILTER' | 'MAP') => {
        if (props.onAddStep) {
            props.onAddStep(newPipelineStepIdx, stepType);
        }
        setNewPipelineStepIdx(-1);
    }

    const moveUp = (idx: number) => {
        if (props.onMoveUp) {
            props.onMoveUp(idx);
        }
    }

    const moveDown = (idx: number) => {
        if (props.onMoveDown) {
            props.onMoveDown(idx);
        }
    }

    const renderNewPipelineStepModal = () => {
        return <Modal show={newPipelineStepIdx >= 0} onHide={() => setNewPipelineStepIdx(-1)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Step</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Dropdown>
                    <Dropdown.Toggle>Choose Step Type</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => confirmAddStep('MAP')}>MAP</Dropdown.Item>
                        <Dropdown.Item onClick={() => confirmAddStep('FILTER')}>FILTER</Dropdown.Item>
                        <Dropdown.Item onClick={() => confirmAddStep('DEDUPE')}>DEDUPE</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Modal.Body>
            
        </Modal>
    }

    return (
        <>
            {renderNewPipelineStepModal()}
            {stepConfiguration.map((s, idx) => {
                let previousStepStatus: string = '';
                if (idx > 0 && !!props.pipelineExecution && props.pipelineExecution.steps[idx - 1]) {
                    previousStepStatus = props.pipelineExecution.steps[idx - 1].status;
                }
                return (
                    <>
                        <div className="row">
                            <div className="col-12">
                                <PipelineStepComponent 
                                    allowMoveUp={idx > 1 && idx <= stepConfiguration.length - 2}
                                    allowMoveDown={idx > 0 && (idx < stepConfiguration.length - 2)}
                                    allowDelete={idx > 0 && (idx <= stepConfiguration.length - 2)} 
                                    status={getStatus(idx)}
                                    stepIndex={idx} 
                                    totalRecordsOutput={getTotalRecords(idx)}
                                    lastRunAt={getLastRun(idx)}
                                    onChange={(key: string, val: any) => updateStep(idx, key, val)} 
                                    stepConfig={s}
                                    error={getError(idx)}
                                    onRemove={() => deleteStep(idx)}
                                    allowInteractivity={props.allowEdits}
                                    onReloadOutput={() => reloadOutput(idx)}
                                    onReloadInput={() => reloadOutput(idx - 1)}
                                    loadingResults={loadingResults}
                                    onMoveUp={() => moveUp(idx)}
                                    onMoveDown={() => moveDown(idx)}
                                    previousStepStatus={previousStepStatus}
                                    pipelineId={props.pipelineId}
                                    pipelineExecutionId={!!props.pipelineExecution ? (props.pipelineExecution.id as string) : undefined}
                                />
                            </div>
                        </div>
                        {props.allowEdits && (idx < (stepConfiguration.length - 1)) && (
                            <div className="d-grid gap-2 m-1">
                                <button 
                                    className="btn btn-outline-light add-step-button"
                                    onClick={() => addStep(idx)}
                                >
                                    Add Step
                                </button>
                            </div>
                        )}
                        {!props.allowEdits && (
                            <div className="mb-3"></div>
                        )}
                    </>
                    
                )
            })}
        </>
    );

}

export default PipelineStepsComponent;