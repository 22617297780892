import { ColumnPreference, SourceRecordType } from "@models/source";
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useCallback } from "react";
import { Form } from "react-bootstrap";
import produce from "immer";
import TrackingService, {Events} from "@services/tracking.service";

interface Props {
    columnPreferences: ColumnPreference[];
    onChangeColumnPreferences: (newPreferences: ColumnPreference[]) => void;
}

const Styles = styled.div`
.header {
    display: flex;
    line-height: 21px;
    font-family: "Poppins";
    .column-container {
        width: 50%;
    }

    .include-container {
        text-align: right;
        width: 50%;
        font-size: 13px;
    }


}
`

interface DraggableColumnProps {
    columnPreference: ColumnPreference;
    index: number;
    onToggleVisibility: (visible: boolean) => void;
}

const DraggableColumnStyles = styled.div`
width: 100%;

padding: 8px 0px;

display: flex;
user-select: none;


&:hover {
    background-color: var(--ct-light-gray);
    cursor: move;

    .icon-container {
        visibility: visible;
    }
}

.name-container {
    width: 80%;
    text-overflow: ellipsis;
    padding-left: .5rem;
    padding-right: .5rem;
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;

}

.icon-container {
    visibility: hidden;
}

.checkbox-container {
    text-align: right;
    width: 20%;
    padding-right: .5rem;
}
`
const DraggableColumnContainer = styled.div``

const DraggableColumn = (props: DraggableColumnProps) => {
    const onToggle = useCallback((isChecked: boolean) => {
        props.onToggleVisibility(isChecked);

    }, [props.onToggleVisibility]);
    return (
        <Draggable draggableId={props.columnPreference.key} key={props.columnPreference.key} index={props.index}>
            {provided => (
                <DraggableColumnStyles
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    key={props.columnPreference.key}
                >
                    <div className="icon-container">
                        <i className="mdi mdi-drag"></i>
                    </div>
                    <div className="name-container">
                        {props.columnPreference.key}

                    </div>
                    <div className="checkbox-container">
                        <Form.Check checked={props.columnPreference.visible} onChange={(e) => onToggle(e.target.checked)}/>
                    </div>
                    <div className="actions-container"></div>
                </DraggableColumnStyles>
            )}
        </Draggable>
    )
}

const SourceRecordTypeColumnSelector = (props: Props) => {
    /**
     * {
     *   "draggableId": "ACTIVITY_CT",
     *   "type": "DEFAULT",
     *   "source": {
     *       "index": 0,
     *       "droppableId": "main"
     *   },
     *   "reason": "DROP",
     *   "mode": "FLUID",
     *   "destination": {
     *       "droppableId": "main",
     *       "index": 1
     *   },
     *   "combine": null
     *  }
     */
    const onReorderColumns = useCallback((res: any) => {
        if (!res.destination) {
            return;
        }

        if (
            res.destination.droppableId === res.source.droppableId &&
            res.destination.index === res.source.index
        ) {
            // Didn't do anything
            return;
        };

        const newOrder = [...props.columnPreferences];
        const movingItem = newOrder[res.source.index];
        newOrder.splice(res.source.index, 1);
        newOrder.splice(res.destination.index, 0, movingItem);

        console.log('New order!', newOrder);

        
        props.onChangeColumnPreferences(newOrder);
        TrackingService.track_event(Events.SRT_COLUMN_PREFS_REORDERED);

    }, [props.onChangeColumnPreferences, props.columnPreferences]);

    const onChangeVisibility = useCallback((idx: number, visible: boolean) => {
        const newColumns = produce(props.columnPreferences, draft => {
            draft[idx].visible = visible;
        });

        console.log('Changed visibility', newColumns, visible);

        props.onChangeColumnPreferences([...newColumns]);
        TrackingService.track_event(Events.SRT_COLUMN_VISIBILITY_TOGGLED, {
            visible: visible
        });
    }, [props.onChangeColumnPreferences, props.columnPreferences]);
    return <Styles>
        <div className="header">
            <div className="column-container">
                Columns
            </div>
            <div className="include-container">
                Include
            </div>
        </div>
        <DragDropContext
            onDragEnd={onReorderColumns}
        >
            <Droppable droppableId="main">
                {provided => (
                    <DraggableColumnContainer
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {props.columnPreferences.map((cp: ColumnPreference, idx: number) => <DraggableColumn
                                columnPreference={cp}
                                onToggleVisibility={(visible: boolean) => onChangeVisibility(idx, visible)}
                                index={idx}
                            />)}
                        {provided.placeholder}
                    </DraggableColumnContainer>
                )}
            </Droppable>

        </DragDropContext>
    </Styles>
}

export default SourceRecordTypeColumnSelector;