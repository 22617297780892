import { PipelineExecution } from "@models/pipelineExecution";
import { Badge, Spinner } from "react-bootstrap";

interface Props {
    execution: PipelineExecution;
}

const PipelineExecutionStatus = (props: Props) => {
    switch (props.execution.status) {
        case 'QUEUED':
            return <Badge bg="info">Queued</Badge>;
        case 'COMPLETE':
            return <Badge bg="success">Complete</Badge>;
        case 'IN_REVIEW':
            return <Badge bg="warning">In Review</Badge>;
        case 'RUNNING':
            return <Badge bg="primary"><Spinner size="sm"/></Badge>
        case 'ERROR':
            return <Badge bg="danger">Failed</Badge>
    }
    return <></>;
}

export default PipelineExecutionStatus;