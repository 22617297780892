import DefaultAuditLogEntry from './DefaultAuditLogEntry.component';
import EventActorLabel from '@components/auditLog/partials/EventActorLabel.component';
import { timeAgo } from '@services/time.service';

interface State {}



export default class CreateResourceLogEntry extends DefaultAuditLogEntry<State> {
    getIcon() {
        return "mdi-plus"
    }

    getMessage() {
        return (
            <div className="d-flex align-items-center" style={{height: '100%'}}>
                <span>
                    <EventActorLabel actor={this.props.entry.actor} /> created {this.props.entry.model_class} {this.props.entry.model_name} - <span title={this.props.entry.created_at.toLocaleString()}>{timeAgo(this.props.entry.created_at)}</span>
                </span>
            </div>
            
        )
    }

    getActions() {
        return (
           <></>
        )
    }
}