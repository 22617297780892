import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { useParams } from "react-router";
import PageTitle from "@components/pageTitle/PageTitle.component";
import DataMartORM, { DataMart } from '@models/dataMart';
import Editor from "@monaco-editor/react";
import { Form } from 'react-bootstrap';
import { SimpleDataTable } from "@components/datatable/DataTable.component";
import AsyncButton from "@components/button/AsyncButton.component";
import toast from "@services/toast.service";
import { Allotment } from "allotment";
import { getErrorMessage } from "@services/errors.service";
import { useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { useBusinessObjects, useDataMarts, usePipelines } from "@stores/data.store";
import ConfirmationModal from "@components/alert/ConfirmationModal.component";
import LoadingCard from "@components/card/LoadingCard.component";
import SnowflakeLoader from "@components/loaders/SnowflakeLoader.component";
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import TrackingService, {Events} from "@services/tracking.service";



const ReportPage = () => {
    const navigate = useNavigate();
    const { dataMartId } = useParams();
    if (!dataMartId) {
        throw Error('Missing ID');
    }
    const [report, setReport] = useImmer<DataMart>({
        id: null,
        name: 'New Report',
        description: '',
        sql: `SELECT *
FROM (VALUES  
    (1,'one'),
    (2,'two'),
    (3,'three') 
) AS exampl_rows (num, txt)`
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [loadingData, setLoadingData] = useState(false);
    const [results, setResults] = useState<any[]>([]);
    const [resultsShape, setResultsShape] = useState<any>({});
    const [originalSql, setOriginalSql] = useState('');
    const [isSqlDirty, setIsSqlDirty] = useState(false);
    const [saving, setSaving] = useState(false);
    const [running, setRunning] = useState(false);
    const editorRef = useRef<any>();
    const [completionDisposable, setCompletionDisposable] = useState<any>();

    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const businessObjects = useBusinessObjects();
    const datamarts = useDataMarts();

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [downloadLocation, setDownloadLocation] = useState('');
    const [downloadError, setDownloadError] = useState('');

    const loadData = async () => {
        setLoading(true);
        setError('');
        try {
            const report = await DataMartORM.findById(dataMartId as string);
            setReport(report);
            setOriginalSql(report.sql);
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (dataMartId !== 'new') {
            loadData();
            loadReport();
        }else{
            setOriginalSql(report.sql);
        }
    }, [dataMartId]);

    useEffect(() => {
        return () => {
            if (
                completionDisposable?.dispose &&
                typeof completionDisposable.dispose === "function"
            ) {
                completionDisposable.dispose();
            }
        };
    }, [completionDisposable]);


    const availableMarts = useMemo(() => {
        if(!datamarts.data){
            return [];
        }else{
            return datamarts.data!.filter((dm) => dm.id != dataMartId)
        }   

    }, [datamarts, dataMartId])

    const editorDidMount = (editor: any, monacoParam: any) => {
        editorRef.current = editor;

        editor.getModel().onDidChangeContent((event: any) => {
            setIsSqlDirty(report.sql != editor.getValue().trim());
        })

        editor.addAction({
            id: "runCurrentSql",
            label: "Run SQL",
            keybindings: [
                monacoParam.KeyMod.CtrlCmd | monacoParam.KeyCode.Enter,
            ],
            precondition: null,
            contextMenuGroupId: "navigation",
	        contextMenuOrder: 1.5,
            run: () => {
                runSQL();
            },
        });

        editor.addAction({
            id: "revertCurrentSql",
            label: "Revert SQL",
            keybindings: [
                monacoParam.KeyMod.WinCtrl | monacoParam.KeyCode.KeyX,
            ],
            precondition: null,
            contextMenuGroupId: "navigation",
	        contextMenuOrder: 1.6,
            run: () => {
                revertSQL();
            },
        });

        editor.addAction({
            id: "saveCurrentSql",
            label: "Save Changes",
            keybindings: [
                monacoParam.KeyMod.WinCtrl | monacoParam.KeyCode.KeyS,
            ],
            precondition: null,
            contextMenuGroupId: "navigation",
	        contextMenuOrder: 1.7,
            run: () => {
                save();
            },
        });

        setCompletionDisposable(
            monacoParam.languages.registerCompletionItemProvider('*', {
                provideCompletionItems: function(model: any, position: any) {

                    var word = model.getWordUntilPosition(position);
                    var range = {
                        startLineNumber: position.lineNumber,
                        endLineNumber: position.lineNumber,
                        startColumn: word.startColumn,
                        endColumn: word.startColumn
                    };

                    // add BO objects
                    const suggestions: any[] = [];
                    businessObjects.data!.map((bo) => {
                        suggestions.push({
                            label: `${bo.name}`,
                            kind: monacoParam.languages.CompletionItemKind.Class,
                            insertText: `{{ ref('${bo.table_name}') }}`,
                            detail: 'Business Object',
                            range: range
                        });

                        bo.fields.map((field) => {
                            suggestions.push({
                                label: `${bo.name}.${field.name}`,
                                kind: monacoParam.languages.CompletionItemKind.Variable,
                                insertText: `${field.name}`,
                                detail: `${field.type} Field`,
                                range: range
                            });
                        })

                    });

                    availableMarts!.map((dm) => {
                        suggestions.push({
                            label: `${dm.name}`,
                            kind: monacoParam.languages.CompletionItemKind.Class,
                            insertText: `{{ ref('${dm.table_name}', 'report') }}`,
                            detail: 'Report',
                            range: range
                        });
                    });

                    return {
                        suggestions: suggestions
                    };
                }
            })
        );

    }

    const loadReport = async () => {
        setLoadingData(true);
        setError('');

        try {
            const data = await DataMartORM.getData(dataMartId);
            setResults(data);
            DataMartORM.getShape(dataMartId).then((shape) => {
                setResultsShape(shape);
            });
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoadingData(false);
        }
    }

    const runSQL = async () => {
        const sql = editorRef.current.getValue();
        setRunning(true);
        setError('');

        try {
            const runQuery = ApiService.getInstance().request('POST', '/sql/execute', {
                'sql': sql,
            });

            const results = await runQuery as ListRecordsResponse<any>;
            setResults(results.records);

            try{
                const countSql = `with query_data as (
                    ${sql}
                )
                select count(*) as total_records from query_data
            `
                const countQuery = ApiService.getInstance().request('POST', '/sql/execute', {
                    'sql': countSql,
                });
                const countResults = await countQuery as ListRecordsResponse<any>;
                setResultsShape(countResults.records[0]);
                
            }catch(ex) {
                // pass
            }
            TrackingService.track_event(Events.REPORT_RUN_CLICK);

        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setRunning(false);
        }
    

    }

    const save = async () => {
        setSaving(true);
        
        try {
            let id: string | null;
            if (dataMartId === 'new') {
                id = null;
            } else {
                id = dataMartId as string;
            }
            const newMart = {
                id: id,
                name: report.name,
                sql: editorRef.current.getValue(),
                description: report.description,
            };

            const result = await DataMartORM.save(newMart);
            toast('success', 'Success!', 'Report saved.');
            if (dataMartId == "new") {
                navigate(`/reports/${result.id}`, {replace: true});
            }else{
                loadReport();
            }
            setIsSqlDirty(false);
            TrackingService.track_event(Events.REPORT_SAVE_CLICK);
            
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }

    }

    const downloadReport = useCallback(async () => {
        if (!report) {
            return;
        }
        setDownloadError('');
        setShowDownloadModal(true);
        setLoadingDownload(true);
        setDownloadLocation('');
        TrackingService.track_event(Events.REPORT_DOWNLOAD_CLICK);

        try {
            const override_query = (isSqlDirty) ? editorRef.current.getValue() : null;
            const download = await DataMartORM.getDownloadUrl(dataMartId, override_query);
            setDownloadLocation(download.url);
            
        } catch(err) {
            setDownloadError(getErrorMessage(err));
        } finally {
            setLoadingDownload(false);
        }
        
    }, [dataMartId, isSqlDirty])


    const handleFormChange = useCallback((event: ChangeEvent) => {
        const target = event.target as HTMLInputElement;

        if (target) {
            const value = target.value;

            const name: 'name' | 'sql' | 'description' = target.name as 'name' | 'sql' | 'description';

            
            setReport((draft: DataMart) => {
                draft[name] = value;
            })
        }
    }, [report])

    const revertSQL = useCallback(async () => {
        editorRef.current.setValue(originalSql);
        setIsSqlDirty(false);
        TrackingService.track_event(Events.REPORT_REVERT_CLICK);
    },[editorRef, originalSql]);

    const insertText = useCallback((text: string) => {
        var selection = editorRef.current.getSelection();
        var id = { major: 1, minor: 1 };             
        var op = {identifier: id, range: selection, text: text, forceMoveMarkers: true};
        editorRef.current.executeEdits("my-source", [op]);
    }, [editorRef]);


    const renderResults = () => {
        if (loadingData || running) {
            return <LoadingCard action="Loading Results" />;
        }
        return <Allotment vertical>
                    <Allotment.Pane maxSize={50}>
                            <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Download</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="text-center">
                                    {loadingDownload && <div>
                                            <h2 className="text-center">Preparing your download...</h2>
                                            <SnowflakeLoader/>
                                        </div>}
                                    {!loadingDownload && downloadError && (
                                        <div className="alert alert-danger">{downloadError}</div>
                                    )}
                                    {!loadingDownload && downloadLocation && !downloadError && (
                                        <div>
                                            <span style={{fontSize: 55}}><i className="mdi mdi mdi-download"></i></span><br />
                                            <a target="_blank" className="btn btn-pliable" href={downloadLocation} >Click here to download your file.</a>
                                        </div>
                                    )}
                                </Modal.Body>
                            </Modal>

                            <div className="d-flex">
                                <div className="p-1 flex-fill">
                                    { isSqlDirty && <>
                                        <AsyncButton
                                            icon="mdi mdi-play-box"
                                            text="Run"
                                            variant="primary"
                                            loading={running}
                                            onClick={() => runSQL()}
                                        />
                                        <AsyncButton
                                            icon="mdi mdi-restart"
                                            text="Revert Changes"
                                            variant="primary"
                                            loading={running}
                                            onClick={() => revertSQL()}
                                        />

                                        <AsyncButton
                                            icon="mdi mdi-content-save"
                                            text="Save Changes"
                                            variant="success"
                                            loading={saving}
                                            onClick={() => save()}
                                        />
                                    </>}

                                </div>
                                <div className="p-1 pt-2 bd-highlight">{results.length > 0 && <>Showing {results.length} of {resultsShape.total_records} records</> }</div>
                                <div className="p-1 bd-highlight">
                                    {results.length > 0 &&
                                        <AsyncButton
                                            icon="mdi mdi-download"
                                            text="Download All"
                                            variant="light"
                                            loading={loadingData}
                                            onClick={() => downloadReport()}
                                        />
                                    }   
                                </div>
                            </div>

                    </Allotment.Pane>
                    <Allotment.Pane>
                        { error && <p className="alert alert-danger">{error}</p> }
                        { !error && <div className="h-100 w-100" style={{overflowX: 'scroll', overflowY: 'scroll'}}>
                            <SimpleDataTable data={results}/>
                        </div>}
                        
                    </Allotment.Pane>
            </Allotment>

    }

    const renderSidebar = () => {
        return <div className="p-3">
            <Form.Group className="mb-1">
                <Form.Control type="text" name="name" onChange={(e) => handleFormChange(e)} value={report.name}/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Control as="textarea" placeholder="Description" name="description" onChange={(e) => handleFormChange(e)} value={report.description}/>
            </Form.Group>
            
            <AsyncButton
                icon="mdi mdi-content-save"
                text="Save"
                variant="success"
                loading={saving}
                onClick={save}
            />

            <hr />
            <h5>Business Objects</h5>
            {businessObjects.isLoading && <Spinner size="sm"/>}
            {businessObjects.data && (
                <ul>
                    {businessObjects.data.map(bo => {
                        return <li>
                            <a role="button" onDoubleClick={() => insertText(`{{ ref('${bo.table_name}') }}`)} title={`Double Click to place ${bo.name} in editor`}>{bo.name}</a>
                        </li>;
                    })}
                </ul>
            )}

            <hr />
            <h5>Reports</h5>
            {datamarts.isLoading && <Spinner size="sm"/>}
            {datamarts.data && (
                <ul>
                    {availableMarts.map(dm => {
                        return <li>
                            <a role="button" onDoubleClick={() => insertText(`{{ ref('${dm.table_name}', 'report') }}`)} title={`Double Click to place ${dm.name} in editor`}>{dm.name}</a>
                        </li>;
                    })}
                </ul>
            )}

            <hr />
            <div className="section">
                <ConfirmationModal
                    header="Delete data source?"
                    confirmationButtonText="Delete"
                    message={`To delete this data source, please type "${report.name}" in the field below:`}
                    show={!!confirmDelete}
                    typedValueExpectation={report.name}
                    onClose={() => setConfirmDelete(false)}
                    confirmationButtonVariant="danger"
                    onConfirm={() => doDeleteReport()}
                />
                <a role="button" className="btn btn-danger" onClick={() => setConfirmDelete(true)}>Delete Report</a>
            </div>
        </div>
    }

    const doDeleteReport =  useCallback(async () => {
        const result = await DataMartORM.delete(report as DataMart);
        navigate('/reports');
    }, [report]);

    const render = () => {
        if (loading) {
            return <Spinner/>
        } else if (report) {
            return (
                <div>
                    <PageTitle title={dataMartId === 'new' ? 'New Report' : 'Edit Report'}
                        breadcrumbs={[
                            {
                                title: 'Reports',
                                path: '/reports',
                            }, {
                                title: report.name,
                            }
                        ]}
                    ></PageTitle>
                    <div className="row">
                        <div className="col-12">
    
                            <div className="card">
                                <div className="row">
                                    <div className="col-12" style={{height:'calc(100vh - 75px)'}}>
                                        <Allotment>
                                            <Allotment.Pane maxSize={300} minSize={200}>
                                                {renderSidebar()}
                                            </Allotment.Pane>
                                            <Allotment.Pane>
                                                <Allotment vertical>
                                                    <Allotment.Pane>
                                                    {(businessObjects.isLoading || datamarts.isLoading) && <Spinner size="sm"/>}
                                                    {businessObjects.data && datamarts.data &&  (
                                                        <Editor
                                                            className="mt-3 mb-3"
                                                            height="90vh"
                                                            defaultLanguage="sql"
                                                            defaultValue={report.sql}
                                                            onMount={(editor, monaco) => editorDidMount(editor, monaco)}
                                                        />
                                                    )}
                                                    </Allotment.Pane>
                                                    <Allotment.Pane>
                                                        {renderResults()}
                                                    </Allotment.Pane>
                                                </Allotment>

                                            </Allotment.Pane>
                                            
                                        </Allotment>      
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            );
        }

        return <p>Error</p>

        
    }

    return render();
}

export default ReportPage;