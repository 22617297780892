import RelationshipsList from "@components/businessObjects/RelationshipsList.component";
import SaveButton from "@components/button/SaveButton.component";
import { BusinessObject, BusinessObjectRelationship, BusinessObjectRelationshipORM } from "@models/businessObject";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { useBusinessObjects } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import Select from 'react-select';
import PageTitle from "@components/pageTitle/PageTitle.component";


interface RelationshipAsItPertainsToThisBusinessObject {
    relationshipId: string;
    relatedBusinessObjectId: string;
    relationshipType: 'HAS_MANY' | 'BELONGS_TO';
    foreignKeyName: string;
}


const BusinessObjectRelationshipsPage = () => {
    const businessObjects = useBusinessObjects();
    const { businessObjectId } = useParams();
    const businessObject = useMemo(() => {
        if (businessObjects.data) {
            return businessObjects.data.find(bo => bo.id === businessObjectId);
        }
    }, [businessObjectId, businessObjects.dataUpdatedAt]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [relationships, setRelationships] = useState<BusinessObjectRelationship[]>([]);

    const [parsedRelationships, setParsedRelationships] = useImmer<RelationshipAsItPertainsToThisBusinessObject[]>([]);

    const [saving, setSaving] = useState(false);

    const loadRelationships = useCallback(async () => {
        setLoading(true);
        setError('');

        try {
            const results = await BusinessObjectRelationshipORM.find({
                '$or': [
                    {
                        'child_business_object_id': businessObjectId
                    }, {
                        'parent_business_object_id': businessObjectId
                    }
                ]
            });

            setRelationships(results.records);

            const relationshipsAsItPertainsToThisBusinessObject = results.records.map((rel): RelationshipAsItPertainsToThisBusinessObject => {
                let relatedObjectId: string;
                let relationshipType: 'HAS_MANY' | 'BELONGS_TO';
                if (rel.parent_business_object_id === businessObjectId) {
                    relatedObjectId = rel.child_business_object_id;
                    relationshipType = 'HAS_MANY';
                } else {
                    relatedObjectId = rel.parent_business_object_id;
                    relationshipType = 'BELONGS_TO';
                }
        
                return {
                    relationshipId: rel.id as string,
                    relatedBusinessObjectId: relatedObjectId,
                    relationshipType: relationshipType,
                    foreignKeyName: rel.child_foreign_key_name ? rel.child_foreign_key_name : 'related_record_uuid',
                };
            });

            setParsedRelationships(relationshipsAsItPertainsToThisBusinessObject);

            
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, [businessObjectId]);

    

    useEffect(() => {
        if (businessObjectId) {
            loadRelationships();

        }
    }, [businessObjectId]);

    const addRelationship = () => {
        setParsedRelationships(draft => {
            draft.push({
                relationshipId: '',
                relatedBusinessObjectId: '',
                relationshipType: 'BELONGS_TO',
                foreignKeyName: '',
            });
        });
    };

    const removeRelationship = async (idx: number) => {
        const relationshipId = parsedRelationships[idx].relationshipId;
        const relationship = relationships.find(r => r.id === relationshipId);

        if (relationship) {
            await BusinessObjectRelationshipORM.delete(relationship);
        }
        setParsedRelationships(draft => {
            draft.splice(idx, 1);
        });
    };

    const businessObjectOptions = useMemo(() => {
        if (!businessObjects.data) {
            return [];
        }
        return businessObjects.data.filter(bo => bo.id !== businessObjectId).map(bo => {
            return {
                label: bo.name,
                value: bo.id as string,
            }
        });
    }, [businessObjects.dataUpdatedAt, businessObjectId]);

    const onChange = useCallback((idx: number, key: 'relatedBusinessObjectId'|'relationshipType'|'foreignKeyName', value: any) => {
        setParsedRelationships(draft => {
            const rel = draft[idx];
            rel[key] = value;
        });
        
    }, []);

    const parseAndSaveRelationship = useCallback(async (rel: RelationshipAsItPertainsToThisBusinessObject) => {
        if (!businessObjectId) {
            return;
        }
        const converted: BusinessObjectRelationship = {
            id: rel.relationshipId === '' ? null : rel.relationshipId,
            child_business_object_id: rel.relationshipType === 'HAS_MANY' ? rel.relatedBusinessObjectId : businessObjectId,
            parent_business_object_id: rel.relationshipType === 'BELONGS_TO' ? rel.relatedBusinessObjectId : businessObjectId,
            child_foreign_key_name: rel.foreignKeyName,
            allow_many_children: true,
        }

        await BusinessObjectRelationshipORM.save(converted);


    }, [businessObjectId]);

    const saveRelationships = async () => {
        setSaving(true);

        try {
            await Promise.all(parsedRelationships.map(parseAndSaveRelationship));
            toast('success', 'Success!', 'Relationships have been saved.');
        } catch (err) {
            toast('danger', 'Oops!', `Failed to save relationships: ${getErrorMessage(err)}`);
            console.error(err);
        } finally {
            setSaving(false);
        }
    }

    if (loading) {
        return <Spinner/>;
    } else if (error) {
        return <p className="alert alert-danger">{error}</p>
    }

    const relationshipTypeOptions = [
        {value: 'HAS_MANY', label: 'Has Many'},
        {value: 'BELONGS_TO', label: 'Belongs To'}
    ];

    return <div className="content-inner has-subnav">
        <PageTitle title="Relationships" subtitle="Define how this business object relates to other objects in your database.">
            <SaveButton
                                loading={saving}
                                onClick={() => saveRelationships()}
                            />
        </PageTitle>
        <div className="section">
        <table className="table table-bordered table-centered table-fixed">
            <thead className="table-light">
                <tr>
                    <th>Relationship Type</th>
                    <th>Related Object</th>
                    <th>Key Name</th>
                    <th style={{width:'80px'}} className="text-center">
                        <a className="action-icon" role="button" onClick={() => addRelationship()}>
                            <i className="mdi mdi-plus"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                {parsedRelationships.map((rel, idx) => {


                    return <tr>
                        <td>
                            <Select 
                                options={relationshipTypeOptions} 
                                value={relationshipTypeOptions.find(opt => opt.value === rel.relationshipType)}
                                onChange={o => onChange(idx, 'relationshipType', o ? o.value : undefined)}
                            />
                        </td>
                        <td>
                            <Select 
                                options={businessObjectOptions} 
                                value={businessObjectOptions.find(opt => opt.value === rel.relatedBusinessObjectId)}
                                onChange={o => onChange(idx, 'relatedBusinessObjectId', o ? o.value : undefined)}
                            />
                        </td>
                        
                        <td>
                            <Form.Control
                                value={rel.foreignKeyName}
                                onChange={(e) => onChange(idx, 'foreignKeyName', e.target.value)}
                            />
                        </td>
                        <td className="text-center">
                            <a role="button" className="action-icon" onClick={() => removeRelationship(idx)}>
                                <i className="mdi mdi-delete"></i>
                            </a>
                            <Link to={`/business-objects/${businessObjectId}/relationships/${rel.relationshipId}`} className="action-icon">
                                <i className="mdi mdi-sync-circle"></i>
                            </Link>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
        </div>
        
    </div>
    
    
    
}

export default BusinessObjectRelationshipsPage;