import { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageTitle from "../../components/pageTitle/PageTitle.component";
import PipelineORM, { Pipeline, PipelineStepConfig } from '../../models/pipeline';
import { useHotkeys } from 'react-hotkeys-hook';
import ApiService, { SingleRecordResponse } from "../../services/api/api.service";
import { getErrorMessage } from "../../services/errors.service";
import toast from "../../services/toast.service";
import AsyncButton from "../../components/button/AsyncButton.component";
import ConfirmationModal from "../../components/alert/ConfirmationModal.component";
import PipelineExecutionORM, { PipelineExecution } from "../../models/pipelineExecution";
import { sleep } from "../../services/bg.service";
import PipelineStepsComponent from "../../components/pipelines/PipelineSteps.component";



interface PipelineStepMeta {
    [key: string]: number;
}


interface PipelineExecutionResponse {
    records: any[];
    meta: PipelineStepMeta;
}
const PipelineConfigurationPage = () => {
    const { pipelineId } = useParams();
    const [loading, setLoading] = useState(true);
    const [pipeline, setPipeline] = useState<Pipeline|undefined>(undefined);
    const [activeStepIndex, setActiveStepIndex] = useState(-1);

    const [currentExecution, setCurrentExecution] = useState<PipelineExecution|undefined>(undefined);
    
    const [steps, setSteps] = useState<PipelineStepConfig[]>([]);
    const [error, setError] = useState('');
    const [search, setSearch] = useSearchParams();
    const [results, setResults] = useState<any[]>([]);
    const [meta, setMeta] = useState<PipelineStepMeta>({});

    const [saving, setSaving] = useState(false);

    // toggle managed pipeline
    useHotkeys('ctrl+m', () => {
        if (!pipeline) {
            throw Error('Pipeline is not yet instantiated!');
        }
        setPipeline({...pipeline, managed: !pipeline?.managed})
    }, [pipeline]);


    const [deletingStepIndex, setDeletingStepIndex] = useState(-1);

    const navigate = useNavigate();
    const [loadingResults, setLoadingResults] = useState(false);

    const sourceId = search.get('source_id') || undefined;
    const sourceRecordTypeId = search.get('source_record_type_id') || undefined;

    useEffect(() => {
        const loadData = async () => {
            if (!pipelineId) {
                setPipeline({
                    id: null,
                    name: 'my_new_pipeline',
                });
                setSteps([
                    {
                        name: 'Load Source Data',
                        step_type: 'SOURCE',
                        config: {
                            source_id: sourceId,
                            source_record_type_id: sourceRecordTypeId,
                        }
                    }, {
                        name: 'Map to Business Object',
                        step_type: 'OUTPUT',
                        config: {

                        }
                    }
                ]);
            } else {
                const pipeline = await PipelineORM.findById(pipelineId as string);
                setPipeline(pipeline);
                if (pipeline.steps && pipeline.steps.length > 0) {
                    setSteps(pipeline.steps);

                } else {
                    setSteps([
                        {
                            name: 'Load Source Data',
                            step_type: 'SOURCE',
                            config: {
                                source_id: sourceId,
                                source_record_type_id: sourceRecordTypeId,
                            }
                        }, {
                            name: 'Map to Business Object',
                            step_type: 'OUTPUT',
                            config: {
    
                            }
                        }
                    ]);
                }
            }
            setLoading(false);
        }

        loadData();
    }, [pipelineId]);


    const updateStep = (stepIdx: number, key: string, value: any) => {
        if (!pipeline) {
            throw Error('No pipeline loaded yet, should not be calling updateStep');
        }

        console.log('Updating step', stepIdx, key, value);

        const newSteps: PipelineStepConfig[] = [];

        steps.forEach((s, idx) => {
            if (idx === stepIdx) {
                if (key === 'name') {
                    newSteps.push({
                        ...s,
                        name: value as string,
                    });
                } else {
                    newSteps.push({
                        ...s,
                        config: {
                            ...s.config,
                            [key]: value,
                        }
                    })
                }

                console.log('Updated step data:', newSteps[newSteps.length - 1]);
                
            } else {
                newSteps.push(s);
            }
        })
        setSteps([...newSteps]);
    }

    const moveUp = (stepIndex: number) => {
        if (stepIndex > 1) {
            const elementToMove = steps[stepIndex];
            const newSteps = [...steps];
            newSteps[stepIndex] = newSteps[stepIndex - 1]
            newSteps[stepIndex-1] = elementToMove;
            setSteps(newSteps);
        }
    }

    const moveDown = (stepIndex: number) => {
        if (stepIndex < (steps.length - 1)) {
            const elementToMove = steps[stepIndex];
            const newSteps = [...steps];
            newSteps[stepIndex] = newSteps[stepIndex + 1]
            newSteps[stepIndex+1] = elementToMove;
            setSteps(newSteps);
        }
    }

    const updatePipelineName = (name: string) => {
        if (!pipeline) {
            throw Error('Pipeline is not yet instantiated!');
        }
        setPipeline({
            ...pipeline,
            name: name,
        });
    }

    const updatePipelineAutoApprove = (autoApprove: boolean) => {
        if (!pipeline) {
            throw Error('Pipeline is not yet instantiated!');
        }
        setPipeline({
            ...pipeline,
            auto_approve: autoApprove,
        });
    }

    const updateBusinessObjectId = (id: string) => {
        if (!pipeline) {
            throw Error('Pipeline is not yet instantiated!');
        }
        setPipeline({
            ...pipeline,
            business_object_id: id,
        });
    }


    const addStep = (afterIdx: number, stepType: 'DEDUPE' | 'FILTER' | 'MAP') => {
        const newSteps = [...steps];

        if (newSteps.length) {
            newSteps.splice(afterIdx+1, 0, {
                name: '',
                step_type: stepType,
                config: {},
            })
            

            setSteps(newSteps);
        } else {
            throw Error('No steps!');
        }
        
    }

    const removeStep = (stepIdx: number) => {
        setDeletingStepIndex(stepIdx);
    }

    const confirmRemoveStep = () => {
        const newSteps = [...steps];
        newSteps.splice(deletingStepIndex, 1);
        setSteps(newSteps);
        setDeletingStepIndex(-1);
    }

    const waitForExecution = async (executionId: string, callback?: (execution: PipelineExecution) => void) => {
        const execution = await PipelineExecutionORM.findById(executionId);
        setCurrentExecution(execution);
        if (!['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(execution.status)) {
            await sleep(1000);
            waitForExecution(executionId, callback);
        } else if (callback) {
            callback(execution);
        }
    }

    const runPipelineToIndex = async (stepIdx: number, callback?: (execution: PipelineExecution) => void) => {
        /**
         * Later we will be more sophisticated in figuring out if something 
         * upstream changed. For now we wil just run every step up to that point
         */
        setError('');
        setLoadingResults(true);


        try {
            const stepsToRun = steps.slice(0, stepIdx+1);
            const response = await ApiService.getInstance().request('POST', `/pipelines/${pipelineId as string}/run`, {
                'steps': stepsToRun,
                'test_mode': true,
            }) as SingleRecordResponse<PipelineExecution>;
            setCurrentExecution(response.record);

            waitForExecution(response.record.id as string, (execution) => {
                setLoadingResults(false);
                console.log('Done waiting for execution');
                if (callback) {
                    console.log('Calling callback');
                    callback(execution);
                }
            });

        } catch (err) {
            setError(getErrorMessage(err));
            setLoadingResults(false);
        }
    }

    const savePipeline = async () => {
        if (!pipeline) {
            throw Error('Pipeline should be defined');
        }
        pipeline.steps = steps;

        setSaving(true);
        try {
            const result = await PipelineORM.save(pipeline);
            toast('success', 'Success!', 'Pipeline saved.');
            if (!pipelineId) {
                navigate(`/pipelines/${result.id as string}/configuration`);
            }
        } catch (err) {
            toast('danger', 'Error saving pipeline', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
        
    }

    if (loading) {
        return <Spinner/>;
    } else if (pipeline) {
        return <div>
            {pipelineId && (
                <PageTitle title="Edit Pipeline Configuration"
                    breadcrumbs={[
                        {
                            title: 'Pipelines',
                            path: '/pipelines'
                        }, {
                            title: (pipeline.name as string),
                            path: '/pipelines/' + (pipeline.id as string)
                        }, {
                            title: 'Edit Configuration',
                        }
                    ]}
                />
            )}
            {!pipelineId && (
                <PageTitle title="New Pipeline"
                    breadcrumbs={[
                        {
                            title: 'Pipelines',
                            path: '/pipelines'
                        }, {
                            title: 'New Pipeline',
                        }
                    ]}
                />
            )}
            <ConfirmationModal
                show={deletingStepIndex >= 0}
                header="Delete Step?"
                message="Are you sure you want to delete this step?"
                onClose={() => setDeletingStepIndex(-1)}
                onConfirm={() => confirmRemoveStep()}
                confirmationButtonText="Confirm Deletion"
                confirmationButtonVariant="danger"
            />
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <Form.Group className="mb-3">
                                <Form.Label>Pipeline Name</Form.Label>
                                <Form.Control className="input-lg" value={pipeline.name as string} onChange={(e) => updatePipelineName(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check 
                                    type="switch"
                                    
                                    checked={!!pipeline.auto_approve}
                                    onChange={(e) => updatePipelineAutoApprove(!pipeline.auto_approve)}
                                    label="Enable automatic approval"
                                />
                                <Form.Text>Data in pipelines set to auto-approve will be automatically promoted after each pipeline execution. If auto approve is off, you will be prompted to review the pipeline output before it gets promoted.</Form.Text>
                            </Form.Group>
                            

                            <div className="d-grid gap-2">
                                <AsyncButton
                                    variant="success"
                                    disabled={saving}
                                    onClick={() => savePipeline()}
                                    icon="mdi mdi-content-save"
                                    loading={saving}
                                    text="Save Pipeline"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PipelineStepsComponent 
                pipelineId={pipelineId as string}
                stepConfiguration={steps} 
                allowEdits={!pipeline.managed}
                onRunPipeline={(toIdx, callback) => runPipelineToIndex(toIdx, callback)}
                onConfigurationChange={updateStep}
                pipelineExecution={currentExecution}
                running={loadingResults}
                onAddStep={addStep}
                onDeleteStep={removeStep}
                onMoveUp={moveUp}
                onMoveDown={moveDown}
            />
            
            {/* <div className="row">
                <div className="col-12">
                <PipelineStepComponent active={activeStepIndex === 0} allowMoveUp={false} allowMoveDown={false} allowDelete={false} onPreview={() => onPreview(0)} stepNumber={1} onChange={(key: string, val: any) => updateStep(0, key, val)} stepConfig={steps[0]}/>
                </div>
            </div>
            
            {steps.slice(1, steps.length-1).map((s, idx) => {
                return <div className="row">
                    <div className="col-12">
                        <PipelineStepComponent 
                            active={activeStepIndex === (idx + 1)} 
                            allowMoveUp={idx > 0}
                            allowMoveDown={idx < steps.length - 3}
                            allowDelete
                            onMoveUp={() => moveUp(idx + 1)}
                            onMoveDown={() => moveDown(idx + 1)} 
                            onPreview={() => onPreview(idx+1)} 
                            stepNumber={idx + 2} 
                            onChange={(key: string, val: any) => updateStep(idx+1, key, val)} 
                            stepConfig={s}
                            onRemove={() => removeStep(idx+1)}
                        />
                    </div>
                </div>
            })}
            <div className="row">
                <div className="col-4">
                        <Dropdown>
                            <Dropdown.Toggle>Add Processing Step</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => addStep('MAP')}>MAP</Dropdown.Item>
                                <Dropdown.Item onClick={() => addStep('FILTER')}>FILTER</Dropdown.Item>
                                <Dropdown.Item onClick={() => addStep('DEDUPE')}>DEDUPE</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <PipelineStepComponent active={activeStepIndex === (steps.length - 1)} allowMoveUp={false} allowMoveDown={false} allowDelete={false} onPreview={() => onPreview(steps.length - 1)} stepNumber={steps.length} onChange={(key: string, val: any) => updateStep(steps.length - 1, key, val)} stepConfig={steps[steps.length - 1]}/>
                </div>
            </div> */}
        </div>

    }
    return <>Error</>
}

export default PipelineConfigurationPage;