import SnowflakeLoader from "@components/loaders/SnowflakeLoader.component";
import SourceRecordTypeName from "@components/sources/SourceRecordTypeName.component";
import SourceRecordTypeDetails from "@pages/SourceRecordType/SourceRecordTypeDetails.page";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { timeAgo } from "@services/time.service";
import { useBusinessObjects, useRecord, useSourceRecord } from "@stores/data.store";
import { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

interface SourceRecordDetailsCardProps {
    sourceId: string;
    sourceRecordTypeId: string;
    recordUUID: string;
}
const SourceRecordDetailsCard = (props: SourceRecordDetailsCardProps) => {
    const record = useSourceRecord(props.sourceId, props.sourceRecordTypeId, props.recordUUID);


    return <div className="card">
        <div className="card-body">
            {record.isLoading && <Spinner/>}
            {!record.isLoading && record.error && <>
                <div className="alert alert-danger">Error</div>
            </>}
            {record.data && <>
                <h5><SourceRecordTypeName sourceRecordTypeId={props.sourceRecordTypeId}/></h5>
                <table className="table table-bordered table-small">
                    <tbody>
                        <tr>
                            <th>UUID</th>
                            <td>{record.data.RECORD_UUID}</td>
                        </tr>
                        <tr>
                            <th>Last Loaded</th>
                            <td>
                                {timeAgo(record.data._LOADED_AT)}
                            </td>
                        </tr>
                        {Object.keys(record.data.DATA).map(k => {
                            return <tr>
                                <th><code>{k}</code></th>
                                <td>{JSON.stringify(record.data.DATA[k])}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </>}

        </div>
    </div>

}

const BusinessObjectRecordDetailsPage = () => {
    const businessObjects = useBusinessObjects();
    const { businessObjectId, recordId } = useParams();

    const record = useRecord(businessObjectId as string, recordId as string);
    const businessObject = useMemo(() => {
        if (businessObjects.data) {
            return businessObjects.data.find(bo => bo.id === businessObjectId);
        }
    }, [businessObjectId, businessObjects.dataUpdatedAt]);

    return <div className="content-inner has-subnav">
        <div className="section">
            {record.isLoading && <>
                <SnowflakeLoader/>
            </>}
            {!record.isLoading && record.data && <>
                <div className="row">
                    <div className="col-6 p-3">
                        <h4>Record Details</h4>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>UUID</th>
                                    <td>{record.data.data.UUID}</td>
                                </tr>

                                {businessObject?.fields.map(f => {
                                    return <tr>
                                        <th>{f.label}</th>
                                        <td>
                                            {record.data.data.hasOwnProperty(f.name) && <span>{record.data.data[f.name]}</span>}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 p-3">
                        <h4>Lineage</h4>
                        {record.data.lineage.map(l => {
                            return <SourceRecordDetailsCard
                                sourceId={l.ROOT_SOURCE_ID}
                                sourceRecordTypeId={l.ROOT_SOURCE_RECORD_TYPE_ID}
                                recordUUID={l.ROOT_UUID}
                            />
                        })}
                    </div>
                </div>
            </>}
        </div>
    </div>
}

export default BusinessObjectRecordDetailsPage;