import { useCallback, useState } from "react";
import { Carousel, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";

interface Answers {
    whatDoesYourBusinessDo: string;
    customerType: string;
    salesCycleType: string;
    runAds: string;
}

const OnboardingDataModelPage = () => {
    const [answers, setAnswers] = useImmer<Answers>({
        whatDoesYourBusinessDo: '',
        customerType: '',
        salesCycleType: '',
        runAds: '',
    });

    const [index, setIndex] = useState(0);

    const answerQuestion = useCallback((question: keyof Answers, answer: string) => {
        setAnswers(draft => {
            draft[question] = answer;
        });
        setTimeout(() => {
            setIndex(index + 1);
        }, 1000)
        
    }, [answers, index]);

    console.log('Answers:', answers);
    console.log('index', index);

    return <>
        <h4>In order to work our magic, we need to know a little bit more about your business.</h4>
        <p className="text-muted font-13">We'll be creating your data model behind the scenes.</p>
        <hr />
        <Carousel activeIndex={index}>
            <Carousel.Item>
                <Form.Group className="mb-3">
                    <Form.Label>What does your business do?</Form.Label>
                    <div className="row">
                        <div className="col">
                            <button className={"onboarding-button " + (answers.whatDoesYourBusinessDo === 'SELL_PRODUCTS' ? 'active' : '')} onClick={() => answerQuestion('whatDoesYourBusinessDo', 'SELL_PRODUCTS')}>
                                <i className="mdi mdi-factory"></i>

                                Sell products
                            </button>
                        </div>
                        <div className="col">
                            <button className={"onboarding-button " + (answers.whatDoesYourBusinessDo === 'SELL_SERVICES' ? 'active' : '')} onClick={() => answerQuestion('whatDoesYourBusinessDo', 'SELL_SERVICES')}>
                                <i className="mdi mdi-hammer-screwdriver"></i>
                                Sell services
                            </button>
                        </div>
                        <div className="col">
                            <button className={"onboarding-button " + (answers.whatDoesYourBusinessDo === 'MANAGE_ASSETS' ? 'active' : '')} onClick={() => answerQuestion('whatDoesYourBusinessDo', 'MANAGE_ASSETS')}>
                                <i className="mdi mdi-office-building"></i>
                                Manage assets
                            </button>
                        </div>
                    </div>
                </Form.Group>
            </Carousel.Item>
            {['SELL_PRODUCTS', 'SELL_SERVICES'].includes(answers.whatDoesYourBusinessDo) && (
                <Carousel.Item>
                    <Form.Group className="mb-3">
                        <Form.Label>Who do you sell to?</Form.Label>
                        <div className="row">
                            <div className="col">
                                <button className={"onboarding-button " + (answers.customerType === 'B2B' ? 'active' : '')} onClick={() => answerQuestion('customerType', 'B2B')}>
                                    <i className="mdi mdi-briefcase-account"></i>

                                    Other businesses
                                </button>
                            </div>
                            <div className="col">
                                <button className={"onboarding-button " + (answers.customerType === 'B2C' ? 'active' : '')} onClick={() => answerQuestion('customerType', 'B2C')}>
                                    <i className="mdi mdi-account"></i>
                                    Individual consumers
                                </button>
                            </div>
                        </div>
                    </Form.Group>
                </Carousel.Item>
            )}
            {['B2B', 'B2C'].includes(answers.customerType) && (
                <Carousel.Item>
                    <Form.Group className="mb-3">
                        <Form.Label>How long is your sales cycle?</Form.Label>
                        <p className="text-muted font-13">For long sales cycles, you typically want to be able to track the various touch points you had with your customer leading up to the sale. For shorter or transactional sales cycles this is less important.</p>
                        <div className="row">
                            <div className="col">
                                <button className={"onboarding-button " + (answers.salesCycleType === 'LONG' ? 'active' : '')} onClick={() => answerQuestion('salesCycleType', 'LONG')}>
                                    <i className="mdi mdi-phone"></i>

                                    Long
                                </button>
                            </div>
                            <div className="col">
                                <button className={"onboarding-button " + (answers.salesCycleType === 'SHORT' ? 'active' : '')} onClick={() => answerQuestion('salesCycleType', 'TRANSACTIONAL')}>
                                    <i className="mdi mdi-cash-multiple"></i>
                                    Short
                                </button>
                            </div>
                        </div>
                    </Form.Group>
                </Carousel.Item>
            )}
            {!!answers.salesCycleType && (
                <Carousel.Item>
                    <Form.Group className="mb-3">
                        <Form.Label>Do you run any advertising?</Form.Label>
                        <div className="row">
                            <div className="col">
                                <button className={"onboarding-button " + (answers.runAds === 'YES' ? 'active' : '')} onClick={() => answerQuestion('runAds', 'YES')}>
                                    <i className="mdi mdi-billboard"></i>

                                    Yes
                                </button>
                            </div>
                            <div className="col">
                                <button className={"onboarding-button " + (answers.runAds === 'NO' ? 'active' : '')} onClick={() => answerQuestion('runAds', 'NO')}>
                                    <i className="mdi mdi-block-helper"></i>
                                    No
                                </button>
                            </div>
                        </div>
                    </Form.Group>
                </Carousel.Item>
            )}
            <Carousel.Item>
                <div className="text-center mt-5 mb-5">
                    <Spinner/>
                    <h4>Our AI is creating your data model.</h4>
                </div>
                
            </Carousel.Item>
            
        </Carousel>
        
        <Link to="welcome/data-model/scratch">Skip this. I'll start from scratch.</Link>

    </>;
}

export default OnboardingDataModelPage;