import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';

TimeAgo.addDefaultLocale(en);

const timeAgoFormatter = new TimeAgo('en-US')



export function timeAgo(t: Date|undefined): string {
    if (!t) {
        return 'Never';
    }
    return timeAgoFormatter.format(t);
}

export function parseDateString(t: string|undefined): Date|undefined {
    const d = moment(t);
    if (d.isValid()) {
        return d.toDate();
    }
    return undefined;
}