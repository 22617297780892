import { Component } from 'react';
import { EventActor } from '@models/auditLogEntry';

interface Props {
    actor: EventActor;
}

export default class EventActorLabel extends Component<Props> {
    render() {
        return (
            <span><i className="mdi mdi-account"></i> {this.props.actor.label}</span>
        )
    }
}