import { subscribe, unsubscribe, emit } from '@nextcloud/event-bus'
import { useCallback, useEffect, useState } from 'react';
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal.component'
import PromptModal, { PromptModalProps } from './PromptModal.component';

export const AlertsContainer = () => {
    const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps|undefined>(undefined);
    const [promptModalProps, setPromptModalProps] = useState<PromptModalProps|undefined>(undefined);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);


    const confirmationModalTriggered = (props: ConfirmationModalProps) => {
        setConfirmationModalProps(props);
        setShowConfirmation(true);
    }

    const promptModalTriggered = (props: PromptModalProps) => {
        setPromptModalProps(props);
        setShowPrompt(true);
    }

    const onConfirmationClose = () => {
        if (!!confirmationModalProps?.onClose) {
            confirmationModalProps.onClose();
        }
        setShowConfirmation(false);
    }

    const onConfirmationConfirm = () => {
        setShowConfirmation(false);
        if (!!confirmationModalProps?.onConfirm) {
            confirmationModalProps.onConfirm();
        }
    }

    const onPromptClose = useCallback(() => {
        if (!!promptModalProps?.onClose) {
            promptModalProps.onClose();
        }
        setShowPrompt(false);
    }, [promptModalProps]);

    const onPromptConfirm = useCallback((val: string) => {
        setShowPrompt(false);
        if (!!promptModalProps?.onConfirm) {
            promptModalProps.onConfirm(val);
        }
    }, [promptModalProps]);


    useEffect(() => {
        subscribe('pliable:alerts:confirmation', confirmationModalTriggered);
        subscribe('pliable:alerts:prompt', promptModalTriggered);

        return () => {
            unsubscribe('pliable:alerts:confirmation', confirmationModalTriggered);
            unsubscribe('pliable:alerts:prompt', promptModalTriggered);
        };
    }, []);

    return <>
        {!!confirmationModalProps && (<ConfirmationModal {...confirmationModalProps} show={showConfirmation} onClose={onConfirmationClose} onConfirm={onConfirmationConfirm}/>)}

        {!!promptModalProps && (<PromptModal {...promptModalProps} show={showPrompt} onClose={onPromptClose} onConfirm={onPromptConfirm}/>)}
    </>
}


export const confirmation = (props: ConfirmationModalProps) => {
    emit('pliable:alerts:confirmation', props);

}

export const prompt = (props: PromptModalProps) => {
    emit('pliable:alerts:prompt', props);
}


const alert = () => {

}