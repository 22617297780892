import ApiService from "./api/api.service";


const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export default class FormService {
  private static instance: FormService;

  public static getInstance(): FormService {
    if (!FormService.instance) {
        FormService.instance = new FormService();
    }

    return FormService.instance;
  }

  constructor() {
  }

  public async saveFormSubmission(sourceId: string, body: any) {
      return await ApiService.getInstance().request('POST', `/forms/${sourceId}/submission`, body);
  }
}
