import { Component } from 'react';
import PageTitle from '../components/pageTitle/PageTitle.component';


import AsyncButton from '@components/button/AsyncButton.component';
import TemplateService from '@services/template.service';

interface Props {}

interface State {
    saving: any;
}

export default class TemplatesPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            saving: {},
        }
    }

    loadTemplate = async (exampleType: string) => {
        let saving : any = {};
        saving[exampleType] = true;
        this.setState({saving});
        const result = await TemplateService.getInstance().generate(exampleType);
        saving = {};
        this.setState({saving});
    }

    render() {
        return (
            <div>
                <PageTitle title="Templates">
                    <p>To be used as examples</p>
                </PageTitle>
                <div className="card">
                    <div className="card-body">
                        <AsyncButton
                                    text="Widgets Co. Example"
                                    loading={this.state.saving['widgets']}
                                    onClick={()=> this.loadTemplate('widgets')}
                                />

                    </div>
                </div>
            </div>
        );
            
    }
}