import React, { Component } from 'react';
import SourceORM, { Source } from '../../models/source';
import Tree, { TreeNode } from 'rc-tree';
import DataTypeIcons from '../../constants/dataTypeIcons';
import "rc-tree/assets/index.css"


interface Props {
    source?: Source;
    treeData: any;
    onSelect?: (i:any) => void;
}

interface State {}


export default class SourceSchemaTree extends Component<Props, State> {
    treeRef: React.RefObject<Tree>;
    constructor(props: Props) {
        super(props);
        this.state = {}
        this.treeRef = React.createRef();

    }

    renderIcon = (obj: any) => {
        let icn = '';
        if (obj.data.children.length > 0) {
            icn = "mdi mdi-code-braces";
        }else{
            switch (obj.data.dtype) {
                case 'Int64':
                    icn = DataTypeIcons.INTEGER_ICON_CLASS;
                    break;
                case 'Float64':
                    icn = DataTypeIcons.DECIMAL_ICON_CLASS;
                    break;
                case 'boolean':
                    icn = DataTypeIcons.BOOLEAN_ICON_CLASS;
                    break;
                case 'datetime64[ns, UTC]':
                case 'datetime64[ns]':
                    icn = DataTypeIcons.DATETIME_ICON_CLASS;
                    break;
                default:
                    icn = DataTypeIcons.STRING_ICON_CLASS;
                    break;
            }
        }

        return <i className={icn}/>;
    }

    // renderSwitcher = (obj: any) => {
    //     if (obj.isLeaf) {
    //         return (
    //             <i className="mdi mdi-chevron-right"/>
    //         );
    //     }

    //     return (
    //         <i className="mdi mdi-chevron-down"/>
    //     );
    // }
    
    render() {
        return (
            <div>
                <Tree
                    ref={this.treeRef}
                    defaultExpandAll
                    checkable
                    treeData={this.props.treeData}
                    onSelect={this.props.onSelect}
                    // switcherIcon={this.renderSwitcher}
                    icon={this.renderIcon}
                />
            </div>
        )
    }
}