import { Component, useEffect, useState } from "react";
import { Spinner, Tab, Tabs, Form, Badge, ProgressBar, Pagination } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { CardHeader } from "../..//card/Card.component";
import { SimpleDataTable } from "../../datatable/DataTable.component";
import BigMetricCard from "../../metric/BigMetricCard.component";
import PageTitle from "../../pageTitle/PageTitle.component";
import PipelineORM, { Pipeline } from "../../../models/pipeline";
import PipelineExecutionORM, { PipelineExecution } from "../../../models/pipelineExecution";
import ReviewORM, { Review } from "../../../models/review";
import ApiService, { ListRecordsResponse, SingleRecordResponse } from "../../../services/api/api.service";
import { getErrorMessage } from "../../../services/errors.service";
import { decimalFormatter, integerFormatter } from "../../../services/formatting.service";
import { getWilsonConfidenceInterval } from "../../../services/stats.service";
import toast from "../../../services/toast.service";
import { useQuery } from "../../../services/url.service";




interface ColumnStats {
    column: string;
    max: any;
    min: any;
    empty: number;
}

interface TableStats {
    total_records: number;
    columns: string[];
    column_stats: ColumnStats[];
}

// 3 review options:
// 1. net new
// 2. updated/changed
// 3. removed

function RowStatusBadge({ status }: {status: string}) {
    let color = 'primary';
    switch (status) {
        case 'new':
            color = 'primary';
            break;
        case 'removed':
            color = 'danger';
            break;
        case 'no_change':
            color = 'default';
            break;
        case 'modified':
            color = 'warning';
            break;
    }

    return <Badge color={color}>{status}</Badge>
}

function ColumnChangeComponent({ sr, column}: {sr: any, column: string}) {
    
    let sourceVal = sr[column + '_source'];
    let destVal = sr[column + '_dest'];

    if (!sourceVal) {
        sourceVal = '(empty)';
    }

    if (!destVal) {
        destVal = '(empty)';
    }

    return (
        <>
            <span className="me-2">{destVal}</span>
            <span className="me-2">&rarr;</span>
            <span>{sourceVal}</span>
        </>
    );
}

function WilsonConfidenceComponent({sampleRecords, columns}: {sampleRecords: any[], columns: string[]}) {
    const [statusByUUID, setStatusByUUID] = useState<{[key: string]: string}>({});
    const [page, setPage] = useState(1);
    const lastPage = Math.ceil(sampleRecords.length/10);
    let totalApproved = 0;
    let totalReviewed = 0;

    Object.keys(statusByUUID).forEach(k => {
        if (statusByUUID[k] === 'approved') {
            totalApproved++;
        }
        totalReviewed++;
    });

    function getStatusByUUID(uuid: string): string {
        if (statusByUUID.hasOwnProperty(uuid)) {
            return statusByUUID[uuid];
        }
        return '';
    }

    function updateStatusForRecord(uuid: string, status: string) {
        setStatusByUUID({
            ...statusByUUID,
            [uuid]: status,
        })
    }

    const [minConfidence, maxConfidence] = getWilsonConfidenceInterval(totalReviewed, totalApproved);

    console.log('Min/max', minConfidence, maxConfidence);

    
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <CardHeader title="Data Accuracy" subtitle="By reviewing a small subset of records we can extrapolate what the overall accuracy rate will be across the entire dataset. The more records you review the more accurate the confidence interval will be."/>
                    <ProgressBar>
                        <ProgressBar variant="success" key={1} now={minConfidence*100}/>
                        <ProgressBar variant="warning" key={2} now={(maxConfidence - minConfidence) * 100}/>
                        <ProgressBar variant="danger" key={3} now={(1-maxConfidence) * 100}/>

                    </ProgressBar>
                    <p className="text-muted">
                        Confidence Interval: {Math.round(minConfidence * 10000)/100} - {Math.round(maxConfidence *10000)/100}%
                    </p>
                    
                </div>
            </div>
            

            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered table-sm table-centered">
                            <thead className="table-light">
                                <tr>
                                    <th></th>
                                    <th>Status</th>
                                    {columns.map((c) => <th>{c}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {sampleRecords.slice((page - 1)*10, page*10).map(sr => {
                                    const status = getStatusByUUID(sr.uuid);
                                    return (
                                        <tr>
                                            <td>
                                                <div className="btn-group btn-sm">
                                                    <button 
                                                        className={'btn '  + (status === 'approved' ? 'btn-success' : 'btn-outline-success')}
                                                        onClick={() => updateStatusForRecord(sr.uuid, 'approved')}
                                                    >
                                                        <i className="mdi mdi-check-decagram"></i>
                                                    </button>
                                                    <button 
                                                         className={'btn '  + (status === 'rejected' ? 'btn-danger' : 'btn-outline-danger')}
                                                         onClick={() => updateStatusForRecord(sr.uuid, 'rejected')}
                                                    >
                                                        <i className="mdi mdi-close-box"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td><RowStatusBadge status={sr.change}/></td>
                                            {columns.map(c => {
                                                return (
                                                    <td>
                                                        <ColumnChangeComponent sr={sr} column={c}/>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-info" disabled={page === 1} onClick={() => setPage(page - 1)}>&larr; Previous Page</button>
                        </div>
                        <div className="col-6 text-end">
                            <button className="btn btn-info" disabled={page === lastPage} onClick={() => setPage(page + 1)}>Next Page &rarr;</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function TableStatsComponent({stats}: {stats: TableStats}) {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <CardHeader title="Column Details"/>
                    <ul className="list-group">
                        {stats.column_stats.map(c => {
                            const filledPercent = (1 - (c.empty / stats.total_records)) * 100;
                            return (
                                <li className="list-group-item">
                                    <h6>{c.column}</h6>
                                    <dl>
                                        <dt>
                                            Range
                                        </dt>
                                        <dd>
                                            <p className="text-muted"><code>{c.min}</code> - <code>{c.max}</code></p>

                                        </dd>
                                        <dt>
                                            Completeness
                                        </dt>
                                        <dd>
                                            <div className="progress-w-percent mb-0">
                                                <span className="progress-value">{decimalFormatter(filledPercent)}%</span>
                                                <div className="progress progress-sm">
                                                    <div className="progress-bar bg-success" style={{width: filledPercent.toString() + '%'}}></div>
                                                </div>
                                            </div>
                                        </dd>
                                        
                                    </dl>
                                    <button className="btn btn-outline-dark btn-sm">View Distribution</button>
                                    
                                </li>
                                
                            )
                        })}
                    </ul>
                </div>
            </div>
            
           
        </>
    );
}

interface ChangeStats {
    new: number;
    modified: number;
    removed: number;
    no_change: number;
}

interface TableComparisonResponse {
    change_stats: ChangeStats;
    sample_data: any[];
}

interface Props {
    execution: PipelineExecution;
    pipeline: Pipeline;
    showConfidenceComponent?: boolean;
    onApprove?: () => void;
}


function FinalReview(props: Props) {
    const { execution, pipeline } = props;

    const [loading, setLoading] = useState(true);
    const [reviewTableStats, setReviewTableStats] = useState<TableStats|undefined>(undefined);
    const [activeTab, setActiveTab] = useState('table_stats');
    const [changeStats, setChangeStats] = useState<ChangeStats|undefined>(undefined);
    const [sampleData, setSampleData] = useState<any[]>([]);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);

        const fetchData = async () => {
            try {

                if (execution.status !== 'IN_REVIEW') {
                    throw Error('Execution is not in review status');
                }

                if (!execution.steps) {
                    throw Error('No results for pipeline steps!');
                }

                
                const tablePrefix = `${pipeline.name as string}_${execution.id as string}`;

                const tableName = `${tablePrefix}_step${execution.steps.length - 1}`;

                // Get comparison data
                const comparisonData = await ApiService.getInstance().request('GET', `/pipelines/${props.pipeline.id}/executions/${props.execution.id}/review_stats`) as TableComparisonResponse;

                setChangeStats(comparisonData.change_stats);
                setSampleData(comparisonData.sample_data);
                
            } catch (err) {
                console.log('ERROR:', err);
                setError(getErrorMessage(err));
            } finally {
                setLoading(false);
            }
            

        };

        fetchData();
    }, []);

    const promoteData = async () => {
        if (props.onApprove) {
            props.onApprove();
        }
    }

    return (
        <>
            {loading && <Spinner/>}
            {!loading && !error && (
                <>
                    <div className="row">
                       
                        <div className="col-12">
                            {changeStats && (
                                <>
                                    <div className="row">
                                        <div className="col-3">
                                            <BigMetricCard
                                                title="New Records"
                                                icon="mdi mdi-file-plus"
                                                metric={integerFormatter(changeStats.new)}
                                                loading={false}
                                                backgroundColor="primary"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <BigMetricCard
                                                title="Modified Records"
                                                icon="mdi mdi-file-edit"
                                                metric={integerFormatter(changeStats.modified)}
                                                loading={false}
                                                backgroundColor="info"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <BigMetricCard
                                                title="Removed Records"
                                                icon="mdi mdi-file-remove"
                                                metric={integerFormatter(changeStats.removed)}
                                                loading={false}
                                                backgroundColor="danger"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <BigMetricCard
                                                title="Unchanged Records"
                                                icon="mdi mdi-minus"
                                                metric={integerFormatter(changeStats.no_change)}
                                                loading={false}
                                            />
                                        </div>
                                    </div>
                                    {props.showConfidenceComponent && (
                                        <>
                                            <WilsonConfidenceComponent sampleRecords={sampleData} columns={reviewTableStats?.columns as string[]}/>
                                            
                                        </>
                                    )}
                                    <div className="mt-3">
                                        <button className="btn btn-lg btn-success" onClick={() => promoteData()}>
                                            Approve
                                        </button>
                                    </div>
                                    
                                    
                                </>
                                
                                
                            )}
                            
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12">
                            {reviewTableStats && <TableStatsComponent stats={reviewTableStats}/>}
                        </div>
                    </div> */}
                </>
            )}
            {error && !loading && (
                <div className="alert alert-danger">{error}</div>
            )}
        </>
    )

}

export default FinalReview;
