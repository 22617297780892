import ApiService, { ListRecordsResponse, SingleRecordResponse, JobEnqueueResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";


export default class TemplateService {
  private static instance: TemplateService;

  public static getInstance(): TemplateService {
    if (!TemplateService.instance) {
        TemplateService.instance = new TemplateService();
    }

    return TemplateService.instance;
  }

  constructor() {
  }

  public async generate(exampleType: string) {
    const result = await ApiService.getInstance().request('POST', `/templates/generate-example/${exampleType}`, {}) as JobEnqueueResponse;
    const template_result : any = await BackgroundService.getInstance().waitForJob(result.job_id);
  }
}
