import { BusinessObject, BusinessObjectField } from "@models/businessObject";
import { BusinessObjectFieldMap, FieldMap, FieldResolution, MappingConfig } from "@models/standardizationPipeline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Form, Offcanvas } from "react-bootstrap";
import Select from 'react-select';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useBusinessObjects } from "@stores/data.store";
import produce from "immer";
import { useImmer } from "use-immer";
import { FieldWithIndex } from "@pages/BusinessObject/BusinessObjectAttributes.page";
import { prompt } from "@services/alert/alert.service";
import {v4 as uuidv4 } from 'uuid';


const ColumnSample = styled.div`
    display: inline-block;
    padding: 3px;
    border: solid 1px #ccc;
    background: white;
    border-radius: .2rem;
    margin-right: 4px;
`
const DraggableColumnContainer = styled.div`

`

const DraggableColumnWrapper = styled.div`
    margin-bottom: 8px;
    padding: 4px;
    border: solid 1px #ccc;
    background: white;
    &:hover {
        background: #f1f3fa;
    }
    border-radius: 0.2rem;
`

interface DraggableColumnProps {
    columnName: string;
    index: number;
}
const DraggableColumn = (props: DraggableColumnProps) => {
    return (
        <Draggable draggableId={props.columnName} key={props.columnName} index={props.index}>
            {provided => (
                <DraggableColumnWrapper
                    {...provided.draggableProps} 
                    {...provided.dragHandleProps} 
                    ref={provided.innerRef}
                    key={props.columnName} 
                >
                    {props.columnName}
                </DraggableColumnWrapper>
            )}
            
        </Draggable>
        
    )
} 

interface FieldMapperProps {
    columnOptions: {
        label: string;
        value: string;
    }[];
    sourceColumns: string[];
    rawSql?: string;
    useRawSql: boolean;
    onChangeSourceColumns: (columns: string[]) => void;
    onChangeRawSql: (sql: string) => void;
    onChangeUseRawSql: (val: boolean) => void;
}
const FieldMapper = (props: FieldMapperProps) => {
    useEffect(()=> {
        if(!!props.rawSql && props.sourceColumns.length == 0) {
            props.onChangeUseRawSql(true);
        }

    }, [props.rawSql, props.sourceColumns]);

    if (props.useRawSql) {
        return (
            <div className="d-flex">
                <div className="flex-1 pe-1">
                    <Form.Control
                        className="input-code"
                        value={props.rawSql}
                        onChange={(e) => props.onChangeRawSql(e.target.value)}
                        placeholder="Enter SQL"
                    />
                </div>
                <button title="Select Column(s)" className="btn btn-light" onClick={() => props.onChangeUseRawSql(false)}>
                    <i className="mdi mdi-view-column"></i>
                </button>
                
            </div>
        )
    }
    return (
        <div className="d-flex">
            <div className="flex-1 pe-1">
                <Select
                    isClearable
                    placeholder="Select source columns"
                    isMulti
                    options={props.columnOptions}
                    value={props.columnOptions.filter(o => props.sourceColumns.includes(o.value))}
                    onChange={(v) => {
                        if (!!v) {
                            props.onChangeSourceColumns(v.map(c => c.value));
                        } else {
                            props.onChangeSourceColumns([]);
                        }
                    }}
                />
            </div>
            
            <button title="Write SQL" className="btn btn-light" onClick={() => props.onChangeUseRawSql(true)}>
                <i className="mdi mdi-code-braces"></i>
            </button>
        </div>
        
    )
}

const resolutionOptions = [{
    label: 'Combine them',
    value: 'CONCAT',
}, {
    label: 'Pick first non-empty value',
    value: 'COALESCE',
}];



interface Props {
    config: MappingConfig;
    businessObjectFields: BusinessObjectField[];
    columnOptions: string[];
    onChangeMappingConfig: (config: MappingConfig) => void;
    onChangeBusinessObjectFields: (fields: BusinessObjectField[]) => void;
}

const FieldMapping = (props: Props) => {
    const columnOptions = useMemo(() => {
        return props.columnOptions.map(f => {
            return {
                label: f,
                value: f,
            }
        }).sort((a, b) => {
            if (a.label > b.label) {
                return 1;
            } else {
                return -1;
            }
        });
    }, [props.columnOptions]);

    const onChangeSourceColumns = useCallback(async (fieldId: string, selectedColumns: string[]) => {
        const updatedMappingConfig = produce(props.config, draft => {
            if (!draft.maps.hasOwnProperty(fieldId)) {
                draft.maps[fieldId] = {
                    source_columns: [],
                    resolution_method: 'CONCAT',
                    concat_separator: ' ',
                    use_raw_sql: false

                }
            }
            draft.maps[fieldId].source_columns = selectedColumns;
        });

        props.onChangeMappingConfig(updatedMappingConfig);
      
        
    }, [props.onChangeMappingConfig, props.config]);

    const onChangeRawSql = useCallback(async (fieldId: string, sql: string) => {
        const updatedMappingConfig = produce(props.config, draft => {
            if (!draft.maps.hasOwnProperty(fieldId)) {
                draft.maps[fieldId] = {
                    source_columns: [],
                    resolution_method: 'CONCAT',
                    concat_separator: ' ',
                    use_raw_sql: false

                }
            }
            draft.maps[fieldId].raw_sql = sql;
        });

        props.onChangeMappingConfig(updatedMappingConfig);
      
        
    }, [props.onChangeMappingConfig, props.config]);

    const onChangeUseRawSql = useCallback(async (fieldId: string, val: boolean) => {
        const updatedMappingConfig = produce(props.config, draft => {
            if (!draft.maps.hasOwnProperty(fieldId)) {
                draft.maps[fieldId] = {
                    source_columns: [],
                    resolution_method: 'CONCAT',
                    concat_separator: ' ',
                    use_raw_sql: false

                }
            }
            draft.maps[fieldId].use_raw_sql = val;
        });

        props.onChangeMappingConfig(updatedMappingConfig);
      
        
    }, [props.onChangeMappingConfig, props.config]);

    const onChangeResolutionMethod = useCallback(async (fieldId: string, resolutionMethod: string) => {
        const updatedMappingConfig = produce(props.config, draft => {
            if (!draft.maps.hasOwnProperty(fieldId)) {
                draft.maps[fieldId] = {
                    source_columns: [],
                    resolution_method: 'CONCAT',
                    concat_separator: ' ',
                    use_raw_sql: false
                }
            }
            draft.maps[fieldId].resolution_method = resolutionMethod;
        });

        props.onChangeMappingConfig(updatedMappingConfig);
    }, [props.onChangeMappingConfig, props.config])

    

    const addAttribute = useCallback(() => {
        prompt({
            onConfirm: (val: string) => {
                const newFields = produce(props.businessObjectFields, draft => {
                    // split / trim / remove blanks
                    const fields = val.split(",").map((a) => a.trim()).filter(n => n);
                    const newAttrs: BusinessObjectField[] = fields.map((fieldname) => {
                        return {
                            name: fieldname,
                            label: fieldname,
                            description: '',
                            type: 'STRING',
                            id: uuidv4(),
                        }
                    });

                    draft.unshift(...newAttrs);
                });

                props.onChangeBusinessObjectFields([...newFields]);
            },

            onClose: () => {
                return;
            },
            promptText: 'Enter new field name',
            header: 'Add New Field',
        });
    }, [props.businessObjectFields, props.onChangeBusinessObjectFields]);

    const removeField = useCallback((idx: number) => {
        const newFields = produce(props.businessObjectFields, draft => {
            draft.splice(idx, 1);
        });
        props.onChangeBusinessObjectFields([...newFields]);
    }, [props.onChangeBusinessObjectFields, props.businessObjectFields]);

    
    const fieldValueChanged = useCallback((idx: number, attribute: keyof BusinessObjectField, value: any) => {
        const newFields = produce(props.businessObjectFields, draft => {
            draft[idx][attribute] = value;
        })
        props.onChangeBusinessObjectFields(newFields);

    }, [props.onChangeBusinessObjectFields, props.businessObjectFields]);

    

    return <>
    
    <div>
        <table className="table table-fixed table-centered">
            <thead className="table-light">
                <tr>
                    <th style={{width: '30%'}}>
                        Attribute
                    </th>

                    <th style={{width: '30%'}}>
                        Source Column(s)
                    </th>
                    <th style={{width: '20%'}}>
                        
                    </th>
                    <th style={{width: '20%'}}>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={4}>
                        <button className="btn btn-outline-light w-100" onClick={() => addAttribute()}>
                            <i className="mdi mdi-plus"></i> Add Attribute
                        </button>

                    </td>
                </tr>
            {props.businessObjectFields.map((f, idx) => {
                let theMap: BusinessObjectFieldMap;

                if (props.config.maps && props.config.maps.hasOwnProperty(f.id as string)) {
                    theMap = props.config.maps[f.id as string]
                } else {
                    theMap = {
                        source_columns: [],
                        concat_separator: ' ',
                        resolution_method: 'CONCAT',
                        use_raw_sql: false
                    };
                }
                return (
                    <tr>
                        <td >
                            <h5 className="font-14">
                                <Form.Control value={f.label} className="inline-edit font-14 mt-1 fw-bold" placeholder="Field Name" onChange={(e) =>fieldValueChanged(idx, 'label', e.target.value)}></Form.Control>
                            </h5>
                            <Form.Control as="textarea" placeholder="Enter description" className="inline-edit font-13 text-muted mb-0" value={f.description} onChange={(e) => fieldValueChanged(idx, 'description', e.target.value)}/>

                        </td>
                        <td>
                            <FieldMapper
                                columnOptions={columnOptions}
                                onChangeSourceColumns={(sourceColumns: string[]) => {
                                    onChangeSourceColumns(f.id as string, sourceColumns)
                                }}
                                onChangeRawSql={(sql: string) => {
                                    onChangeRawSql(f.id as string, sql);
                                }}
                                onChangeUseRawSql={(val: boolean) =>{
                                    onChangeUseRawSql(f.id as string, val);
                                }}
                                useRawSql={theMap.use_raw_sql}
                                sourceColumns={theMap.source_columns}
                                rawSql={theMap.raw_sql ? theMap.raw_sql : ''}
                            />
                           
                        </td>
                        <td>
                            {theMap.source_columns.length > 1 && (
                                <Select
                                    options={resolutionOptions}
                                    value={resolutionOptions.find(o => o.value === theMap.resolution_method)}
                                    onChange={(v => onChangeResolutionMethod(f.id as string, v ? v.value : ''))}
                                />
                            )}
                                

                        </td>
                        <td className="text-end">
                            <a className="action-icon" role="button" onClick={() => removeField(idx)}>
                                <i className="mdi mdi-delete"></i>
                            </a>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
        
    </div></>
}

export default FieldMapping;