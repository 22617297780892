import { TableExplorer, TableLoadResponse } from "@components/datatable/TableExplorer.component";
import PageTitle from "@components/pageTitle/PageTitle.component";
import { useBusinessObjectRelationships, useBusinessObjects } from "@stores/data.store";
import Dropdown from 'react-bootstrap/Dropdown';
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import ApiService, { JobEnqueueResponse, SingleRecordResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { PipelineExecution } from "@models/pipelineExecution";
import { integerFormatter } from "@services/formatting.service";
import BackgroundService from "@services/bg.service";
import SnowflakeLoader from "@components/loaders/SnowflakeLoader.component";
import TrackingService, {Events} from "@services/tracking.service";

const BusinessObjectDataPage = () => {
    const navigate = useNavigate();
    const businessObjects = useBusinessObjects();

    const { businessObjectId } = useParams();
    const relationships = useBusinessObjectRelationships(businessObjectId as string);
    const [queuingPipeline, setQueuingPipeline] = useState(false);

    const [totalRecords, setTotalRecords] = useState(0);
    const [displayingRecords, setDisplayingRecords] = useState(0);

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [downloadLocation, setDownloadLocation] = useState('');
    const [downloadError, setDownloadError] = useState('');

    const businessObject = useMemo(() => {
        if (!businessObjects.data) {
            return undefined;
        }

        return businessObjects.data.find(bo => bo.id === businessObjectId);
    }, [businessObjects.dataUpdatedAt, businessObjectId]);

    const onDataLoaded = useCallback((data: TableLoadResponse) => {
        setTotalRecords(data.total_records);
        if (data.total_records < 50) {
            setDisplayingRecords(data.total_records);
        } else {
            setDisplayingRecords(50);
        }
    }, []);

    const trackDownload = useCallback(() => {
        if (!businessObject) {
            return;
        }
        TrackingService.track_event(Events.BUSINESS_OBJECT_DOWNLOAD_ALL_DOWNLOADED, {
            buisness_object_id: businessObject.id,
            buisness_object_name: businessObject.name
        });
    }, [businessObject]);

    const download = useCallback(async () => {
        if (!businessObject) {
            return;
        }
        setDownloadError('');
        setShowDownloadModal(true);
        setLoadingDownload(true);
        setDownloadLocation('');

        
        TrackingService.track_event(Events.BUSINESS_OBJECT_DOWNLOAD_ALL_CLK, {
            buisness_object_id: businessObject.id,
            buisness_object_name: businessObject.name
        });

        try {
            const start = Date.now();
            const jobInfo = await ApiService.getInstance().request('GET', `/pipelines/${businessObject.consolidation_pipeline_id as string}/data`, {
                download: true,
            }) as JobEnqueueResponse;
    
            const results = await BackgroundService.getInstance().waitForJob(jobInfo.job_id);
            setDownloadLocation(results.url);

            const finish = Date.now();
            let elapsed_seconds = (finish - start)/1000; 
            TrackingService.track_event(Events.BUSINESS_OBJECT_DOWNLOAD_ALL_READY, {
                buisness_object_id: businessObject.id,
                buisness_object_name: businessObject.name,
                elapsed_seconds: elapsed_seconds
            });
            
        } catch(err) {
            const msg = getErrorMessage(err);
            setDownloadError(msg);
            TrackingService.track_event(Events.BUSINESS_OBJECT_DOWNLOAD_ALL_ERROR, {
                buisness_object_id: businessObject.id,
                buisness_object_name: businessObject.name,
                error_msg: msg
            });
        } finally {
            setLoadingDownload(false);
        }
        
    }, [businessObject?.consolidation_pipeline_id]);

    const columnOrder = useMemo(() => {
        if (!businessObject) {
            return [];
        }

        let arr = ['uuid'];

        if (relationships.data) {
            relationships.data.forEach(rel => {
                if (rel.child_business_object_id == businessObject.id) {
                    arr.push(rel.child_foreign_key_name as string);
                }
            })
        }

        return arr.concat(businessObject.fields.map(f => f.name.toLowerCase()));
    }, [businessObject, relationships.dataUpdatedAt]);

    const onColumnClick = useMemo(() => {
        if (!businessObject) {
            return {};
        }

        const rv: {
            [key: string]: (value: any) => void;
        } = {
            'uuid': (value: any) => {
                navigate(`/business-objects/${businessObject.id}/data/record/${value as string}`);
            }, 
        }

        if (relationships.data) {
            relationships.data.forEach(rel => {
                if (rel.child_business_object_id == businessObject.id) {
                    rv[rel.child_foreign_key_name as string] = (value: any) => {
                        if (value !== '<AMBIGUOUS>') {
                            navigate(`/business-objects/${rel.parent_business_object_id}/data/record/${value as string}`);
                        }
                        
                    }
                }
            });
        }

        return rv;
    }, [businessObject, relationships.dataUpdatedAt]);



    if (!businessObject) {
        return <></>;
    }



    return <div >
        <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Download</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadingDownload && <div>
                        <h2>Preparing your download...</h2>
                        <SnowflakeLoader/>
                    </div>}
                {!loadingDownload && downloadError && (
                    <div className="alert alert-danger">{downloadError}</div>
                )}
                {!loadingDownload && downloadLocation && !downloadError && (
                    <a target="_blank" className="btn btn-pliable" href={downloadLocation} onClick={trackDownload}>Click here to download your file.</a>
                )}
            </Modal.Body>
        </Modal>
        <div className="content-inner no-scroll has-subnav has-footer">
        {!businessObject.consolidation_pipeline_id && (
                    <p className="alert alert-warning">You haven't set up the consolidation logic for this business object.</p>
                )}
                {!!businessObject.consolidation_pipeline_id && (
                    <TableExplorer
                        tablePath={`/pipelines/${businessObject.consolidation_pipeline_id as string}/data`}
                        defaultOrderBy="random()"
                        onDataLoaded={onDataLoaded}
                        columnOrder={columnOrder}
                        onColumnClick={onColumnClick}
                    />
                )}
        </div>
        <footer className="footer">
            <div className="row">
                <div className="col-6">
                <span>
                        Showing {integerFormatter(displayingRecords)} of {integerFormatter(totalRecords)} records

                    </span>
                    <span className="ms-3 me-3">&bull;</span>
                    <a role="button" onClick={() => download()}>Download All</a>
                </div>
                <div className="col-6 text-end">
                    
                </div>
            </div>
        </footer>
    
    </div>

}

export default BusinessObjectDataPage;