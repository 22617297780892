import { useEffect, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom";
import AsyncButton from "../components/button/AsyncButton.component";
import { CardHeader } from "../components/card/Card.component";
import PageTitle from "../components/pageTitle/PageTitle.component";
import BusinessObjectORM, { BusinessObject } from "../models/businessObject";
import PipelineORM, { Pipeline } from "../models/pipeline";
import PipelineExecutionORM, { PipelineExecution } from "../models/pipelineExecution";
import SourceORM, { Source, SourceRecordType, SourceRecordTypeORM } from "../models/source";
import ApiService, { SingleRecordResponse } from "../services/api/api.service";
import { getErrorMessage } from "../services/errors.service";
import { timeAgo } from "../services/time.service";
import toast from "../services/toast.service";

function PipelineDetails() {
    const { pipelineId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [pipeline, setPipeline] = useState<Pipeline|undefined>(undefined);
    const [executions, setExecutions] = useState<PipelineExecution[]>([]);
    const [error, setError] = useState('');
    const [businessObject, setBusinessObject] = useState<BusinessObject|undefined>(undefined);
    const [source, setSource] = useState<Source|undefined>(undefined);
    const [sourceRecordType, setSourceRecordType] = useState<SourceRecordType|undefined>(undefined);
    const [sourcePipelines, setSourcePipelines] = useState<Pipeline[]>([]);
    const [triggeringPipelineRun, setTriggeringPipelineRun] = useState(false);

    const runPipeline = async () => {
        setTriggeringPipelineRun(true);
        try {
            const execution = await ApiService.getInstance().request('POST', `/pipelines/${pipelineId as string}/run`, {}) as SingleRecordResponse<PipelineExecution>;
            navigate(`/pipelines/${pipelineId as string}/executions/${execution.record.id as string}`);
        }  catch (err) {
            const errMessage = getErrorMessage(err);
            toast('danger', 'Error', errMessage);
        } finally {
            setTriggeringPipelineRun(false);
        }

    }

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);

            try {
                const fetchedPipeline = await PipelineORM.findById(pipelineId as string);
                setPipeline(fetchedPipeline);
                const execustions = await PipelineExecutionORM.find({
                    'pipeline_id': {
                        '$eq': pipelineId as string
                    },
                    'test': {
                        '$eq': false,
                    }
                }, 50, 1, '-executed_at')
                setExecutions(execustions.records);

                if (fetchedPipeline.business_object_id) {
                    setBusinessObject(await BusinessObjectORM.findById(fetchedPipeline.business_object_id as string));
                }

                if (fetchedPipeline.source_id) {
                    setSource(await SourceORM.findById(fetchedPipeline.source_id as string));
                }

                if (fetchedPipeline.source_record_type_id) {
                    setSourceRecordType(await SourceRecordTypeORM.findById(fetchedPipeline.source_record_type_id as string));
                }

                if (fetchedPipeline.source_pipeline_ids) {
                    const pipelines = await PipelineORM.find({
                        'id': {
                            '$in': fetchedPipeline.source_pipeline_ids
                        }
                    })
                    setSourcePipelines(pipelines.records);
                }
            } catch (err) {
                setError(getErrorMessage(err));
            } finally {
                setLoading(false);
            }
            
        };

        loadData();
    }, []);

    if (loading) {
        return <Spinner/>;
    } else if (pipeline) {
        return (
            <>
                <PageTitle title="Pipeline Details" breadcrumbs={[
                    {
                        path: '/pipelines',
                        title: 'Pipelines',
                    }, {
                        title: pipeline.name as string
                    }
                ]}></PageTitle>
                <div className="row">
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <CardHeader title={pipeline.name as string}>
                                    
                                </CardHeader>
                                <Link to={'/pipelines/' + (pipeline.id as string) + '/configuration'} className="btn btn-sm btn-outline-dark">Configure</Link>

                                {pipeline.steps?.map((s, idx) => {
                                    return (
                                        <div className="mt-3">
                                            <h6>Step {idx + 1}: {s.name}</h6>
                                        </div>
                                    )
                                })}

                                <div className="mt-3">
                                    <div className="d-grid gap-2">
                                        <Link to={`/pipelines/${pipelineId as string}/data`} className="btn btn-primary">View Output Data</Link>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="card">
                            <div className="card-body">
                                <CardHeader title="Executions">
                                    <AsyncButton
                                        text="Run Now"
                                        variant="primary"
                                        onClick={runPipeline}
                                        loading={triggeringPipelineRun}
                                        icon="mdi mdi-play"
                                    />
                                </CardHeader>
                                <table className="table table-centered table-nowrap mb-0">
                                    <tbody>
                                        {executions.map(e => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <h5 className="font-16 mt-1 fw-normal">{timeAgo(e.executed_at)}</h5>
                                                    </td>
                                                    <td>
                                                        <span className="text-muted font-13">Triggered By</span>
                                                        <h5 className="font-14 mt-1 fw-normal">{e.executed_by}</h5>
                                                    </td>
                                                    <td>
                                                        <span className="text-muted font-13">Status</span>
                                                        <h5 className="font-14 mt-1 fw-normal">
                                                            {e.status === 'QUEUED' && (
                                                                <Badge bg="info">Queued</Badge>
                                                            )}
                                                            {e.status === 'COMPLETE' && (
                                                                <Badge bg="success">Complete</Badge>
                                                            )}
                                                            {e.status === 'IN_REVIEW' && (
                                                                <Badge bg="warning">In Review</Badge>
                                                            )}
                                                            {e.status === 'RUNNING' && (
                                                                <Badge bg="primary"><Spinner size="sm"/></Badge>
                                                            )}
                                                            {e.status === 'ERROR' && (
                                                                <Badge bg="danger">Failed</Badge>
                                                            )}
                                                        </h5>
                                                    </td>
                                                    <td className="table-action text-end">
                                                        <Link to={'/pipelines/' + (pipelineId as string) + '/executions/' + (e.id as string)} className="action-icon">
                                                            <i className="mdi mdi-eye"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <>Error</>
    }


}

export default PipelineDetails;