import { Component } from 'react';
import AuditLogEnrtiesList from '@components/auditLog/AuditLogEntriesList.component';
import PageTitle from '../components/pageTitle/PageTitle.component';



export default class AuditLogListPage extends Component {
    render() {
        return (
            <div>
                <PageTitle title="Audit Log"></PageTitle>
                <div className="row justify-content-center">
                    <div className="col-8 col-offset-2">
                        <AuditLogEnrtiesList />
                    </div>
                </div>
            </div>
        );
    }
}

