import { useEffect } from "react";
import { useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { SimpleDataTable } from "../components/datatable/DataTable.component";
import { TableExplorer } from "../components/datatable/TableExplorer.component";
import PageTitle from "../components/pageTitle/PageTitle.component";
import PipelineORM, { Pipeline } from "../models/pipeline";
import PipelineExecutionORM, { PipelineExecution } from "../models/pipelineExecution";
import ApiService, { ListRecordsResponse } from "../services/api/api.service";
import { getErrorMessage } from "../services/errors.service";

interface Props {
    pipelineId: string;
    pipelineExecutionId: string;
    stepIndex: number;
}

export const PipelineExecutionStepReview = (props: Props) => {
    const [pipeline, setPipeline] = useState<Pipeline|undefined>(undefined);
    const [pipelineExecution, setPipelineExecution] = useState<PipelineExecution|undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [loadingTableData, setLoadingTableData] = useState(true);
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('uuid');
    const [orderDir, setOrderDir] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [activeTab, setActiveTab] = useState('output');

    const [tableData, setTableData] = useState<any[]>([]);
    const [error, setError] = useState('');

    

    useEffect(() => {
        const loadObjectData = async () => {
            setLoading(true);
            setPipeline(await PipelineORM.findById(props.pipelineId));
            setPipelineExecution(await PipelineExecutionORM.findById(props.pipelineExecutionId));
            setLoading(false);
        }
        loadObjectData();
        
    }, [props.pipelineId, props.pipelineExecutionId, props.stepIndex]);

   

    if (pipeline && pipelineExecution) {
        return (
            <>
                <PageTitle title="Pipeline Review" breadcrumbs={[
                    {
                        path: '/pipelines',
                        title: 'Pipelines'
                    }, {
                        path: '/pipelines/' + (pipeline.id as string),
                        title: pipeline.name as string
                    }, {
                        title: 'Execution Details',
                        path: '/pipelines/' + (props.pipelineId) + '/executions/' + props.pipelineExecutionId,
                    }, {
                        title: 'Review Step: ' + pipelineExecution.steps[props.stepIndex].step_config.name,
                    }
                ]}/>
                {/* <div className="row">
                    {Object.keys(meta).map(k => {
                                const metric = meta[k];
                                return <div className="col">
                                    <BigMetricCard
                                        title={k}
                                        icon=""
                                        loading={false}
                                        metric={integerFormatter(metric)}
                                    />
                                </div>
                            })}
                </div> */}
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k as string)}
                                    className="mb-3 nav-bordered"
                                >
                                    <Tab eventKey="output" title="Step Output">
                                        <TableExplorer
                                            tablePath={`/pipelines/${props.pipelineId}/executions/${props.pipelineExecutionId}/step/${props.stepIndex}/output`}
                                            defaultOrderBy="uuid"
                                        />
                                    </Tab>
                                    {pipelineExecution.steps[props.stepIndex].step_config.step_type === 'DEDUPE' && (
                                        <Tab eventKey="dedupe" title="Deduplication Review">
                                            <TableExplorer
                                                tablePath={`/pipelines/${props.pipelineId}/executions/${props.pipelineExecutionId}/step/${props.stepIndex}/crosswalk`}
                                                defaultOrderBy="group_id"
                                            />
                                        </Tab>
                                    )}
                                </Tabs>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </>
            
        );
    }
    return <Spinner/>;
}

const PipelineExecutionStepReviewPage = () => {
    const { pipelineId, pipelineExecutionId, stepIndex } = useParams();
    return <PipelineExecutionStepReview
        pipelineId={pipelineId as string}
        pipelineExecutionId={pipelineExecutionId as string}
        stepIndex={parseInt(stepIndex as string, 10)}
    />
}

export default PipelineExecutionStepReviewPage;