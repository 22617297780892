import AsyncButton from "@components/button/AsyncButton.component";
import { TableExplorer } from "@components/datatable/TableExplorer.component";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DedupeStepQA from "./dedupe/DedupeStepQA";
import OutputStepQA from "./output/OutputStepQA.component";

interface StepResultsProps {
    previousStepStatus?: string;
    thisStepStatus?: string;
    thisStepError?: string;
    thisStepIndex: number;
    allowInteractivity: boolean;
    pipelineId: string;
    pipelineExecutionId?: string;
    loading: boolean;
    stepType: string;

    onPreviewInput: () => void;
    onPreviewOutput: () => void;
};

const completedStepStatuses = ['IN_REVIEW', 'COMPLETE'];

const StepResults = (props: StepResultsProps) => {
    const [activeTab, setActiveTab] = useState('output');

    const previewOutput = () => {
        if (props.onPreviewOutput) {
            props.onPreviewOutput();
        }
    }

    const previewInput = () => {
        if (props.onPreviewInput) {
            props.onPreviewInput();
        }
    }
    return <>
        <Tabs
            activeKey={activeTab}
            onSelect={k => setActiveTab(k as string)}
            className="nav-bordered"
        >
            <Tab eventKey="input" title="Input" disabled={props.thisStepIndex === 0}>

                
                {!!props.pipelineExecutionId && props.thisStepIndex > 0 && completedStepStatuses.includes(props.previousStepStatus as string) && (
                    <>
                        <TableExplorer
                            tablePath={`/pipelines/${props.pipelineId}/executions/${props.pipelineExecutionId}/step/${props.thisStepIndex - 1}/output`}
                            defaultOrderBy="random()"
                        />
                    </>
                )}
                {!completedStepStatuses.includes(props.previousStepStatus as string) && (
                    <>
                        <div className="mb-3">
                            <AsyncButton
                                loading={props.loading}
                                icon="mdi mdi-refresh-circle"
                                variant="info"
                                text="Preview"
                                onClick={() => previewInput()}
                            />
                        </div>
                    </>
                )}
            </Tab>
            <Tab eventKey="output" title="Output">
                {props.thisStepStatus === 'ERROR' && (
                    <div className="alert alert-danger">
                        <h6>Oops!</h6>
                        <p>{props.thisStepError}</p>
                    </div>
                )}
                {props.allowInteractivity && (
                    <>
                        <div className="mb-3">
                            <AsyncButton
                                loading={props.loading}
                                icon="mdi mdi-refresh-circle"
                                variant="info"
                                text="Preview"
                                onClick={() => previewOutput()}
                            />
                        </div>
                        <hr/ >
                    </>
                    
                )}
                
                
                {completedStepStatuses.includes(props.thisStepStatus as string) &&  (
                    <>
                        <TableExplorer
                            tablePath={`/pipelines/${props.pipelineId}/executions/${props.pipelineExecutionId}/step/${props.thisStepIndex}/output`}
                            defaultOrderBy="random()"
                            maxHeight="400px"
                        />
                    </>
                    
                )}
            </Tab>
            {props.stepType === 'DEDUPE' && (
                <Tab eventKey="qa" title="QA" disabled={!completedStepStatuses.includes(props.thisStepStatus as string)}>
                    {completedStepStatuses.includes(props.thisStepStatus as string) && (
                        <DedupeStepQA
                            stepIndex={props.thisStepIndex}
                            pipelineId={props.pipelineId}
                            pipelineExecutionId={props.pipelineExecutionId as string}
                        />

                    )}
                </Tab>
            )}

            {props.stepType === 'OUTPUT' && (
                <Tab eventKey="qa" title="QA" disabled={!completedStepStatuses.includes(props.thisStepStatus as string)}>
                    {completedStepStatuses.includes(props.thisStepStatus as string) && (
                        <OutputStepQA
                            stepIndex={props.thisStepIndex}
                            pipelineId={props.pipelineId}
                            pipelineExecutionId={props.pipelineExecutionId as string}
                        />

                    )}
                </Tab>
            )}
            
        </Tabs>
    </>
}

export default StepResults;