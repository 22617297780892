import BusinessObjectSelector from "@components/businessObjects/BusinessObjectSelector.component";
import { ColumnRefForm } from "@components/sql/ColumnRef.component";
import BusinessObjectORM, { BusinessObject } from "@models/businessObject";
import { ColumnRef } from "@models/shared";
import { getColumnsForPipelineStep } from "@services/tablestructure.service";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Mapper, MapStepConfig } from "../map/MapStep.component";
import { StepComponentProps } from "../shared";

interface OutputStepConfig extends MapStepConfig{
    business_object_id: string;
}


const OutputStep = (props: StepComponentProps) => {
    const parsedConfig = props.stepConfig.config as OutputStepConfig;
    if (!parsedConfig.mappers) {
        parsedConfig.mappers = [];
    }

    // XXX todo: use global state for business objects
    const [businessObjects, setBusinessObjects] = useState<BusinessObject[]>([]);

    const [currentBusinessObject, setCurrentBusinessObject] = useState<BusinessObject|undefined>(undefined);

    const [loading, setLoading] = useState(true);

    const [upstreamColumnOptions, setUpstreamColumnOptions] = useState<string[]>([]);


    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            const resp  = await BusinessObjectORM.find();
            setBusinessObjects(resp.records);
            setLoading(false);
        }

        loadData();
    }, []);

    useEffect(() => {
        const loadUpstreamColumnOptions = async () => {
            const columns = await getColumnsForPipelineStep(props.pipelineId, props.pipelineExecutionId as string, props.stepIndex - 1);

            setUpstreamColumnOptions(columns.map(c => c.PATH));
        }
        if (props.previousStepStatus === 'COMPLETE') {
            loadUpstreamColumnOptions();
        }
    }, [props.previousStepStatus])


    useEffect(() => {
        console.log('Running business object id effect', parsedConfig.business_object_id);
        if (parsedConfig.business_object_id) {
            console.log('Setting active business object:', businessObjects, parsedConfig.business_object_id)
            const theBizObj = businessObjects.find(b => b.id === parsedConfig.business_object_id);
            setCurrentBusinessObject(theBizObj);
        }
    }, [parsedConfig.business_object_id, businessObjects])

    useEffect(() => {
        if (currentBusinessObject) {
            var reducedMappers = currentBusinessObject.fields.map((f) => parsedConfig.mappers.find(m => m.output_name === f.name)).filter((m) => m !== undefined);
            if(parsedConfig.mappers.length != reducedMappers.length) {
                // lets get ride of stale mappers
                props.onChange('mappers', reducedMappers);
            }

        }
        
    }, [currentBusinessObject])

    const updateMapperValue = (fieldId: string, fieldName: string, value: ColumnRef) => {
        const newMappers: Mapper[] = [];

        let found = false;

        parsedConfig.mappers.forEach(m => {
            if (m.output_name === fieldName) {
                found = true;
                m = {
                    ...m,
                    column_ref: value,
                };
            }

            newMappers.push(m);
        });

        if (!found) {
            newMappers.push({
                output_id: fieldId,
                output_name: fieldName,
                column_ref: value,
            });
        }
        props.onChange('mappers', newMappers);
    }

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Output Business Object</Form.Label>
                <BusinessObjectSelector
                    selectedId={parsedConfig.business_object_id}
                    disabled={!props.allowInteractivity}
                    onSelect={(bo) => props.onChange('business_object_id', bo ? bo.id : '')}
                />
                
                <Form.Text className="text-muted">
                    Select the business object you want this pipeline to generate. This affects the fields you are able to map to.
                </Form.Text>
            </Form.Group>
            {currentBusinessObject && (
                <Form.Group>
                    <Form.Label>Field Mappings</Form.Label>
                    <div style={{maxHeight: "75vh", overflowX: "scroll"}}>
                    <table className="table table-bordered mw-100 table-fixed">
                        <tbody>
                            {currentBusinessObject.fields.map(f => {
                                const mapperForThisField = parsedConfig.mappers.find(m => m.output_id === f.id || m.output_name === f.name);

                                let columnRef: ColumnRef;

                                if (!!mapperForThisField) {
                                    if (!!mapperForThisField.column_ref) {
                                        columnRef = mapperForThisField.column_ref;
                                    } else {
                                        columnRef = {
                                            raw_sql: mapperForThisField.source_definition
                                        }
                                    }
                                } else {

                                    // Just so it compiles; need to dig into why this would happen
                                    columnRef = {
                                        raw_sql: '',
                                    };
                                }
                                return (
                                    <tr>
                                        <td>
                                            <Form.Label className="font-13">
                                                {f.name}
                                            </Form.Label>
                                                <ColumnRefForm
                                                    disabled={!props.allowInteractivity}
                                                    columnOptions={upstreamColumnOptions}
                                                    columnRef={columnRef}
                                                    onChange={(newRef: ColumnRef) => {
                                                        updateMapperValue(f.id as string, f.name, newRef);
                                                    }}
                                                />
                                            
                                            <Form.Text>
                                            {f.description}
                                            </Form.Text>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                            
                </Form.Group>

            )}
            
        </>
    )
    
}

export default OutputStep;