import React, { Component, createContext, useState, ReactNode } from "react";
import ApiService from "../api/api.service";



interface Props {
  children: ReactNode;
}

interface State {
}

export default class TenantService {
  private static instance: TenantService;

  public static getInstance(): TenantService {
    if (!TenantService.instance) {
        TenantService.instance = new TenantService();
    }

    return TenantService.instance;
  }

  constructor() {
  }

  public async getTenantRegistration() {
        // checks existence of tenant for authenticated user.
        const response = await ApiService.getInstance().request('GET', '/tenant-config/init');
        return response;
  }

}

