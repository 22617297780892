import { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { AuditLogEntry } from '@models/auditLogEntry';
import { timeAgo } from '@services/time.service';
import EventActorLabel from '@components/auditLog/partials/EventActorLabel.component';
import { addEmitHelpers } from 'typescript';
import ConfirmationModal from '@components/alert/ConfirmationModal.component';
import DefaultAuditLogEntry from './DefaultAuditLogEntry.component';
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import AsyncButton from '@components/button/AsyncButton.component';
import toast from "@services/toast.service";

interface Props {
    entry: AuditLogEntry;
}

interface State {
    confirmRevert: boolean;
    saving: boolean;
}

const MAX_LABEL = 25;


export default class EditRecordColumnEntry extends DefaultAuditLogEntry<State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            confirmRevert: false,
            saving: false
        }
    }

    confirmRevert() {
        this.setState({
            confirmRevert: true
        })
    }

    async doRevertColumnEdit() {
        this.setState({
            saving: true
        });

        const body = {
            transaction_id: this.props.entry.transaction_id,
            original_value: this.props.entry.meta['new_value'],
            data: {}
        };
        // @ts-ignore
        body.data[this.props.entry.meta['record_column']] = this.props.entry.meta['original_value'];
        const saveResp = await ApiService.getInstance().request('PUT', `/sources/${this.props.entry.source_id as string}/record-type/${this.props.entry.source_record_type_id as string}/record/${this.props.entry.model_id}/col/${this.props.entry.meta['record_column']}/revert`, body) as ListRecordsResponse<any>;
        this.setState({
            saving: false,
            confirmRevert: false
        });
        toast('info', 'Changes Reverted', 'Source records have been modified')
    }

    cancelConfirmRevert() {
        this.setState({
            confirmRevert: false
        })
    }

    renderConfirmMessage(record_column: string, edits_count: number, new_value: string, original_value: string) {
        const editForm = (
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>From</Form.Label>
                    <Form.Control value={new_value} type="text" disabled={true} />
                    {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>To</Form.Label>
                    <Form.Control value={original_value} type="text"  disabled={true} />
                    {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>
            </Form>
        )

        if (edits_count == 1) {
            return (
                <>
                    <p>Change {record_column}:  </p>
                    {editForm}
                </>
            )
        } 

        return (
            <>
                <p>Change {record_column} for {edits_count} record{(edits_count)?'s':''} <i className='mdi mdi-note-multiple-outline'></i> : </p>
                {editForm}
            </>
        )

    }

    render() {
        const affected_rows_count = (this.props.entry.meta['affected_rows_count']) ? this.props.entry.meta['affected_rows_count'] : 1;
        const record_type_name = this.props.entry.meta['record_type_name'] || 'Record'
        const record_label = (affected_rows_count > 1) ? `${record_type_name}s` : record_type_name;
        const record_column = this.props.entry.meta['record_column'];
        const original_value = this.props.entry.meta['original_value'];
        const new_value = this.props.entry.meta['new_value'];
        const original_label = (original_value.length > MAX_LABEL) ? `${original_value.substring(0, MAX_LABEL)}...` : original_value;
        const new_label = (new_value.length > MAX_LABEL) ? `${new_value.substring(0, MAX_LABEL)}...` : new_value;
        const edits_count = affected_rows_count.toLocaleString('us-EN');

        return (
            <div className="card h-100">
                <div className="card-body">
                    <Modal show={this.state.confirmRevert == true} onHide={() => this.cancelConfirmRevert()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Revert {(record_type_name)? record_type_name: 'this'} edit?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.renderConfirmMessage(record_column, edits_count, new_value, original_value)}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={() => this.cancelConfirmRevert()}>
                                Cancel
                            </Button>
                            <AsyncButton
                                icon="mdi mdi-content-save"
                                variant="warning"
                                text={`Edit ${edits_count} Record${(affected_rows_count>1)?'s':''}`}
                                loading={this.state.saving}
                                onClick={()=> this.doRevertColumnEdit()}
                            />
                        </Modal.Footer>
                    </Modal>
                    <div className='row'>
                        <div className="col-1 log-entry-icon">
                            <i className="mdi  mdi-table-edit"></i>
                        </div>
                        <div className="col-10">
                            <span className="d-inline-block">Column: <strong>{record_column}</strong> changed from</span> <strong title={original_value}>{original_label}</strong> to <strong title={new_value}>{new_label}</strong>< br/>
                            <EventActorLabel actor={this.props.entry.actor} /> edited {edits_count} {record_label} - <span title={this.props.entry.created_at.toLocaleString()}>{timeAgo(this.props.entry.created_at)}</span>
                        </div>
                        <div className="col-1">
                            <button className='btn' onClick={() => this.confirmRevert()} title="Revert this change"><i className="mdi mdi-undo-variant"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}