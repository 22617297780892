import BusinessObjectORM, { BusinessObject } from "@models/businessObject";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { useBusinessObjects } from "@stores/data.store";
import { useCallback, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface Props {
    selectedId: string;
    inline?: boolean;
    disabled?: boolean;
    allowCreate?: boolean;
    onSelect: (businessObject: BusinessObject|undefined) => void;
}

const BusinessObjectSelector = (props: Props) => {
    const businessObjects = useBusinessObjects();
    const [backgroundLoading, setBackgroundLoading] = useState(false);

    const options = businessObjects.data ? businessObjects.data.map(s => ({
        label: s.name,
        value: s.id as string,
    })) : [];

    const onSelect = useCallback(async (opt: any) => {
        if (!opt) {
            props.onSelect(undefined);
        } else if (opt.__isNew__) {
            setBackgroundLoading(true);
            try {
                const response = await BusinessObjectORM.save({
                    id: null,
                    fields: [],
                    name: opt.value,
                    description: '',
                })
                props.onSelect(response);
            } catch (err) {
                toast('danger', 'Oops!', getErrorMessage(err));
            } finally {
                setBackgroundLoading(false);
            }
        } else {
            props.onSelect(businessObjects.data?.find(b => b.id === opt.value))
        }
    }, [businessObjects.dataUpdatedAt]);

    let wrapperClass = '';
    if (props.inline) {
        wrapperClass = 'inline-form-control';
    }

    return <div className={wrapperClass}>
        {businessObjects.isLoading && (
            <Spinner size="sm"/>
        )}
        {!businessObjects.isLoading && (
            <>
                {props.allowCreate && (
                    <>
                        <CreatableSelect
                            isClearable
                            isDisabled={backgroundLoading}
                            placeholder="Select one or create new"
                            options={options}
                            value={options.find(o => o.value === props.selectedId)}
                            onChange={onSelect}
                        />
                        <Form.Text>
                            Try "Contacts", "Clients", or "Ad Campaigns".
                        </Form.Text>
                        {backgroundLoading && <Spinner size="sm"/>}
                    </>
                    
                )}
                {!props.allowCreate && (
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        isDisabled={!!props.disabled}
                        options={options} 
                        value={options.find(o => o.value === props.selectedId)}
                        onChange={onSelect}
                    />
                )}
            </>

            
            
        )}

    </div>
}

export default BusinessObjectSelector;