import ApiService from "./api/api.service";


const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export default class TenantConfigService {
  private static instance: TenantConfigService;

  public static getInstance(): TenantConfigService {
    if (!TenantConfigService.instance) {
      TenantConfigService.instance = new TenantConfigService();
    }

    return TenantConfigService.instance;
  }

  constructor() {
  }

  public async saveSnowflakeDetails(tenantConfigId: string, details: any) {
      return await ApiService.getInstance().request('PUT', `/tenant-config/${tenantConfigId}/snowflake-creds`, details);
  }

  public async verifySnowflakeAccess(tenantConfigId: string) {
      return await ApiService.getInstance().request('GET', `/tenant-config/${tenantConfigId}/verify-snowflake-creds`);
  }

  public async initInfrustructure(tenantConfigId: string) {
    return await ApiService.getInstance().request('POST', `/tenant-config/${tenantConfigId}/init-infrustructure`, {});
  }
}
