import PageTitle from "@components/pageTitle/PageTitle.component";
import SourceIcon from "@components/sources/SourceIcon.component";
import SourceRecordTypeName from "@components/sources/SourceRecordTypeName.component";
import { Source, SourceRecordType } from "@models/source";
import { StandardizationPipeline } from "@models/standardizationPipeline";
import { summarizeNumber } from "@services/formatting.service";
import { parseDateString } from "@services/time.service";
import { timeAgo } from "@services/time.service";
import { saveBusinessObject, useBusinessObjects, useSourceRecordTypes, useSources, useStandardizationPipelines } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Form, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Select from 'react-select';
import { useImmer } from "use-immer";
import { ConsolidationPipelineConfig, BusinessObjectField } from '@models/businessObject';
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import { ColumnRef } from "@models/shared";

interface SourceInfoProps {
    source?: Source;
    sourceRecordType?: SourceRecordType;
}

const SourceInfo = (props: SourceInfoProps) => {
    if (!props.source || !props.sourceRecordType) {
        return <>
            <Spinner size="sm"/>
        </>
    }
    return <>
        <h4 className="mb-0 pt-0 pb-1">
            {props.source.name}
        </h4>
        <span className="font-13 text-muted">
            {props.sourceRecordType.name}
        </span>
    </>
}


const BusinessObjectSourcesPage = () => {
    const { businessObjectId } = useParams();
    const businessObjects = useBusinessObjects();
    const standardizationPipelines = useStandardizationPipelines('', businessObjectId);
    const sources = useSources();
    const sourceRecordTypes = useSourceRecordTypes();

    const [sharedFields, setSharedFields] = useState<ColumnRef[]>([]);
    const [mergeUsingSharedFields, setMergeUsingSharedFields] = useState(false);
    const [fields, setFields] = useImmer<BusinessObjectField[]>([]);

    const businessObject = useMemo(() => {
        if (businessObjects.data) {
            return businessObjects.data.find(bo => bo.id === businessObjectId);
        }
    }, [businessObjectId, businessObjects.dataUpdatedAt]);

    const isFormValid = useMemo(() => {
        console.log('Checking validity', sharedFields);
        if (mergeUsingSharedFields && sharedFields.length === 0) {
            return false;
        }
        return true;
    }, [mergeUsingSharedFields, sharedFields])


    useEffect(() => {
        if (businessObject) {
            if (businessObject.consolidation_settings) {
                setSharedFields(businessObject.consolidation_settings?.shared_fields);
                setMergeUsingSharedFields(businessObject.consolidation_settings.merge_using_shared_fields);

            } else {
                setSharedFields([]);
                setMergeUsingSharedFields(false);
            }
            console.log('Business object changed. Updating fields')
            setFields([...businessObject.fields].sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                return 1;
            }));
        }
    }, [businessObject]);


    const columnOptions = useMemo(() => {
        if (!fields) {
            return [];
        }
        return fields.map(f => {
            return {
                label: f.name,
                value: f.name,
            }
        })
    }, [fields]);

    const updateSharedFields = useCallback((values: string[]) => {
        setSharedFields(values.map(v => {
            return {
                column_path: v,
            }
        }));
    }, [sharedFields]);


    // !!BUG - if they haven't yet run the upstream pipeline, pipeline_id here will be NULL, even if the standardization pipeline exists.
    const dataSourceOptions = useMemo(() => {
        if (!standardizationPipelines.data || !sources.data || !sourceRecordTypes.data) {
            return [];
        }

        return standardizationPipelines.data.map(sp => {
            const srt = sourceRecordTypes.data.find(s => s.id === sp.source_record_type_id);

            if (!srt) {
                return {
                    label: 'INVALID',
                    value: '',
                }
            }
            const source = sources.data.find(s => s.id === srt.source_id);
            if (!source) {
                return {
                    label: 'INVALID',
                    value: '',
                }
            }

            return {
                label: source.name + ' - ' + srt.name,
                value: sp.pipeline_id,
            }
        })
    }, [standardizationPipelines.dataUpdatedAt, sources.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt]);

    const updateFieldSourceOfTruth = useCallback((idx: number, pipelineId: string) => {
        console.log('Upading idx', idx, pipelineId);
        setFields(draft => {
            if (pipelineId) {
                draft[idx].source_pipeline_priority = [pipelineId];
            } else {
                draft[idx].source_pipeline_priority = [];
            }
        });
    }, [fields]);

    const [saving, setSaving] = useState(false);
    const save = useCallback(async () => {
        if (!businessObject) {
            return;
        }
        setSaving(true);
        try {
            console.log('fields', fields);
            await saveBusinessObject({
                ...businessObject,
                fields: [...fields],
                consolidation_settings: {
                    merge_using_shared_fields: mergeUsingSharedFields,
                    merge_using_shared_identifiers: false,
                    shared_fields: sharedFields,
                }
            });
            toast('success', 'Success', 'Configuration saved');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [businessObject, fields, mergeUsingSharedFields, sharedFields]);



    return <>
    
        
                <div className="content-inner has-subnav">
                    <div className="section">
                    <button className="float-right btn btn-success me-1" onClick={() => save()} disabled={saving || !isFormValid}>
                {saving && <Spinner size="sm"/>}
                Save Settings
            </button>
                    <h2>Consolidation Options</h2>
                    {standardizationPipelines.data && standardizationPipelines.data.length > 1 && (
                            <>
                                <Form.Group className="mt-3">
                                    <Form.Check checked={mergeUsingSharedFields} onChange={(e) => setMergeUsingSharedFields(!mergeUsingSharedFields)} type="switch" label="Merge records from different sources"/>
                                </Form.Group>
                                {mergeUsingSharedFields && (
                                    <>
                                        <Form.Group className="mt-3">
                                        <Form.Label>Combine Using</Form.Label>
                                        <Select
                                            options={columnOptions}
                                            isMulti
                                            value={columnOptions.filter(co => sharedFields.map(sf => sf.column_path).includes(co.value))}
                                            onChange={(e) => {
                                                if (!!e) {
                                                    updateSharedFields(e.map(c => c.value));
                                                } else {
                                                    updateSharedFields([]);
                                                }
                                            }}
                                        />
                                        <Form.Text >
                                            Records will be merged if they have the same value in all of these columns.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Attribute Resolution</Form.Label>
                                        
                                        <table className="table table-centered table-fixed">
                                            <thead className="table-light">
                                                <tr>
                                                    <th style={{width:'300px'}}>Attribute</th>
                                                    <th>Source of Truth</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fields.map((f, idx) => {
                                                    let selectedOption = '';
                                                    if (f.source_pipeline_priority && f.source_pipeline_priority.length > 0) {
                                                        selectedOption = f.source_pipeline_priority[0];
                                                    }
                                                    return <tr key={idx}>
                                                        <th>{f.name}</th>
                                                        <td>
                                                            <Select
                                                                placeholder="Use latest value"
                                                                options={dataSourceOptions}
                                                                value={dataSourceOptions.find(o => o.value === selectedOption)}
                                                                onChange={(e) => {
                                                                    console.log('Selected', e);
                                                                    updateFieldSourceOfTruth(idx, e ? (e.value as string) : '')
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </Form.Group>
                                  

                                    </>
                                    
                                )}
                            </>
                        )}
                        {(!standardizationPipelines.data || (standardizationPipelines.data.length === 1)) && (
                            <p className="text-muted">Add more sources to configure consolidation.</p>
                        )}
                    </div>
                </div>

            


        
    </>
}

export default BusinessObjectSourcesPage;