import { Option } from "@components/sources/SourceSelector.component";
import { BusinessObject } from "@models/businessObject";
import { Pipeline } from "@models/pipeline";
import { usePipelines } from "@stores/data.store";
import { Form, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';

interface Props {
    selectedIds: string[];
    disabled?: boolean;
    ignorePipelineIds?: string[];
    onChange: (pipelines: Pipeline[]) => void;
}

const PipelineSelector = (props: Props) => {
    const pipelines = usePipelines();

    const options = pipelines.data ? pipelines.data.map(s => ({
        label: s.name,
        value: s.id as string,
    })) : [];

    const onChange = (values: string[]) => {
        if (!pipelines.data) {
            return;
        }
        props.onChange(pipelines.data.filter(p => values.includes(p.id as string)));
    }

    let ignoreIds: string[] = !!props.ignorePipelineIds ? props.ignorePipelineIds : [];

    let piplinesOptions: any[] = [];
    if (pipelines && pipelines.data) {
        piplinesOptions = pipelines.data.map(p => {
            return {
                value: p.id as string,
                label: p.name as string
            };
        }).filter((op) => !ignoreIds.includes(op.value));
    }
    const pipelinesSelectValue = piplinesOptions.filter(o => props.selectedIds.includes(o.value));

    return <>
        {pipelines.isLoading && (
            <Spinner size="sm"/>
        )}
        {!pipelines.isLoading && (
            <Select
                isMulti
                value={pipelinesSelectValue}
                onChange={(e) => onChange(e.map(e => e.value))}
                options={piplinesOptions}
            ></Select>
        )}

    </>
}

export default PipelineSelector;