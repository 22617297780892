import { ChangeEvent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

interface Props {
    header: string;
    message: string;
    onConfirm?: () => void;
    onClose?: () => void;
    show: boolean;
    confirmationButtonVariant?: string;
    confirmationButtonText: string;
    typedValueExpectation?: string;
}

function ConfirmationModal({header, message, onConfirm, show, typedValueExpectation, onClose, confirmationButtonText, confirmationButtonVariant='success'}: Props) {
    const [typedValue, setTypedValue] = useState('');
    const confirm = () => {
        !!onConfirm && onConfirm();
        setTypedValue('');
    }

    const close = () => {
        setTypedValue('');
        !!onClose && onClose();
    }
    return (
        <Modal show={show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>

                {typedValueExpectation && (
                    <Form.Control placeholder="Name" value={typedValue} onChange={(e: ChangeEvent) => setTypedValue((e.target as HTMLInputElement).value)} type="text" size="lg"/>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
                <Button 
                    variant={confirmationButtonVariant}
                    disabled={!!typedValueExpectation && (typedValueExpectation as string !== typedValue)}
                    onClick={confirm}
                >{confirmationButtonText}</Button>
                
            </Modal.Footer>
        </Modal>
    );
    
}

export default ConfirmationModal;